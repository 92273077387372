/* eslint-disable no-unused-vars */

import { CreateUnitData, UpdateUnitData } from '@/services/units.types';
import { RootState } from '@/store/store.types';
import { UnitsState } from '@/store/types/units';
import { Module } from 'vuex';
import {
    sendCreateUnitRequest,
    sendDeleteUnitRequest,
    sendGetUnitsRequest,
    sendUpdateUnitRequest
} from '../../services/units';

function initialState(): UnitsState {
    return {
        units: null,
        newUnitEvents: []
    };
}

export default {
    namespaced: true,

    state: initialState(),

    actions: {
        getUnits({ dispatch }) {
            return sendGetUnitsRequest().then((units) => {
                dispatch('setUnits', units);

                return units;
            });
        },

        setUnits({ commit }, units) {
            commit('SET_UNITS', units);
        },

        createUnit({ dispatch }, unit: CreateUnitData) {
            return sendCreateUnitRequest(unit).then((response) => {
                dispatch('newUnitEvent');
                return response;
            });
        },

        updateUnit({ dispatch }, unit: UpdateUnitData) {
            return sendUpdateUnitRequest(unit).then((response) => {
                dispatch('newUnitEvent');
                return response;
            });
        },

        deleteUnit(context, id: number) {
            return sendDeleteUnitRequest(id);
        },

        async newUnitEvent({ commit, dispatch }) {
            dispatch('setUnits', null);
            commit('SET_NEW_UNIT_EVENT');
        }
    },

    mutations: {
        SET_UNITS(state, units) {
            state.units = units;
        },
        SET_NEW_UNIT_EVENT(state) {
            state.newUnitEvents = state.newUnitEvents.concat(1);
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<UnitsState, RootState>;
