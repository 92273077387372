import { UpdateStatutenData } from 'dexxter-types';
import { defineStore } from 'pinia';
import { reactive, ref, set, watch } from 'vue';
import { sendGetTaxesRequest, sendUpdateStatutenRequest } from '../../../services/tax';
import { ITaxViewModel } from '../../modules/taxes.types';
import { useGlobalStore } from '../global.store';

export const useTaxStore = defineStore('tax', () => {
    const tax = reactive<Record<number, ITaxViewModel | null | undefined>>({});

    const loadTax = async (year: number) => {
        set(tax, year, await sendGetTaxesRequest(year));
    };

    const globalStore = useGlobalStore();

    const updateStatuten = async (year: number, data: UpdateStatutenData) => {
        const result = await sendUpdateStatutenRequest(year, data);

        loadTax(year);

        return result;
    };

    const wasUserStudentInCurrentYear = ref(false);

    watch(
        tax,
        () => {
            const taxForGivenYear = tax[globalStore.selectedYear as number];

            if (taxForGivenYear) {
                wasUserStudentInCurrentYear.value =
                    taxForGivenYear.statuutQ1 === 'student' ||
                    taxForGivenYear.statuutQ2 === 'student' ||
                    taxForGivenYear.statuutQ3 === 'student' ||
                    taxForGivenYear.statuutQ4 === 'student';
            } else {
                wasUserStudentInCurrentYear.value = false;
            }
        },
        { deep: true, immediate: true }
    );

    return {
        updateStatuten,
        tax,
        loadTax,
        wasUserStudentInCurrentYear
    };
});
