import { EUCountries } from '@/config/constants';
import { Dayjs, OpUnitType, QUnitType } from 'dayjs';
import { isEmpty, isNil } from 'lodash';

export function onlyFailedPromises<T>(promise: PromiseSettledResult<T>): promise is PromiseRejectedResult {
    return promise.status === 'rejected';
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function getIndexOfItem(_: any, index: number): number {
    return index;
}
export const pipe =
    (...fns: any[]) =>
    // eslint-disable-next-line prettier/prettier, @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    (x: any) =>
        fns.reduce((v, f) => f(v), x);

export function clearFirstCountryCharactersFromPasteEvent(event: ClipboardEvent): string | undefined {
    return removeFirstCountryCharactersFromCustomerNumber(event.clipboardData!.getData('text'));
}

/**
 *
 * @param {string} companyNumber
 * @returns string
 */
export function removeFirstCountryCharactersFromCustomerNumber(companyNumber: string): string {
    let result = companyNumber.trim();

    for (const countryProposition of EUCountries) {
        if (countryProposition === companyNumber.slice(0, 2)) {
            result = companyNumber.slice(2);
        }
    }

    result = result.replaceAll('.', '');

    return result;
}

export function isPDFFile(fileURL: string): boolean {
    if (isNil(fileURL)) {
        throw new Error('Empty file url');
    }
    return fileURL.substr(fileURL.length - 3) === 'pdf' || fileURL.substr(fileURL.length - 3) === 'PDF';
}

export function getDiff(dayjsFrom: Dayjs, dayjsTo: Dayjs, unit?: QUnitType | OpUnitType): number {
    return Math.round(dayjsFrom.diff(dayjsTo, unit, true));
}

/**
 * NOT SUITABLE FOR NUMBERS!!
 * @deprecated
 * It is not suitable for numbers since isEmptyOrNil(1) === true
 */
export function isEmptyOrNil(value: string | null | undefined | any[]): value is undefined | null {
    return isNil(value) || isEmpty(value);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function toClipboard(this: any, text: string): void {
    navigator.clipboard.writeText(text);

    this.$notify({
        title: this.$i18n.t('general.copy_succesful.title'),
        text: this.$i18n.t('general.copy_succesful.content')
    });
}

export const removeFromObject = <T extends Record<string, any>>(obj: T, filter: (value: any) => boolean): T =>
    Object.fromEntries(Object.entries(obj).filter(([, v]) => filter(v))) as T;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emptyOrNil = (v: any | any[]): boolean => v == null || v === '' || (Array.isArray(v) && v.length === 0);
