import LoggedInLayout from '../layouts/LoggedInLayout.vue';

export const adminRoutes = [
    {
        path: '/admin',
        component: LoggedInLayout,
        meta: {
            title: 'pageTitles.admin',
            admin: true,
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'admin',
                redirect: {
                    name: 'reports-users'
                }
            },
            {
                path: 'reports/users',
                name: 'reports-users',
                // route level code-splitting
                component: () =>
                    import(
                        /* webpackChunkName: 'admin' */
                        '../views/admin/Userreport.vue'
                    ),
                meta: {
                    title: 'pageTitles.user_report'
                }
            },
            {
                path: 'manage-learningcenter',
                name: 'manageLearningCenter',
                // route level code-splitting
                component: () =>
                    import(
                        /* webpackChunkName: 'admin' */
                        '../views/admin/ManageLearningcenter.vue'
                    ),
                meta: {
                    title: 'pageTitles.learningcenter.manage_learningcenter'
                }
            },
            {
                path: 'add-learningcenteritem',
                name: 'add-learningcenteritem',
                // route level code-splitting
                component: () =>
                    import(
                        /* webpackChunkName: 'admin' */
                        '../views/admin/AddLearningcenteritem.vue'
                    ),
                meta: {
                    title: 'pageTitles.learningcenter.add_learningcenteritem'
                }
            },
            {
                path: 'edit-learningcenteritem/:id',
                name: 'edit-learningcenteritem',
                // route level code-splitting
                component: () =>
                    import(
                        /* webpackChunkName: 'admin' */
                        '../views/admin/AddLearningcenteritem.vue'
                    ),
                meta: {
                    title: 'pageTitles.learningcenter.edit_learningcenteritem'
                }
            },
            {
                path: 'community-questions-overview',
                name: 'communityQuestionsOverview',
                // route level code-splitting
                component: () =>
                    import(
                        /* webpackChunkName: 'admin' */
                        '../views/admin/CommunityQuestionsOverview.vue'
                    ),
                meta: {
                    title: 'pageTitles.community_overview'
                }
            },
            {
                path: 'users',
                name: 'users',
                // route level code-splitting
                component: () =>
                    import(
                        /* webpackChunkName: 'admin' */
                        '../views/admin/Users.vue'
                    ),
                meta: {
                    title: 'pageTitles.users'
                }
            },
            {
                path: 'user/:id',
                name: 'view-user',
                component: () =>
                    import(
                        /* webpackChunkName: 'admin' */
                        '../views/admin/User.vue'
                    ),
                props: true,
                meta: {
                    title: 'pageTitles.users'
                }
            },
            {
                path: 'add-notification',
                name: 'add-notification',
                // route level code-splitting
                component: () =>
                    import(
                        /* webpackChunkName: 'admin' */
                        '../views/AddNotification.vue'
                    ),
                meta: {
                    title: 'pageTitles.add_notification'
                }
            },
            {
                path: 'calendarevents',
                name: 'calendarevents',
                // route level code-splitting
                component: () =>
                    import(
                        /* webpackChunkName: 'admin' */
                        '../views/calendarevents/view/ManageCalendarevents.vue'
                    ),
                meta: {
                    title: 'pageTitles.manage_calendarevents'
                }
            },
            {
                path: 'marketing',
                name: 'marketing',
                // route level code-splitting
                component: () =>
                    import(
                        /* webpackChunkName: 'admin' */
                        '../views/admin/Marketing.vue'
                    ),
                meta: {
                    title: 'pageTitles.marketing'
                }
            }
        ]
    }
];
