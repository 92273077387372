/* eslint-disable no-unused-vars */

// --- Firestore ---
import { RootState } from '@/store/store.types';
import { TaxesState } from '@/store/types/taxes';
import { UpdateStatutenData } from 'dexxter-types';
import { Module } from 'vuex';
import {
    sendCreateNewBookyearRequest,
    sendEditDepositRequest,
    sendEditInventoryRequest,
    sendGetIncomeStatementRequest,
    sendGetPersonaltaxesStatementRequest,
    sendGetSocialcontributionsStatementRequest,
    sendGetTaxSettingsRequest,
    sendGetTaxesRequest,
    sendToggleTaxStatusRequest,
    sendUpdateStatutenRequest
} from '../../services/tax';

function initialState(): TaxesState {
    return {
        taxes: null,
        financialYears: []
    };
}
export default {
    namespaced: true,

    state: initialState(),

    actions: {
        getTaxes({ state, commit }, { year, force }: { year: number; force?: boolean }) {
            if (state.taxes && !force) {
                return Promise.resolve(state.taxes);
            }

            return sendGetTaxesRequest(year).then((response) => {
                commit('SET_TAXES', response);
                return response;
            });
        },
        getTaxSettings(context, { year }: { year: number }) {
            return sendGetTaxSettingsRequest(year);
        },
        getIncomeStatement(context, { year }: { year: number }) {
            return sendGetIncomeStatementRequest(year);
        },
        getSocialcontributionsStatement(context, { year }: { year: number }) {
            return sendGetSocialcontributionsStatementRequest(year);
        },
        getPersonaltaxesStatement(context, { year }: { year: number }) {
            return sendGetPersonaltaxesStatementRequest(year);
        },
        createNewBookyear(context, year: number) {
            return sendCreateNewBookyearRequest(year);
        },
        editDeposit(
            context,
            {
                deposit,
                quarter,
                year
            }: {
                deposit: number;
                quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4';
                year: number;
            }
        ) {
            return sendEditDepositRequest({ deposit, quarter, year });
        },
        editInventory(
            context,
            {
                inventory,
                year
            }: {
                inventory: { inventoryBegin: number; inventoryEnd: number };
                year: number;
            }
        ) {
            return sendEditInventoryRequest({ inventory, year });
        },
        toggleTaxStatus(context, year: number) {
            return sendToggleTaxStatusRequest(year);
        },
        updateStatuten(context, data: { year: number; statuten: UpdateStatutenData }) {
            return sendUpdateStatutenRequest(data.year, data.statuten);
        }
    },

    mutations: {
        SET_TAXES(state, taxes) {
            state.taxes = taxes;
        },
        SET_FINANCIAL_YEARS(state, financialYears) {
            state.financialYears = financialYears;
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<TaxesState, RootState>;
