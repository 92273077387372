import { DeepNullable } from '@/helpers/types';
import { BASE_URL_STRIPE } from '@/modules/subscription/infrastructure/product.repository';
import { IFeatureViewModel } from '@/services/auth.types';
import { generateEndpoint } from '@/services/config';
import { BASE_URL_SETTINGS } from '@/services/usersettings';
import { Billinginfo, Customerinvoice, ITokens, registrationData } from '@/store/modules/auth.types';
import axios from 'axios';
import {
    AccountantRegisterUserData,
    AuthViewModel,
    IPermissionViewModel,
    IUseraccountViewModel,
    completeRegistrationVakantiewoningVerhuurder,
    completeRegistrationWithCompanyData,
    completeRegistrationWithoutCompanyData
} from 'dexxter-types';

const BASE_URL = generateEndpoint('/api/auth');

export function sendLoginRequest(data: { email: string; password: string }): Promise<AuthViewModel> {
    return axios.post(`${BASE_URL}/login`, data).then((response) => response.data);
}

export function checkRedirectRegistrations(): Promise<boolean> {
    return axios
        .get(`${generateEndpoint('/api/globalSettings')}/redirect-registration`)
        .then((response) => response.data);
}

export function sendMigrateLoginRequest(data: { email: string; password: string }): Promise<AuthViewModel> {
    return axios.post(`${BASE_URL}/migrate-login`, data).then((response) => response.data);
}

export function sendLogoutRequest(refresh_token: string): Promise<void> {
    return axios.post(`${BASE_URL}/logout`, {
        refresh_token
    });
}

export function sendLogoutImpersonateRequest(refresh_token: string): Promise<void> {
    return axios.post(`${BASE_URL}/logout`, {
        refresh_token
    });
}

export function sendImpersonateRequest(uid: number): Promise<{ token: string }> {
    return axios.post(`${BASE_URL}/login/impersonate/${uid}`).then((response) => response.data);
}

export function sendSignupRequest(user: registrationData): Promise<{ id: number }> {
    return axios.post(`${BASE_URL}/signup`, user).then((response) => response.data);
}

export function sendGetBillinginfoRequest(): Promise<{
    invoices: Customerinvoice[];
    billinginfo: Billinginfo;
    subscription?: { id: string };
}> {
    return axios.get(`${BASE_URL_STRIPE}/userInformation`).then((response) => response.data);
}

export function sendGetInvoicesRequest(): Promise<{
    dexxter: Customerinvoice[];
    stripe: {
        /**
         * Unique identifier for the object.
         */
        id: string;

        /**
         * Time at which the object was created. Measured in seconds since the Unix epoch.
         */
        created: number;

        /**
         * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies).
         */
        currency: string;

        /**
         * An arbitrary string attached to the object. Often useful for displaying to users. Referenced as 'memo' in the Dashboard.
         */
        description: string | null;

        /**
         * The date on which payment for this invoice is due. This value will be `null` for invoices where `collection_method=charge_automatically`.
         */
        due_date: number | null;

        /**
         * Ending customer balance after the invoice is finalized. Invoices are finalized approximately an hour after successful webhook delivery or when payment collection is attempted for the invoice. If the invoice has not been finalized yet, this will be null.
         */
        ending_balance: number | null;

        /**
         * Footer displayed on the invoice.
         */
        footer: string | null;

        /**
         * The URL for the hosted invoice page, which allows customers to view and pay an invoice. If the invoice has not been finalized yet, this will be null.
         */
        hosted_invoice_url?: string | null;

        /**
         * The link to download the PDF for the invoice. If the invoice has not been finalized yet, this will be null.
         */
        invoice_pdf?: string | null;

        /**
         * A unique, identifying string that appears on emails sent to the customer for this invoice. This starts with the customer's unique invoice_prefix if it is specified.
         */
        number: string | null;

        /**
         * Whether payment was successfully collected for this invoice. An invoice can be paid (most commonly) with a charge or with credit from the customer's account balance.
         */
        paid: boolean;

        /**
         * Only set for upcoming invoices that preview prorations. The time used to calculate prorations.
         */
        subscription_proration_date?: number;

        /**
         * Total of all subscriptions, invoice items, and prorations on the invoice before any invoice level discount or tax is applied. Item discounts are already incorporated
         */
        subtotal: number;

        /**
         * The amount of tax on this invoice. This is the sum of all the tax amounts on this invoice.
         */
        tax: number | null;

        /**
         * Total after discounts and taxes.
         */
        total: number;
    }[];
}> {
    return axios.get(`${BASE_URL_STRIPE}/invoices`).then((response) => response.data);
}

export async function sendForgotpasswordRequest(email: string): Promise<void> {
    return axios.post(`${BASE_URL}/recover`, { email }).then((response) => response.data);
}

export async function sendResetpasswordRequest({
    resetPasswordToken,
    password
}: {
    resetPasswordToken: string;
    password: string;
}): Promise<{
    response: { status: number; data?: any };
}> {
    return axios.post(`${BASE_URL}/resetPassword`, { resetPasswordToken, password }).then((response) => response.data);
}

export function sendVerifyMailRequest(token: string): Promise<void> {
    return axios.post(`${BASE_URL}/verifyEmailadres`, { token }).then((response) => response.data);
}

export function sendResendVerifyMailRequest(): Promise<void> {
    return axios.post(`${BASE_URL}/resendEmailverificationEmail`).then((response) => response.data);
}

export function sendSetLanguageRequest(language: string): Promise<void> {
    return axios.put(`${BASE_URL_SETTINGS}/updateLanguage`, { language }).then((response) => response.data);
}

export function sendSetSocialSecretariatPercentageRequest(socialSecretariatInformation: {
    socialsecretariatId: number;
}): Promise<void> {
    return axios
        .put(`${BASE_URL_SETTINGS}/updateSocialsecretariatInformation`, socialSecretariatInformation)
        .then((response) => response.data);
}

export function sendSetbtwMedecontractantStatusRequest(value: boolean): Promise<void> {
    return axios
        .put(`${BASE_URL_SETTINGS}/changeBtwMedecontractant`, {
            btwMedecontractant: value
        })
        .then((response) => response.data);
}

export function sendSetVatLiableRequest(): Promise<void> {
    return axios.put(`${BASE_URL_SETTINGS}/vatLiable`).then((response) => response.data);
}

export async function sendChangePasswordRequest({
    oldPassword,
    newPassword
}: {
    oldPassword: string;
    newPassword: string;
}) {
    return axios
        .put(`${BASE_URL_SETTINGS}/changePassword`, {
            oldPassword,
            newPassword
        })
        .then((response) => response.data);
}

export async function sendChangeCompanyColorRequest(color: string): Promise<void> {
    return axios
        .put(`${BASE_URL_SETTINGS}/updateColor`, {
            color
        })
        .then((response) => response.data);
}

export function sendChangeLogoRequest(logo: string): Promise<void> {
    const formData = new FormData();
    formData.append('file', logo);

    return axios.put(`${BASE_URL_SETTINGS}/updateLogo`, formData).then((response) => response.data);
}

export function sendDownloadUserDataRequest(): Promise<void> {
    return axios.get(`${BASE_URL_SETTINGS}/generateCSVs`).then((response) => response.data);
}

export function sendSetIncomeCountersRequest(
    year: number,
    counters: { invoiceCounter: number; quotationCounter: number; creditnoteCounter: number }
): Promise<void> {
    return axios.put(`${BASE_URL_SETTINGS}/updateCounters/year/${year}`, counters).then((response) => response.data);
}

export function sendGetPermissionsRequest(): Promise<IPermissionViewModel[]> {
    return axios.get(`${BASE_URL}/permissions`).then((response) => response.data);
}

export function sendGetFeaturesRequest(): Promise<IFeatureViewModel[]> {
    return axios.get(`${BASE_URL}/features`).then((response) => response.data);
}

export function sendGetCurrentUserRequest(): Promise<IUseraccountViewModel> {
    return axios.get(`${BASE_URL}/currentUser`).then((response) => response.data);
}

export function sendRefreshAccessTokenRequest(refreshToken: string): Promise<ITokens> {
    return axios
        .post(`${BASE_URL}/refresh`, {
            refresh_token: refreshToken
        })
        .then((response) => response.data);
}

export function sendCompleteUserRegistrationWithoutCompany(
    registrationData: DeepNullable<completeRegistrationWithoutCompanyData>
) {
    return axios
        .post(`${BASE_URL}/completeUserRegistration-without-company`, registrationData)
        .then((response) => response.data);
}

export function sendCompleteUserRegistrationVerhuurVakantiewoningen(
    registrationData: DeepNullable<completeRegistrationVakantiewoningVerhuurder>
) {
    return axios
        .post(`${BASE_URL}/completeUserRegistration-vakantiewoningen`, registrationData)
        .then((response) => response.data);
}

export function sendCompleteUserRegistrationWithCompany(
    registrationData: DeepNullable<completeRegistrationWithCompanyData>
) {
    return axios.post(`${BASE_URL}/completeUserRegistration`, registrationData).then((response) => response.data);
}

export const sendAccountantCreateUserRequestPath = `${BASE_URL}/signup/accountant/register`;

export async function sendAccountantCreateUserRequest(data: DeepNullable<AccountantRegisterUserData>): Promise<void> {
    await axios.post(sendAccountantCreateUserRequestPath, data).then((response) => response.data);
}

export function sendCreateAccountantUserRequest(user: any, userCouplingToken: string | null) {
    return axios
        .post(`${BASE_URL}/signup/accountant`, {
            ...user,
            userCouplingToken: userCouplingToken ? userCouplingToken : undefined
        })
        .then((response) => response.data);
}
