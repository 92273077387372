import { BILLING_PLANS, Quarters, VatStatus, vatStatuses } from '@/config/constants';
import { IICSubmissionViewModel } from '@/services/viewmodels/general';
import { IVATSubmissionViewModel } from '@/views/VAT/models/models';

function throwErrorIfPassedVATStatusIsNotValid(vatStatus: VatStatus | undefined) {
    if (typeof vatStatus === 'string' && !Object.values(vatStatuses).includes(vatStatus)) {
        throw new Error(`VATStatus ${vatStatus} is not valid`);
    }
}

export function isVatLiable(vatStatus: VatStatus | undefined): boolean {
    throwErrorIfPassedVATStatusIsNotValid(vatStatus);

    return vatStatus === vatStatuses.VATLIABLE;
}

export function isNotVatLiable(vatStatus: VatStatus | undefined): boolean {
    throwErrorIfPassedVATStatusIsNotValid(vatStatus);

    return !isVatLiable(vatStatus);
}

export function mustUserHaveArticle56OnDocument(vatStatus: VatStatus | undefined): boolean {
    throwErrorIfPassedVATStatusIsNotValid(vatStatus);

    return isNotVatLiable(vatStatus);
}

export function isNonVatLiable(vatStatus: VatStatus | undefined): boolean {
    throwErrorIfPassedVATStatusIsNotValid(vatStatus);

    return vatStatus === vatStatuses.NOTVATLIABLE;
}

export function canVatTypeSubmitClientListing(vatStatus: VatStatus | undefined): boolean {
    throwErrorIfPassedVATStatusIsNotValid(vatStatus);

    return isVatLiable(vatStatus) || isNonVatLiable(vatStatus);
}

export function canVatTypeSubmitIcListing(vatStatus: VatStatus | undefined): boolean {
    throwErrorIfPassedVATStatusIsNotValid(vatStatus);

    return isVatLiable(vatStatus) || isNonVatLiable(vatStatus);
}

export function canUserSubmitVatListing(vatStatus: VatStatus | undefined): boolean {
    throwErrorIfPassedVATStatusIsNotValid(vatStatus);

    return vatStatus === vatStatuses.VATLIABLE;
}
/**
 *
 * @param {vatStatuses} VATLiableType
 * @returns
 */
export function canViewPersonalTaxRelatedElements(user: any): boolean {
    return user.userinformation.billinginfo.subscriptionType == BILLING_PLANS.PRO_TIER;
}

export function hasSubmittedVatListingForCurrentQuarter(
    submissions: IVATSubmissionViewModel[] | IICSubmissionViewModel[],
    currentQuarter: Quarters,
    currentYear: number
): boolean {
    return submissions.some(({ quarter, year }) => {
        const currentQuarterNumberString: string = currentQuarter.replace('Q', '');
        const parsedCurrentQuarterNumber: number = parseInt(currentQuarterNumberString);
        return quarter === parsedCurrentQuarterNumber && year === currentYear;
    });
}
