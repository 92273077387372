import Vue from 'vue';
import VideoModal from '@/components/Modal/VideoModal.vue';

export interface Action {
    action: (vm: Vue) => void;
    icon?: string;
    appendIcon?: string;
    i18nPath: string;
    disabled?: boolean;
    outlined?: boolean;
    color?: 'blue' | 'red';
    buttonState?: {
        loading?: boolean;
        disabled?: boolean;
    };
}

interface ErrorModalProps {
    key: string;
    i18nTitle?: string;
    i18nText: string;
    actions: Action[];
}

export const errorModalProps: ErrorModalProps[] = [
    {
        key: 'allPreviousQuartersMustBeClosed',
        i18nTitle: 'videotooltips.errors.allPreviousQuartersMustBeClosed.title',
        i18nText: 'videotooltips.errors.allPreviousQuartersMustBeClosed.text',
        actions: [
            {
                action: (vue) => renderVideoModal(vue, 'videotooltips.closeQuarter'),
                i18nPath: 'videotooltips.errors.allPreviousQuartersMustBeClosed.primary_button',
                icon: 'mdi-youtube'
            }
        ]
    },
    {
        key: 'bookyearNotCreated',
        i18nText: 'videotooltips.errors.bookyearNotCreated.text',
        actions: [
            {
                action: (vue) => renderVideoModal(vue, 'videotooltips.bookyear.createNewBookyear'),
                i18nPath: 'videotooltips.errors.bookyearNotCreated.primary_button',
                icon: 'mdi-youtube'
            }
        ]
    },
    {
        key: 'cannotUpdateExpenseCreatedWhenUserWasOtherVATType',
        i18nTitle: 'videotooltips.errors.cannotUpdateExpenseCreatedWhenUserWasOtherVATType.title',
        i18nText: 'videotooltips.errors.cannotUpdateExpenseCreatedWhenUserWasOtherVATType.text',
        actions: [
            {
                action: closeModal,
                i18nPath: 'videotooltips.errors.cannotUpdateExpenseCreatedWhenUserWasOtherVATType.primary_button'
            }
        ]
    },
    {
        key: 'cannotUpdateInvoicableCreatedWhenUserWasOtherVATType',
        i18nTitle: 'videotooltips.errors.cannotUpdateInvoicableCreatedWhenUserWasOtherVATType.title',
        i18nText: 'videotooltips.errors.cannotUpdateInvoicableCreatedWhenUserWasOtherVATType.text',
        actions: [
            {
                action: closeModal,
                i18nPath: 'videotooltips.errors.cannotUpdateInvoicableCreatedWhenUserWasOtherVATType.primary_button'
            }
        ]
    },
    {
        key: 'cannotDeleteInvoicableCreatedWhenUserWasOtherVATType',
        i18nTitle: 'videotooltips.errors.cannotDeleteInvoicableCreatedWhenUserWasOtherVATType.title',
        i18nText: 'videotooltips.errors.cannotDeleteInvoicableCreatedWhenUserWasOtherVATType.text',
        actions: [
            {
                action: closeModal,
                i18nPath: 'videotooltips.errors.cannotDeleteInvoicableCreatedWhenUserWasOtherVATType.primary_button'
            }
        ]
    },
    {
        key: 'quarterIsClosed',
        i18nTitle: 'videotooltips.errors.quarterIsClosed.title',
        i18nText: 'videotooltips.errors.quarterIsClosed.text',
        actions: [
            {
                action: (vue) => renderVideoModal(vue, 'videotooltips.closeQuarter'),
                i18nPath: 'videotooltips.errors.quarterIsClosed.primary_button',
                icon: 'mdi-youtube'
            }
        ]
    },
    {
        key: 'yearNotPresent',
        i18nText: 'videotooltips.errors.yearNotPresent.text',
        actions: [
            {
                action: (vue) => renderVideoModal(vue, 'videotooltips.bookyear.createNewBookyear'),
                i18nPath: 'videotooltips.errors.yearNotPresent.primary_button',
                icon: 'mdi-youtube'
            }
        ]
    }
];

export function readArticle(url: string): void {
    window.open(url, '_blank');
}

function renderVideoModal(vue: Vue, i18nPath: string): void {
    vue.$store.commit('modal/changeToNewModalPage', {
        component: VideoModal,
        props: { i18nPath }
    });
}

function closeModal(vue: Vue): void {
    vue.$store.commit('modal/setIsModalOpen', false);
}
