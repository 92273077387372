<template>
    <header class="bottom-sheet-header">
        <v-avatar v-if="icon" :color="avatarColor">
            <v-icon :color="iconColor">
                {{ icon }}
            </v-icon>
        </v-avatar>
        <h2 class="mr-auto"><slot /></h2>
        <submit-button v-if="showAdd" @click="$emit('new-item')">{{
            buttonText ? buttonText : $t('general.add')
        }}</submit-button>
    </header>
</template>

<script>
import SubmitButton from '../SubmitButton.vue';
export default {
    components: {
        SubmitButton
    },

    props: {
        avatarColor: {
            type: String,
            default: ''
        },
        iconColor: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        showAdd: {
            type: Boolean,
            default: false
        },
        buttonText: {
            type: String,
            default: null
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/sass/mixin';

header.bottom-sheet-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;

    @include respond-to('tablet-max') {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        div.v-avatar {
            margin-bottom: 2rem;
        }

        h2 {
            text-align: center;
        }
    }

    @include respond-to('desktop') {
        div.v-avatar {
            margin-right: 2rem;
        }
    }
}
</style>
