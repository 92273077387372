import store from '../../store';
import { routeRequiresAuth } from './helpers/index';
export function redirectIfUserIsDisabled(to, from, next) {
    const requiresAuth = routeRequiresAuth(to);

    if (requiresAuth) {
        const user = store.state.auth.currentUserData;

        // Disabled user should be redirected
        if (isUserDisabled(user) && to.path != '/user-disabled') {
            return next({ name: 'userDisabled' });
        }
    }

    return next();
}

function isUserDisabled(user) {
    return user.userinformation.disabled === true;
}
