export const generateEndpoint = (relativePath: string): string => {
    if (process.env.VUE_APP_MODE === 'production') {
        return `https://heroku-api.dexxter.be${relativePath}`;
    }

    return relativePath;
};

export const DEXXTER_CORE_API_ENDPOINT =
    process.env.VUE_APP_MODE === 'production' ? `https://api-v2.dexxter.be` : process.env.VUE_APP_DEXXTER_CORE_URL;
