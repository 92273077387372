
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
export default Vue.extend({
    name: 'ImpersonatingUserBadge',
    props: {
        currentUserData: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState('auth', ['isUserImpersonating'])
    },
    methods: {
        ...mapActions('auth', ['logout'])
    }
});
