import { generateEndpoint } from '@/services/config';
import { initializePaginationVars } from '@/store/utils';
import axios from 'axios';
import { IAccountingExpenseViewModel, ICreateAccountingExpenseData } from 'dexxter-types';
import { IUpdateAccountingExpenseData } from '@/services/accountingexpenses.types';
import { PaginationResponse } from '@/common/types';

const BASE_URL = generateEndpoint('/api/accountingexpenses');

export function getExpensesSum(year: number, group: string): Promise<Record<number, { total: number }>> {
    return axios
        .get(`${BASE_URL}/grouped/year/${year}`, {
            params: {
                group
            }
        })
        .then((response) => response.data);
}

export function bookExpense(
    expense: ICreateAccountingExpenseData & { id: number }
): Promise<IAccountingExpenseViewModel> {
    return axios.post(`${BASE_URL}/book/${expense.id}`, expense).then((response) => response.data);
}

export function getTotalAccountingCost(year: number): Promise<{ total: number }> {
    return axios.get(`${BASE_URL}/totalcost/year/${year}`).then((response) => response.data);
}

export function getExpense(id: number): Promise<IAccountingExpenseViewModel> {
    return axios.get(`${BASE_URL}/${id}`).then((response) => response.data);
}

export function createExpense(expense: ICreateAccountingExpenseData): Promise<IAccountingExpenseViewModel> {
    return axios.post(`${BASE_URL}`, expense).then((response) => response.data);
}

export function updateExpense({
    id,
    expense
}: {
    id: number;
    expense: IUpdateAccountingExpenseData;
}): Promise<IAccountingExpenseViewModel> {
    return axios.put(`${BASE_URL}/${id}`, expense).then((response) => response.data);
}

export function getExpenses(
    year: number,
    options: Record<string, any>
): Promise<PaginationResponse<IAccountingExpenseViewModel>> {
    return axios
        .get(`${BASE_URL}/year/${year}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}
