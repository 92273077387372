import VCurrencyField from 'v-currency-field';
import Vue from 'vue';
import en from 'vuetify/src/locale/en';
import fr from 'vuetify/src/locale/fr';
import nl from 'vuetify/src/locale/nl';
import Vuetify from 'vuetify/lib';
import { getStartingLocale } from '@/i18n';

Vue.use(Vuetify);

Vue.use(VCurrencyField, {
    locale: 'nl-NL',
    decimalLength: 2,
    autoDecimalMode: false,
    min: null,
    max: null,
    distractionFree: false,
    defaultValue: null
});

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
        values: {}
    },
    theme: {
        themes: {
            light: {
                primary: '#335EEA',
                secondary: '#28CCFF',
                textGrey: '#6e84a3',
                dexDark: '#170040',
                accent: '#5c02ff',
                error: '#FF5252',
                info: '#45E8FF',
                success: '#38FFA7',
                warning: '#FFBC2B',
                white: '#FFFFFF'
            },
            dark: {
                primary: '#28CCFF',
                secondary: '#21005C',
                accent: '#5c02ff',
                error: '#FF5252',
                info: '#45E8FF',
                success: '#38FFA7',
                warning: '#FFBC2B'
            }
        }
    },
    lang: {
        locales: { nl, fr, en },
        current: getStartingLocale()
    }
});
