import { assertUnreachable } from '@/helpers/typeHelper';
import { getAccountingCreditnote } from './accountingcreditnotes';
import { getAccountingInvoice } from './accountinginvoice';
import { generateEndpoint } from './config';
import { getHTMLRepresentationOfCreditnote } from './creditnote';
import { getHTMLRepresentationOfInvoice } from './invoice';
import { getHTMLRepresentationOfQuotation } from './quotation';

export const BASE_URL_INVOICES = generateEndpoint(`/api/invoices`);
export const BASE_URL_CREDITNOTES = generateEndpoint(`/api/creditnotes`);
export const BASE_URL_QUOTATIONS = generateEndpoint(`/api/quotations`);
export const BASE_URL_DAILYRECEIPTS = generateEndpoint(`/api/dailyreceipteincomes`);
export const BASE_URL_JOBSTUDENT = generateEndpoint(`/api/jobstudentincome`);

export async function getAccountingInvoicablelById({ id, type }: { id: number; type: 'invoice' | 'creditnote' }) {
    if (type === 'invoice') {
        return getAccountingInvoice(id);
    } else if (type === 'creditnote') {
        return getAccountingCreditnote(id);
    }

    assertUnreachable(type);
}

export function getHtmlRepresentation(id: number, type: 'invoice' | 'quotation' | 'creditnote'): Promise<string> {
    if (type === 'invoice') {
        return getHTMLRepresentationOfInvoice(id);
    } else if (type === 'quotation') {
        return getHTMLRepresentationOfQuotation(id);
    } else if (type === 'creditnote') {
        return getHTMLRepresentationOfCreditnote(id);
    }

    throw new Error('Invalid type');
}
