import {
    companyNumber,
    dateMaxFuture1Year,
    dateOnlyInCurrentOrPreviousYears,
    emailValidator,
    fileTypeInvoicable,
    fileTypePdf,
    IBANnumber,
    isValidPhone,
    isValidWebsite,
    logoTypeInvoicable,
    maxFileSizeUpload,
    minLength,
    noDateInPast,
    numberBetween6162,
    numberValidator,
    numberValidatorEmptyStringAllowed,
    percentageValidator,
    positiveNumberGreaterThanZeroValidator,
    positiveNumberValidator,
    positiveNumberValidatorEmptyStringAllowed,
    positiveNumberValidatorMayBeEmpty,
    required,
    securePassword,
    selectRequired,
    timeregistrationDurationRules,
    validYear,
    xmlFile,
    yearNoFuture
} from '@/helpers/validationRules.js';

export const formRulesMixin = {
    data() {
        return {
            // --- General ---
            requiredRules: [required],
            validYearRules: [validYear],
            numberRules: [required, numberValidator, positiveNumberValidator],
            numberRulesNotRequired: [positiveNumberValidatorEmptyStringAllowed],
            numberRulesNotRequiredNegativePossible: [numberValidatorEmptyStringAllowed],
            numberRulesRequiredNegativePossible: [required, numberValidatorEmptyStringAllowed],
            onlyPositiveNumber: [required, positiveNumberValidatorEmptyStringAllowed],
            financialYearInPast: [required, yearNoFuture],
            numberRulesGreaterThanZero: [required, numberValidator, positiveNumberGreaterThanZeroValidator],
            selectRequiredRules: [selectRequired],
            // --- Date rules ---
            dateRules: [required],
            dateNoPastRules: [required, noDateInPast],
            dateNoFutureRules: [required, dateOnlyInCurrentOrPreviousYears],
            dateMax1YearInFutureRules: [required, dateMaxFuture1Year],
            dateNoPastAndMax1YearInFutureRules: [required, noDateInPast, dateMaxFuture1Year],
            // --- Entry rules ---
            invoiceNumberRules: [required],
            descriptionRules: [required],
            descriptionRulesNoRequired: [],

            urlRules: [isValidWebsite],
            validPhone: [isValidPhone],
            // --- Account rules ---
            employerRules: [required],
            nameRules: [required],
            emailRules: [required, emailValidator],
            emailNotRequiredRules: [emailValidator],
            percentagesRules: [required, percentageValidator],
            percentagesWithoutRequiredRules: [percentageValidator],
            companyNumberRules: (country) => [required, companyNumber(country)],
            companyNumberRulesNotRequired: (country) => [companyNumber(country)],
            IBANRules: [required, IBANnumber],
            IBANRulesNotRquired: [IBANnumber],
            // --- File rules ---
            pdfFileRules: [maxFileSizeUpload, fileTypePdf],
            invoicableFileRules: [maxFileSizeUpload, fileTypeInvoicable],
            uploadXMLRules: [required, maxFileSizeUpload, xmlFile],
            logoRules: [maxFileSizeUpload, logoTypeInvoicable],
            // --- Url rules ---
            // --- invoice rules ---
            discountRules: [positiveNumberValidatorMayBeEmpty],
            // --- ExpenseCategoryRules
            expenseCategoryNumberRules: [numberBetween6162],
            passwordRules: [required, securePassword],

            timeRangeRules: [required, timeregistrationDurationRules]
        };
    },

    methods: {
        minRules(length) {
            return [minLength(length)];
        }
    }
};
