import { getField, updateField } from 'vuex-map-fields';
import { Module } from 'vuex';
import { RootState } from '@/store/store.types';
import { ExpenseFilesState } from '@/store/types/expensefiles';

function initialState(): ExpenseFilesState {
    return {
        expenseFileIdsUserWantsToProcess: [],
        totalExpenseFileIdsUserWantsToProcess: 0
    };
}

export default {
    namespaced: true,

    state: initialState(),

    getters: {
        getField
    },

    actions: {
        setToProcessExpenseFiles({ commit }, expenseFiles) {
            commit('SET_EXPENSE_FILES_TO_PROCESS', expenseFiles);
        },
        skipCurrentProcessExpenseFile({ commit, state }) {
            if (state.expenseFileIdsUserWantsToProcess.length <= 1) {
                return commit('SET_EXPENSE_FILES_TO_PROCESS', []);
            }

            commit('SKIP_CURRENT_EXPENSE_FILES_TO_PROCESS');
        },
        markCurrentProcessExpenseFileAsHandled({ commit, state }) {
            if (state.expenseFileIdsUserWantsToProcess.length <= 1) {
                return commit('SET_EXPENSE_FILES_TO_PROCESS', []);
            }

            commit('MARK_CURRENT_EXPENSE_FILES_TO_PROCESS_AS_HANDLED');
        }
    },

    mutations: {
        updateField,
        SET_EXPENSE_FILES_TO_PROCESS(state, expenseFiles) {
            state.expenseFileIdsUserWantsToProcess = expenseFiles;
            state.totalExpenseFileIdsUserWantsToProcess = expenseFiles.length === 0 ? 0 : expenseFiles.length;
        },
        SKIP_CURRENT_EXPENSE_FILES_TO_PROCESS(state) {
            state.expenseFileIdsUserWantsToProcess = state.expenseFileIdsUserWantsToProcess.slice(
                1,
                state.expenseFileIdsUserWantsToProcess.length
            );
        },
        MARK_CURRENT_EXPENSE_FILES_TO_PROCESS_AS_HANDLED(state) {
            state.expenseFileIdsUserWantsToProcess = state.expenseFileIdsUserWantsToProcess.slice(
                1,
                state.expenseFileIdsUserWantsToProcess.length
            );
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<ExpenseFilesState, RootState>;
