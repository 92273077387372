<template>
    <div>
        <v-list-item :to="{ name: 'accountantClients' }">
            <v-list-item-icon>
                <v-icon>mdi-file-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.clients_accounting') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'accountantDocumentCommunication' }">
            <v-list-item-icon>
                <v-icon>mdi-message</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('pageTitles.communication') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$can('READ', 'ManageInvitations')" :to="{ name: 'accountantInvitations' }">
            <v-list-item-icon>
                <v-icon>mdi-email-newsletter</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.invitations') }} ({{ pendingInvitations }})</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$can('READ', 'ManageAccountant')" :to="{ name: 'manageAccountants' }">
            <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.accountants') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-group no-action>
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Community</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'communityQuestions' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('pageTitles.community') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'createCommunityQuestion' }">
                <v-list-item-content>
                    <v-list-item-title>Stel een vraag</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'viewUserCommunityQuestion', params: { id: currentUserData.id } }">
                <v-list-item-content>
                    <v-list-item-title>Bekijk je vragen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>

        <v-divider class="px-4" />

        <v-subheader v-if="!isCollapsed">{{ $t('navigation.management').toUpperCase() }}</v-subheader>

        <v-list-group no-action>
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settings') }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'accountantSettings' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settingsMenu.general') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'accountantOfficeSettings' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settingsMenu.accountingOffice') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
    </div>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

// --- Mixins ---

export default {
    name: 'AccountantNavigationItems',
    mixins: [screenSizeMixin],
    props: {
        isCollapsed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapFields('users', ['selectedUsers']),
        ...mapState('accountant', ['pendingInvitations'])
    },
    async created() {
        try {
            await this.getNumberOfPendingInvitations();
        } catch (e) {
            apiErrorAndDisplay.call(this, e);
        }
    },
    methods: {
        ...mapActions('accountant', ['getNumberOfPendingInvitations'])
    }
};
</script>

<style lang="scss" scoped></style>
