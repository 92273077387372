
import dayjs from 'dayjs';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { NotificationbarViewModel } from 'dexxter-types';
import { getBrowserLanguage } from '@/helpers/language';
import { MapStateToComputed } from '@/helpers/types';
export default Vue.extend({
    data() {
        return {
            notificationbarInterval: null as null | ReturnType<typeof setInterval>
        };
    },

    computed: {
        ...(mapState('notifications', ['notificationbars']) as MapStateToComputed<{
            notificationbars: NotificationbarViewModel[];
        }>),

        currentNotificationbar() {
            if (this.notificationbars.length === 0) {
                return null;
            }

            const localStorageNotificationbars = localStorage.getItem('closedNotificationbars');

            const closedNotificationbars =
                localStorageNotificationbars === null ? [] : JSON.parse(localStorageNotificationbars);

            function getCurrentNotificationbar(notificationbars: NotificationbarViewModel[]) {
                const browserLanguage = getBrowserLanguage();

                let language = 'nl-BE';
                if (['nl-BE', 'fr', 'en'].includes(browserLanguage)) {
                    language = browserLanguage;
                }

                for (const notificationbar of notificationbars) {
                    if (
                        !closedNotificationbars.includes(notificationbar.id) &&
                        notificationbar.language.code === language &&
                        dayjs().isBefore(notificationbar.displayUntil)
                    ) {
                        return notificationbar;
                    }
                }

                return null;
            }

            const notificationbar = getCurrentNotificationbar(this.notificationbars);
            if (notificationbar) {
                return notificationbar;
            }

            return null;
        }
    },

    async created() {
        await this.getNotificationbars();

        this.notificationbarInterval = setInterval(async () => {
            await this.getNotificationbars();
        }, 100000);
    },

    methods: {
        ...mapActions('notifications', ['getNotificationbars']),

        closeNotificationbar() {
            if (!this.currentNotificationbar) {
                throw new Error('Cannot close notificationbar without notification');
            }

            const closedNotificationbarsLocalstorage = localStorage.getItem('closedNotificationbars');
            const closeNotificationbars =
                closedNotificationbarsLocalstorage === null ? [] : JSON.parse(closedNotificationbarsLocalstorage);

            localStorage.setItem(
                'closedNotificationbars',
                JSON.stringify([...closeNotificationbars, this.currentNotificationbar.id])
            );
        }
    }
});
