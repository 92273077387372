import { isImpersonating, isUserAccount } from '@/helpers/auth';
import store from '../../store';
import { routeRequiresAuth } from './helpers/index';
export async function setUserImpersonating(to, from, next) {
    const requiresAuth = routeRequiresAuth(to);

    if (requiresAuth) {
        const user = store.state.auth.currentUserData;

        if (isUserAccount(user)) {
            store.dispatch('auth/setUserImpersonating', await isImpersonating());
        }
    }

    return next();
}
