import { removeAllTokens, resetLoggingState } from '@/helpers/auth';
import { reportError } from '@/helpers/logging';
import store from '../../store';
import { routeRequiresAuth } from './helpers/index';

async function getUserDataIfNotExists() {
    if (store.state.auth.currentUserData == null) {
        // Make sure the current user is set in vuex, as other functions depend on the user id to get data.
        await Promise.all([store.dispatch('auth/getCurrentUser'), store.dispatch('auth/getPermissions')]);
    }
}

export async function fetchUserInfo(to, from, next) {
    try {
        const requiresAuth = routeRequiresAuth(to);

        if (requiresAuth) {
            await getUserDataIfNotExists();
        }

        next();
        return;
    } catch (e) {
        reportError(e);
    } finally {
        store.dispatch('stopLoading');
    }

    removeAllTokens();
    resetLoggingState();
    next({ name: 'login' });
}
