export const segmentMixin = {
    data() {
        return {
            segments: [
                {
                    label: 'Allemaal',
                    value: 'ALL'
                },
                {
                    label: 'Students',
                    value: 'STUDENTS'
                },
                {
                    label: 'Bijberoepers',
                    value: 'SECONDARY'
                },
                {
                    label: 'Boekhouders',
                    value: 'ACCOUNTANTS'
                },
                {
                    label: 'BTW plichtig',
                    value: 'VATLIABLE'
                },
                {
                    label: 'Niet BTW plichtig',
                    value: 'NOTVATLIABLE'
                }
            ],
            segmentsCalendarevents: [
                {
                    label: 'Allemaal',
                    value: 'ALL'
                },
                {
                    label: 'Btw-plichtig',
                    value: 'VATLIABLE'
                },
                {
                    label: 'Btw-vrijgesteld',
                    value: 'NOTVATLIABLE'
                },
                {
                    label: 'Niet btw-plichtig',
                    value: 'VATEXEMPT'
                }
            ],
            operators: [
                {
                    label: 'AND',
                    value: 'AND'
                },
                {
                    label: 'OR',
                    value: 'OR'
                }
            ]
        };
    }
};
