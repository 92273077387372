<template>
    <base-bottom-sheet
        v-if="isAddNewBookyearOpen"
        v-model="isAddNewBookyearOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
        class="add-income-billed-sheet"
    >
        <template #header>
            <bottom-sheet-header>
                {{ $t('general.create_new_bookyear') }}
            </bottom-sheet-header>
        </template>
        <template #content>
            <v-form ref="form" v-model="valid">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6" class="pt-0 pb-0" data-select-container="selectBookyear">
                            <v-select
                                v-model="selectedYear"
                                :items="possibleYears"
                                :label="$t('accountantTerms.boekjaar')"
                                outlined
                                dense
                                hide-details
                            />
                        </v-col>

                        <v-col cols="12" md="12">
                            <submit-button
                                data-action="submit-create-bookyear"
                                color="primary"
                                :disabled="!valid"
                                @click="handleSubmit"
                            >
                                {{ $t('general.create') }}
                            </submit-button>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>

            <loader v-if="loading" />
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
// --- Components ---
import BottomSheetHeader from './BottomSheetHeader';
import Loader from '../Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Constants ---
// --- Helper ---
import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import dayjs from 'dayjs';
import SubmitButton from '../SubmitButton.vue';

export default {
    components: {
        BottomSheetHeader,
        Loader,
        SubmitButton
    },

    mixins: [formRulesMixin],

    data() {
        return {
            valid: true,
            loading: false,
            selectedYear: null,
            possibleYears: [dayjs().year() + 1, dayjs().year(), dayjs().year() - 1]
        };
    },

    computed: {
        ...mapFields('bottomSheets', ['isAddNewBookyearOpen'])
    },

    methods: {
        ...mapActions('taxes', ['createNewBookyear']),
        ...mapActions(['setSelectedYear']),
        async handleSubmit() {
            try {
                this.loading = true;

                await this.createNewBookyear(this.selectedYear);

                notify.call(this, {
                    title: this.$t('general.succesfuly_created'),
                    text: this.$t('general.succesfuly_created')
                });

                // Close bottom sheet when submit successful.
                this.setSelectedYear(this.selectedYear);
                window.location.reload();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
