<template>
    <div class="d-flex align-center">
        <slot />
        <tooltip :underlined="false" :i18n-path="i18nPath || null" :title="title || null" :content="content || null"
            ><template slot="activator"
                ><v-icon :class="{ small: small, normal: !small }">mdi-information-outline</v-icon></template
            ></tooltip
        >
    </div>
</template>

<script>
export default {
    props: {
        i18nPath: {
            type: String
        },
        title: {
            type: String
        },
        content: {
            type: String
        },
        small: {
            type: Boolean,
            default: false
        },
        activate: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped>
.small {
    width: none;
    height: none;
    padding-left: 5px;
}
.normal {
    width: 44px;
    height: 44px;
}
</style>
