import Vue from 'vue';
import {
    addEuroSign,
    addPercentSign,
    capitalizeFirstLetter,
    formatDate,
    formatDateCustom,
    formatDateUnix,
    formatTimestamp,
    numericFormat,
    removeHTMLTags,
    sanitize,
    toLowerCase,
    truncate
} from '.';

Vue.filter('numericFormat', numericFormat);
Vue.filter('addEuroSign', addEuroSign);
Vue.filter('addPercentSign', addPercentSign);
Vue.filter('formatDate', formatDate);
Vue.filter('formatDateCustom', formatDateCustom);
Vue.filter('formatDateUnix', formatDateUnix);
Vue.filter('truncate', truncate);
Vue.filter('formatTimestamp', formatTimestamp);
Vue.filter('santizeHTML', sanitize);
Vue.filter('removeHTMLTags', removeHTMLTags);
Vue.filter('toLowerCase', toLowerCase);
Vue.filter('capitalizeFirstLetter', capitalizeFirstLetter);
