
import Vue, { PropOptions } from 'vue';

import { isAccountant, isAdmin, isLiteTier } from '@/helpers/auth';

import AccountantNavigationItems from '@/components/Navigation/AccountantNavigationItems.vue';
import AdminNavigationItems from '@/components/Navigation/AdminNavigationItems.vue';
import LiteTierNavigationItems from '@/components/Navigation/LiteTierNavigationItems.vue';
import NavigationItems from '@/components/Navigation/NavigationItems.vue';

import { IUseraccountViewModel } from 'dexxter-types';

export default Vue.extend({
    name: 'SideNavigation',
    components: {
        AccountantNavigationItems,
        AdminNavigationItems,
        LiteTierNavigationItems,
        NavigationItems
    },
    props: {
        currentUserData: {
            required: true
        } as PropOptions<IUseraccountViewModel>,
        isSideNavCollapsed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        switchNavigationItems() {
            if (isAccountant(this.currentUserData)) {
                return AccountantNavigationItems;
            } else if (isAdmin(this.currentUserData)) {
                return AdminNavigationItems;
            } else if (isLiteTier(this.currentUserData)) {
                return LiteTierNavigationItems;
            } else {
                return NavigationItems;
            }
        }
    },
    methods: {}
});
