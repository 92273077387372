import {
    Notification,
    NotificationusersettingsViewModel,
    UpdateNotificationSettingData
} from '@/services/notifications.types';
import axios from 'axios';
import { NotificationbarViewModel } from 'dexxter-types';
import { generateEndpoint } from './config';

export const BASE_URL_NOTIFICATIONS = generateEndpoint(`/api/notifications`);
export const BASE_URL_NOTIFICATION_QUEUE = generateEndpoint(`/api/notificationqueue`);

export function getAllNotifications(): Promise<Notification[]> {
    return axios.get(`${BASE_URL_NOTIFICATIONS}`).then((response) => response.data);
}

export function setNotificationsSeen(): Promise<void> {
    return axios.put(`${BASE_URL_NOTIFICATIONS}/seen`).then((response) => response.data);
}

export function getAllNotificationSettings(): Promise<NotificationusersettingsViewModel[]> {
    return axios.get(`${BASE_URL_NOTIFICATIONS}/settings`).then((response) => response.data);
}

export function updateNotificationSettingsRequest(
    id: number,
    data: UpdateNotificationSettingData
): Promise<NotificationusersettingsViewModel> {
    return axios.put(`${BASE_URL_NOTIFICATIONS}/settings/${id}`, data).then((response) => response.data);
}

export function sendGetAllNotificationbarsRequest(): Promise<NotificationbarViewModel[]> {
    return axios.get(`${BASE_URL_NOTIFICATIONS}/notificationbars`).then((response) => response.data);
}

export function sendGetHasNewNotificationRequest(): Promise<boolean> {
    return axios.get(`${BASE_URL_NOTIFICATIONS}/hasNewNotification`).then((response) => response.data);
}
