import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import { storeConfig } from './config';
import { RootState } from '@/store/store.types';

Vue.use(Vuex);

const store = new Vuex.Store(storeConfig);

export default store;

export const useStore = (): Store<RootState> => store;
