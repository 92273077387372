import { PaginationResponse } from '@/common/types';
import {
    IAccountingInvoiceViewModel,
    ICreateAccountingInvoiceData,
    ICreateHistoricalAccountingInvoiceData
} from '@/services/accountinginvoice.types';
import { generateEndpoint } from '@/services/config';
import { initializePaginationVars } from '@/store/utils';
import axios from 'axios';

const BASE_URL = generateEndpoint('/api/invoices/accounting');

export function getAccountingInvoice(invoiceID: number): Promise<IAccountingInvoiceViewModel> {
    return axios.get(`${BASE_URL}/${invoiceID}`).then((response) => response.data);
}

export function bookInvoice(
    invoicable: ICreateAccountingInvoiceData & { id: number }
): Promise<IAccountingInvoiceViewModel> {
    return axios
        .post(`${BASE_URL}/book/${invoicable.id}`, {
            invoice: JSON.stringify(invoicable)
        })
        .then((response) => response.data);
}

export function bookHistoricalInvoice(
    invoicable: ICreateHistoricalAccountingInvoiceData & { id: number }
): Promise<IAccountingInvoiceViewModel> {
    return axios
        .post(`${BASE_URL}/book/historical/${invoicable.id}`, {
            invoice: JSON.stringify(invoicable)
        })
        .then((response) => response.data);
}

export function getAccountingInvoicesPagination(
    year: number,
    options: Record<string, any>
): Promise<PaginationResponse<IAccountingInvoiceViewModel>> {
    return axios
        .get(`${BASE_URL}/year/${year}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination, options.options),
                ...options.query
            }
        })
        .then((response) => response.data);
}
