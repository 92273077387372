import { BILLING_PLANS } from '@/config/constants';
import * as Sentry from '@sentry/vue';
import dayjs from 'dayjs';
import {
    IRoleViewModel,
    IUseraccountViewModel,
    JwtTokens,
    USER_ACCOUNT_TYPE,
    USER_TYPE,
    UserObjectTypeViewModels
} from 'dexxter-types';
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { sendRefreshAccessTokenRequest } from '../services/auth';
import { reportError } from './logging';
import { afterLogoutActions } from './logoutUser';
import { assertUnreachable } from './typeHelper';

export const impersonateTokenKey = 'impersonateToken';
export const authTokenKey = 'token';

// ==================== General ====================

export function isImpersonating(): boolean {
    try {
        const token = localStorage.getItem(impersonateTokenKey);

        if (!token) return false;

        return true;
    } catch (e) {
        return false;
    }
}

export async function checkIfUserIsLoggedIn(): Promise<boolean> {
    return isLoggedAsDexxterUser();
}

function isLoggedAsDexxterUser(): boolean {
    const tokens = localStorage.getItem(authTokenKey);
    if (!tokens) return false;
    const parsedTokens: JwtTokens[] = JSON.parse(tokens);
    if (Array.isArray(parsedTokens) && !parsedTokens[0]) {
        return false;
    }

    return Boolean(parsedTokens);
}

let refreshRequests: any[] = [];
let isRefreshingToken = false;

export async function refreshToken(): Promise<JwtTokens> {
    if (isRefreshingToken) {
        return new Promise((res, rej) => {
            return refreshRequests.push((error: any, value: any) => {
                if (error) {
                    return rej(error);
                }
                res(value);
            });
        });
    }

    isRefreshingToken = true;
    let jwtTokens: JwtTokens;
    try {
        const tokens = getCurrentToken();
        if (!tokens) {
            throw new Error('No token present');
        }
        if (isTokenExpired(jwtDecode<any>(tokens.refresh_token).exp)) {
            await cantRefreshToken();
        }

        jwtTokens = await sendRefreshAccessTokenRequest(tokens.refresh_token);

        removeToken();
        setToken(jwtTokens);

        // Call each of the queued requests
        refreshRequests.forEach((cb) => cb(null, jwtTokens));
        refreshRequests = [];
    } catch (e) {
        reportError(`Could not refresh token`);
        await cantRefreshToken();

        // Make typescript happy since the above function refreshes the page anyway
        return undefined as any;
    } finally {
        isRefreshingToken = false;
    }

    return jwtTokens;
}

export async function cantRefreshToken(): Promise<never> {
    try {
        await afterLogoutActions({
            impersonating: false
        });

        // Just wait a second before refreshing, since otherwise we will return to quickly and the rest of callers the code will execute
        await new Promise((res) => {
            setTimeout(() => {
                res(null);
            }, 1500);
        });
    } catch (e) {
        reportError(e);
        localStorage.removeItem(authTokenKey);
        window.location.reload();
    }

    return '' as never;
}

export function isTokenExpired(tokenExpiresAt: number): boolean {
    return new Date() > new Date(tokenExpiresAt * 1000);
}

export function doesTokenExpiredWithin(now: Date, tokenExpiresAt: number, seconds: number): boolean {
    return dayjs(now).isAfter(dayjs.unix(tokenExpiresAt).subtract(seconds, 'second'));
}

export function resetLoggingState(): void {
    Sentry.configureScope((scope) => scope.setUser(null));
}

export function getUserType(user: UserObjectTypeViewModels): USER_ACCOUNT_TYPE | null {
    if (user._type !== 'completedRegistrationUserAccount') {
        return null;
    }

    return user.userType;
}

export function isLiteTier(user: IUseraccountViewModel): boolean {
    return user.userinformation.billinginfo.subscriptionType === 'lite_tier';
}

export function isAdmin(user: UserObjectTypeViewModels): boolean {
    return hasUserRole(user, 'admin');
}

export function hasRole(roles: IRoleViewModel[], role: USER_TYPE): boolean {
    return isRoleArray(roles, role);
}

export function isAccountant(user: UserObjectTypeViewModels): boolean {
    return hasUserRole(user, 'accountant');
}

export function isUserAccount(user: UserObjectTypeViewModels): boolean {
    if (user._type !== 'completedRegistrationUserAccount') {
        return false;
    }

    const userType = getUserType(user);
    if (userType === 'main' || userType === 'secondary' || userType === 'student') {
        return true;
    } else if (userType === null) {
        return false;
    }

    assertUnreachable(userType);
}

export function isUserAccountWithFreeTier(user: IUseraccountViewModel): boolean {
    if (user._type !== 'completedRegistrationUserAccount') {
        return false;
    }

    return (
        isUserAccount(user) &&
        user.userinformation.billinginfo.subscriptionType === BILLING_PLANS.FREE_TIER &&
        !user.userinformation.billinginfo.isInTrial
    );
}

function hasUserRole(user: UserObjectTypeViewModels, role: USER_TYPE): boolean {
    if (user._type === 'registeredUser') {
        return false;
    } else if (user._type === 'completedRegistrationUserAccount') {
        return isRoleArray(user.userinformation.roles, role);
    }

    return isRoleArray(user.userinformation.roles, role);
}

function isRoleArray(roles: IRoleViewModel[], role: USER_TYPE): boolean {
    return (
        _.find(roles, (userRole) => {
            return userRole.name === role;
        }) !== undefined
    );
}

// ==================== Token ====================

export function removeAllTokens(): void {
    localStorage.removeItem(authTokenKey);
    localStorage.removeItem(impersonateTokenKey);
    localStorage.removeItem('selectedYear');
}

export function setToken(token: JwtTokens): void {
    localStorage.setItem(authTokenKey, JSON.stringify(token));
}

export function addImpersonateToken(token: string): void {
    localStorage.setItem(impersonateTokenKey, JSON.stringify(token));
}

export function getImpersonateToken(): string | null {
    try {
        const result = localStorage.getItem(impersonateTokenKey);

        return result ? JSON.parse(result) : null;
    } catch (e) {
        removeImpersonateToken();
        return null;
    }
}

export function removeToken(): void {
    localStorage.removeItem(authTokenKey);
}

export function removeImpersonateToken(): void {
    localStorage.removeItem(impersonateTokenKey);
}

export function getCurrentToken(): JwtTokens | null {
    try {
        const tokens = localStorage.getItem(authTokenKey);
        if (!tokens) return null;
        return JSON.parse(tokens);
    } catch (e) {
        localStorage.removeItem(authTokenKey);
        return null;
    }
}

export declare type HubCapsule = {
    channel: string;
    payload: HubPayload;
    source: string;
    patternInfo?: string[];
};
export declare type HubPayload = {
    event: string;
    data?: any;
    message?: string;
};
