/* eslint-disable no-unused-vars */

import { RootState } from '@/store/store.types';
import { DepreciationFichesState } from '@/store/types/depreciationfiches';
import { Module } from 'vuex';
import {
    sendCreateDepreciationficheRequest,
    sendGetAllDepreciationfichePaginationRequest,
    sendGetDepreciationficheRequest,
    sendUpdateDepreciationficheRequest
} from '../../services/depreciationfiche';
import { isNil } from 'lodash';
import { ICreateDepreciationficheData, IUpdateDepreciationficheData } from '@/services/depreciationfiche.types';

function initialState(): DepreciationFichesState {
    return {
        depreciationfichesEvents: []
    };
}

export default {
    namespaced: true,

    state: initialState(),

    actions: {
        async getDepreciationfichesPagination({ rootState }, options) {
            const year = rootState.selectedYear;

            if (isNil(year)) {
                throw new Error('Year is not defined');
            }

            return sendGetAllDepreciationfichePaginationRequest(year, options);
        },
        async getDepreciationficheByID(context, depreciationficheID: number) {
            return sendGetDepreciationficheRequest(depreciationficheID);
        },
        updateDepreciationfiche(context, { depreciationfiche }: { depreciationfiche: IUpdateDepreciationficheData }) {
            return sendUpdateDepreciationficheRequest(depreciationfiche);
        },
        createDepreciationfiche(context, { depreciationfiche }: { depreciationfiche: ICreateDepreciationficheData }) {
            return sendCreateDepreciationficheRequest(depreciationfiche);
        },
        newDepreciationficheEvent({ commit }) {
            commit('SET_NEW_DEPRECIATIONFICHE_EVENT');
        }
    },

    mutations: {
        SET_NEW_DEPRECIATIONFICHE_EVENT(state) {
            state.depreciationfichesEvents = state.depreciationfichesEvents.concat(1);
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<DepreciationFichesState, RootState>;
