<template>
    <v-dialog
        :value="value"
        :data-id="dataId"
        :max-width="maxWidth"
        v-bind="$attrs"
        scrollable
        @click:outside="dismiss"
    >
        <v-card :id="id">
            <v-card-title class="headline">
                {{ title }}
            </v-card-title>
            <v-card-text>
                <p class="preserveEnters" v-html="message" />
                <slot name="extraContent" />
            </v-card-text>
            <slot name="test" />
            <v-card-actions>
                <v-btn
                    v-if="neutralButton"
                    data-action="clickNeutral"
                    text
                    :color="neutralColor"
                    @click="
                        $emit('click-neutral');
                        $emit('clickNeutral');
                    "
                >
                    {{ neutralButton }}
                </v-btn>
                <v-spacer />
                <v-btn
                    v-if="negativeButton"
                    data-action="clickNegative"
                    text
                    :color="negativeColor"
                    @click="
                        $emit('click-negative');
                        $emit('clickNegative');
                    "
                >
                    {{ negativeButton }}
                </v-btn>
                <v-btn
                    v-if="positiveButton"
                    data-action="clickPositive"
                    :loading="loading"
                    text
                    :color="positiveColor"
                    @click="
                        $emit('click-positive');
                        $emit('clickPositive');
                    "
                >
                    {{ positiveButton }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { screenSizeMixin } from '../../mixins/screenSizeMixin';

export default {
    mixins: [screenSizeMixin],
    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        id: {
            type: String,
            required: false
        },
        dataId: {
            type: String,
            required: false
        },
        value: {
            type: Boolean,
            required: true
        },
        neutralButton: {
            type: String,
            default: null
        },
        negativeButton: {
            default: null
        },
        positiveButton: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        message: {
            type: String,
            default: null
        },
        neutralColor: {
            type: String,
            default: null
        },
        negativeColor: {
            type: String,
            default: null
        },
        positiveColor: {
            type: String,
            default: 'error'
        },
        loading: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        maxWidth() {
            if (this.isPhone) {
                return '500px';
            }

            return '640px';
        }
    },

    methods: {
        dismiss() {
            if (this.$attrs.persistent) {
                return;
            }

            this.$emit('input', false);
            this.$emit('click-outside');
        }
    }
};
</script>
<style lang="scss" scoped>
.headline {
    word-break: break-word;
    font-family: 'Cerebri Sans', sans-serif !important;
    font-weight: bold;
    color: $dexxter-dark;
}
</style>
