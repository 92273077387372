import { expenseTypeOfServiceType } from '../config/constants';

export function initCurrentExpenseCategory({
    id,
    VATDeductible,
    businessPercentage,
    parentId,
    potentialInvestement,
    deletable,
    name,
    number,
    forTypeOfService
}: {
    name?: string;
    id?: number;
    VATDeductible?: number;
    businessPercentage?: number;
    parentId?: number | null;
    deletable?: boolean;
    number?: number;
    forTypeOfService?: expenseTypeOfServiceType;
    potentialInvestement?: boolean;
} = {}) {
    return {
        name: name ?? null,
        id: id ?? null,
        VATDeductible: VATDeductible ?? null,
        businessPercentage: businessPercentage ?? null,
        deletable: deletable ?? null,
        parentId: parentId ?? null,
        number: number ?? null,
        forTypeOfService: forTypeOfService ?? null,
        potentialInvestement: potentialInvestement ?? null
    };
}
