import * as Sentry from '@sentry/vue';
import { UserObjectTypeViewModels, USER_ACCOUNT_TYPE, USER_TYPE } from 'dexxter-types';
import { NavigationGuardNext, Route } from 'vue-router';
import { isAccountant, isAdmin, isUserAccount } from '@/helpers/auth';
import store from '../../store';
import { routeRequiresAuth } from './helpers/index';

export function errorTracking(to: Route, from: Route, next: NavigationGuardNext) {
    const requiresAuth = routeRequiresAuth(to);
    if (requiresAuth) {
        const user = (store.state as any).auth.currentUserData;

        Sentry.setUser({
            email: user.userinformation.email,
            role: getUserInformation(user),
            billingStatus: isUserAccount(user) ? user.userinformation.billinginfo : undefined
        });
    }

    return next();
}

function getUserInformation(user: UserObjectTypeViewModels): USER_TYPE | USER_ACCOUNT_TYPE | null {
    if (isUserAccount(user)) {
        return 'useraccount';
    } else {
        if (isAdmin(user)) {
            return 'admin';
        } else if (isAccountant(user)) {
            return 'accountant';
        }
    }

    return null;
}
