var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:[
        'dxt-button',
        _vm.appearance,
        _vm.className,
        {
            clicked: _vm.clicked,
            'full-width': _vm.fullWidth
        }
    ],attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)},"animationend":_vm.handleAnimationEnd}},[(_vm.icon || _vm.loading)?_c('v-icon',{staticClass:"icon prepending",class:{ loading: _vm.loading }},[_vm._v(" "+_vm._s(_vm.prependingIcon)+" ")]):_vm._e(),_c('span',{staticClass:"text"},[_vm._t("default")],2),(_vm.appendIcon)?_c('v-icon',{staticClass:"icon appending"},[_vm._v(" "+_vm._s(_vm.appendIcon)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }