<template>
    <v-card class="Widget" outlined>
        <v-card-header>
            <template #title><slot name="title" /></template>
            <template #right> <slot name="right"></slot></template>
        </v-card-header>
        <div class="Widget_Content">
            <slot name="content"></slot>
        </div>
    </v-card>
</template>

<script>
import VCardHeader from './vCardHeader.vue';
export default {
    components: {
        VCardHeader
    },

    props: {
        icon: {
            type: String,
            default: ''
        }
    }
};
</script>

<style lang="scss" scoped>
.Widget {
    padding: 26px 26px 30px;

    &_IconTitleWrapper {
        display: flex;
    }

    &_Title {
        margin-bottom: 0.3rem;
        font-size: 0.875rem;
        font-weight: bold;
        text-transform: uppercase;
        color: $color-text-grey;
    }

    &_Icon {
        padding-right: 10px;
    }

    &_Content {
    }

    &_IconTitleWrapper {
    }
}
</style>
