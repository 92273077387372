import { newSupplier } from '@/models/supplier';
import { CreateSupplierData, UpdateSupplierData } from '@/services/suppliers.types';
import { SuppliersState } from '@/store/types/suppliers';
import { defineStore } from 'pinia';
import { filteringOptions } from '../../common/types';
import {
    sendCreateSupplierRequest,
    sendDeleteSupplierRequest,
    sendGetAllSuppliersPaginationRequest,
    sendSearchSupplierRequest,
    sendUpdateSupplierRequest
} from '../../services/suppliers';

function initialState(): SuppliersState {
    return {
        isAddSupplierOpen: false,
        isCreatingNewSupplier: false,
        newSupplierEvents: [],
        currentSupplier: newSupplier()
    };
}

export const useSupplierStore = defineStore('suppliers', {
    state: initialState,
    actions: {
        createSupplier(supplier: CreateSupplierData) {
            return sendCreateSupplierRequest(supplier);
        },
        closeAddSupplier() {
            this.isAddSupplierOpen = false;
        },
        resetCurrentSupplier() {
            this.currentSupplier = newSupplier();
        },
        newSupplier() {
            this.isCreatingNewSupplier = true;
            this.isAddSupplierOpen = true;
        },
        deleteSupplier(supplierId: number) {
            return sendDeleteSupplierRequest(supplierId);
        },
        getAllSuppliersPagination(options: filteringOptions) {
            return sendGetAllSuppliersPaginationRequest(options);
        },
        /**
         * @deprecated
         */
        searchSupplier(input: string) {
            return sendSearchSupplierRequest(input);
        },
        updateSupplier(supplier: UpdateSupplierData) {
            return sendUpdateSupplierRequest(supplier);
        },

        async newSupplierCreated() {
            this.newSupplierEvents = this.newSupplierEvents.concat(1);
        },
        editSupplier(supplier: any) {
            this.isAddSupplierOpen = true;
            this.isCreatingNewSupplier = false;
            // Make a deep clone of the Expense object to prevent modification of the original object.
            const supplierCopy = JSON.parse(JSON.stringify(supplier));
            this.currentSupplier = supplierCopy;
        }
    }
});
