
import { computed, defineComponent, ref } from 'vue';

interface DxtButtonProps {
    icon?: string;
    appendIcon?: string;
    loading?: boolean;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    appearance?: 'primary' | 'secondary' | 'tertiary';
    fullWidth?: boolean;
    className?: string;
}

export default defineComponent<DxtButtonProps>({
    name: 'DxtButton',
    props: {
        icon: {
            type: String,
            default: ''
        },
        appendIcon: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'button'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        appearance: {
            type: String,
            default: 'primary'
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        className: {
            type: String,
            default: ''
        }
    },
    emits: ['click'],
    setup(props, { emit }) {
        const clicked = ref(false);

        const handleClick = (event: MouseEvent) => {
            clicked.value = true;
            emit('click', event);
        };

        const handleAnimationEnd = () => {
            clicked.value = false;
        };

        const prependingIcon = computed(() => {
            if (props.loading) {
                return 'mdi-loading';
            }
            return props.icon;
        });

        return {
            clicked,
            handleClick,
            handleAnimationEnd,
            prependingIcon
        };
    }
});
