<template>
    <div class="desktop-header">
        <article class="impersonating-and-subscription">
            <impersonating-user-badge :current-user-data="currentUserData" />

            <status-text-subscription
                class="subscription"
                :current-user-data="currentUserData"
                :user-billinginfo="userBillinginfo"
            />
        </article>
        <article class="header-actions">
            <v-btn v-if="hasRunningTimer" small color="red" @click="openEditTimeregistrationTimer">
                <span class="mr-1">{{ timeDifference }}</span>
                <v-icon>mdi-timer-outline</v-icon>
            </v-btn>

            <v-btn color="dexDark" class="mr-5 white--text" small @click="toggleHelp">
                {{ $t('general.support') }}
            </v-btn>

            <v-badge
                v-if="canShowLearningCenter"
                data-id="learningCenterItemCount"
                color="primary"
                offset-x="12"
                offset-y="18"
                class="mr-5"
                :content="totalNumberOfLearningCenterItems.toString()"
            >
                <v-btn
                    id="v-tour-learning-center"
                    data-action="openLearningcenter"
                    icon
                    color="textGrey"
                    @click="toggleLearn"
                >
                    <v-icon>mdi-school</v-icon>
                </v-btn>
            </v-badge>

            <v-badge v-if="hasNewNotification" color="red" dot overlap offset-x="9" offset-y="11" class="mr-4">
                <v-btn icon color="textGrey">
                    <v-icon
                        class="notification-bell"
                        size="22"
                        @click="$router.push({ name: 'notifications' }).catch(() => {})"
                        >mdi-bell
                    </v-icon>
                </v-btn>
            </v-badge>

            <v-btn
                v-else
                icon
                color="textGrey"
                class="mr-4"
                @click="$router.push({ name: 'notifications' }).catch(() => {})"
            >
                <v-icon size="22" color="#6e84a3">mdi-bell-outline</v-icon>
            </v-btn>

            <v-menu
                v-if="canViewBookyear"
                v-model="isChangeBookyearModalOpen"
                :close-on-content-click="false"
                nudge-width="200"
                transition="scale-transition"
                origin="top right"
                offset-y
            >
                <template #activator="{ on, attrs }">
                    <v-btn text color="#6e84a3" class="mr-8" v-bind="attrs" v-on="on">
                        <v-badge
                            color="primary"
                            class="font-weight-medium"
                            data-action="change-bookyear"
                            :content="selectedYear"
                            >Boekjaar</v-badge
                        >
                    </v-btn>
                </template>

                <v-card class="py-2">
                    <v-list v-if="canChangeBookyear">
                        <v-list-item>
                            <div class="d-flex align-center">
                                <v-select
                                    :value="parseInt(selectedYear)"
                                    :items="years"
                                    :label="$t('accountantTerms.boekjaar')"
                                    outlined
                                    dense
                                    hide-details
                                    @change="selectNewYear"
                                />
                                <v-btn
                                    data-action="add-bookyear"
                                    icon
                                    small
                                    class="ml-2"
                                    @click="isAddNewBookyearOpen = true"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                            </div>
                        </v-list-item>
                        <v-list-item>
                            <div data-id="videoChangeBookyear">
                                <v-col cols="12" sm="12">
                                    <tooltip-video-wrapper i18n-path="videotooltips.bookyear.createNewBookyear" />
                                </v-col>
                            </div>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>

            <v-menu
                v-model="isAccountInfoOpen"
                :close-on-content-click="false"
                nudge-width="200"
                transition="scale-transition"
                origin="top right"
                offset-y
            >
                <template #activator="{ on, attrs }">
                    <v-avatar
                        data-action="open-user-header-menu"
                        class="avatar"
                        v-bind="attrs"
                        size="38"
                        color="dexDark"
                        v-on="on"
                    >
                        {{ getCurrentUserInitials }}
                    </v-avatar>
                </template>

                <v-card>
                    <v-list>
                        <v-list-item>
                            <v-list-item-avatar>
                                <v-avatar class="avatar" color="dexDark" min-width="auto" :style="{ color: '#fff' }">
                                    {{ getCurrentUserInitials }}
                                </v-avatar>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ getCurrentUserFullName }}
                                </v-list-item-title>
                            </v-list-item-content>

                            <v-list-item-icon>
                                <v-tooltip top>
                                    <template #activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" :to="{ name: 'userSettingsGeneral' }" v-on="on">
                                            <v-icon>mdi-cog</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('header.user_settings') }}</span>
                                </v-tooltip>
                            </v-list-item-icon>
                            <v-list-item-icon>
                                <v-tooltip v-if="isUserImpersonating" top>
                                    <template #activator="{ on, attrs }">
                                        <v-btn icon v-bind="attrs" color="textGrey" v-on="on" @click="logout">
                                            <v-icon>mdi-account-convert-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('header.switch_impersonate') }}</span>
                                </v-tooltip>
                                <v-tooltip v-else top>
                                    <template #activator="{ on, attrs }">
                                        <v-btn
                                            data-action="logout"
                                            icon
                                            v-bind="attrs"
                                            color="textGrey"
                                            v-on="on"
                                            @click="logout"
                                        >
                                            <v-icon>mdi-logout</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('header.logout') }}</span>
                                </v-tooltip>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
        </article>
        <add-new-bookyear />
    </div>
</template>

<script>
// --- Components ---
import AddNewBookyear from '@/components/BottomSheets/AddNewBookyear';
import StatusTextSubscription from '@/components/StatusTextSubscription';
import TooltipVideoWrapper from '@/components/TooltipVideoWrapper';
// --- State ---
import { mapActions, mapGetters, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
// --- Helpers ---
import ImpersonatingUserBadge from '@/components/Header/ImpersonatingUserBadge';
import { isUserAccount, isUserAccountWithFreeTier } from '@/helpers/auth';
import { bottomSheetsMixin } from '@/mixins/bottomSheetsMixin';
import dayjs from 'dayjs';

export default {
    name: 'DesktopHeader',
    components: {
        StatusTextSubscription,
        TooltipVideoWrapper,
        AddNewBookyear,
        ImpersonatingUserBadge
    },
    mixins: [bottomSheetsMixin],
    props: {
        totalNumberOfLearningCenterItems: {
            type: Number,
            default: 0
        }
    },
    data: function () {
        return {
            isAccountInfoOpen: false,
            isChangeBookyearModalOpen: false
        };
    },
    computed: {
        ...mapState('auth', ['isUserImpersonating']),
        ...mapFields('bottomSheets', ['isAddNewBookyearOpen']),
        ...mapFields(['selectedYear', 'globalSettingsYears']),
        ...mapFields('timeregistrations', ['currentTimeregistrationTimer', 'timeDifference']),
        ...mapState(['loading']),
        ...mapState('auth', ['currentUserData', 'userBillinginfo']),
        ...mapState('notifications', ['hasNewNotification']),
        ...mapGetters('auth', ['getCurrentUserInitials', 'getCurrentUserFullName']),
        canViewBookyear() {
            return isUserAccount(this.currentUserData) && !this.isFreeUser;
        },
        canShowLearningCenter() {
            return isUserAccount(this.currentUserData);
        },
        canChangeBookyear() {
            return !this.isFreeUser && isUserAccount(this.currentUserData) === true;
        },
        isFreeUser() {
            return isUserAccountWithFreeTier(this.currentUserData);
        },
        pageName() {
            return this.$route.meta.title;
        },
        years() {
            return [...this.globalSettingsYears].sort((a, b) => (a < b ? 1 : -1));
        },
        isInTrial() {
            return this.userBillinginfo.isInTrial;
        },
        trialDaysRemaining() {
            if (this.userBillinginfo.currentPeriodEnds) {
                return -dayjs().diff(this.userBillinginfo.currentPeriodEnds, 'day');
            }

            return 0;
        },
        hasRunningTimer() {
            return this.currentTimeregistrationTimer && this.currentTimeregistrationTimer.id;
        }
    },
    methods: {
        ...mapActions('auth', ['logout']),
        ...mapActions('timeregistrations', ['openEditTimeregistrationTimer']),
        ...mapActions(['setSelectedYear', 'startLoading', 'stopLoading', 'openSupportDialog']),
        selectNewYear(year) {
            this.setSelectedYear(year);
            window.location.reload();
        },
        toggleHelp() {
            this.openSupportDialog();
        },
        toggleLearn() {
            this.$parent.$emit('toggle-learningcenter');
        },
        isUserAccount: isUserAccount
    }
};
</script>

<style lang="scss" scoped>
@import '../../sass/variables.scss';

.desktop-header {
    display: none;
    position: relative;
    padding: 0 $header-padding-y;

    @media screen and (min-width: $sm) {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .impersonating-and-subscription {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
    }

    .header-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .v-toolbar__content {
        box-shadow: 0 10px 8px $grey-100;
    }

    :deep().v-toolbar__title {
        overflow: visible;
        margin-right: 16px;
    }

    section.left {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    section.right {
        display: flex;
        align-items: center;
    }

    .app-bar {
        height: auto !important;
        background-color: #ffffff !important;
    }

    .app-bar .v-app-bar__nav-icon {
        color: $color-text-grey;
    }

    .navbar-button i::before {
        color: $color-text-grey !important;
    }

    .navbar-button:hover {
        background-color: transparent;
    }

    .navbar-button i::before {
        font-size: 22px !important;
    }

    .avatar {
        color: $white;
    }

    .app-bar .v-app-bar__nav-icon:hover,
    .app-bar .v-app-bar__nav-icon:focus,
    .app-bar .v-app-bar__nav-icon {
        color: rgba(0, 0, 0, 0) !important;
    }

    .highlighted-learning-center {
        border: 2px solid $color-primary;
        border-radius: 50%;
        pointer-events: none;
    }
}
</style>
