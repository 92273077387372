import { generateEndpoint } from './config';
import axios from 'axios';
import { initializePaginationVars } from '@/store/utils';
import { filteringOptions, PaginationResponse } from '@/common/types';
import { CreateInvoiceItem, IInvoiceItemViewModel, UpdateInvoiceItem } from '@/services/invoiceitems.types';

export const BASE_URL_INVOICEITEMS = generateEndpoint(`/api/invoiceitems`);

export function sendGetAllInvoiceitemsRequest(): Promise<IInvoiceItemViewModel[]> {
    return axios.get(`${BASE_URL_INVOICEITEMS}`).then((response) => response.data);
}

export function sendGetAllInvoiceitemsPaginationRequest(
    options: filteringOptions
): Promise<PaginationResponse<IInvoiceItemViewModel>> {
    return axios
        .get(`${BASE_URL_INVOICEITEMS}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}
export function sendGetInvoiceitemsRequest(): Promise<IInvoiceItemViewModel> {
    return axios.get(`${BASE_URL_INVOICEITEMS}`).then((response) => response.data);
}

export function sendCreateInvoiceitemRequest(invoiceitem: CreateInvoiceItem): Promise<IInvoiceItemViewModel> {
    return axios.post(`${BASE_URL_INVOICEITEMS}`, { invoiceitem }).then((response) => response.data);
}

export function sendUpdateInvoiceitemRequest(invoiceitem: UpdateInvoiceItem): Promise<IInvoiceItemViewModel> {
    return axios
        .put(`${BASE_URL_INVOICEITEMS}/${invoiceitem.id}`, {
            invoiceitem
        })
        .then((response) => response.data);
}

export function sendDeleteInvoiceitemRequest(id: number): Promise<void> {
    return axios.delete(`${BASE_URL_INVOICEITEMS}/${id}`).then((response) => response.data);
}
