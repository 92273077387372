import { isAdmin } from '@/helpers/auth';
import store from '../../store';
import { routeRequiresAuth } from './helpers/index';
export function redirectIfNonAdminuserVisitsAdminPage(to, from, next) {
    const requiresAuth = routeRequiresAuth(to);
    const isAdminPage = to.matched.some((record) => record.meta.admin);

    if (requiresAuth) {
        const user = store.state.auth.currentUserData;

        // Admin page
        if (isAdminPage && !isAdmin(user)) {
            next({ name: 'dashboard' });
            return;
        }
    }

    next();
}
