import { GeneralFncType } from '@/types/general';
import { isNil } from 'lodash';
import Vue from 'vue';

type DialogAttributes = {
    dataId: string | null;
    title: string | null;
    message: string | null;
    negativeButton: string | null;
    positiveButton: string | null;
    negativeColor: string | null;
    positiveColor: string | null;
};

type DialogCustomAttributes = {
    [k: string]: any;
};

export type MiddlewareNextFnc = () => any;

export type MiddlewareFnc = (nextFnc: MiddlewareNextFnc, data?: any) => void | Promise<void>;

export const middlewareMixin = Vue.extend({
    data() {
        return {
            registeredSubmitMiddleware: [],
            dialogAttributes: {
                // eslint-disable-next-line @typescript-eslint/ban-types
                component: null as any | null | undefined,
                attributes: {
                    dataId: null as string | null,
                    title: null as string | null,
                    message: null as string | null,
                    negativeButton: null as string | null,
                    positiveButton: null as string | null,
                    negativeColor: null as string | null,
                    positiveColor: null as string | null
                } as DialogAttributes & DialogCustomAttributes,
                actions: {
                    clickPositive: null,
                    clickNegative: null,
                    clickNeutral: null
                } as {
                    clickPositive?: null | GeneralFncType;
                    clickNegative?: null | GeneralFncType;
                    clickNeutral?: null | GeneralFncType;
                }
            },
            showDialog: false
        };
    },

    methods: {
        checkCorrectlyFormattedMiddlewareFunctions(middlewares: (MiddlewareFnc | undefined)[]): MiddlewareFnc[] {
            if (middlewares.some((_middleware) => isNil(_middleware))) {
                throw new Error('Error initalizing middleware');
            }

            return middlewares as MiddlewareFnc[];
        },
        runMiddleware(middlewarechain: MiddlewareFnc[], data?: any): void {
            const runner = (index: number) => {
                const middleware = middlewarechain ? middlewarechain[index] : this.registeredSubmitMiddleware[index];

                if (middleware) {
                    middleware(() => {
                        runner(index + 1);
                    }, data);
                }
            };

            runner(0);
        },
        clickPositive(next: GeneralFncType): void {
            this.showDialog = false;
            next();
            return;
        },
        clickNegative(): void {
            this.showDialog = false;
        },
        setDialogAttributes(
            {
                title,
                dataId,
                message,
                negativeButton,
                positiveButton,
                component,
                negativeColor = 'grey',
                positiveColor = 'green',
                onlyPositiveButton,
                props
            }: {
                // eslint-disable-next-line @typescript-eslint/ban-types
                component?: any | null;
                title: string;
                dataId: string;
                message: string;
                negativeButton?: string | null;
                positiveButton?: string | null;
                negativeColor?: string | null;
                positiveColor?: string | null;
                onlyPositiveButton?: boolean;
                props?: any;
            },
            { clickPositive, clickNegative }: { clickPositive?: GeneralFncType; clickNegative?: GeneralFncType }
        ): void {
            this.dialogAttributes = {
                component: component,
                attributes: {
                    dataId,
                    title,
                    message,
                    negativeButton: onlyPositiveButton
                        ? null
                        : negativeButton ?? (this.$i18n.t('general.cancel') as string),
                    positiveButton: positiveButton ?? (this.$i18n.t('general.confirm') as string),
                    negativeColor,
                    positiveColor,
                    ...props
                },
                actions: {
                    clickPositive: clickPositive ?? this.clickPositive,
                    clickNegative: clickNegative ?? this.clickNegative
                }
            };
        },
        openConfirmationDialog(): void {
            this.showDialog = true;
        }
    }
});
