import Vue from 'vue';

export const screenSizeMixin = Vue.extend({
    // https://vuetifyjs.com/en/styles/display/#visibility
    data() {
        return { windowWidth: window.innerWidth };
    },
    computed: {
        isSmallDevice(): boolean {
            return this.windowWidth < 960;
        },
        isPhone(): boolean {
            return this.windowWidth < 600;
        },
        isTablet(): boolean {
            return this.windowWidth > 600 && window.innerWidth < 960;
        },
        isSmallDesktop(): boolean {
            return this.windowWidth > 960 && window.innerWidth < 1264 - 16; // Zie vuetify docs voor -16px.
        },
        isSmallDesktopAndUp(): boolean {
            return this.windowWidth > 960;
        },
        isDesktop(): boolean {
            return this.windowWidth > 1264 - 16 && window.innerWidth < 1904 - 16; // Zie vuetify docs voor -16px.
        },
        isUltraWide(): boolean {
            return this.windowWidth > 1904 - 16;
        }
    },
    created() {
        window.addEventListener('resize', this.resized);
    },
    destroyed() {
        window.removeEventListener('resize', this.resized);
    },
    methods: {
        resized() {
            this.windowWidth = window.innerWidth;
        }
    }
});
