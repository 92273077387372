<template>
    <v-btn
        :id="id"
        :block="block"
        :color="color"
        :outlined="outlined"
        v-bind="$attrs"
        :disabled="disabled"
        :type="type"
        class="ButtonPrimary"
        @click="handleClick"
    >
        <v-icon v-if="prependIcon" left dense dark> {{ prependIcon }} </v-icon>
        <slot></slot>
        <v-icon v-if="icon" right dense dark> {{ icon }} </v-icon>
    </v-btn>
</template>

<script>
export default {
    props: {
        id: {
            type: String
        },
        icon: {
            type: String,
            default: ''
        },
        prependIcon: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        block: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'primary'
        },
        outlined: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'button'
        }
    },

    created() {
        if (!this.$slots.default) {
            throw new Error('Submitbutton must be provided in the slot.');
        }
    },

    methods: {
        handleClick(e) {
            this.$emit('click', e);
        }
    }
};
</script>

<style lang="scss" scoped>
.ButtonPrimary {
    color: white;
}
</style>
