<template>
    <v-bottom-sheet v-model="isOpen" data-id="support-dialog" scrollable :max-width="maxWidth">
        <v-card class="py-2">
            <v-list v-if="canChangeBookyear">
                <v-list-item>
                    <div class="d-flex align-center">
                        <v-select
                            :value="parseInt(selectedYear)"
                            :items="years"
                            :label="$t('accountantTerms.boekjaar')"
                            outlined
                            dense
                            hide-details
                            @change="selectNewYear"
                        />
                        <v-btn icon small @click="isAddNewBookyearOpen = true"><v-icon>mdi-plus</v-icon></v-btn>
                    </div>
                </v-list-item>
                <v-list-item>
                    <div data-id="videoChangeBookyear">
                        <v-col cols="12" sm="12">
                            <tooltip-video-wrapper i18n-path="videotooltips.bookyear.createNewBookyear" />
                        </v-col>
                    </div>
                </v-list-item>
            </v-list>
        </v-card>
    </v-bottom-sheet>
</template>

<script>
// --- State ---
import { mapState, mapGetters, mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import dayjs from 'dayjs';
// --- Mixins ---
import { bottomSheetsMixin } from '../mixins/bottomSheetsMixin.js';
import { screenSizeMixin } from '../mixins/screenSizeMixin';
import { isUserAccount, isUserAccountWithFreeTier } from '@/helpers/auth';
import TooltipVideoWrapper from '@/components/TooltipVideoWrapper.vue';

export default {
    components: {
        TooltipVideoWrapper
    },

    mixins: [bottomSheetsMixin, screenSizeMixin],

    props: ['value'],

    data() {
        return {
            isAccountInfoOpen: false,
            isChangeBookyearModalOpen: false
        };
    },

    computed: {
        ...mapFields(['selectedYear', 'globalSettingsYears', 'learningModuleStatus']),
        ...mapFields('bottomSheets', ['isAddNewBookyearOpen']),
        ...mapFields('timeregistrations', ['currentTimeregistrationTimer', 'timeDifference']),
        ...mapState('auth', ['currentUserData', 'userBillinginfo']),
        ...mapState('notifications', ['hasNewNotification']),
        ...mapGetters('auth', ['getCurrentUserInitials', 'getCurrentUserFullName']),

        maxWidth() {
            if (this.isSmallDevice) {
                return '100%';
            } else {
                return '35%';
            }
        },

        canShowLearningCenter() {
            return isUserAccount(this.currentUserData);
        },
        canChangeBookyear() {
            return !this.isFreeUser && isUserAccount(this.currentUserData) === true;
        },
        isFreeUser() {
            return isUserAccountWithFreeTier(this.currentUserData);
        },
        pageName() {
            return this.$route.meta.title;
        },
        years() {
            return [...this.globalSettingsYears].sort((a, b) => (a < b ? 1 : -1));
        },
        isInTrial() {
            return this.userBillinginfo.isInTrial;
        },
        trialDaysRemaining() {
            if (this.userBillinginfo.currentPeriodEnds) {
                return -dayjs().diff(this.userBillinginfo.currentPeriodEnds, 'day');
            }

            return 0;
        },
        menuIcon() {
            if (this.isSmallDevice) {
                return 'mdi-menu';
            }

            if (this.miniModus) {
                return 'mdi-chevron-right-circle-outline';
            } else {
                return 'mdi-chevron-left-circle-outline';
            }
        },
        hasRunningTimer() {
            return this.currentTimeregistrationTimer && this.currentTimeregistrationTimer.id;
        },

        isOpen: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    },

    methods: {
        ...mapActions('timeregistrations', ['openEditTimeregistrationTimer']),
        ...mapActions(['setSelectedYear', 'startLoading', 'stopLoading', 'openMobileMenu', 'toggleLearningcenter']),
        selectNewYear(year) {
            this.setSelectedYear(year);
            window.location.reload();
        },
        isUserAccount: isUserAccount
    }
};
</script>

<style lang="scss" scoped>
.avatar {
    color: white;
}

:deep().v-toolbar__title {
    overflow: visible;
    margin-right: 16px;
}
section.left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

section.right {
    display: flex;
    align-items: center;
}

.NavigationWrapper {
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: space-between;
}

.app-bar {
    height: auto !important;
    background-color: #ffffff !important;
}

.app-bar .v-app-bar__nav-icon {
    color: $color-text-grey;
}

.navbar-button i::before {
    color: $color-text-grey !important;
}

.navbar-button:hover {
    background-color: none;
}
.navbar-button i::before {
    font-size: 22px !important;
}
.app-bar .v-app-bar__nav-icon:hover,
.app-bar .v-app-bar__nav-icon:focus,
.app-bar .v-app-bar__nav-icon {
    color: rgba(0, 0, 0, 0) !important;
}

.headerContentWrapper > * {
    margin-bottom: 20px;
}

.headerContentWrapper > *:last-child {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>
