<template>
    <base-bottom-sheet v-if="open" v-model="open" style="border-radius: 10px" :width="width">
        <template #header>
            <div class="Header">
                <div class="vCardHeader_TitleWrapper">
                    <h4 class="vCardHeader_Title">{{ getTitle }}</h4>
                </div>
            </div>
        </template>
        <template #content>
            <div class="TooltipContent preserveEnters" v-html="getContent"></div>
            <div class="Footer">
                <v-spacer />
                <div v-if="getReadmore" class="mr-1 readmore">
                    <a class="link-small" target="_blank" :href="getReadmore">{{ $t('tooltips.readmore') }}</a>
                </div>
                <submit-button class="ml-1" @click="open = false">{{ $t('tooltips.gotit') }}</submit-button>
            </div>
        </template>
    </base-bottom-sheet>
</template>

<script>
import { screenSizeMixin } from '../mixins/screenSizeMixin';
import SubmitButton from './SubmitButton.vue';

export default {
    components: { SubmitButton },
    mixins: [screenSizeMixin],

    props: {
        value: {
            type: Boolean,
            required: true
        },
        i18nPath: {
            type: String
        },
        content: {
            type: String
        },
        title: {
            type: String
        },
        readmore: {
            type: String,
            default: null
        }
    },

    computed: {
        open: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        },
        width() {
            if (this.isSmallDesktopAndUp) {
                return '60%';
            }

            return '85%';
        },
        getTitle() {
            if (this.i18nPath && this.$i18n.te(this.i18nPath.concat('.title'))) {
                return this.$t(this.i18nPath.concat('.title'));
            }

            return this.title;
        },
        getContent() {
            if (this.i18nPath && this.$i18n.te(this.i18nPath.concat('.content'))) {
                return this.$t(this.i18nPath.concat('.content'));
            }

            return this.content;
        },
        getReadmore() {
            if (this.i18nPath && this.$i18n.te(this.i18nPath.concat('.readmore'))) {
                return this.$t(this.i18nPath.concat('.readmore'));
            }

            return this.readmore;
        }
    }
};
</script>

<style lang="scss" scoped>
.vCardHeader_Title {
    font-size: 1.2rem;
}

.Header {
    border: 0px;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-radius: 0px !important;
}
.Footer {
    border: 0px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    border-radius: 0px !important;
}

.TooltipContent {
    padding-bottom: 10px;
    overflow-y: auto;

    p {
        margin-bottom: 16px !important;
    }
}
.tooltip {
    padding: 20px !important;
}
.readmore {
    padding: 0 19.5555555556px;
}

.activator {
    cursor: pointer;
}
</style>
