import axios from 'axios';

export function addDexxterHeaderSourceInterceptor(): void {
    axios.interceptors.request.use((config) => {
        if (config.method === 'OPTIONS') {
            return config;
        }

        if (process.env.NODE_ENV !== 'production') {
            config.headers['Cache-Control'] = 'no-cache';
        }

        config.headers['Dexxter-Source'] = `frontend-client`;
        return config;
    });
}
