import { isAccountant, isImpersonating, isUserAccount } from '@/helpers/auth';
import { analyticsIdentify } from '@/services/analytics';
import {
    getAnalyticsUserAccountPlanType,
    getAnalyticsUserType,
    getAnalyticsVatType
} from '@/services/analytics.helpers';
import dayjs from 'dayjs';
import _ from 'lodash';
import store from '../../store';

export function useraccountTracking(router, to, from, next) {
    // const dontTrackRoute = shouldNotTrackRoute(to);

    if (_.get(store, 'state.auth.currentUserData', null)) {
        const user = store.state.auth.currentUserData;

        if (isUserAccount(user) && !isImpersonating()) {
            analyticsIdentify(user.id, {
                email: user.userinformation.email,
                firstName: user.userinformation.firstName,
                lastName: user.userinformation.name,
                phone: user.userinformation.phone,
                Plan_Type: getAnalyticsUserAccountPlanType(user),
                Vat_Type: getAnalyticsVatType(user.settings),
                User_Type: getAnalyticsUserType(user),
                ...(user.userinformation.billinginfo.isInTrial === true
                    ? { Trial_Expires_At: dayjs(user.userinformation.billinginfo.currentPeriodEnds).toISOString() }
                    : { Trial_Expires_At: null }),
                ...(user.userinformation.billinginfo.paymentMethod === 'MANUAL'
                    ? {
                          Manual_Subscription_Ends_At: dayjs(
                              user.userinformation.billinginfo.currentPeriodEnds
                          ).toISOString()
                      }
                    : { Manual_Subscription_Ends_At: null }),
                ...(user.company.number ? { Company_Number: user.company.number } : {}),
                ...(user.userinformation.verifiedEmailadres ? { Verified_Email: true } : {}),
                ...(user.userinformation.roles.length > 0 ? { Completed_Registration: true } : {})
            });
        } else if (isAccountant(user) && !isImpersonating()) {
            analyticsIdentify(user.id, {
                User_Type: 'accountant',
                ...(user.userinformation.verifiedEmailadres ? { Verified_Email: true } : {})
            });
        }
    }

    // if (dontTrackRoute === false) {
    //     analytics.track(`Pageview`, {
    //         Page_Name: to.name
    //     });
    // }

    return next();
}
