import { BILLING_PLANS } from '@/config/constants';

export class UserBillinginfo {
    id: number;
    interval: string | null;
    isInTrial: boolean;
    paymentMethod: string | null;
    stripeCustomerId: string;
    subscriptionID: string | null;
    subscriptionType: string;
    usedTrial: boolean;
    currentPeriodEnds: Date | null;

    constructor({
        id,
        interval,
        isInTrial,
        paymentMethod,
        stripeCustomerId,
        subscriptionID,
        subscriptionType,
        usedTrial,
        currentPeriodEnds
    }: {
        id: number;
        interval: string | null;
        isInTrial: boolean;
        paymentMethod: string | null;
        stripeCustomerId: string;
        subscriptionID: string | null;
        subscriptionType: string;
        usedTrial: boolean;
        currentPeriodEnds: Date | null;
    }) {
        this.id = id;
        this.interval = interval;
        this.isInTrial = isInTrial;
        this.paymentMethod = paymentMethod;
        this.stripeCustomerId = stripeCustomerId;
        this.subscriptionID = subscriptionID;
        this.subscriptionType = subscriptionType;
        this.usedTrial = usedTrial;
        this.currentPeriodEnds = currentPeriodEnds;
    }

    get subscriptionTier() {
        return this.subscriptionType;
    }

    hasRenewableSubscription() {
        return this.subscriptionID && !this.hasFreeSubscription() && !this.hasTrialSubscription();
    }

    hasOnetimeSubscription() {
        return this.currentPeriodEnds && !this.hasTrialSubscription();
    }

    hasFreeSubscription() {
        return this.subscriptionType === BILLING_PLANS.FREE_TIER;
    }

    hasTrialSubscription() {
        return this.isInTrial;
    }
}
