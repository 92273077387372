import { USER_ACCESS_OPERATIONS } from '@/config/constants';
import LoggedInLayout from '../layouts/LoggedInLayout.vue';
import { carRoutes } from './userRoutes/cars';

const accountingInvoicableRoutes = [
    {
        path: 'accounting-invoices',
        name: 'accountingInvoices',
        component: () =>
            import(
                /* webpackChunkName: 'user' */
                '../views/accountingInvoice/view/AccountingInvoices.vue'
            ),
        props: true,
        meta: {
            title: 'pageTitles.invoices',

            action: USER_ACCESS_OPERATIONS.READ,
            subject: 'Accountinginvoicable'
        }
    },
    {
        path: 'accounting-creditnotes',
        name: 'accountingCreditNotes',
        component: () =>
            import(
                /* webpackChunkName: 'user' */
                '../views/accountingCreditnote/view/AccountingCreditnotes.vue'
            ),
        props: true,
        meta: {
            title: 'pageTitles.creditnotes',

            action: USER_ACCESS_OPERATIONS.READ,
            subject: 'Accountinginvoicable'
        }
    },
    // Adding and editing
    {
        path: '/add-accounting-expense',
        name: 'add-accounting-expense',
        component: () =>
            import(
                /* webpackChunkName: 'user' */
                '../views/accountingexpense/add/AddAccountingExpense.vue'
            ),
        meta: {
            title: 'pageTitles.addExpense',

            action: USER_ACCESS_OPERATIONS.CREATE,
            subject: 'Accountingexpense'
        }
    },
    {
        path: '/edit-accounting-expense/:id',
        name: 'edit-accounting-expense',
        component: () =>
            import(/* webpackChunkName: 'user' */ '../views/accountingexpense/add/AddAccountingExpense.vue'),
        meta: {
            title: 'pageTitles.editExpense',

            action: USER_ACCESS_OPERATIONS.UPDATE,
            subject: 'Accountingexpense'
        }
    },
    {
        path: '/view-accounting-expense/:id',
        name: 'view-accounting-expense',
        component: () =>
            import(/* webpackChunkName: 'user' */ '../views/accountingexpense/view/ViewAccountingExpense.vue'),
        meta: {
            action: USER_ACCESS_OPERATIONS.READ,
            subject: 'Accountingexpense'
        }
    },
    // Add/edit accouting INVOICE
    {
        path: '/add-accounting-invoice',
        name: 'add-accounting-invoice',
        component: () =>
            import(/* webpackChunkName: 'user' */ '../views/accountingInvoice/add/AddAccountingInvoice.vue'),
        props: true,
        meta: {
            title: 'pageTitles.addInvoice',

            action: USER_ACCESS_OPERATIONS.CREATE,
            subject: 'Accountinginvoicable'
        }
    },
    {
        path: '/edit-accounting-invoice/:id',
        name: 'edit-accounting-invoice',
        component: () =>
            import(/* webpackChunkName: 'user' */ '../views/accountingInvoice/add/AddAccountingInvoice.vue'),
        meta: {
            title: 'pageTitles.edit_invoice',

            action: USER_ACCESS_OPERATIONS.UPDATE,
            subject: 'Accountinginvoicable'
        }
    },
    // Add/edit accouting historical INVOICE
    {
        path: '/add-accounting-historical-invoice',
        name: 'add-accounting-historical-invoice',

        component: () =>
            import(/* webpackChunkName: 'user' */ '../views/accountingInvoice/add/AddHistoricalAccountingInvoice.vue'),
        meta: {
            title: 'pageTitles.add_invoice_historical',
            action: USER_ACCESS_OPERATIONS.CREATE,
            subject: 'Accountinginvoicable'
        }
    },
    {
        path: '/edit-accounting-historical-invoice/:id',
        name: 'edit-accounting-historical-invoice',

        component: () =>
            import(/* webpackChunkName: 'user' */ '../views/accountingInvoice/add/AddHistoricalAccountingInvoice.vue'),
        meta: {
            title: 'pageTitles.edit_invoice',
            action: USER_ACCESS_OPERATIONS.UPDATE,
            subject: 'Useronlyaccess'
        }
    },
    // Add/edit accouting historical CREDITNOTE
    {
        path: '/add-accounting-historical-creditnote',
        name: 'add-accounting-historical-creditnote',

        component: () =>
            import(
                /* webpackChunkName: 'user' */ '../views/accountingCreditnote/add/AddHistoricalAccountingCreditnote.vue'
            ),
        meta: {
            title: 'pageTitles.add_creditnote_historical',

            action: USER_ACCESS_OPERATIONS.UPDATE,
            subject: 'Accountinginvoicable'
        }
    },
    {
        path: '/edit-accounting-historical-creditnote/:id',
        name: 'edit-accounting-historical-creditnote',
        component: () =>
            import(
                /* webpackChunkName: 'user' */ '../views/accountingCreditnote/add/AddHistoricalAccountingCreditnote.vue'
            ),
        meta: {
            title: 'pageTitles.edit_creditnote',

            action: USER_ACCESS_OPERATIONS.UPDATE,
            subject: 'Accountinginvoicable'
        }
    },
    // Add/edit accouting historical CREDITNOTE
    {
        path: '/add-accounting-creditnote',
        name: 'add-accounting-creditnote',

        component: () =>
            import(/* webpackChunkName: 'user' */ '../views/accountingCreditnote/add/AddAccountingCreditnote.vue'),
        props: true,
        meta: {
            title: 'pageTitles.addCreditnote',

            action: USER_ACCESS_OPERATIONS.CREATE,
            subject: 'Accountinginvoicable'
        }
    },
    {
        path: '/edit-accounting-creditnote/:id',
        name: 'edit-accounting-creditnote',
        component: () =>
            import(/* webpackChunkName: 'user' */ '../views/accountingCreditnote/add/AddAccountingCreditnote.vue'),
        meta: {
            title: 'pageTitles.edit_creditnote',

            action: USER_ACCESS_OPERATIONS.UPDATE,
            subject: 'Accountinginvoicable'
        }
    },
    // Booking invoice
    {
        path: '/book-invoice/:id',
        name: 'book-invoice',
        component: () => import(/* webpackChunkName: 'user' */ '../views/bookInvoice/BookInvoice.vue'),
        meta: {
            title: 'pageTitles.book_invoice',

            action: USER_ACCESS_OPERATIONS.UPDATE,
            subject: 'Accountinginvoicable'
        }
    },
    // Booking invoice historical
    {
        path: '/book-historical-invoice/:id',
        name: 'book-historical-invoice',
        component: () => import(/* webpackChunkName: 'user' */ '../views/bookInvoice/BookHistoricalInvoice.vue'),
        meta: {
            title: 'pageTitles.book_invoice',

            action: USER_ACCESS_OPERATIONS.UPDATE,
            subject: 'Accountinginvoicable'
        }
    },
    // Booking creditnote
    {
        path: '/book-creditnote/:id',
        name: 'book-creditnote',
        component: () => import(/* webpackChunkName: 'user' */ '../views/bookCreditnote/BookCreditnote.vue'),
        meta: {
            title: 'pageTitles.book_creditnote',

            action: USER_ACCESS_OPERATIONS.UPDATE,
            subject: 'Accountinginvoicable'
        }
    },
    // Booking historical creditnote
    {
        path: '/book-historical-creditnote/:id',
        name: 'book-historical-creditnote',
        component: () => import(/* webpackChunkName: 'user' */ '../views/bookCreditnote/BookHistoricalCreditnote.vue'),
        meta: {
            title: 'pageTitles.book_creditnote',

            action: USER_ACCESS_OPERATIONS.UPDATE,
            subject: 'Accountinginvoicable'
        }
    }
];

export const userRoutes = [
    {
        path: '/',
        components: {
            default: LoggedInLayout
        },
        redirect: {
            name: 'dashboard'
        },
        name: 'root',
        meta: {
            requiresUserAccount: true,
            requiresAuth: true
        },
        children: [
            ...carRoutes,
            ...accountingInvoicableRoutes,
            {
                path: '/verify-change-emailadres',
                name: 'verify-change-emailadres',
                component: () => import(/* webpackChunkName: 'user' */ '../views/VerifyChangeEmailadres.vue')
            },
            {
                path: 'dashboard',
                name: 'dashboard',
                component: () => import(/* webpackChunkName: 'user' */ '../views/UserApp/dashboard/Dashboard.vue'),
                meta: {
                    title: 'pageTitles.dashboard'
                }
            },
            {
                path: '/integrations',
                name: 'integrations',
                props: true,
                component: () => import(/* webpackChunkName: 'settings' */ '../views/integrations/Integrations.vue'),
                meta: {
                    title: 'pageTitles.integrations',
                    requiresAuth: true
                }
            },

            {
                path: 'comingsoon-timeregistration',
                name: 'comingSoonTimeRegistration',
                component: () =>
                    import(/* webpackChunkName: 'timeregistration' */ '../views/ComingsoonTimeregistration.vue'),
                meta: {
                    title: 'pageTitles.comingsoon'
                }
            },
            {
                path: 'comingsoon-analyse',
                name: 'comingSoonAnalyse',
                component: () => import(/* webpackChunkName: 'user' */ '../views/ComingsoonAnalyse.vue'),
                meta: {
                    title: 'pageTitles.comingsoon'
                }
            },
            {
                path: 'documents-to-book',
                name: 'documentsToBook',
                component: () => import(/* webpackChunkName: 'litetier' */ '../views/DocumentsToBook.vue'),
                props: true,
                meta: {
                    title: 'pageTitles.DocumentsToBook',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Accountinginvoicable'
                }
            },
            {
                path: 'send-documents-to-accountant',
                name: 'send-documents-to-accountant',
                component: () => import(/* webpackChunkName: 'litetier' */ '../views/SendDocumentsToAccountant.vue'),
                props: true,
                meta: {
                    title: 'pageTitles.SendDocumentsToAccountant',
                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'SendDocumentsToAccountant'
                }
            },
            {
                path: 'invoices',
                name: 'invoices',
                component: () => import(/* webpackChunkName: 'litetier' */ '../views/invoices/view/Invoices.vue'),
                props: true,
                meta: {
                    title: 'pageTitles.invoices',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: 'quotations',
                name: 'quotations',
                props: true,
                component: () => import(/* webpackChunkName: 'user' */ '../views/quotations/view/Quotation.vue'),
                meta: {
                    title: 'pageTitles.quotations',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: 'invoicable/view/:type/:id',
                name: 'invoicable-view',
                props: true,
                component: () =>
                    import(/* webpackChunkName: 'litetier' */ '../views/UserApp/invoicable-view/InvoicableView.vue'),
                meta: {
                    title: 'pageTitles.invoicable_view',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: 'accounting-invoicable/view/:type/:id',
                name: 'accounting-invoicable-view',
                props: true,
                component: () =>
                    import(
                        /* webpackChunkName: 'user' */ '../views/UserApp/invoicable-view/AccountingInvoicableView.vue'
                    ),
                meta: {
                    title: 'pageTitles.invoicable_view',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Accountinginvoicable'
                }
            },
            {
                path: '/reports/startResultatenrekening',
                name: 'startResultatenrekening',
                component: () =>
                    import(
                        /* webpackChunkName: 'user' */ '../views/UserApp/startResultatenrekening/StartResultatenrekening'
                    ),
                meta: {
                    title: 'pageTitles.start_resultatenrekening',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/reports/incomestatement',
                name: 'incomeStatement',
                component: () => import(/* webpackChunkName: 'user' */ '../views/reports/Incomestatement.vue'),
                meta: {
                    title: 'pageTitles.incomestatement',

                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/reports/expensecategory-frequencies',
                name: 'expenseByCategoryFrequencies',
                component: () =>
                    import(
                        /* webpackChunkName: 'user' */ '../views/reports/ExpenseCategoryFrequencies/ExpenseCategoryFrequencies.vue'
                    ),
                meta: {
                    title: 'pageTitles.expenseByCategoryFrequencies',

                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },

            {
                path: '/reports/resultatenrekening-advanced',
                name: 'resultatenrekeningAdvanced',
                component: () =>
                    import(/* webpackChunkName: 'user' */ '../views/grootboekrekeningBookings/Resultatenrekening'),
                meta: {
                    title: 'pageTitles.resultatenrekening',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/personal-taxes',
                name: 'personalTaxes',
                component: () =>
                    import(/* webpackChunkName: 'user' */ '@/views/UserApp/taxes/PersonalTaxes/PersonaltaxesV2.vue'),
                meta: {
                    title: 'pageTitles.personal_taxes',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Personaltaxes'
                }
            },
            {
                path: '/deposits',
                name: 'deposits',
                component: () => import(/* webpackChunkName: 'user' */ '../views/deposits/DepositsPage.vue'),
                meta: {
                    title: 'pageTitles.deposits',

                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Personaltaxes'
                }
            },
            {
                path: '/inventory',
                name: 'inventory',
                component: () => import(/* webpackChunkName: 'user' */ '../views/Inventory.vue'),
                meta: {
                    title: 'pageTitles.inventory',

                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Personaltaxes'
                }
            },
            {
                path: '/add-expense',
                name: 'add-expense',

                component: () => import(/* webpackChunkName: 'litetier' */ '../views/AddExpense.vue'),
                meta: {
                    title: 'pageTitles.addExpense',

                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Expense'
                }
            },
            {
                path: '/add-depreciationfiche',
                name: 'add-depreciationfiche',
                props: true,

                component: () => import(/* webpackChunkName: 'user' */ '../views/AddDepreciationfiche.vue'),
                meta: {
                    title: 'pageTitles.addDepreciationfiche',

                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Depreciationfiche'
                }
            },
            {
                path: '/edit-depreciationfiche/:id',
                name: 'edit-depreciationfiche',
                props: true,

                component: () => import(/* webpackChunkName: 'user' */ '../views/AddDepreciationfiche.vue'),
                meta: {
                    title: 'pageTitles.editDepreciationfiche',
                    action: USER_ACCESS_OPERATIONS.UPDATE,
                    subject: 'Depreciationfiche'
                }
            },
            {
                path: '/add-timeregistration',
                name: 'addTimeRegistration',

                component: () => import(/* webpackChunkName: 'timeregistration' */ '../views/AddTimeregistration.vue'),
                meta: {
                    title: 'pageTitles.addTimeregistration',

                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/activity',
                name: 'activity',

                component: () => import(/* webpackChunkName: 'user' */ '../views/Activity.vue'),
                meta: {
                    title: 'pageTitles.activity',
                    freeTier: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Activity'
                }
            },
            {
                path: '/send-invoice-email/:id',
                name: 'send-invoice-email',

                component: () => import(/* webpackChunkName: 'user' */ '../views/sendInvoiceEmail.vue'),
                meta: {
                    title: 'pageTitles.send_email',
                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/send-quotation-email/:id',
                name: 'send-quotation-email',

                component: () => import(/* webpackChunkName: 'user' */ '../views/sendQuotationEmail.vue'),
                meta: {
                    title: 'pageTitles.send_email',
                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/send-creditnote-email/:id',
                name: 'send-creditnote-email',

                component: () => import(/* webpackChunkName: 'user' */ '../views/sendCreditnoteEmail.vue'),
                meta: {
                    title: 'pageTitles.send_email',
                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/customers',
                name: 'customers',

                component: () => import(/* webpackChunkName: 'user' */ '../views/Customers.vue'),
                meta: {
                    title: 'pageTitles.customers',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/customer/:customerId',
                name: 'customer',

                component: () => import(/* webpackChunkName: 'user' */ '../views/Customer.vue'),
                meta: {
                    title: 'pageTitles.customer',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/invoiceitems',
                name: 'invoiceItems',

                component: () => import(/* webpackChunkName: 'user' */ '../views/Invoiceitem.vue'),
                meta: {
                    title: 'pageTitles.invoiceitems',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/suppliers',
                name: 'suppliers',

                component: () => import(/* webpackChunkName: 'user' */ '../views/Suppliers.vue'),
                meta: {
                    title: 'pageTitles.suppliers',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/edit-expense/:id',
                name: 'edit-expense',

                component: () => import(/* webpackChunkName: 'litetier' */ '../views/AddExpense.vue'),
                meta: {
                    title: 'pageTitles.editExpense',

                    action: USER_ACCESS_OPERATIONS.UPDATE,
                    subject: 'Expense'
                }
            },
            {
                path: '/edit-historical-invoice/:id',
                name: 'edit-historical-invoice',

                component: () => import(/* webpackChunkName: 'user' */ '../views/AddHistoricalInvoice.vue'),
                meta: {
                    title: 'pageTitles.edit_invoice',

                    action: USER_ACCESS_OPERATIONS.UPDATE,
                    subject: 'Invoicable'
                }
            },
            {
                path: '/edit-invoice/:id',
                name: 'edit-invoice',

                component: () => import(/* webpackChunkName: 'litetier' */ '../views/invoices/add/AddInvoice.vue'),
                meta: {
                    title: 'pageTitles.edit_invoice',

                    action: USER_ACCESS_OPERATIONS.UPDATE,
                    subject: 'Invoicable'
                }
            },
            {
                path: '/edit-historical-creditnote/:id',
                name: 'edit-historical-creditnote',

                component: () => import(/* webpackChunkName: 'user' */ '../views/AddHistoricalCreditnote.vue'),
                meta: {
                    title: 'pageTitles.edit_creditnote',

                    action: USER_ACCESS_OPERATIONS.UPDATE,
                    subject: 'Invoicable'
                }
            },
            {
                path: '/edit-creditnote/:id',
                name: 'edit-creditnote',

                component: () => import(/* webpackChunkName: 'user' */ '../views/AddCreditnote.vue'),
                meta: {
                    title: 'pageTitles.edit_creditnote',

                    action: USER_ACCESS_OPERATIONS.UPDATE,
                    subject: 'Invoicable'
                }
            },
            {
                path: '/edit-historical-quotation/:id',
                name: 'edit-historical-quotation',

                component: () => import(/* webpackChunkName: 'user' */ '../views/AddHistoricalQuotation.vue'),
                meta: {
                    title: 'pageTitles.edit_quotation',

                    action: USER_ACCESS_OPERATIONS.UPDATE,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/edit-quotation/:id',
                name: 'edit-quotation',

                component: () => import(/* webpackChunkName: 'user' */ '../views/quotations/add/AddQuotation.vue'),
                meta: {
                    title: 'pageTitles.edit_quotation',

                    action: USER_ACCESS_OPERATIONS.UPDATE,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/user-settings/subscription',
                name: 'user-settings-subscription',

                component: () =>
                    import(/* webpackChunkName: 'settings' */ '@/modules/subscription/presentation/Subscription.vue'),
                meta: {
                    title: 'pageTitles.subscription',
                    freeTier: true,

                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Subscriptionsetting'
                }
            },
            {
                path: '/user-settings/templates',
                name: 'user-settings-templates',

                component: () => import(/* webpackChunkName: 'settings' */ '../views/settings/Templates.vue'),
                meta: {
                    title: 'pageTitles.templates'
                }
            },
            {
                path: '/user-settings/subscription/success',
                name: 'user-settings-subscription-success',

                component: () =>
                    import(
                        /* webpackChunkName: 'settings' */ '@/modules/subscription/presentation/SubscriptionSuccessful.vue'
                    ),
                meta: {
                    title: 'pageTitles.subscriptionSuccessful'
                }
            },
            {
                path: '/user-settings/invoice',
                name: 'user-settings-invoice',

                component: () => import(/* webpackChunkName: 'settings' */ '../views/settings/Invoice.vue'),
                meta: {
                    title: 'pageTitles.invoice_settings'
                }
            },
            {
                path: '/user-settings/notifications',
                name: 'user-settings-notifications',

                component: () => import(/* webpackChunkName: 'settings' */ '../views/settings/Notification.vue'),
                meta: {
                    title: 'pageTitles.setting_titels.notifications',
                    freeTier: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Notification'
                }
            },
            {
                path: '/user-settings/calculations',
                name: 'user-settings-calculations',

                component: () => import(/* webpackChunkName: 'settings' */ '../views/settings/Calculations.vue'),
                meta: {
                    title: 'pageTitles.setting_titels.calculations'
                }
            },
            {
                path: '/user-settings/accountant',
                name: 'user-settings-accountant',
                component: () => import(/* webpackChunkName: 'settings' */ '../views/settings/Accountant.vue'),
                meta: {
                    title: 'pageTitles.setting_titels.accountant',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'ManageAccountant'
                }
            },
            {
                path: '/user-settings/accountantemailbcc',
                name: 'user-settings-accountantemailbcc',
                component: () => import(/* webpackChunkName: 'settings' */ '../views/settings/AccountantEmailBCC.vue'),
                meta: {
                    title: 'pageTitles.setting_titels.accountantemailbcc',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'AccountantEmailBCC'
                }
            },
            {
                path: '/emails/:type/:id',
                name: 'customer-mail',
                component: () => import(/* webpackChunkName: 'settings' */ '../views/CustomerEmail.vue'),
                meta: {
                    title: 'pageTitles.customerMail',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/vat-declaration',
                name: 'vat-declaration',

                component: () => import(/* webpackChunkName: 'user' */ '../views/VAT/VATListing/VATListing.vue'),
                meta: {
                    title: 'pageTitles.vat-declaration',

                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'VATDeclaration'
                }
            },
            {
                path: '/vat-listing',
                name: 'vat-listing',
                component: () => import(/* webpackChunkName: 'user' */ '../views/clientlisting/ClientListing.vue'),
                meta: {
                    title: 'pageTitles.client_listing',

                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'ClientListing'
                }
            },
            {
                path: '/ic-listing',
                name: 'ic-listing',
                component: () => import(/* webpackChunkName: 'user' */ '../views/ICListing/view/ICListing.vue'),
                meta: {
                    title: 'pageTitles.ic_listing',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'ICListing'
                }
            },
            {
                path: '/add-creditnote',
                name: 'add-creditnote',

                component: () => import(/* webpackChunkName: 'litetier' */ '../views/AddCreditnote.vue'),
                props: true,
                meta: {
                    title: 'pageTitles.addCreditnote',

                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Invoicable'
                }
            },
            {
                path: '/add-income-as-employee',
                name: 'addIncomeAsEmployee',

                component: () => import(/* webpackChunkName: 'user' */ '../views/AddJobstudentIncome.vue'),
                meta: {
                    title: 'pageTitles.add_income_as_student_employee',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/edit-income-as-employee/:id',
                name: 'edit-income-as-employee',

                component: () => import(/* webpackChunkName: 'settings' */ '../views/AddJobstudentIncome.vue'),
                props: true,
                meta: {
                    title: 'pageTitles.add_income_as_student_employee',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.UPDATE,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/add-invoice',
                name: 'add-invoice',

                component: () => import(/* webpackChunkName: 'litetier' */ '../views/invoices/add/AddInvoice.vue'),
                props: true,
                meta: {
                    title: 'pageTitles.addInvoice',

                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Invoicable'
                }
            },
            {
                path: '/add-invoice-historical',
                name: 'add-invoice-historical',

                component: () => import(/* webpackChunkName: 'litetier' */ '../views/AddHistoricalInvoice.vue'),
                meta: {
                    title: 'pageTitles.add_invoice_historical',

                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Invoicable'
                }
            },
            {
                path: '/add-creditnote-historical',
                name: 'add-creditnote-historical',

                props: true,
                component: () => import(/* webpackChunkName: 'litetier' */ '../views/AddHistoricalCreditnote.vue'),
                meta: {
                    title: 'pageTitles.add_creditnote_historical',

                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Invoicable'
                }
            },
            {
                path: '/add-quotation-historical',
                name: 'add-quotation-historical',

                component: () => import(/* webpackChunkName: 'user' */ '../views/AddHistoricalQuotation.vue'),
                meta: {
                    title: 'pageTitles.add_quotation_historical',

                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/add-quotation',
                name: 'add-quotation',

                component: () => import(/* webpackChunkName: 'user' */ '../views/quotations/add/AddQuotation.vue'),
                meta: {
                    title: 'pageTitles.add_quotation',

                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/add-daily-receipt/:date',
                name: 'add-daily-receipt',

                component: () =>
                    import(/* webpackChunkName: 'user' */ '../views/dailyreceipts/add/AddDailyReceipt.vue'),
                meta: {
                    title: 'pageTitles.add_daily_receipt',

                    action: USER_ACCESS_OPERATIONS.CREATE,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/time-registration',
                name: 'timeRegistration',

                component: () => import(/* webpackChunkName: 'timeregistration' */ '../views/TimeRegistration.vue'),
                meta: {
                    title: 'pageTitles.time_registration',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/timeline',
                name: 'timeline',

                component: () => import(/* webpackChunkName: 'user' */ '../views/Timeline.vue'),
                meta: {
                    title: 'pageTitles.timeline',

                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/expenses',
                name: 'expenses',
                component: () => import(/* webpackChunkName: 'user' */ '../views/Expenses.vue'),
                meta: {
                    title: 'pageTitles.expenses',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/manage-costcategory',
                name: 'manageCostCategory',
                component: () => import(/* webpackChunkName: 'user' */ '../views/ManageCostcategory.vue'),
                meta: {
                    title: 'pageTitles.manage_costcategory',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.UPDATE,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/dependent-on',
                name: 'dependentOn',
                component: () => import(/* webpackChunkName: 'user' */ '../views/dependentOn/DependentOn.vue'),
                meta: {
                    title: 'pageTitles.dependent_on',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/depreciations',
                name: 'depreciations',
                component: () =>
                    import(
                        /* webpackChunkName: 'user' */ '../views/depreciations/presentation/pagination/Depreciation.vue'
                    ),
                meta: {
                    title: 'pageTitles.depreciations',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Depreciationfiche'
                }
            },
            {
                path: '/maintenance',
                name: 'maintenance',

                component: () => import(/* webpackChunkName: 'user' */ '../views/Maintenance.vue')
            },
            {
                path: '/creditnotes',
                name: 'creditNotes',
                props: true,
                component: () => import(/* webpackChunkName: 'user' */ '../views/Creditnote.vue'),
                meta: {
                    title: 'pageTitles.creditnotes',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/dailyreceipts',
                name: 'dailyReceipts',
                component: () => import(/* webpackChunkName: 'user' */ '../views/Dailyreceipt.vue'),
                meta: {
                    title: 'pageTitles.dailyreceipts',

                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/jobstudentincomes',
                name: 'jobstudentincomes',
                component: () => import(/* webpackChunkName: 'user' */ '../views/Jobstudentincome.vue'),
                meta: {
                    title: 'pageTitles.jobstudentincomes',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/projects',
                name: 'projects',
                component: () => import(/* webpackChunkName: 'user' */ '../views/Project.vue'),
                meta: {
                    title: 'pageTitles.projects',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/socialcontributions-overview',
                name: 'socialContributionsOverview',
                component: () =>
                    import(
                        /* webpackChunkName: 'user' */ '../views/socialcontributions/pages/SocialcontributionsOverview.vue'
                    ),
                meta: {
                    title: 'pageTitles.socialcontributions_overview',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Socialcontribution'
                }
            },
            {
                path: '/personaltaxes-overview',
                name: 'personalTaxesOverview',
                component: () => import(/* webpackChunkName: 'user' */ '../views/PersonaltaxesOverview.vue'),
                meta: {
                    title: 'pageTitles.personaltaxes_overview',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Personaltaxes'
                }
            },
            {
                path: '/timeregistration-summary',
                name: 'timeregistration-summary',
                component: () =>
                    import(/* webpackChunkName: 'timeregistration' */ '../views/TimeRegistrationSummary.vue'),
                meta: {
                    title: 'pageTitles.timeregistration.report',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/learning-center',
                name: 'learningCenter',
                component: () => import(/* webpackChunkName: 'user' */ '../views/learningCenter/LearningCenter'),
                meta: {
                    title: 'pageTitles.learningcenter.manage_learningcenter',
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/document-communication',
                name: 'documentCommunication',
                component: () => import('../views/communication/DocumentCommunication'),
                meta: {
                    title: 'pageTitles.document_communication',
                    requiresAuth: true
                }
            },
            {
                path: '/components',
                name: 'Components',
                component: () => import(/* webpackChunkName: 'user' */ '../views/components/Components.vue'),
                meta: {
                    requiresUserAccount: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: '/dagboeken',
                name: 'dagboeken',
                component: () => import('../views/dagboeken/DagboekenPage'),
                meta: {
                    title: 'pageTitles.dagboeken',
                    requiresAuth: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            }
        ]
    }
];
