import { generateEndpoint } from './config';
import axios from 'axios';
import { initializePaginationVars } from '@/store/utils';
import { filteringOptions } from '@/common/types';
import { CreateProjectData, Project, UpdateProjectData } from '@/store/modules/project.types';

export const BASE_URL_PROJECTS = generateEndpoint(`/api/projects`);

export function sendGetProjectsRequest() {
    return axios.get(`${BASE_URL_PROJECTS}`).then((response) => response.data);
}
export function getAllProjectsPagination(options: filteringOptions) {
    return axios
        .get(`${BASE_URL_PROJECTS}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function generateInvoicePerProject(invoiceRequest: {
    projectIds: number[];
    beginDateRange: string | null;
    endDateRange: string | null;
    itemMode: number;
    unitMode: number;
}): Promise<void> {
    return axios.post(`${BASE_URL_PROJECTS}/projectsToInvoices`, invoiceRequest).then((response) => response.data);
}

export function getActiveProjects(): Promise<Project[]> {
    return axios.get(`${BASE_URL_PROJECTS}/active`).then((response) => response.data);
}

export function getActiveProjectsWithTimeregistrations() {
    return axios.get(`${BASE_URL_PROJECTS}/active/withRegistrations`).then((response) => response.data);
}

export function searchProjects(input: string): Promise<Project[]> {
    return axios.get(`${BASE_URL_PROJECTS}/search?search=${input}`).then((response) => response.data);
}

export function createProject(project: CreateProjectData): Promise<Project> {
    return axios.post(`${BASE_URL_PROJECTS}`, { project }).then((response) => response.data);
}

export function updateProject(project: UpdateProjectData): Promise<Project> {
    return axios
        .put(`${BASE_URL_PROJECTS}/${project.id}`, {
            project
        })
        .then((response) => response.data);
}
export function toggleProjectClosed(projectId: number): Promise<Project> {
    return axios.put(`${BASE_URL_PROJECTS}/${projectId}/toggleClosed`).then((response) => response.data);
}
export function deleteProject(id: number): Promise<void> {
    return axios.delete(`${BASE_URL_PROJECTS}/${id}`).then((response) => response.data);
}
