export type RequestPaginationOptions = {
    force?: boolean;
    q?: string;
    limit: number;
    offset?: number;
    currentPage: number;
};

export interface IPaginationResponse<T> {
    previousPage: number | null;
    currentPage: number | null;
    total: number;
    limit: number;
    data: T[];
}

export const initializePaginationVars = function (
    options: RequestPaginationOptions,
    sortingOptions?: {
        sortBy: string[];
        sortDesc: boolean[];
    }
): {
    force: boolean;
    page: number;
    offset: number;
    limit: number;
    q: string;
    sortByColumn: undefined | string[];
    sortDesc: boolean | undefined;
} {
    const force = options?.force || false;
    const page = options?.currentPage || 1;
    const offset = options?.offset || 1;
    const limit = options?.limit || 10;
    const q = options?.q || '';

    return {
        force,
        page,
        offset,
        limit,
        q,
        sortByColumn: sortingOptions?.sortBy,
        sortDesc: sortingOptions?.sortDesc[0] // We must return a boolean to the backend...
    };
};
