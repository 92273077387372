import { Quarters } from '@/config/constants';
import { generateEndpoint } from '@/services/config';
import { IQuarterViewModel, QuarterType } from '@/services/quarter.types';
import axios, { AxiosResponse } from 'axios';

export const BASE_URL_QUARTERS = generateEndpoint('/api/quarters');

export interface vatDeclaration {
    IBAN: string;
    BIC: string;
    structuredNumber: string;
}

export async function getQuartersInformation(year: number): Promise<IQuarterViewModel[]> {
    const { data } = await axios.get(`${BASE_URL_QUARTERS}/information/year/${year}`);
    return data;
}

export async function sendGetBookingsFromQuarterAndRasterRequest(
    year: number,
    quarter: QuarterType,
    raster: number
): Promise<
    {
        raster: number;
        amount: number;
        date: string;
        description: string;
        bookableType: string;
        bookableId: number;
        bookable: any;
    }[]
> {
    return axios
        .get(`${BASE_URL_QUARTERS}/year/${year}/${quarter}/bookings/rasters/${raster}`)
        .then((response) => response.data);
}

export async function sendDeleteBookingRequest(bookingId: number): Promise<void> {
    return axios.delete(`${BASE_URL_QUARTERS}/bookings/${bookingId}`);
}

export async function getAllVATListingResultsRequest(year: number): Promise<IQuarterViewModel[]> {
    const { data } = await axios.get(`${BASE_URL_QUARTERS}/year/${year}`);
    return data;
}

export async function sendGetQuarterResultRequest(year: number, quarter: Quarters): Promise<number | null> {
    const { data } = await axios.get(`${BASE_URL_QUARTERS}/year/${year}/${quarter}/result`);
    return data;
}

export async function sendUpdateQuarterRequest(year: number, quarter: Quarters, isClosed: boolean): Promise<void> {
    const { data } = await axios.put(`${BASE_URL_QUARTERS}/year/${year}/${quarter}`, {
        closed: isClosed
    });
    return data;
}

export async function sendGetVatDeclarationInfo(): Promise<AxiosResponse<vatDeclaration | undefined>> {
    return await axios.get(`${BASE_URL_QUARTERS}/vatdeclaration/info`);
}

export function sendGetVATDeclarationTextRequest(year: number, quarter: QuarterType): Promise<{ result: string }> {
    return axios.get(`${BASE_URL_QUARTERS}/year/${year}/${quarter}/text`).then((response) => response.data);
}
