<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'SelectInput',
    inheritAttrs: false,
    props: {
        value: {
            default: null
        },
        items: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    emits: ['input'],
    setup(props, { emit }) {
        const computedValue = computed({
            get() {
                return props.value;
            },
            set(newValue) {
                emit('input', newValue);
            }
        });

        return {
            computedValue
        };
    }
});
</script>

<template>
    <v-select
        v-model="computedValue"
        :label="label"
        :items="items"
        dense
        outlined
        v-bind="$attrs"
        :class="{ required }"
    />
</template>
