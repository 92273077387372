<template>
    <div class="px-4 py-0 NavigationWrapper">
        <desktop-header :total-number-of-learning-center-items="totalNumberOfLearningCenterItems" />
        <mobile-header />
    </div>
</template>

<script>
// --- Components ---
import MobileHeader from '@/components/Header/MobileHeader';
import DesktopHeader from '@/components/Header/DesktopHeader';

export default {
    components: {
        MobileHeader,
        DesktopHeader
    },
    props: {
        totalNumberOfLearningCenterItems: {
            type: Number,
            default: 0
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../sass/variables';

.NavigationWrapper {
    height: $header-height;
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: space-between;
    background-color: $white;
    border-bottom: 1px solid rgba($grey-100, 0.5);
    box-shadow: 0 0 12px rgba($grey-100, 0.2);
    z-index: 9;
}
</style>
