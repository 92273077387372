import { generateEndpoint } from '@/services/config';
import axios from 'axios';
import { ISupplierViewModel } from 'dexxter-types';
import { initializePaginationVars } from '@/store/utils';
import { filteringOptions, PaginationResponse } from '@/common/types';
import { CreateSupplierData, UpdateSupplierData } from '@/services/suppliers.types';

export const BASE_URL_SUPPLIERS = generateEndpoint('/api/suppliers');

export function sendGetAllSuppliersRequest(): Promise<ISupplierViewModel[]> {
    return axios.get(`${BASE_URL_SUPPLIERS}/all`).then((response) => response.data);
}

export function sendCreateSupplierRequest(supplier: CreateSupplierData): Promise<ISupplierViewModel> {
    return axios.post(`${BASE_URL_SUPPLIERS}`, { supplier }).then((response) => response.data);
}
export function sendDeleteSupplierRequest(supplierId: number): Promise<void> {
    return axios.delete(`${BASE_URL_SUPPLIERS}/${supplierId}`).then((response) => response.data);
}
export function sendGetAllSuppliersPaginationRequest(
    options: filteringOptions
): Promise<PaginationResponse<ISupplierViewModel>> {
    return axios
        .get(`${BASE_URL_SUPPLIERS}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}
export function sendSearchSupplierRequest(input: string): Promise<ISupplierViewModel[]> {
    return axios.get(`${BASE_URL_SUPPLIERS}/search?search=${input}`).then((response) => response.data);
}
export function sendUpdateSupplierRequest(supplier: UpdateSupplierData): Promise<ISupplierViewModel> {
    return axios.put(`${BASE_URL_SUPPLIERS}/${supplier.id}`, { supplier }).then((response) => response.data);
}
