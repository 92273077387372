
import { defineComponent } from 'vue';

import { ParagraphTag, TextColorClass } from '@/components/typography';

interface ParagraphProps {
    tag?: ParagraphTag;
    loading?: boolean;
    color?: TextColorClass;
    className?: string;
}

export default defineComponent<ParagraphProps>({
    name: 'Paragraph',
    props: {
        tag: {
            type: String,
            default: 'p'
        },
        loading: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'dark-grey'
        },
        className: {
            type: String,
            default: ''
        }
    }
});
