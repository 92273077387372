<template>
    <text-field
        class="companyNumberSelection"
        v-bind="$attrs"
        v-on="$listeners"
        @paste.stop.prevent="companyNumberPasteSanitize"
    >
        <template v-for="(index, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data"></slot>
        </template>
        <template v-for="(index, name) in $slots" :slot="name">
            <slot :name="name"></slot>
        </template>
        <template #prepend-inner>
            <span translate="no">{{ selectedCountry }} </span>
        </template>
    </text-field>
</template>

<script>
import { clearFirstCountryCharactersFromPasteEvent } from '@/helpers/general';

export default {
    props: {
        selectedCountry: {
            type: String,
            default: ''
        }
    },

    methods: {
        companyNumberPasteSanitize(event) {
            this.$emit('input', clearFirstCountryCharactersFromPasteEvent(event));
        }
    }
};
</script>

<style lang="scss" scoped></style>
