<template>
    <div class="d-flex">
        <v-text-field
            v-mask="'##-##-####'"
            :value="dateString"
            hide-details="auto"
            outlined
            dense
            v-bind="$attrs"
            :rules="rules"
            :error-messages="errors"
            :error="error"
            placeholder="dd-mm-jjjj"
            :class="{ required }"
            :readonly="disableTyping"
            @click.prevent="clickAction"
            @blur="checkValidity($event.target.value)"
            @input="manualTyping"
        />
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="290">
            <template #activator="{ on, attrs }">
                <v-btn color="primary" icon v-bind="attrs" v-on="on"><v-icon small>mdi-calendar</v-icon></v-btn>
            </template>
            <v-date-picker
                v-show="isDatePickerVisible"
                v-model="date"
                color="primary"
                :show-current="currentDate"
                :min="min"
                :max="max"
                no-title
                first-day-of-week="1"
                @input="handleInput"
            />
        </v-menu>
    </div>
</template>

<script>
// --- Modules ---
import dayjs from 'dayjs';

import { formRulesMixin } from '@/mixins/formRulesMixin.js';
export default {
    mixins: [formRulesMixin],
    inheritAttrs: false,

    props: {
        active: {
            required: false,
            default: dayjs().format('YYYY-MM-DD')
        },
        min: {
            default: null,
            required: false
        },
        max: {
            default: null,
            required: false
        },
        value: {
            type: String,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        disableTyping: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            menu: false,
            date: null,
            isDatePickerVisible: true,
            errors: [],
            test: null
        };
    },

    computed: {
        currentDate() {
            return this.active;
        },
        dateString() {
            if (this.value && dayjs(this.value, 'YYYY-MM-DD').isValid()) {
                return dayjs(this.value, 'YYYY-MM-DD').format('DD-MM-YYYY');
            } else {
                return this.value;
            }
        },
        rules() {
            if (this.required) {
                return this.requiredRules;
            }

            return [];
        },
        error() {
            return this.errors && this.errors.length > 0;
        }
    },

    watch: {
        value(newValue) {
            this.checkValidity(newValue);
            if (dayjs(newValue, 'YYYY-MM-DD', true).isValid()) {
                this.date = newValue;
            } else {
                this.date = dayjs().format('YYYY-MM-DD');
            }
        }
    },

    methods: {
        clickAction() {
            if (this.disableTyping) {
                this.menu = true;
            }
        },
        checkValidity(value) {
            if (value === null || (value === '' && !this.required)) {
                // When a form gets reset and they set the value to null, the error should dissapear
                this.errors = [];
                return;
            }

            if (!dayjs(value, 'DD-MM-YYYY', true).isValid() && !dayjs(value, 'YYYY-MM-DD', true).isValid()) {
                this.errors = ['Dit is geen geldige datum'];
            } else {
                this.errors = [];
            }
        },
        handleInput(value) {
            this.$emit('input', value);
            if (dayjs(value, 'YYYY-MM-DD', true).isValid()) {
                this.menu = false;
                this.errors = [];
            }
        },
        manualTyping(value) {
            if (dayjs(value, 'DD-MM-YYYY', true).isValid()) {
                this.errors = [];
                this.$emit('input', dayjs(value, 'DD-MM-YYYY', true).format('YYYY-MM-DD'));
            } else if (value === '') {
                this.$emit('input', null);
            } else {
                this.$emit('input', value);
            }
        },
        close() {
            this.menu = false;
            this.isDatePickerVisible = true;
        }
    }
};
</script>
