type PopupName =
    | 'WELCOME_TO_DEXXTER_MOBILE'
    | 'WELCOME_TO_DEXXTER'
    | 'WHITELIST_DEXXTER_EMAIL'
    | 'SOCIALCONTRIBUTIONS_OPENFIRSTTIME'
    | 'INVOICESETTINGS_OPENFIRSTTIME'
    | 'BOOK_INTRODUCTION_CALL';

export class PopupFactory {
    static createPopup(popupName: PopupName) {
        return {
            content: `popups.${popupName}.content`,
            buttonText: `popups.${popupName}.buttonText` || 'popups.defaultButtonText',
            linkButtonText: `popups.${popupName}.linkButtonText`,
            readmore: `popups.${popupName}.readmore`
        };
    }
}
