export const authRoutes = [
    {
        path: '/login',
        name: 'login',
        // route level code-splitting
        component: () => import('../views/auth/Login.vue'),
        meta: {
            title: 'pageTitles.login',
            requiresAuth: false,
            dontTrack: true
        }
    },
    {
        path: '/user-disabled',
        name: 'userDisabled',
        // route level code-splitting
        component: () => import('../views/userDisabled.vue'),
        meta: {
            title: 'pageTitles.userDisabled',
            requiresAuth: true
        }
    },
    {
        path: '/user-migrated',
        name: 'userMigrated',
        // route level code-splitting
        component: () => import('../views/UserMigrated.vue'),
        meta: {
            title: 'pageTitles.userMigrated'
        }
    },
    {
        path: '/register',
        name: 'register',
        // route level code-splitting
        component: () => import('../views/Register.vue'),
        meta: {
            dontTrack: true
        }
    },
    {
        path: '/register-accountant',
        name: 'register-accountant',
        // route level code-splitting
        component: () => import('../views/RegisterAccountant.vue'),
        meta: {
            dontTrack: true
        }
    },
    {
        path: '/registration-complete',
        name: 'registrationComplete',
        // route level code-splitting
        component: () => import('../views/UserMigrated.vue'),
        meta: {
            requiresAuth: true,
            dontTrack: true
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        // route level code-splitting
        component: () => import('../views/UserMigrated.vue'),
        meta: {
            dontTrack: true
        }
    },
    {
        // Verify emailadres
        path: '/verify-emailadres',
        name: 'verify-emailadres',
        // route level code-splitting
        component: () => import('../views/UserMigrated.vue'),
        meta: {
            dontTrack: true
        }
    },
    {
        // Emailadres is not yet verified
        path: '/emailadres-verified',
        name: 'emailadres-verified',
        // route level code-splitting
        component: () => import('../views/UserMigrated.vue'),
        meta: {
            requiresAuth: true,
            dontTrack: true
        }
    },
    {
        path: '/password-reset/:token',
        name: 'password-reset',
        // route level code-splitting
        component: () => import('../views/UserMigrated.vue'),
        meta: {
            dontTrack: true
        }
    },
    {
        path: '/complete-user-registration',
        name: 'completeUserRegistration',
        component: () => import('../views/registration/completeUserRegistration/views/CompleteUserRegistration.vue'),
        meta: {
            title: 'pageTitles.completeUserRegistration',
            requiresUserAccount: true,
            requiresAuth: true
        }
    },
    {
        path: '/complete-user-registration/nonexisting-company',
        name: 'CompleteUserRegistrationWithoutCompany',
        component: () =>
            import('../views/registration/completeUserRegistration/views/CompleteUserRegistrationWithoutCompany.vue'),
        meta: {
            title: 'pageTitles.completeUserRegistration',
            requiresUserAccount: true,
            requiresAuth: true
        }
    },
    {
        path: '/complete-user-registration/verhuur-vakantiewoningen',
        name: 'CompleteUserRegistrationVakantiewoningen',
        component: () =>
            import('../views/registration/completeUserRegistration/views/CompleteUserRegistrationVakantiewoningen.vue'),
        meta: {
            title: 'pageTitles.completeUserRegistration',
            requiresUserAccount: true,
            requiresAuth: true
        }
    },
    {
        path: '/complete-user-registration/existing-company',
        name: 'CompleteUserRegistrationWithCompany',
        component: () =>
            import('../views/registration/completeUserRegistration/views/CompleteUserRegistrationWithCompany.vue'),
        meta: {
            title: 'pageTitles.completeUserRegistration',
            requiresUserAccount: true,
            requiresAuth: true
        }
    }
];
