import { isUserAccountWithFreeTier } from '@/helpers/auth';
import store from '../../store';
import { routeRequiresAuth } from './helpers/index';

export function redirectIfUserHasNoSubscription(to, from, next) {
    const requiresAuth = routeRequiresAuth(to);
    const freeTierRoute = to.matched.some((record) => record.meta.freeTier);

    if (requiresAuth) {
        const user = store.state.auth.currentUserData;

        if (isUserAccountWithFreeTier(user) && !freeTierRoute) {
            return next({
                name: 'userSettingsGeneral'
            });
        }
    }

    return next();
}
