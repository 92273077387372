
import { computed, defineComponent } from 'vue';

// Pass 'custom' as purpose to set custom icon
interface InfoBarProps {
    icon?: string;
    purpose?: 'success' | 'warning' | 'error' | 'info' | 'custom';
    className?: string;
}

export default defineComponent<InfoBarProps>({
    name: 'Alert',
    props: {
        icon: {
            type: String,
            default: ''
        },
        purpose: {
            type: String,
            default: 'info'
        },
        className: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const iconBasedOnPurpose = computed(() => {
            switch (props.purpose) {
                case 'info':
                    return 'mdi-information';
                case 'warning':
                    return 'mdi-alert';
                case 'error':
                    return 'mdi-alert-circle';
                case 'success':
                    return 'mdi-check-circle';
                case 'custom':
                    return props.icon;
                default:
                    return 'mdi-information';
            }
        });

        return {
            iconBasedOnPurpose
        };
    }
});
