import { initializePaginationVars, IPaginationResponse, RequestPaginationOptions } from '@/store/utils';
import {
    CreateLearningcenterItemData,
    ILearningcenterViewModel,
    LearningcenterLocation,
    LearningcenterUserTypes,
    LearningcenterVATTypes,
    UpdateLearningcenterItemData
} from './learningcenter.types';
import { generateEndpoint } from '@/services/config';
import axios from 'axios';

const BASE_URL = generateEndpoint(`/api/learningcenteritems`);

export function sendGetVATTypesRequest(): Promise<LearningcenterVATTypes[]> {
    return axios.get(`${BASE_URL}/vattypes`).then((response) => response.data);
}

export function sendGetUserTypesRequest(): Promise<LearningcenterUserTypes[]> {
    return axios.get(`${BASE_URL}/usertypes`).then((response) => response.data);
}

export function sendGetLocationsRequest(): Promise<LearningcenterLocation[]> {
    return axios.get(`${BASE_URL}/locations`).then((response) => response.data);
}

export function sendCreateLearningcenteritemRequest(
    learningcenteritemData: CreateLearningcenterItemData
): Promise<ILearningcenterViewModel> {
    return axios.post(`${BASE_URL}`, learningcenteritemData).then((response) => response.data);
}

export function sendUpdateLearningcenteritemRequest(
    learningcenteritemId: number,
    learningcenteritemData: UpdateLearningcenterItemData
): Promise<ILearningcenterViewModel> {
    return axios.put(`${BASE_URL}/${learningcenteritemId}`, learningcenteritemData).then((response) => response.data);
}

export function sendDeleteLearningcenteritemRequest(learningcenteritemId: number): Promise<void> {
    return axios.delete(`${BASE_URL}/${learningcenteritemId}`).then((response) => response.data);
}

export function sendGetLearningcenteritemRequest(learningcenteritemId: number): Promise<ILearningcenterViewModel> {
    return axios.get(`${BASE_URL}/${learningcenteritemId}`).then((response) => response.data);
}

export function sendGetLearningcenteritemByLanguageRequest(language: string): Promise<ILearningcenterViewModel[]> {
    return axios.get(`${BASE_URL}/language/${language}`).then((response) => response.data);
}

export function sendGetLearningcenteritemPaginationRequest(options: {
    pagination: RequestPaginationOptions;
    query: Record<string, any>;
}): Promise<IPaginationResponse<ILearningcenterViewModel>> {
    return axios
        .get(`${BASE_URL}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}
