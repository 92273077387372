<template>
    <v-bottom-sheet
        ref="bottomSheet"
        v-model="modal"
        :max-width="maxWidth"
        transition="fab-transition"
        class="bottom-sheet"
        retain-focus
        scrollable
        eager
        content-class="multipleModal"
        overlay-opacity="0.25"
        @input="$emit('input', $event)"
    >
        <v-card>
            <div class="close-button">
                <v-btn color="black" icon small @click="closeModal"><v-icon>mdi-close</v-icon></v-btn>
            </div>

            <div class="illustration">
                <img :src="illustration" />
            </div>

            <div class="Content">
                <h2 class="Title">{{ title }}</h2>
                <div class="LeadText" v-html="content"></div>
            </div>

            <div v-if="getContent && getContent.length > 1" class="Bullets">
                <v-btn
                    v-for="(notification, index) of getContent"
                    :key="notification.id"
                    icon
                    @click="selectCurrent(index)"
                    ><v-icon :class="{ active: index === current }" size="40">mdi-circle-small</v-icon></v-btn
                >
            </div>
            <div v-else class="d-flex justify-center">
                <a :href="linkButtonLink" target="_blank"
                    ><submit-button v-if="canShowLinkButtonText" class="mr-3 mt-6">{{
                        linkButtonText
                    }}</submit-button></a
                >
                <submit-button dexxter-action="closeButton" class="mt-6" @click="closeModal">{{
                    buttonText
                }}</submit-button>
            </div>
        </v-card>
    </v-bottom-sheet>
</template>

<script>
// --- Components ---
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';

import { PopupFactory } from '@/factories/popup';
import _ from 'lodash';

// --- Constants ---

export default {
    mixins: [formRulesMixin, screenSizeMixin],
    props: {
        notificationInput: {}
    },

    data() {
        return {
            current: 0,
            modal: false
        };
    },

    computed: {
        maxWidth() {
            if (this.isSmallDevice) {
                return '100%';
            } else {
                return '35%';
            }
        },
        notification() {
            return this.fillInPopupi18nStrings(PopupFactory.createPopup(this.notificationInput.name));
        },
        buttonText() {
            if (!this.notification || this.notification.content.length === 0) {
                return [];
            }

            return this.notification.buttonText || this.$t('general.popup_confirm');
        },
        linkButtonText() {
            return this.notification.linkButtonText;
        },
        linkButtonLink() {
            return this.notification.readmore;
        },
        canShowLinkButtonText() {
            return !_.isEmpty(this.notification.linkButtonText);
        },
        getContent() {
            if (!this.notification || this.notification.content.length === 0) {
                return [];
            }

            return this.notification.content;
        },
        title() {
            if (!this.notification || this.notification.content.length === 0) {
                return;
            }

            return this.notification.content[this.current].title;
        },
        content() {
            if (!this.notification || this.notification.content.length === 0) {
                return;
            }

            return this.notification.content[this.current].content;
        },
        illustration() {
            if (!this.notification || this.notification.content.length === 0) {
                return;
            }

            return this.notification.content[this.current].illustration;
        }
    },

    watch: {
        notification: {
            handler(newNotification) {
                if (newNotification) {
                    this.modal = true;
                }
            },
            immediate: true,
            deep: true
        }
    },

    methods: {
        selectCurrent(value) {
            this.current = value;
        },
        closeModal() {
            this.$emit('input', { status: false });
            this.modal = false;
        },
        openModal() {
            this.modal = true;
        },
        fillInPopupi18nStrings(popup) {
            return {
                content: this.$t(popup.content),
                buttonText: this.$t(popup.buttonText),
                linkButtonText: this.$i18n.te(popup.linkButtonText) ? this.$t(popup.linkButtonText) : null,
                readmore: this.$i18n.te(popup.readmore) ? this.$t(popup.readmore) : null
            };
        }
    }
};
</script>

<style lang="scss">
i.active {
    color: $dexxter-blue !important;
}

.multipleModal {
    box-shadow: none;

    .close-button {
        position: absolute;
        right: 10px;
        top: 10px;
    }

    .Title {
        text-align: center;
        margin-bottom: 10px;
    }

    .illustration {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
        object-fit: cover;

        img {
            height: 150px;
            width: auto;
        }
    }

    .LeadText {
        text-align: center;

        p {
            margin-bottom: 8px;
        }
    }

    .Content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Bullets {
        margin-top: 45px;
        margin-bottom: 15px;
        button {
            width: 20px;
            height: 20px;
        }
        display: flex;
        justify-content: center;
    }
}
</style>
