import axios from 'axios';
import { initializePaginationVars } from '@/store/utils';
import { generateEndpoint } from '@/services/config';
import { filteringOptions, PaginationResponse } from '@/common/types';
import { ICreateExpenseData, IExpenseViewModel, IUpdateExpenseData } from '@/services/expenses.types';

const BASE_URL = generateEndpoint('/api/expenses');

export function getExpensesSum(year: number, group: 'month' | 'quarter'): Promise<Record<string, { total: number }>> {
    return axios
        .get(`${BASE_URL}/grouped/year/${year}`, {
            params: {
                group
            }
        })
        .then((response) => response.data);
}

export function countExpensesNotSendToAccountantRequest(): Promise<number> {
    return axios.get(`${BASE_URL}/countNotSendToAccountant`).then((response) => response.data);
}

export function getTotalCost(year: number): Promise<number> {
    return axios.get(`${BASE_URL}/totalcost/year/${year}`).then((response) => response.data);
}

export function getCountAllUnpaidExpenses(): Promise<number> {
    return axios.get(`${BASE_URL}/unpaid/count`).then((response) => response.data);
}

export function getExpense(id: number): Promise<IExpenseViewModel> {
    return axios.get(`${BASE_URL}/${id}`).then((response) => response.data);
}

export function deleteExpense(id: number): Promise<void> {
    return axios.delete(`${BASE_URL}/${id}`).then((response) => response.data);
}

export function togglePayedExpense(expenseId: number): Promise<IExpenseViewModel> {
    return axios.put(`${BASE_URL}/${expenseId}/togglePayed`).then((response) => response.data);
}

export function createExpense(expense: ICreateExpenseData): Promise<IExpenseViewModel> {
    return axios.post(`${BASE_URL}`, expense).then((response) => response.data);
}

export function updateExpense(expense: IUpdateExpenseData): Promise<IExpenseViewModel> {
    return axios.put(`${BASE_URL}/${expense.id}`, expense).then((response) => response.data);
}

export function findExistingExpense({
    documentDate,
    supplier,
    amountEuroInclVAT
}: {
    amountEuroInclVAT?: number;
    supplier: { id: number };
    documentDate: string;
}): Promise<{ found: boolean }> {
    return axios
        .get(`${BASE_URL}/checkExpenseExists`, {
            params: {
                documentDate,
                supplierId: supplier.id,
                amountEuroInclVAT
            }
        })
        .then((response) => response.data);
}

export function getExpenses(year: number, options: filteringOptions): Promise<PaginationResponse<IExpenseViewModel>> {
    return axios
        .get(`${BASE_URL}/year/${year}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function getExpensesNotYetBookedPagination(
    year: number,
    options: filteringOptions
): Promise<PaginationResponse<IExpenseViewModel>> {
    return axios
        .get(`${BASE_URL}/not-yet-booked/year/${year}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}
