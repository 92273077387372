/* eslint-disable no-unused-vars */

import { RootState } from '@/store/store.types';
import { ZipCodeState } from '@/store/types/zipcode';
import { Module } from 'vuex';
import {
    sendCreateZipcodeRequest,
    sendGetZipcodePaginationRequest,
    sendUpdateZipcodeRequest
} from '../../services/zipcodes';

function initialState(): ZipCodeState {
    return {
        newZipcodeEvent: []
    };
}

export default {
    namespaced: true,

    state: initialState(),

    actions: {
        async getZipcodePagination(context, { options, year }) {
            return sendGetZipcodePaginationRequest({ options, year });
        },

        createZipcode(context, zipcode) {
            return sendCreateZipcodeRequest(zipcode);
        },
        updateZipcode(context, zipcode) {
            return sendUpdateZipcodeRequest(zipcode);
        },
        newZipcodeUpdated({ commit }) {
            commit('SET_NEW_ZIPCODE_EVENT');
        }
    },

    mutations: {
        SET_NEW_ZIPCODE_EVENT(state) {
            state.newZipcodeEvent = state.newZipcodeEvent.concat(1);
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<ZipCodeState, RootState>;
