import { get, isEmpty, set } from 'lodash';
import { DefaultMapKeys } from '@/mixins/lookupCompanynumberMixin';

export const useLookupCompanyNumber = () => {
    const fillInMissingCompanyInfoBasedOnLookup = (data: any, lookupInfo: any, mapKeys = DefaultMapKeys): void => {
        const attributes = [
            { lookupInfoKey: 'companyName', customerKey: mapKeys.companyName },
            { lookupInfoKey: 'zipcode', customerKey: mapKeys.zipcode },
            { lookupInfoKey: 'street', customerKey: mapKeys.street },
            { lookupInfoKey: 'number', customerKey: mapKeys.number },
            { lookupInfoKey: 'busNumber', customerKey: mapKeys.busNumber },
            { lookupInfoKey: 'city', customerKey: mapKeys.city }
        ];

        attributes.forEach((attribute) => {
            if (isEmpty(get(data, attribute.customerKey))) {
                set(data, attribute.customerKey, get(lookupInfo, attribute.lookupInfoKey));
            }
        });
    };

    return { fillInMissingCompanyInfoBasedOnLookup };
};
