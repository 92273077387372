import store from '../../store';
import { routeRequiresAuth } from './helpers/index';
export function redirectIfUserIsMigrated(to, from, next) {
    const requiresAuth = routeRequiresAuth(to);

    if (requiresAuth) {
        const user = store.state.auth.currentUserData;

        // Disabled user should be redirected
        if (isUserMigrated(user) && to.path != '/user-migrated') {
            return next({ name: 'userMigrated' });
        }
    }

    return next();
}

function isUserMigrated(user) {
    return user.userinformation.migratedAccount === true;
}
