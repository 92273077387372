<template>
    <div>
        <v-list-item :to="{ name: 'users' }">
            <v-list-item-icon>
                <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.users') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'manageNotifications' }">
            <v-list-item-icon>
                <v-icon>mdi-bell</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.notifications') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'calendarevents' }">
            <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('pageTitles.manage_calendarevents') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'marketing' }">
            <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('pageTitles.marketing') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'manageLearningCenter' }">
            <v-list-item-icon>
                <v-icon>mdi-school</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('pageTitles.learningcenter.manage_learningcenter') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ name: 'adminSettings' }">
            <v-list-item-icon>
                <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.settings') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-group no-action>
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-chart-bubble</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.reports') }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item to="/admin/reports/users">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.user_report') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>

        <v-list-group no-action>
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Community</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'communityQuestions' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('pageTitles.community') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'createCommunityQuestion' }">
                <v-list-item-content>
                    <v-list-item-title>Stel een vraag</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'viewUserCommunityQuestion', params: { id: currentUserData.id } }">
                <v-list-item-content>
                    <v-list-item-title>Bekijk je vragen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'communityQuestionsOverview' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.community_overview') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>

        <v-subheader v-if="!isCollapsed">{{ $t('navigation.management').toUpperCase() }}</v-subheader>

        <v-list-group no-action>
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settings') }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'userSettingsGeneral' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settingsMenu.general') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
    </div>
</template>

<script>
// --- State ---
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';

// --- Mixins ---
import { screenSizeMixin } from '../../mixins/screenSizeMixin';

export default {
    name: 'AdminNavigationItems',
    mixins: [screenSizeMixin],
    props: {
        isCollapsed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState('auth', ['currentUserData']),
        ...mapFields('users', ['selectedUsers'])
    }
};
</script>

<style lang="scss" scoped></style>
