/**
 *
 * @param {Boolean} includeEuEntry
 * @returns
 */
export const getEuCountries = function (includeEuEntry = false) {
    return [
        ...(includeEuEntry
            ? [
                  {
                      name: this.$i18n.t('countries.europe'),
                      value: 'EU'
                  }
              ]
            : []),
        {
            name: this.$i18n.t('countries.oostenrijk'),
            value: 'AT'
        },
        {
            name: this.$i18n.t('countries.bulgarije'),
            value: 'BG'
        },
        {
            name: this.$i18n.t('countries.kroatie'),
            value: 'HR'
        },
        {
            name: this.$i18n.t('countries.cyprus'),
            value: 'CY'
        },
        {
            name: this.$i18n.t('countries.tjechie'),
            value: 'CZ'
        },
        {
            name: this.$i18n.t('countries.denemarken'),
            value: 'DK'
        },
        {
            name: this.$i18n.t('countries.estland'),
            value: 'EE'
        },
        {
            name: this.$i18n.t('countries.finland'),
            value: 'FI'
        },
        {
            name: this.$i18n.t('countries.frankrijk'),
            value: 'FR'
        },
        {
            name: this.$i18n.t('countries.duitsland'),
            value: 'DE'
        },
        {
            name: this.$i18n.t('countries.griekenland'),
            value: 'EL'
        },
        {
            name: this.$i18n.t('countries.hongarije'),
            value: 'HU'
        },
        {
            name: this.$i18n.t('countries.ierland'),
            value: 'IE'
        },
        {
            name: this.$i18n.t('countries.italie'),
            value: 'IT'
        },
        {
            name: this.$i18n.t('countries.letland'),
            value: 'LV'
        },
        {
            name: this.$i18n.t('countries.litouwen'),
            value: 'LT'
        },
        {
            name: this.$i18n.t('countries.luxemburg'),
            value: 'LU'
        },
        {
            name: this.$i18n.t('countries.malta'),
            value: 'MT'
        },
        {
            name: this.$i18n.t('countries.nederland'),
            value: 'NL'
        },
        {
            name: this.$i18n.t('countries.polen'),
            value: 'PL'
        },
        {
            name: this.$i18n.t('countries.portugal'),
            value: 'PT'
        },
        {
            name: this.$i18n.t('countries.roemnie'),
            value: 'RO'
        },
        {
            name: this.$i18n.t('countries.slovaijke'),
            value: 'SK'
        },
        {
            name: this.$i18n.t('countries.slovenie'),
            value: 'SI'
        },
        {
            name: this.$i18n.t('countries.spanje'),
            value: 'ES'
        },
        {
            name: this.$i18n.t('countries.zweden'),
            value: 'SE'
        }
    ];
};
