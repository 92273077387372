import axios from 'axios';
import {
    CreateAccountantbccemailData,
    IBCCMailaddressViewModel,
    sendAllDocumentsData
} from './accountantemailbcc.types';

const BASE_URL = '/api/accountantemailbcc';

export function getAccountantEmailBCCs(): Promise<IBCCMailaddressViewModel[]> {
    return axios.get(`${BASE_URL}`).then((response) => response.data);
}

export function createAccountantEmailBCC(data: CreateAccountantbccemailData): Promise<IBCCMailaddressViewModel> {
    return axios.post(`${BASE_URL}`, data).then((response) => response.data);
}

export function deleteAccountantEmailBCC(accountantemailbccID: number): Promise<void> {
    return axios.delete(`${BASE_URL}/${accountantemailbccID}`).then((response) => response.data);
}

export function sendDocumentsRequest(options: sendAllDocumentsData): Promise<void> {
    return axios.post(`${BASE_URL}/sendAll`, options).then((response) => response.data);
}
