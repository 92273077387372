<template>
    <base-bottom-sheet v-if="open" v-model="open" style="border-radius: 10px" :width="width" no-overflow-y>
        <template #header>
            <div class="Header mb-4">
                <div class="vCardHeader_TitleWrapper">
                    <h4 class="vCardHeader_Title">{{ getTitle }}</h4>
                </div>
            </div>
        </template>
        <template #content>
            <div :style="{ width: '100%', height: `${videoHeight}px`, overflow: 'hidden' }">
                <div ref="video" style="height: 100%; width: 100%" data-id="popupVideo" />
            </div>

            <div v-if="loading" class="text-center">
                <v-progress-circular color="primary" indeterminate size="32" width="4" z-index="999" />
            </div>
        </template>
    </base-bottom-sheet>
</template>

<script>
import { screenSizeMixin } from '../mixins/screenSizeMixin';

export default {
    mixins: [screenSizeMixin],

    props: {
        value: {
            type: Boolean,
            required: true
        },
        i18nPath: {
            type: String
        },
        title: {
            type: String
        },
        url: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            loading: true,
            provider: 'vimeo'
        };
    },

    computed: {
        viewportHeight() {
            return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        },
        viewportWidth() {
            return Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        },
        videoHeight() {
            const videoWidth = this.viewportWidth / 2;
            return videoWidth / (16 / 9);
        },
        open: {
            get() {
                return this.value;
            },
            set(newValue) {
                this.$emit('input', newValue);
            }
        },
        getTitle() {
            if (this.i18nPath && this.$i18n.te(this.i18nPath.concat('.title'))) {
                return this.$t(this.i18nPath.concat('.title'));
            }

            return this.title;
        },
        width() {
            if (this.isSmallDesktopAndUp) {
                return '50%';
            }

            return '50%';
        }
    },

    mounted() {
        setTimeout(() => {
            const iframe = document.createElement('iframe');
            this.$refs.video.appendChild(iframe);
            if (this.provider === 'vimeo') {
                this.vimeoProvider(iframe);
            } else if (this.provider === 'youtube') {
                this.youtubeProvider(iframe);
            } else {
                this.vimeoProvider(iframe);
            }
            this.loading = false;
        }, 250);
    },
    methods: {
        vimeoProvider(iframe) {
            iframe.setAttribute('frameborder', 0);
            iframe.setAttribute('allow', 'autoplay; fullscreen; picture-in-picture');
            iframe.setAttribute('src', this.url);
            iframe.setAttribute('height', '100%');
            iframe.setAttribute('width', '100%');
            iframe.setAttribute('title', 'Dexxter');
            iframe.setAttribute('allowfullscreen', true);
            iframe.setAttribute('loading', 'eager');
        },
        youtubeProvider(iframe) {
            iframe.setAttribute('height', 615);
            iframe.setAttribute('width', '100%');
            iframe.setAttribute('src', this.url);
            iframe.setAttribute('title', 'Dexxter');
            iframe.setAttribute('frameborder', 0);
            iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope');
            iframe.setAttribute('loading', 'eager');
            iframe.setAttribute('allowfullscreen', true);
        }
    }
};
</script>

<style lang="scss" scoped>
.vCardHeader_Title {
    font-size: 1.2rem;
}

.Header {
    border: 0px;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-radius: 0px !important;
}
.Footer {
    border: 0px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    border-radius: 0px !important;
}

.TooltipContent {
    padding-bottom: 10px;
    overflow-y: auto;

    p {
        margin-bottom: 16px !important;
    }
}
.tooltip {
    padding: 20px !important;
}
.readmore {
    padding: 0 19.5555555556px;
}

.activator {
    cursor: pointer;
}
</style>
