
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { chatMixin } from '@/mixins/chat';
import { InitializedUseraccountAuthState } from '@/store/modules/auth';
import { MapStateToComputed } from '@/helpers/types';
import mixins from 'vue-typed-mixins';
import { mapState } from 'vuex';

export default mixins(screenSizeMixin, chatMixin).extend({
    mixins: [screenSizeMixin, chatMixin],

    props: ['value'],

    computed: {
        ...(mapState('auth', ['currentUserData']) as MapStateToComputed<InitializedUseraccountAuthState>),
        isOpen: {
            get(): boolean {
                return this.value;
            },
            set(value: boolean): void {
                this.$emit('input', value);
            }
        },
        maxWidth(): string {
            return '740px';
        }
    },

    methods: {
        questionForDexxterSupport(): void {
            this.openChatDialog({
                email: this.currentUserData.userinformation.email,
                name: `${this.currentUserData.userinformation.firstName} ${this.currentUserData.userinformation.name}`,
                phone: this.currentUserData.userinformation.phone ?? undefined,
                id: this.currentUserData.id.toString()
            });
            this.isOpen = false;
        },
        questionForAdvisors(): void {
            window.open('https://dexxter.be/adviseurs/');
            this.isOpen = false;
        }
    }
});
