import * as Sentry from '@sentry/browser';

export function reportError(error) {
    if (process.env.VUE_APP_MODE !== 'production') {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    if (typeof error === 'string') {
        Sentry.captureException(new Error(error));
    } else {
        Sentry.captureException(error);
    }
}
