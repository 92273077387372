<template>
    <v-text-field
        ref="textfield"
        :class="{ required }"
        v-bind="$attrs"
        outlined
        hide-details="auto"
        dense
        v-on="$listeners"
    >
        <template v-for="(index, name) in $scopedSlots" #[name]="data">
            <slot :name="name" v-bind="data"></slot>
        </template>
        <template v-for="(index, name) in $slots" :slot="name">
            <slot :name="name"></slot>
        </template>
    </v-text-field>
</template>

<script>
export default {
    name: 'TextField',
    inheritAttrs: false,

    props: {
        required: {
            type: Boolean,
            default: false
        },
        number: {
            type: Boolean,
            default: false
        },
        date: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped>
.v-input__prepend-outer :deep() {
    margin-top: 0px !important;
}

.v-icon--link {
    tab-index: 0 !important;
}
</style>
