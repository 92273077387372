
import { computed, defineComponent, ref } from 'vue';
import { useRemainingWindowHeight } from '@/composables/useRemainingWindowHeight';
import { Paddings } from '@/common/types';

interface SidebarProps {
    width: number;
    padding?: Paddings;
    wrapperClass?: string;
}

export default defineComponent<SidebarProps>({
    name: 'Sidebar',
    props: {
        width: {
            type: Number,
            default: 300
        },
        padding: {
            type: String,
            default: '32'
        },
        wrapperClass: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const sidebarRef = ref<HTMLElement>();
        const SCROLLBAR_HEIGHT = 6;

        const { remainingWindowHeightCssVar } = useRemainingWindowHeight(sidebarRef, SCROLLBAR_HEIGHT);

        const widthCssVar = computed(() => {
            return `--sidebar-width: ${props.width}px`;
        });

        const cssVars = computed(() => {
            return `${widthCssVar.value}; ${remainingWindowHeightCssVar.value};`;
        });

        return { sidebarRef, cssVars };
    }
});
