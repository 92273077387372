import dayjs from 'dayjs';
import { isUserAccount, isUserAccountWithFreeTier } from '@/helpers/auth';
import store from '../../store';
import { routeRequiresAuth } from './helpers/index';
export async function getUserTaxInformation(to, from, next) {
    const requiresAuth = routeRequiresAuth(to);

    if (requiresAuth) {
        const user = store.state.auth.currentUserData;

        if (isUserAccount(user) && !isUserAccountWithFreeTier(user)) {
            const yearsOfUser = await store.dispatch('getNumberOfTaxesYears');

            if (!store.state.selectedYear) {
                if (!yearsOfUser.includes(dayjs().year())) {
                    store.dispatch('setSelectedYear', Math.max(...yearsOfUser));
                } else {
                    store.dispatch('setSelectedYear', dayjs().year());
                }
            }
        }
    }

    return next();
}
