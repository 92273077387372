/* eslint-disable no-unused-vars */
import { filteringOptions } from '@/common/types';
import { CreateInvoiceItem, UpdateInvoiceItem } from '@/services/invoiceitems.types';
import { RootState } from '@/store/store.types';
import { InvoiceItemState } from '@/store/types/invoiceitem';
import { Module } from 'vuex';
import {
    sendCreateInvoiceitemRequest,
    sendDeleteInvoiceitemRequest,
    sendGetAllInvoiceitemsPaginationRequest,
    sendGetAllInvoiceitemsRequest,
    sendGetInvoiceitemsRequest,
    sendUpdateInvoiceitemRequest
} from '../../services/invoiceitems';

function initialState(): InvoiceItemState {
    return {
        newInvoiceitemEvents: []
    };
}

export default {
    namespaced: true,

    state: initialState(),

    actions: {
        getAllInvoiceitems() {
            return sendGetAllInvoiceitemsRequest();
        },
        getAllInvoiceitemsPagination(context, options: filteringOptions) {
            return sendGetAllInvoiceitemsPaginationRequest(options);
        },
        getInvoiceitems() {
            return sendGetInvoiceitemsRequest();
        },
        createInvoiceitem(context, invoiceitem: CreateInvoiceItem) {
            return sendCreateInvoiceitemRequest(invoiceitem);
        },
        updateInvoiceitem(context, invoiceitem: UpdateInvoiceItem) {
            return sendUpdateInvoiceitemRequest(invoiceitem);
        },
        deleteInvoiceitem(context, id: number) {
            return sendDeleteInvoiceitemRequest(id);
        },
        async newInvoiceitemEvent({ commit }) {
            commit('SET_NEW_INVOICEITEM_EVENT');
        }
    },

    mutations: {
        SET_NEW_INVOICEITEM_EVENT(state) {
            state.newInvoiceitemEvents = state.newInvoiceitemEvents.concat(1);
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<InvoiceItemState, RootState>;
