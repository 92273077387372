import { generateEndpoint } from './config';
import axios from 'axios';
import { initializePaginationVars } from '@/store/utils';
import { filteringOptions } from '@/common/types';

export const BASE_URL_TIMEREGISTRATION = generateEndpoint(`/api/timeregistrations`);

export function sendCountAllNonInvoicedTimeregistrationsHoursRequest() {
    return axios.get(`${BASE_URL_TIMEREGISTRATION}/uninvoiced/euro`).then((response) => response.data);
}
export function sendGetTimeregistrationsRequest() {
    return axios.get(`${BASE_URL_TIMEREGISTRATION}`).then((response) => response.data);
}
export function sendGetRunningTimerRequest() {
    return axios.get(`${BASE_URL_TIMEREGISTRATION}/runningTimer`).then((response) => response.data);
}

export function sendGetAllTimeregistrationsPaginationRequest(options: filteringOptions) {
    return axios
        .get(`${BASE_URL_TIMEREGISTRATION}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}
export function sendCreateTimeregistrationsRequest(timeregistrations: any) {
    return axios.post(`${BASE_URL_TIMEREGISTRATION}/multiple`, { timeregistrations }).then((response) => response.data);
}
export function sendGetTimeregistrationSummaryRequest({ beginDate }: { beginDate: string }) {
    return axios
        .get(`${BASE_URL_TIMEREGISTRATION}/summary`, {
            params: {
                beginDate
            }
        })
        .then((response) => response.data);
}
export function sendStartTimerRequest(timeregistration: any) {
    return axios.post(`${BASE_URL_TIMEREGISTRATION}/start`, { timeregistration }).then((response) => response.data);
}
export function sendStopTimerRequest(timerID: number) {
    return axios.post(`${BASE_URL_TIMEREGISTRATION}/stop/${timerID}`).then((response) => {
        return response.data;
    });
}
export function sendUpdateTimeregistrationRequest(timeregistration: any) {
    return axios
        .put(`${BASE_URL_TIMEREGISTRATION}/${timeregistration.id}`, {
            timeregistration
        })
        .then((response) => response.data);
}
export function sendTimeregistrationToInvoiceRequest(timeregistrationId: number) {
    return axios.post(`${BASE_URL_TIMEREGISTRATION}/${timeregistrationId}/toInvoice`).then((response) => response.data);
}
export function sendDeleteTimeregistrationRequest(id: number) {
    return axios.delete(`${BASE_URL_TIMEREGISTRATION}/${id}`).then((response) => response.data);
}
