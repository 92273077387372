import { RootState } from '@/store/store.types';
import { BottomSheetsState } from '@/store/types/bottomSheets';
import { Module } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import { initCurrentExpenseCategory } from '../../models/expenseCategory';
import { newInvoiceitem } from '../../models/invoiceitem';

function initialState(): BottomSheetsState {
    return {
        isAddNewBookyearOpen: false,
        isViewingCurrentRasterBookingsOpen: false,
        isCreateInvoiceFromTimeregistrationWizardOpen: false,
        currentRasterWithBookings: null,
        // --- Globalsettings selction ---
        isAddGlobalsettingsOpen: false,
        // --- Invoiceitem selction ---
        isSelectInvoiceitemOpen: false,
        // --- Invenory
        isEditInventoryOpen: false,
        // --- Zippercentage deposit
        isEditZippercentageOpen: false,
        currentZippercentage: initZippercenage(),
        // --- personaltaxes deposit
        // --- Invoiceitem ---
        isAddInvoiceitemOpen: false,
        isCreatingNewInvoiceitem: false,
        currentInvoiceitem: newInvoiceitem(),
        // --- Expense category ---
        isAddExpenseCategoryOpen: false,
        isCreatingNewExpenseCategory: false,
        currentExpenseCategory: initCurrentExpenseCategory(),
        // --- Unit ---
        isAddUnitOpen: false,
        isCreatingNewUnit: false,
        currentUnit: initUnit(),
        // --- Income ---
        isAddCreditnoteOpen: false,
        isCreatingNewCreditnote: false,
        isEditDailyReceiptOpen: false,
        currentDailyReceipt: {
            id: null,
            date: null,
            employername: null,
            hours: null,
            amountEuroInclVAT: null,
            description: null
        },
        isCreatingNewAsEmployee: false,
        currentAsEmployee: {
            id: null,
            year: null,
            employer: null,
            grossAmountEuro: null,
            description: null
        },
        isAddPaymentOpen: false,
        isCreatingNewPayment: false,
        // --- Expenses ---
        isAddExpenseOpen: false,
        isCreatingNewExpense: false,

        // --- Projects ---
        isAddProjectOpen: false,
        isCreatingNewProject: false,
        currentProject: initCurrentProject(),
        // --- Time Registrations ---
        isAddTimeRegistrationOpen: false,
        isCreatingNewTimeRegistration: false,
        currentTimeRegistration: {
            description: null,
            start: null,
            end: null,
            projectId: null
        },
        // --- Account ---
        isAddAccountOpen: false,
        isCreatingNewAccount: false,
        currentAccount: {
            name: null,
            firstName: null,
            email: null,
            password: null,
            passwordRepeat: null,
            birthDate: null,
            language: null,
            socialSecretariat: null,
            vatLiable: null,
            role: null,
            address: {
                street: null,
                number: null,
                city: null,
                zipcode: null
            },
            company: {
                name: null,
                number: null,
                email: null,
                phone: null,
                IBAN: null,
                ITAA: null
            },
            mayBeContactedByAcerta: true
        },
        // --- Calendar events ---
        isAddCalendareventOpen: false,
        isCreatingNewCalendarevent: false,
        currentCalendarevent: {
            name: {
                nl: null,
                fr: null,
                en: null
            },
            description: {
                nl: null,
                fr: null,
                en: null
            },
            mandatory: null,
            segment: null
        },
        // --- Calendarevents date
        isAddCalendareventDateOpen: false,
        isCreatingNewCalendareventDate: false,
        currentCalendareventDate: {
            calendareventId: null,
            date: null,
            dateEnd: null,
            year: null
        },
        // --- Tutorial ---
        isAddTutorialOpen: false,

        // --- Notification ---
        isEditNotificationOpen: false,
        isCreatingNewNotification: false,
        currentNotification: {
            id: null,
            title: {
                nl: null,
                fr: null,
                en: null
            },
            content: {
                nl: null,
                fr: null,
                en: null
            },
            icon: null,
            date: null
        }
    };
}

export default {
    namespaced: true,

    state: initialState(),

    getters: {
        getField
    },

    mutations: {
        updateField,
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        },
        OPEN_EDIT_ZIPPERCTAGE(state, { zip }) {
            state.isEditZippercentageOpen = true;
            state.currentZippercentage = zip;
        },
        CLOSE_EDIT_ZIPPERCTAGE(state) {
            state.isEditZippercentageOpen = false;
        },
        CLOSE_ADD_INVOICEITEM(state) {
            state.isAddInvoiceitemOpen = false;
        },
        SET_CURRENT_INVOICEITEM(state, { invoiceitem }) {
            state.currentInvoiceitem = invoiceitem;
        },
        OPEN_EDIT_INVENTORY(state) {
            state.isEditInventoryOpen = true;
        },
        CLOSE_EDIT_INVENTORY(state) {
            state.isEditInventoryOpen = false;
        },
        CLOSE_EDIT_DAILYRECEIPT(state) {
            state.isEditDailyReceiptOpen = false;
        },
        RESET_CURRENT_PROJECT(state, { currentProject }) {
            state.currentProject = currentProject;
        },
        RESET_ZIPPERCENTAGE(state, { zip }) {
            state.currentZippercentage = zip;
        }
    },

    actions: {
        resetCurrentProject: ({ commit }) => {
            commit('RESET_CURRENT_PROJECT', {
                currentProject: initCurrentProject()
            });
        },
        openEditZippercentage({ commit }, zip) {
            const zipClone = JSON.parse(JSON.stringify(zip));
            commit('OPEN_EDIT_ZIPPERCTAGE', {
                zip: zipClone
            });
        },
        closeEditZippercentage({ commit }) {
            commit('CLOSE_EDIT_ZIPPERCTAGE');
            commit('RESET_ZIPPERCENTAGE', {
                zip: initZippercenage()
            });
        },
        closeAddInvoiceitem({ commit, dispatch }) {
            commit('CLOSE_ADD_INVOICEITEM');
            dispatch('resetCurrentInvoiceitem');
        },
        resetCurrentInvoiceitem({ commit }, preset) {
            commit('SET_CURRENT_INVOICEITEM', {
                invoiceitem: preset ? preset : newInvoiceitem()
            });
        },
        openEditInventoryModal({ commit }) {
            commit('OPEN_EDIT_INVENTORY');
        },
        closeEditInventoryModal({ commit }) {
            commit('CLOSE_EDIT_INVENTORY');
        },
        closeEditPersonaltaxDeposit({ commit }) {
            commit('CLOSE_EDIT_PERSONAL_TAX_DEPOSIT');
        },
        openInvoiceTimeregistrationWizard: ({ state }) => {
            state.isCreateInvoiceFromTimeregistrationWizardOpen = true;
        },
        editIncomeDailyReceipt: ({ state }, income) => {
            state.isEditDailyReceiptOpen = true;

            // Make a deep clone of the Income object to prevent modification of the original object.
            const incomeCopy = JSON.parse(JSON.stringify(income));
            // Set the date in the correct format for the date input field (yyyy-mm-dd)
            // incomeCopy.date = dayjs.utc().format("YYYY-MM-DD");
            state.currentDailyReceipt = incomeCopy;
        },
        openSelectInvoiceitem: ({ state }) => {
            state.isSelectInvoiceitemOpen = true;
        },
        closeSelectInvoiceitem: ({ state }) => {
            state.isSelectInvoiceitemOpen = false;
        },
        openEditDepreciationfiche: ({ commit }, depreciationfiche) => {
            commit('OPEN_EDIT_DEPRECIAIONFICHE', depreciationfiche);
        },
        closeEditDailyreceipt: ({ commit }) => {
            commit('CLOSE_EDIT_DAILYRECEIPT');
        },
        newIncomeAsEmployee: ({ state }) => {
            state.isCreatingNewAsEmployee = true;
        },
        editIncomeAsEmployee: ({ state }, income) => {
            state.isCreatingNewAsEmployee = false;
            // Make a deep clone of the Income object to prevent modification of the original object.
            const incomeCopy = JSON.parse(JSON.stringify(income));
            state.currentAsEmployee = incomeCopy;
        },
        newExpense: ({ state }) => {
            state.isCreatingNewExpense = true;
        },
        newProject: ({ state }) => {
            state.isAddProjectOpen = true;
            state.isCreatingNewProject = true;
        },
        editProject: ({ state }, project) => {
            state.isAddProjectOpen = true;
            state.isCreatingNewProject = false;
            // Make a deep clone of the Expense object to prevent modification of the original object.
            const projectCopy = JSON.parse(JSON.stringify(project));
            state.currentProject = projectCopy;
        },
        newTimeRegistration: ({ state }) => {
            state.isAddTimeRegistrationOpen = true;
            state.isCreatingNewTimeRegistration = true;
        },
        editTimeregistration: ({ state }, timeRegistration) => {
            state.isAddTimeRegistrationOpen = true;
            state.isCreatingNewTimeRegistration = false;
            // TODO: make deep copy instead of shallow copy.
            const timeRegistrationCopy = { ...timeRegistration };
            state.currentTimeRegistration = timeRegistrationCopy;
        },
        newAccount: ({ state }) => {
            state.isCreatingNewAccount = true;
            state.isAddAccountOpen = true;
        },
        editAccount: ({ state }, account) => {
            state.isAddAccountOpen = true;
            state.isCreatingNewAccount = false;
            // Make a deep clone of the Expense object to prevent modification of the original object.
            const accountCopy = JSON.parse(JSON.stringify(account));
            state.currentAccount = accountCopy;
        },
        newCalendarevent: ({ state }) => {
            state.isCreatingNewCalendarevent = true;
            state.isAddCalendareventOpen = true;
        },
        editCalendarevent: ({ state }, calendarevent) => {
            state.isAddCalendareventOpen = true;
            state.isCreatingNewCalendarevent = false;
            // Make a deep clone of the Expense object to prevent modification of the original object.
            const calendareventCopy = JSON.parse(JSON.stringify(calendarevent));
            state.currentCalendarevent = calendareventCopy;
        },
        newCalendareventDate: ({ state }, { year, calendarevent }) => {
            state.isCreatingNewCalendareventDate = true;
            state.isAddCalendareventDateOpen = true;
            state.currentCalendareventDate = {
                year,
                calendareventId: calendarevent.id,
                date: null,
                dateEnd: null
            };
        },
        editCalendareventDate: ({ state }, { calendareventDate, year }) => {
            state.isAddCalendareventDateOpen = true;
            state.isCreatingNewCalendareventDate = false;
            // Make a deep clone of the Expense object to prevent modification of the original object.
            const calendareventDateCopy = JSON.parse(JSON.stringify(calendareventDate));
            state.currentCalendareventDate = { year, ...calendareventDateCopy };
        },
        newInvoiceitem: ({ dispatch, state }, preset) => {
            dispatch('resetCurrentInvoiceitem', preset);
            state.isCreatingNewInvoiceitem = true;
            state.isAddInvoiceitemOpen = true;
        },
        editInvoiceitem: ({ state }, Invoiceitem) => {
            state.isAddInvoiceitemOpen = true;
            state.isCreatingNewInvoiceitem = false;
            // Make a deep clone of the Expense object to prevent modification of the original object.
            const InvoiceitemCopy = JSON.parse(JSON.stringify(Invoiceitem));
            state.currentInvoiceitem = InvoiceitemCopy;
        },
        newExpenseCategory: ({ state }) => {
            state.isCreatingNewExpenseCategory = true;
            state.isAddExpenseCategoryOpen = true;

            // initalize form
            state.currentExpenseCategory = initCurrentExpenseCategory();
        },
        editExpenseCategory: ({ state }, expenseCategory) => {
            state.isAddExpenseCategoryOpen = true;
            state.isCreatingNewExpenseCategory = false;
            // Make a deep clone of the Expense object to prevent modification of the original object.
            const expenseCategoryCopy = JSON.parse(JSON.stringify(expenseCategory));
            state.currentExpenseCategory = expenseCategoryCopy;
        },
        editUnit: ({ state }, unit) => {
            state.isAddUnitOpen = true;
            state.isCreatingNewUnit = false;
            // Make a deep clone of the Expense object to prevent modification of the original object.
            const unitCopy = JSON.parse(JSON.stringify(unit));
            state.currentUnit = unitCopy;
        },
        newUnit: ({ state }) => {
            state.currentUnit = initUnit();
            state.isCreatingNewUnit = true;
            state.isAddUnitOpen = true;
        },
        editNotification: ({ state }, notification) => {
            state.isCreatingNewNotification = false;
            state.isEditNotificationOpen = true;
            // Make a deep clone of the Expense object to prevent modification of the original object.
            const notificationCopy = JSON.parse(JSON.stringify(notification));
            state.currentNotification = notificationCopy;
        }
    }
} as Module<BottomSheetsState, RootState>;

export function currentInvoicableNewInstance(type: 'invoice' | 'creditnote' | 'quotation') {
    const base = {
        id: null,
        [`${type}Number`]: null,
        date: null,
        creator: null,
        customer: null,
        comment: null,
        draft: false,
        items: [],
        discount: null,
        isDiscountPercentage: false,
        amountEuroInclVAT: null,
        vatOnInvoicable: false,
        itemsAllSameVat: false,
        allItemsVat: 0,
        btwMedecontractant: false,
        typeOfService: null
    };

    if (type === 'creditnote') {
        return {
            ...base,
            invoiceId: null
        };
    }

    return base;
}

function initCurrentProject() {
    return {
        id: null,
        name: null,
        customerId: null,
        customer: null
    };
}

function initZippercenage() {
    return {
        year: null,
        zipcode: null,
        percentage: null
    };
}

function initUnit() {
    return {
        singular: null,
        plural: null
    };
}
