
import Vue, { PropOptions } from 'vue';
import BackButton from '@/components/Button/BackButton.vue';

export default Vue.extend({
    name: 'SliderModal',
    components: { BackButton },
    props: {
        isModalOpen: {
            type: Boolean,
            required: true
        } as PropOptions<boolean>,
        modalComponents: {
            type: Array,
            required: true
        } as PropOptions<Vue[]>,
        modalComponentPropsArray: {
            type: Array,
            required: true
        } as PropOptions<Record<string, any | any[]>[]>,
        modalComponentListenersArray: {
            type: Array,
            required: true
        } as PropOptions<Record<string, () => void>[]>,
        activeComponentId: {
            type: Number,
            required: false
        } as PropOptions<number>,
        nextTransition: {
            type: String,
            required: false
        } as PropOptions<string>,
        dataId: {
            type: String,
            required: false
        } as PropOptions<string>
    },
    computed: {
        hasPreviousModal() {
            return this.modalComponents.length > 1 && this.activeComponentId > 0;
        }
    },
    mounted() {
        window.addEventListener('keydown', this.keyPressHandler);
    },
    destroyed() {
        window.removeEventListener('keydown', this.keyPressHandler);
    },
    methods: {
        goToPreviousModal() {
            this.$emit('previous');
        },
        async closeModal(): Promise<void> {
            this.$emit('update:isModalOpen', false);
            this.$store.commit('modal/setIsModalOpen', false);
        },
        keyPressHandler(e: KeyboardEvent) {
            if (e.key === 'Escape') this.closeModal();
        }
    }
});
