import { NavigationGuardNext, Route } from 'vue-router';

export async function permissionGuard(
    // eslint-disable-next-line no-unused-vars
    router: { app: { $can: (action: string, subject: string) => boolean } },
    to: Route,
    from: Route,
    next: NavigationGuardNext
) {
    const matchedRoute = to.matched.find((route) => {
        return route.meta?.action && route.meta?.subject;
    });

    if (!matchedRoute) {
        return next();
    }

    const { action, subject } = matchedRoute.meta;

    if (!router.app.$can(action, subject)) {
        if (to.name === 'dashboard') {
            return next({
                name: 'userSettingsGeneral'
            });
        } else {
            return next({
                name: 'dashboard'
            });
        }
    }

    return next();
}
