import { generateEndpoint } from '@/services/config';
import { IChangeEmailViewModel } from '@/services/usersettings.types';
import axios from 'axios';
import { IUpdateBasicInformationData } from 'dexxter-types';
import { BASE_URL_TAXES } from './tax';

export const BASE_URL_SETTINGS = generateEndpoint('/api/settings');

export function updateSocialcontributionsExemptStatus(status: boolean, year: number): Promise<void> {
    return axios
        .put(`${BASE_URL_SETTINGS}/socialcontributionsExemptStatus/year/${year}`, { status })
        .then((response) => response.data);
}

export function sendRemoveLogoRequest(): Promise<void> {
    return axios.delete(`${BASE_URL_SETTINGS}/logo`).then((response) => response.data);
}

export function sendChangeAccountEmailRequest({ email }: { email: string }): Promise<void> {
    return axios
        .put(`${BASE_URL_SETTINGS}/changeAccountEmail`, {
            email
        })
        .then((response) => response.data);
}

export function sendVerifyAccountEmailRequest({ token }: { token: string }): Promise<void> {
    return axios
        .put(`${BASE_URL_SETTINGS}/verifyNewAccountEmail`, null, { params: { token } })
        .then((response) => response.data);
}

export function getChangeEmailRequestRequest(): Promise<null | IChangeEmailViewModel> {
    return axios.get(`${BASE_URL_SETTINGS}/changeAccountEmail`).then((response) => response.data);
}

export function sendDeleteAccountEmailRequest(id: number): Promise<void> {
    return axios.delete(`${BASE_URL_SETTINGS}/removeChangeNewAccountEmail/${id}`).then((response) => response.data);
}

export function updateEmailSettingsRequest({ replyToAddress }: { replyToAddress: string }): Promise<void> {
    return axios.put(`${BASE_URL_SETTINGS}/emailSettings`, { replyToAddress }).then((response) => response.data);
}

export function updateInvoicableEmailSettingsRequest({ invoicableEmail }: { invoicableEmail: string }): Promise<void> {
    return axios
        .put(`${BASE_URL_SETTINGS}/invoicableEmailSettings`, { invoicableEmail })
        .then((response) => response.data);
}

export function updateStructuredNumberSettingsRequest({
    structuredNumber
}: {
    structuredNumber: string;
}): Promise<void> {
    return axios.put(`${BASE_URL_SETTINGS}/structuredNumber`, { structuredNumber }).then((response) => response.data);
}

export function sendSetTermsAndConditionsRequest(termsAndConditions: string): Promise<void> {
    return axios.put(`${BASE_URL_SETTINGS}/updateTermsAndConditions`, { termsAndConditions });
}

// Toggle the close status of the tax object
export const getSetSelectedDiskPath = (year: number) => `${BASE_URL_TAXES}/${year}/setDisk`;
export function setSelectedDiskRequest({
    year,
    selectedDisk
}: {
    year: number;
    selectedDisk: number | null;
}): Promise<void> {
    return axios.put(getSetSelectedDiskPath(year), { selectedDisk }).then((response) => response.data);
}

export const getSetInvoicelayoutRequestPath = () => `${BASE_URL_SETTINGS}/updateInvoiceLayout`;
export function setInvoicelayoutRequest(invoiceLayout: number): Promise<void> {
    return axios.put(getSetInvoicelayoutRequestPath(), { invoiceLayout }).then((response) => response.data);
}

export const getPreviewInvoiceLayoutRequestPath = () => `${BASE_URL_SETTINGS}/preview-invoice-layout`;
export function previewInvoiceLayoutRequest(invoiceTemplate: number): Promise<string> {
    return axios
        .get(getPreviewInvoiceLayoutRequestPath(), {
            params: {
                invoiceTemplate
            }
        })
        .then((response) => response.data);
}

export function sendUpdateUserBasicSettingsRequest(userData: IUpdateBasicInformationData['user']): Promise<void> {
    return axios
        .put(`${BASE_URL_SETTINGS}/updateBasicInformation`, {
            user: userData
        })
        .then((response) => response.data);
}

export function sendUpdateDefaultPaymentDaysRequest(defaultPaymentDays: number): Promise<void> {
    return axios
        .put(`${BASE_URL_SETTINGS}/updateDefaultPaymentDays`, { defaultPaymentDays })
        .then((response) => response.data);
}

export function sendUpdateDefaultVATPercentageRequest(defaultVATPercentage: number): Promise<void> {
    return axios
        .put(`${BASE_URL_SETTINGS}/updateDefaultVATPercentage`, { defaultVATPercentage })
        .then((response) => response.data);
}
