
import Vue, { PropOptions } from 'vue';
// --- Components ---
import ErrorModalIcon from '@/components/Icons/ErrorModalIcon.vue';
// --- Helpers ---
import { Action } from '@/helpers/errorModals';

export default Vue.extend({
    name: 'ErrorModal',
    components: { ErrorModalIcon },
    props: {
        i18nTitle: {
            type: String
        },
        i18nText: {
            type: String,
            required: true
        },
        actions: {
            type: Array,
            required: false
        } as PropOptions<Action[]>
    },
    computed: {
        isShortTitle() {
            return this.$i18n.t(this.i18nText).length < 250;
        }
    },
    methods: {
        handleButtonClick(action: (vue: Vue) => void) {
            action(this);
        }
    }
});
