import { SupplierType } from '../services/suppliers.types';

export type NewSupplier = {
    id: null | number;
    company: {
        number: string | null;
        name: string | null;
        phone: string | null;
        email: string | null;
        IBAN: string | null;
    };
    address: {
        country: string | null;
        zipcode: string | null;
        city: string | null;
        number: string | null;
        street: string | null;
        busNumber: string | null;
    };
    type: SupplierType | null;
    notes: null | string;
};

export function newSupplier(): NewSupplier {
    return {
        id: null,
        company: {
            name: null,
            number: null,
            email: null,
            IBAN: null,
            phone: null
        },
        address: {
            street: null,
            number: null,
            city: null,
            zipcode: null,
            country: null,
            busNumber: null
        },
        type: null,
        notes: null
    };
}
