import Vue from 'vue';
import { Module } from 'vuex';
import { RootState } from '@/store/store.types';
import { ModalState } from '@/store/types/modal';

export interface RenderModalProps {
    component: any;
    props?: Record<string, unknown>;
    listeners?: Record<string, () => void>;
    dataId?: string;
}

function initialState(): ModalState {
    return {
        isModalOpen: false,
        activeComponentId: 0,
        modalComponents: [],
        modalComponentPropsArray: [],
        modalComponentListenersArray: [],
        nextTransition: 'slide-to-next',
        dataId: ''
    };
}

export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        setIsModalOpen(state: ModalState, newIsModalOpen: boolean): void {
            state.isModalOpen = newIsModalOpen;
        },
        setModalContent(
            state: ModalState,
            {
                component,
                props,
                listeners,
                dataId
            }: {
                component: Vue;
                props: typeof component.$props | undefined;
                listeners?: Record<string, () => void>;
                dataId?: string;
            }
        ): void {
            state.modalComponents = [component];
            state.modalComponentPropsArray = [props ?? {}];
            state.modalComponentListenersArray = [listeners ?? {}];
            state.dataId = dataId;
            state.activeComponentId = 0;
            state.isModalOpen = true;
        },
        changeToNewModalPage(state: ModalState, { component, props, listeners, dataId }: RenderModalProps): void {
            state.nextTransition = 'slide-to-next';
            state.modalComponents.push(component);
            state.modalComponentPropsArray.push(props ?? {});
            state.modalComponentListenersArray.push(listeners ?? {});
            state.dataId = dataId;
            state.activeComponentId += 1;
        },
        goToPreviousAndRemoveLastModalPage(state: ModalState): void {
            state.nextTransition = 'slide-to-previous';
            state.activeComponentId -= 1;
            state.modalComponents.pop();
            state.modalComponentPropsArray.pop();
            state.modalComponentListenersArray.pop();
        },
        RESET_STATE(state: ModalState) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<ModalState, RootState>;
