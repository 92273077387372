export function createTimeregistration() {
    return {
        id: null,
        invoiceId: null,
        description: null,
        start: null,
        end: null,
        date: null,
        project: null
    };
}
