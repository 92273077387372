export function i18nCodeToLocali18nName(code: string): string {
    if (code === 'nl-BE') {
        return 'nl';
    } else if (code === 'fr-BE') {
        return 'fr';
    } else if (code === 'en') {
        return 'en';
    }

    throw new Error('Code not found');
}

export function getDefaultInvoicableLanguages(): { name: string; code: string }[] {
    return [
        {
            name: 'nl',
            code: 'nl-BE'
        },
        {
            name: 'fr',
            code: 'fr-BE'
        },
        {
            name: 'en',
            code: 'en'
        }
    ];
}

export function getBrowserLanguage(): string {
    return (navigator as any).language || (navigator as any).userLanguage;
}
