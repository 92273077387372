
import { PropOptions } from 'vue';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import VueI18n from 'vue-i18n';
import mixins from 'vue-typed-mixins';
import TranslateResult = VueI18n.TranslateResult;

export default mixins(screenSizeMixin).extend({
    name: 'VideoModal',
    mixins: [screenSizeMixin],
    props: {
        i18nPath: {
            type: String
        } as PropOptions<string>,
        title: {
            type: String
        } as PropOptions<string>,
        url: {
            type: String
        } as PropOptions<string>
    },
    computed: {
        getTitle(): string | TranslateResult {
            if (this.i18nPath && this.$i18n.te(this.i18nPath.concat('.title'))) {
                return this.$i18n.t(this.i18nPath.concat('.title'));
            }

            return this.title;
        },
        getUrl(): string | TranslateResult {
            if (this.i18nPath && this.$i18n.te(this.i18nPath.concat('.url'))) {
                return this.$i18n.t(this.i18nPath.concat('.url'));
            }

            return this.url;
        },
        videoDimensionsCssVars(): { '--video-width': string; '--video-height': string } {
            let videoWidth: number;
            if (this.isPhone) {
                videoWidth = this.windowWidth * 0.8;
            } else if (this.isTablet) {
                videoWidth = this.windowWidth * 0.65;
            } else {
                videoWidth = this.windowWidth * 0.5;
            }
            const videoHeight = videoWidth / (16 / 9);

            return { '--video-width': `${videoWidth}px`, '--video-height': `${videoHeight}px` };
        }
    }
});
