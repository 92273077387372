import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import { Informationpopup } from '@/services/informationpopup.types';

const BASE_URL = generateEndpoint('/api/informationpopups');

export const BASE_URL_MARK_INFORMATIONPOPUP_AS_DONE_URL = (informationpopupId: number) =>
    `${BASE_URL}/${informationpopupId}`;

export function getAllInformationpopups(): Promise<Informationpopup[]> {
    return axios.get(`${BASE_URL}`).then((response) => response.data);
}

export function markInformationpopupAsDone(informationpopupId: number): Promise<void> {
    return axios
        .delete(BASE_URL_MARK_INFORMATIONPOPUP_AS_DONE_URL(informationpopupId))
        .then((response) => response.data);
}
