
import { defineComponent } from 'vue';

import { HeadingTag } from '@/components/typography';

export interface CardHeaderProps {
    title: string;
    headingTag?: HeadingTag;
}

export default defineComponent<CardHeaderProps>({
    name: 'CardHeader',
    props: {
        icon: {
            type: String
        },
        headingTag: {
            type: String,
            default: 'h2'
        }
    }
});
