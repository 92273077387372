import axios from 'axios';
import { generateEndpoint } from '../../../services/config';
import { ProductRepositoryInterface } from '../domain/product.repository.interface';

export const BASE_URL_STRIPE = generateEndpoint('/api/stripe');

class SubscriptionRepository implements ProductRepositoryInterface {
    getProducts() {
        return axios.get(`${BASE_URL_STRIPE}/products`).then((response) => response.data);
    }
    cancelSubscription() {
        return axios.post(`${BASE_URL_STRIPE}/subscription/cancel-subscription`).then((response) => response.data);
    }
    undoCancelSubscription() {
        return axios.post(`${BASE_URL_STRIPE}/subscription/undo-cancel-subscription`).then((response) => response.data);
    }
    createCheckoutSession(planID: string) {
        return axios
            .post(`${BASE_URL_STRIPE}/subscription/create-checkout-session`, {
                planID
            })
            .then((response) => response.data);
    }
    createCheckoutSessionCard(planID: string) {
        return axios
            .post(`${BASE_URL_STRIPE}/subscription/create-checkout-session-card`, {
                planID
            })
            .then((response) => response.data);
    }
    createPortalSession() {
        return axios.post(`${BASE_URL_STRIPE}/subscription/create-portal-session`).then((response) => response.data);
    }
    updateSubscriptionToMainPlan() {
        return axios
            .post(`${BASE_URL_STRIPE}/subscription/upgrade-current-subscription`)
            .then((response) => response.data);
    }
}

export default new SubscriptionRepository();
