import { removeAllTokens, resetLoggingState } from '@/helpers/auth';
import { logoutNormalUser } from '@/helpers/logoutUser.helpers';
import { analyticsLogout } from '@/services/analytics';

export async function logoutUsersFromDexxter(): Promise<void> {
    await logoutNormalUser();
}

export async function afterLogoutActions(options: { impersonating: boolean }): Promise<void> {
    if (options.impersonating) {
        window.location.reload();
    } else {
        analyticsLogout();
        removeAllTokens();
        resetLoggingState();
        window.location.reload();
    }
}
