import '@/setup/dayjs';
import dayjs from 'dayjs';
import { checkVAT, countries } from 'jsvat';
import { isEmpty, isNil, isNumber, isString } from 'lodash';
import isIBAN from 'validator/es/lib/isIBAN';
import isMobilePhone from 'validator/es/lib/isMobilePhone';
import isURL from 'validator/es/lib/isURL';
import i18n from '../i18n';

export const required = (value) => {
    // Using != instead of !== also checks for undefiend.
    return (value != null && value !== '') || i18n.t('validation.required');
};

export const timeregistrationDurationRules = (value) => {
    if (!value) {
        return false;
    }

    if (value.toString().indexOf(':') === -1 || value.toString().length !== 5) {
        return i18n.t('validation.timeMustBeOfSpecificFormat');
    }

    const rawValue = value.toString().replace(':', '');

    return (rawValue >= 0 && rawValue <= 2400) || i18n.t('validation.between0and2400');
};

export const minLength = (minLength) => {
    return function (value) {
        if (typeof value !== 'string') {
            return false;
        }

        return (
            value.length >= minLength ||
            i18n.t('validation.min_length', {
                minLength
            })
        );
    };
};

export const isValidWebsite = (value) => {
    if (!value) {
        return true;
    }
    return (
        isURL(value, {
            protocols: ['http', 'https'],
            require_protocol: true
        }) || i18n.t('validation.no_valid_url')
    );
};

export const isValidPhone = (value) => {
    if (!value) {
        return true;
    }

    return (
        isMobilePhone(value, ['nl-BE', 'nl-NL'], {
            strictMode: true
        }) || i18n.t('validation.no_valid_phone')
    );
};

export const wordDoesNotBeginsOrEndsWithWhiteSpace = /^[\S]+.*[\S]+$/;

export const securePassword = (password) => {
    if (!password || typeof password !== 'string') {
        return false;
    }

    if (password.length < 8) {
        return i18n.t('validation.password_too_short');
    } else if (password.length > 80) {
        return i18n.t('validation.password_too_long');
    } else if (!password.match(wordDoesNotBeginsOrEndsWithWhiteSpace)) {
        return i18n.t('validation.no_spaces_begin_or_end');
    } else if (password.search(/\d/) == -1) {
        return i18n.t('validation.password_does_not_contain_a_number');
    } else if (password.search(/[a-z]/) == -1) {
        return i18n.t('validation.password_does_not_contain_a_letter');
    } else if (password.search(/[A-Z]/) == -1) {
        return i18n.t('validation.password_does_not_contain_a_capital_letter');
    } else if (password.match(/[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/) === null) {
        return i18n.t('validation.password_does_not_contain_a_special_character');
    }

    return true;
};

export const vSelectRequired = (value) => !!value;

export const numberValidator = (value) => {
    if (value != null) {
        // Check wether the value is a string by testing for the replace method.
        if (value.replace) {
            value = value.replace('.', '');
            value = value.replace(',', '.');
        }
        return (!isNaN(value) && isFinite(value)) || i18n.t('validation.enter_a_number');
    } else {
        return false || i18n.t('validation.enter_a_number');
    }
};

export const positiveNumberValidatorEmptyStringAllowed = (value) => {
    if (value === null || value === undefined || (typeof value === 'string' && value === '')) {
        return true;
    }

    return checkPostiveNumber(value);
};

export const strictPostiveNumber = (value) => {
    let valueToCheck = value;

    if (typeof value === 'string' && isNumber(parseFloat(value))) {
        valueToCheck = parseFloat(value);
    }

    if (!isNumber(valueToCheck)) {
        return i18n.t('validation.enter_a_strict_positive_number');
    }

    return valueToCheck > 0 || i18n.t('validation.enter_a_strict_positive_number');
};

export const numberValidatorEmptyStringAllowed = (value) => {
    if (value === null || value === undefined || (typeof value === 'string' && value === '')) {
        return true;
    }

    return checkNumber(value);
};

export const numberBetween6162 = (value) => {
    if (value === null || value === undefined) {
        return false || i18n.t('validation.required');
    }

    return (value >= 610000 && value < 620000) || i18n.t('validation.number_between_61_62');
};

function checkPostiveNumber(value) {
    // Check wether the value is a string by testing for the replace method.
    if (value.replace) {
        value = parseFloat(value.replace(',', '.'));
    }
    return value >= 0 || i18n.t('validation.enter_a_positive_number');
}

function checkNumber(value) {
    // Check wether the value is a string by testing for the replace method.
    if (value.replace) {
        value = parseFloat(value.replace(',', '.'));
    }
    return (typeof value === 'number' && !Number.isNaN(value)) || i18n.t('validation.enter_a_number');
}

export const positiveNumberValidator = (value) => {
    if (value != null) {
        return checkPostiveNumber(value);
    } else {
        return false || i18n.t('validation.enter_a_number');
    }
};

export const validYear = (value) => {
    if (isNil(value) || isEmpty(value) || !isString(value)) {
        return true;
    }

    return value.length === 4 || i18n.t('validation.enter_a_valid_year');
};

export const positiveNumberGreaterThanZeroValidator = (value) => {
    if (value != null) {
        // Check wether the value is a string by testing for the replace method.
        if (value.replace) {
            value = parseFloat(value.replace(',', '.'));
        }
        return value > 0 || i18n.t('validation.enter_a_positive_number_larger_than_zero');
    } else {
        return false;
    }
};

export const positiveNumberValidatorMayBeEmpty = (value) => {
    if (value === undefined || value === null || value === '') {
        return true;
    }

    const numberOfStringRepresentation = parseFloat(value);

    if (isNaN(numberOfStringRepresentation)) {
        return false;
    }

    // Check wether the value is a string by testing for the replace method.
    const sign = Math.sign(numberOfStringRepresentation);
    if (sign === -1) {
        return false || i18n.t('validation.enter_a_positive_number_larger_than_zero');
    }

    return true;
};

export const percentageValidator = (value) => {
    if (value != null) {
        // Check wether the value is a string by testing for the replace method.
        if (value.replace) {
            value = parseFloat(value.replace(',', '.'));
        }
        return (value >= 0 && value <= 100) || i18n.t('validation.percentage_between_0_100');
    } else {
        return false;
    }
};

export const emailValidator = (value) => {
    if (isNil(value) || isEmpty(value)) {
        return true;
    }

    if (Array.isArray(value)) {
        return value.every(validEmail) || i18n.t('validation.enter_valid_emails');
    }

    return validEmail(value) || i18n.t('validation.enter_valid_email');
};

function validEmail(value) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
    );
}

export const selectRequired = (v) => {
    return !!v || i18n.t('validation.required');
};

export const belgianCompanyNumberRegex = /^([0-1]\d{9})$/;

export const companyNumber = (country) => {
    return (companyNumberInput) => {
        if (!companyNumberInput) return true; // not required.

        if (country === 'EU') {
            if (typeof companyNumberInput === 'string' && companyNumberInput.length > 0) {
                return true;
            }

            return i18n.t('validation.company_number');
        }

        if (!/^[\dA-Za-z]+$/.test(companyNumberInput)) {
            return i18n.t('validation.company_number_only_numbers_or_letters');
        }

        if (country.toLowerCase() === 'be' && companyNumberInput.length === 9) {
            return i18n.t('validation.company_number');
        }

        if (country.toLowerCase() === 'be') {
            if (belgianCompanyNumberRegex.test(companyNumberInput)) {
                const newVat = companyNumberInput.length === 9 ? `0${companyNumberInput}` : `${companyNumberInput}`;
                const check = 97 - (Number(newVat.slice(0, 8)) % 97);
                return check === Number(newVat.slice(8, 10)) || i18n.t('validation.company_number');
            } else {
                return i18n.t('validation.company_number');
            }
        }

        return checkVAT(`${country}${companyNumberInput}`, countries).isValid || i18n.t('validation.company_number');
    };
};

export const IBANnumber = (value) => {
    if (!value) {
        return true;
    }
    return isIBAN(value) || i18n.t('validation.not_a_valid_iban');
};

export const maxFileSizeUpload = (file) => {
    if (!file) return true; // Only check for file size when a file is specified.
    return (
        file.size < 3_000_000 ||
        i18n.t('validation.smaller_than_xmb', {
            size: 3
        })
    );
};

export const fileTypePdf = (file) => {
    if (!file) return true; // Only check for file type when a file is specified.
    return file.type === 'application/pdf' || i18n.t('validation.enter_pdf');
};

export const fileTypeInvoicable = (file) => {
    if (!file) return true; // Only check for file type when a file is specified.
    const type = file.type;
    return (
        ['application/pdf', 'image/jpeg', 'image/png'].includes(type) ||
        i18n.t('validation.enter_allowed_invoicable_type')
    );
};

export const xmlFile = (file) => {
    if (!file) return true; // Only check for file type when a file is specified.
    const type = file.type;
    return ['text/xml'].includes(type) || i18n.t('validation.only_xml_file');
};

export const logoTypeInvoicable = (file) => {
    if (!file) return true; // Only check for file type when a file is specified.
    const type = file.type;
    return ['image/jpeg', 'image/png'].includes(type) || i18n.t('validation.enter_allowed_logo_type');
};

export const noDateInPast = (value) => {
    const date = new Date(value);
    date.setHours(0, 0, 0, 0);
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return date >= now || i18n.t('validation.no_date_in_the_past');
};

export const dateOnlyInCurrentOrPreviousYears = (value) => {
    const year = dayjs().year();

    const givenYear = dayjs(value, 'YYYY-MM-DD').year();

    return year >= givenYear || i18n.t('validation.no_date_in_future_year');
};

export const noDateInFuture = (value) => {
    const date = new Date(value);
    date.setHours(0, 0, 0, 0);
    const now = new Date();
    now.setHours(0, 0, 0, 0);

    return date <= now || i18n.t('validation.no_date_in_future_year');
};

export const noDateInFutureMonthYearOnly = (value) => {
    if (!dayjs(`01-${value}`, 'DD-MM-YYYY', true).isValid()) {
        return i18n.t('not_a_valid_date');
    }

    const firstDayOfGivenDate = dayjs(
        `${dayjs(`01-${value}`, 'DD-MM-YYYY', true).format('YYYY-MM')}-01`,
        'YYYY-MM-DD',
        true
    );
    const firstDayOfCurrentDate = dayjs(`${dayjs().format('YYYY-MM')}-01`, 'YYYY-MM-DD', true);

    return (
        firstDayOfCurrentDate.isSame(firstDayOfGivenDate) ||
        firstDayOfGivenDate.isBefore(firstDayOfCurrentDate) ||
        i18n.t('validation.no_date_in_future')
    );
};

export const yearNoFuture = (value) => {
    return new Date().getFullYear() >= value || i18n.t('validation.no_year_in_the_future');
};

export const dateMaxFuture1Year = (value) => {
    const date = new Date(value);
    date.setHours(0, 0, 0, 0);

    const futureDate = new Date();
    futureDate.setHours(0, 0, 0, 0);
    futureDate.setFullYear(futureDate.getFullYear() + 1);

    return date <= futureDate || i18n.t('validation.no_more_than_1_year_in_future');
};
