import axios from 'axios';
import { generateEndpoint } from './config';
import { initializePaginationVars } from '@/store/utils';
import { filteringOptions, PaginationResponse } from '@/common/types';
import { IDepreciationficheViewModel } from 'dexxter-types';
import { ICreateDepreciationficheData, IUpdateDepreciationficheData } from '@/services/depreciationfiche.types';

export const BASE_URL_DEPRECIATIONFICHES = generateEndpoint('/api/depreciationfiches');

export async function deleteDepreciationficheByIDRequest(depreciationficheID: number): Promise<void> {
    return axios.delete(`${BASE_URL_DEPRECIATIONFICHES}/${depreciationficheID}`).then((response) => response.data);
}

export async function sendGetAllDepreciationfichePaginationRequest(
    year: number,
    options: filteringOptions
): Promise<PaginationResponse<IDepreciationficheViewModel>> {
    return axios
        .get(`${BASE_URL_DEPRECIATIONFICHES}/year/${year}`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export async function sendGetDepreciationficheRequest(
    depreciationficheID: number
): Promise<IDepreciationficheViewModel> {
    return axios.get(`${BASE_URL_DEPRECIATIONFICHES}/${depreciationficheID}`).then((response) => response.data);
}

export async function sendUpdateDepreciationficheRequest(
    depreciationfiche: IUpdateDepreciationficheData
): Promise<IDepreciationficheViewModel> {
    return axios
        .put(`${BASE_URL_DEPRECIATIONFICHES}/${depreciationfiche.id}`, { depreciationfiche })
        .then((response) => response.data);
}

export async function sendCreateDepreciationficheRequest(
    depreciationfiche: ICreateDepreciationficheData
): Promise<IDepreciationficheViewModel> {
    return axios.post(`${BASE_URL_DEPRECIATIONFICHES}`, { depreciationfiche }).then((response) => response.data);
}
