import { generateEndpoint } from '@/services/config';
import { PersonalTaxesViewModel } from '@/services/tax.types';
import {
    IPBStatement,
    ITaxViewModel,
    ITaxsettingsViewModel,
    IncomeStatement,
    SocialcontributionStatement
} from '@/store/modules/taxes.types';
import axios from 'axios';
import {
    IPersonaltaxesSubmissionViewModel,
    ResultatenrekeningViewModel,
    UpdateDependendOnNumbersData,
    UpdateStatutenData,
    dependendOnNumbers
} from 'dexxter-types';

export const BASE_URL_TAXES = generateEndpoint('/api/taxes');

export function sendGetPersonalTaxRastersRequest(year: number, typeOfCompany: string): Promise<PersonalTaxesViewModel> {
    return axios
        .get(`${BASE_URL_TAXES}/personaltaxRasters/${year}`, {
            params: {
                typeOfCompany
            }
        })
        .then((response) => response.data);
}

export function sendGetResultatenrekeningRequest(year: number): Promise<ResultatenrekeningViewModel> {
    return axios.get(`${BASE_URL_TAXES}/resultatenrekening/${year}`).then((response) => response.data);
}

export function sendGetCalculatedTypeOfCompanyRequest(year: number): Promise<string> {
    return axios.get(`${BASE_URL_TAXES}/typeOfCompany/${year}`).then((response) => response.data);
}

export function sendGetNumberOfTaxesYearsRequest(): Promise<number[]> {
    return axios
        .get(`${BASE_URL_TAXES}/years`)
        .then((response) => response.data)
        .then((response) => response.map((yearObj: { year: number }) => yearObj.year));
}

export function sendGetDependendOnNumbersRequest(year: number): Promise<dependendOnNumbers> {
    return axios.get(`${BASE_URL_TAXES}/dependendonNumbers/${year}`).then((response) => response.data);
}

export function sendUpdateDependendOnNumbersRequest(
    year: number,
    data: UpdateDependendOnNumbersData
): Promise<dependendOnNumbers> {
    return axios.put(`${BASE_URL_TAXES}/dependendonNumbers/${year}`, data).then((response) => response.data);
}

export function getPersonaltaxesSubmissionsRequest(year: number): Promise<IPersonaltaxesSubmissionViewModel | null> {
    return axios.get(`${BASE_URL_TAXES}/personaltaxessubmission/${year}`).then((response) => response.data);
}

export function createPersonaltaxesSubmissionsRequest(year: number): Promise<IPersonaltaxesSubmissionViewModel> {
    return axios
        .post(`${BASE_URL_TAXES}/personaltaxessubmission`, {
            year
        })
        .then((response) => response.data);
}

export function deletePersonaltaxesSubmissionsRequest(id: number): Promise<void> {
    return axios.delete(`${BASE_URL_TAXES}/personaltaxessubmission/${id}`).then((response) => response.data);
}

export function sendGetTaxesRequest(year: number): Promise<ITaxViewModel> {
    return axios.get(`${BASE_URL_TAXES}/${year}`).then((response) => response.data);
}
export function sendGetTaxSettingsRequest(year: number): Promise<ITaxsettingsViewModel> {
    return axios.get(`${BASE_URL_TAXES}/${year}/settings`).then((response) => response.data);
}
export function sendGetIncomeStatementRequest(year: number): Promise<IncomeStatement> {
    return axios.get(`${BASE_URL_TAXES}/${year}/incomeStatement`).then((response) => response.data);
}
export function sendGetSocialcontributionsStatementRequest(year: number): Promise<SocialcontributionStatement> {
    return axios.get(`${BASE_URL_TAXES}/${year}/socialcontributionsStatement`).then((response) => response.data);
}
export function sendGetPersonaltaxesStatementRequest(year: number): Promise<IPBStatement> {
    return axios.get(`${BASE_URL_TAXES}/${year}/personaltaxesStatement`).then((response) => response.data);
}

export function sendCreateNewBookyearRequest(year: number): Promise<void> {
    return axios.post(`${BASE_URL_TAXES}`, { year }).then((response) => response.data);
}
export function sendEditDepositRequest({
    deposit,
    quarter,
    year
}: {
    deposit: number;
    quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4';
    year: number;
}) {
    return axios.put(`${BASE_URL_TAXES}/${year}/deposit`, { deposit, quarter }).then((response) => response.data);
}

export function sendEditInventoryRequest({
    inventory,
    year
}: {
    inventory: { inventoryBegin: number; inventoryEnd: number };
    year: number;
}): Promise<void> {
    return axios.put(`${BASE_URL_TAXES}/${year}/inventory`, { inventory }).then((response) => response.data);
}

export function sendToggleTaxStatusRequest(year: number): Promise<void> {
    return axios.put(`${BASE_URL_TAXES}/${year}/toggleStatus`).then((response) => response.data);
}

export function sendCloseTaxStatusRequest(year: number): Promise<void> {
    return axios.put(`${BASE_URL_TAXES}/${year}/close`).then((response) => response.data);
}

export function sendUpdateStatutenRequest(year: number, data: UpdateStatutenData): Promise<ITaxViewModel> {
    return axios.put(`${BASE_URL_TAXES}/${year}/statuten`, data).then((response) => response.data);
}

export function sendUpdateMarriedRequest(year: number, data: boolean): Promise<ITaxViewModel> {
    return axios
        .put(`${BASE_URL_TAXES}/${year}/married`, {
            value: data
        })
        .then((response) => response.data);
}

export function sendUpdateTypeOfCompanyRequest(year: number, data: string | null): Promise<ITaxViewModel> {
    return axios
        .put(`${BASE_URL_TAXES}/${year}/typeOfCompany`, {
            value: data
        })
        .then((response) => response.data);
}
