import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import { IInvitationViewModel } from '@/services/invitations.types';

const BASE_URL = generateEndpoint('/api/invitations');

export function sendInviteToAccountingOfficeRequest(accountingOfficeEmail: string): Promise<number> {
    return axios
        .post(`${BASE_URL}/invite`, {
            accountingOfficeEmail
        })
        .then((response) => response.data);
}

export function getAllInvitationsRequest(): Promise<IInvitationViewModel[]> {
    return axios.get(`${BASE_URL}`).then((response) => response.data);
}

export function acceptInvitationRequest(invitationId: number): Promise<void> {
    return axios.post(`${BASE_URL}/${invitationId}/accept`).then((response) => response.data);
}

export function declineInvitationRequest(invitationId: number): Promise<void> {
    return axios.post(`${BASE_URL}/${invitationId}/decline`).then((response) => response.data);
}
