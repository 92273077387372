<template>
    <v-app>
        <v-main>
            <router-view />
            <notifications classes="notification" position="bottom right" :max="1" />
            <new-version-snackbar />
        </v-main>
    </v-app>
</template>

<script>
import { screenSizeMixin } from './mixins/screenSizeMixin';
import NewVersionSnackbar from '@/components/global/NewVersionSnackbar';

export default {
    name: 'App',
    components: {
        NewVersionSnackbar
    },
    mixins: [screenSizeMixin]
};
</script>

<style lang="scss">
@import './sass/app.scss';
</style>
