<template>
    <fragment v-if="!isUserImpersonating">
        <template v-if="isFreeUser">
            <div class="SubscriptionTrialWrapper">
                Je proefperiode is verlopen
                <router-link class="Link" :to="{ name: 'user-settings-subscription' }"
                    >Nu verlengen <v-icon>mdi-arrow-right</v-icon></router-link
                >
            </div>
        </template>

        <template v-if="isUserAccount(currentUserData)">
            <div v-if="isInTrial" class="SubscriptionTrialWrapper">
                <template v-if="trialDaysRemaining === 0"> Je proefperiode vervalt vandaag </template>
                <template v-else>
                    Nog {{ trialDaysRemaining }} dagen proefperiode.
                    <router-link class="Link" :to="{ name: 'user-settings-subscription' }">Verleng nu</router-link>
                </template>
            </div>
        </template>
    </fragment>
</template>

<script>
import dayjs from 'dayjs';
import { isUserAccount, isUserAccountWithFreeTier } from '@/helpers/auth';
import { mapState } from 'vuex';

export default {
    props: ['currentUserData', 'userBillinginfo'],

    computed: {
        ...mapState('auth', ['isUserImpersonating']),
        isFreeUser() {
            return isUserAccountWithFreeTier(this.currentUserData);
        },
        isInTrial() {
            return this.userBillinginfo.isInTrial;
        },
        trialDaysRemaining() {
            if (this.userBillinginfo.currentPeriodEnds) {
                return -dayjs().diff(this.userBillinginfo.currentPeriodEnds, 'day');
            }

            return 0;
        }
    },

    methods: {
        isUserAccount: isUserAccount
    }
};
</script>

<style lang="scss" scoped>
@import '../sass/variables';
.SubscriptionTrialWrapper {
    font-size: 0.9rem;
    color: black;

    @media screen and (min-width: $md) {
        font-size: 1rem;
    }

    .Link {
        color: $dexxter-web-blue;
        text-decoration: none;
        font-weight: 500;

        i {
            color: $dexxter-web-blue;
        }
    }
}
</style>
