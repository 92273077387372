import axios from 'axios';
import { initializePaginationVars } from '@/store/utils';
import { generateEndpoint } from '@/services/config';
import { filteringOptions, PaginationResponse } from '@/common/types';
import { ICreditnoteViewModel, IEmailDataRequest } from '@/services/creditnote.types';

const BASE_URL = generateEndpoint('/api/creditnotes');

export function getCreditnote(creditnoteID: number): Promise<ICreditnoteViewModel> {
    return axios.get(`${BASE_URL}/${creditnoteID}`).then((response) => response.data);
}

export function deleteCreditnote(creditnoteId: number): Promise<void> {
    return axios.delete(`${BASE_URL}/${creditnoteId}`).then((response) => response.data);
}

export function togglePayedCreditnote(creditnoteId: number): Promise<void> {
    return axios.put(`${BASE_URL}/${creditnoteId}/togglePayed`).then((response) => response.data);
}

export function sendCreditnoteEmail(creditnoteID: number, emailData: IEmailDataRequest): Promise<void> {
    return axios.post(`${BASE_URL}/${creditnoteID}/email`, { emailData }).then((response) => response.data);
}

export function toggleSendToCustomerCreditnote(creditnoteId: number): Promise<void> {
    return axios.put(`${BASE_URL}/${creditnoteId}/toggleSendToCustomer`).then((response) => response.data);
}

export function getCreditnotesPagination(
    year: number,
    options: filteringOptions
): Promise<PaginationResponse<ICreditnoteViewModel>> {
    return axios
        .get(`${BASE_URL}/year/${year}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function getHTMLRepresentationOfCreditnote(creditnoteId: number): Promise<string> {
    return axios.get(`${BASE_URL}/${creditnoteId}/html`).then((response) => response.data);
}

export const getPdfRepresentationOfCreditnoteUrl = (invoiceID: number) => `${BASE_URL}/${invoiceID}/pdf`;

export function getCreditnotesNotYetBookedPagination(
    year: number,
    options: filteringOptions
): Promise<PaginationResponse<ICreditnoteViewModel>> {
    return axios
        .get(`${BASE_URL}/not-yet-booked/year/${year}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function getCreditnoteByUUID(creditnoteUUID: string): Promise<ICreditnoteViewModel> {
    return axios.get(`${BASE_URL}/byUUID/${creditnoteUUID}`).then((response) => response.data);
}

export function countCreditnotesNotSendToAccountantRequest(): Promise<number> {
    return axios.get(`${BASE_URL}/countNotSendToAccountant`).then((response) => response.data);
}
