/* eslint-disable no-unused-vars */

import { createTimeregistration } from '@/models/timeregistration';
import { RootState } from '@/store/store.types';
import { TimeRegistrationsState } from '@/store/types/timeRegistrations';
import dayjs from 'dayjs';
import { Module } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import {
    sendCountAllNonInvoicedTimeregistrationsHoursRequest,
    sendCreateTimeregistrationsRequest,
    sendDeleteTimeregistrationRequest,
    sendGetAllTimeregistrationsPaginationRequest,
    sendGetRunningTimerRequest,
    sendGetTimeregistrationsRequest,
    sendGetTimeregistrationSummaryRequest,
    sendStartTimerRequest,
    sendStopTimerRequest,
    sendTimeregistrationToInvoiceRequest,
    sendUpdateTimeregistrationRequest
} from '../../services/timeregistration';
import Timeout = NodeJS.Timeout;

function initialState(): TimeRegistrationsState {
    return {
        newTimeregistrationEvents: [],
        isTimeregistrationTimerModalOpen: false,
        currentTimeregistrationTimer: null,
        timeDifference: null
    };
}

let calculateDifferenceInterval: null | Timeout = null;

export default {
    namespaced: true,

    state: initialState(),

    getters: {
        getField
    },

    actions: {
        countAllNonInvoicedTimeregistrationsHours() {
            return sendCountAllNonInvoicedTimeregistrationsHoursRequest();
        },
        getTimeregistrations() {
            return sendGetTimeregistrationsRequest();
        },
        getRunningTimer() {
            return sendGetRunningTimerRequest().then((_runningTimer) => {
                // dispatch('setCurrentTimer', _runningTimer);
                return _runningTimer;
            });
        },

        getAllTimeregistrationsPagination(context, options) {
            return sendGetAllTimeregistrationsPaginationRequest(options);
        },
        createTimeregistrations(context, timeregistrations) {
            return sendCreateTimeregistrationsRequest(timeregistrations);
        },
        getTimeregistrationSummary(context, { beginDate }) {
            return sendGetTimeregistrationSummaryRequest({ beginDate });
        },
        startTimer(context, timeregistration) {
            return sendStartTimerRequest(timeregistration);
        },
        stopTimer({ state }, timerID: number) {
            return sendStopTimerRequest(timerID).then((response) => {
                state.timeDifference = null;
                return response;
            });
        },
        updateTimeregistration(context, timeregistration) {
            return sendUpdateTimeregistrationRequest(timeregistration);
        },
        timeregistrationToInvoice(context, timeregistrationId: number) {
            return sendTimeregistrationToInvoiceRequest(timeregistrationId);
        },
        deleteTimeregistration(context, id: number) {
            return sendDeleteTimeregistrationRequest(id);
        },

        setCurrentTimer: ({ commit, dispatch }, timeregistration) => {
            if (timeregistration === null) {
                clearInterval(calculateDifferenceInterval as any);
                commit('SET_CURRENT_TIMER', createTimeregistration());
            } else {
                dispatch('calculateTimePassed');
                commit('SET_CURRENT_TIMER', timeregistration);
            }
        },
        openEditTimeregistrationTimer: ({ commit }) => {
            commit('OPEN_EDIT_TIMEREGSTRATION_TIMER');
        },
        closeEditTimeregistrationTimer: ({ commit }) => {
            commit('CLOSE_EDIT_TIMEREGSTRATION_TIMER');
        },
        newTimeregistrationEvent({ commit }) {
            commit('SET_NEW_TIMEREGISTRATION_EVENT');
        },
        calculateTimePassed({ state }) {
            const format = (value: number) => {
                const formatValue = value.toString().length;

                if (formatValue === 1) {
                    return `0${value}`;
                }

                return value;
            };

            const constcalculate = (
                currentTimeregistrationTimer: NonNullable<TimeRegistrationsState['currentTimeregistrationTimer']>
            ): string => {
                const now = dayjs();
                const timerStartedAt = dayjs.utc(currentTimeregistrationTimer.start);

                const hourDiff = now.diff(timerStartedAt, 'hour');
                const minDiff = now.diff(timerStartedAt, 'minute') - hourDiff * 60;
                const secDiff = now.diff(timerStartedAt, 'second') - minDiff * 60;

                return `${format(hourDiff)}:${format(minDiff)}:${format(secDiff)}`;
            };

            if (calculateDifferenceInterval) {
                clearInterval(calculateDifferenceInterval);
            }
            calculateDifferenceInterval = setInterval(() => {
                if (state.currentTimeregistrationTimer && state.currentTimeregistrationTimer.start) {
                    state.timeDifference = constcalculate(state.currentTimeregistrationTimer);
                } else {
                    state.timeDifference = null;
                }
            }, 1000);
        }
    },

    mutations: {
        updateField,
        SET_CURRENT_TIMER(state, timeregistration) {
            state.currentTimeregistrationTimer = timeregistration;
        },
        RESET_CURRENT_TIMER(state) {
            state.currentTimeregistrationTimer = null;
        },
        OPEN_EDIT_TIMEREGSTRATION_TIMER(state) {
            state.isTimeregistrationTimerModalOpen = true;
        },
        CLOSE_EDIT_TIMEREGSTRATION_TIMER(state) {
            state.isTimeregistrationTimerModalOpen = false;
        },
        SET_NEW_TIMEREGISTRATION_EVENT(state) {
            state.newTimeregistrationEvents = state.newTimeregistrationEvents.concat(1);
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<TimeRegistrationsState, RootState>;
