import axios from 'axios';
import { IInvoiceViewModel } from 'dexxter-types';
import { initializePaginationVars } from '@/store/utils';
import { generateEndpoint } from '@/services/config';
import { IEmailDataRequest } from '@/services/creditnote.types';
import { filteringOptions, PaginationResponse } from '@/common/types';

const BASE_URL = generateEndpoint('/api/invoices');

export function sendInvoiceEmail(invoiceID: number, emailData: IEmailDataRequest): Promise<void> {
    return axios.post(`${BASE_URL}/${invoiceID}/email`, { emailData }).then((response) => response.data);
}

export function getInvoice(invoiceID: number): Promise<IInvoiceViewModel> {
    return axios.get(`${BASE_URL}/${invoiceID}`).then((response) => response.data);
}

export function getHTMLRepresentationOfInvoice(invoiceID: number): Promise<string> {
    return axios.get(`${BASE_URL}/${invoiceID}/html`).then((response) => response.data);
}

export const getPdfRepresentationOfInvoiceUrl = (invoiceID: number): string => `${BASE_URL}/${invoiceID}/pdf`;

export function countInvoicesNotSendToAccountantRequest(): Promise<number> {
    return axios.get(`${BASE_URL}/countNotSendToAccountant`).then((response) => response.data);
}

export function getInvoiceByUUID(invoiceUUID: number): Promise<IInvoiceViewModel> {
    return axios.get(`${BASE_URL}/byUUID/${invoiceUUID}`).then((response) => response.data);
}

export function getCountAllUnpayedInvoices(): Promise<{
    total: number;
    amountEuroInclVAT: number;
    amountEuroExclVAT: number;
}> {
    return axios.get(`${BASE_URL}/unpaid/count`).then((response) => response.data);
}

export function togglePayedInvoice(invoiceId: number): Promise<IInvoiceViewModel> {
    return axios.put(`${BASE_URL}/${invoiceId}/togglePayed`).then((response) => response.data);
}
export function toggleSendToCustomerInvoice(invoiceId: number): Promise<IInvoiceViewModel> {
    return axios.put(`${BASE_URL}/${invoiceId}/toggleSendToCustomer`).then((response) => response.data);
}

export function deleteInvoice(invoicableId: number): Promise<void> {
    return axios.delete(`${BASE_URL}/${invoicableId}`).then((response) => response.data);
}

export function getInvoicesPagination(year: number, options: any): Promise<PaginationResponse<IInvoiceViewModel>> {
    return axios
        .get(`${BASE_URL}/year/${year}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function getNotYetBookedInvoicesPagination(
    year: number,
    options: filteringOptions
): Promise<PaginationResponse<IInvoiceViewModel>> {
    return axios
        .get(`${BASE_URL}/not-yet-booked/year/${year}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}
