/* eslint-disable no-unused-vars */
import { filteringOptions } from '@/common/types';
import { CreateExpenseCategoryData, UpdateExpenseCategoryData } from '@/services/expensecategories.types';
import { RootState } from '@/store/store.types';
import { ExpenseCategoriesState } from '@/store/types/expenseCategories';
import { Module } from 'vuex';
import {
    createExpenseCategories,
    deleteExpenseCategory,
    getExpenseCategoriesFrequencies,
    sendGetExpenseCategoriesRequest,
    sendGetExpenseSubCategoriesPaginationRequest,
    sendUpdatexpenseCategoriesRequest,
    toggleVisibleExpenseCategories
} from '../../services/expensecategories';

function initialState(): ExpenseCategoriesState {
    return {
        newExpenseCategoryEvents: []
    };
}

export default {
    namespaced: true,

    state: initialState(),

    actions: {
        // [Begin: Read data]
        getExpenseCategories() {
            return sendGetExpenseCategoriesRequest().then((expensecategory) => {
                return expensecategory;
            });
        },
        getExpenseSubCategoriesPagination(_, options: filteringOptions) {
            return sendGetExpenseSubCategoriesPaginationRequest(options);
        },
        getExpenseCategoriesFrequencies(context, year: number) {
            return getExpenseCategoriesFrequencies(year);
        },
        setExpenseCategoriesFrequencies({ commit }, expensecategories) {
            commit('SET_EXPENSECATEGORIES_FREQUENCIES', expensecategories);
        },
        resetExpenseCategoriesFreqencies({ commit }) {
            commit('RESET_EXPENSECATEGORIES_FREQUENCIES');
        },
        createExpenseCategories({ dispatch }, expenseCategory: CreateExpenseCategoryData) {
            return createExpenseCategories(expenseCategory).then((response) => {
                dispatch('newExpenseCategoryEvent');
                return response;
            });
        },
        updateExpenseCategories({ dispatch }, expenseCategory: UpdateExpenseCategoryData) {
            return sendUpdatexpenseCategoriesRequest(expenseCategory).then((response) => {
                dispatch('newExpenseCategoryEvent');
                return response;
            });
        },
        toggleVisibleExpenseCategories({ dispatch }, expenseCategoryId: number) {
            return toggleVisibleExpenseCategories(expenseCategoryId).then((expensecategory) => {
                dispatch('newExpenseCategoryEvent');
                return expensecategory;
            });
        },
        deleteExpenseCategory({ dispatch }, expenseCategoryId: number) {
            return deleteExpenseCategory(expenseCategoryId).then((response) => {
                dispatch('newExpenseCategoryEvent');
                return response;
            });
        },
        newExpenseCategoryEvent({ commit }) {
            commit('NEW_EXPENSE_CATEGORY_EVENT');
        }
    },

    mutations: {
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        },
        NEW_EXPENSE_CATEGORY_EVENT(state) {
            state.newExpenseCategoryEvents = state.newExpenseCategoryEvents.concat(1);
        }
    }
} as Module<ExpenseCategoriesState, RootState>;
