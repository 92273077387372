export class Learningitem {
    id: number;
    title: string;
    summary: string;
    tags: string[];
    userType: string[];
    VATType: string[];
    places: string[];
    url: string;

    getType() {
        throw new Error('Not implemented');
    }

    constructor(
        id: number,
        title: string,
        summary: string,
        tags: string[],
        userType: string[],
        VATType: string[],
        places: string[],
        url: string
    ) {
        this.id = id;
        this.title = title;
        this.summary = summary;
        this.tags = tags;
        this.userType = userType;
        this.VATType = VATType;
        this.places = places;
        this.url = url;
    }
}

export class LearningBlog extends Learningitem {
    getType() {
        return 'BLOG';
    }

    getIcon() {
        return 'mdi-school';
    }

    constructor(
        id: number,
        title: string,
        summary: string,
        tags: string[],
        userType: string[],
        VATType: string[],
        places: string[],
        url: string
    ) {
        super(id, title, summary, tags, userType, VATType, places, url);
    }
}

export class LearningVideo extends Learningitem {
    getType() {
        return 'VIDEO';
    }

    getIcon() {
        return 'mdi-video';
    }

    constructor(
        id: number,
        title: string,
        summary: string,
        tags: string[],
        userType: string[],
        VATType: string[],
        places: string[],
        url: string
    ) {
        super(id, title, summary, tags, userType, VATType, places, url);
    }
}

export class LearningTextPopup extends Learningitem {
    getIcon() {
        return 'mdi-text';
    }

    getType() {
        return 'TextPopup';
    }
    constructor(
        id: number,
        title: string,
        summary: string,
        tags: string[],
        userType: string[],
        VATType: string[],
        places: string[],
        url: string
    ) {
        super(id, title, summary, tags, userType, VATType, places, url);
    }
}
