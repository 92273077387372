import { InitializedUseraccountAuthState } from '@/store/modules/auth';
import { BILLING_PLAN_TYPE } from 'dexxter-types';
import { VatStatus } from '../config/constants';

export function getAnalyticsUserAccountPlanType(
    user: InitializedUseraccountAuthState['currentUserData']
): 'trial' | BILLING_PLAN_TYPE {
    return user.userinformation.billinginfo.isInTrial ? 'trial' : user.userinformation.billinginfo.subscriptionType;
}

export function getAnalyticsUserType(
    user: InitializedUseraccountAuthState['currentUserData']
): 'student' | 'secondary' | 'main' | 'vakantiewoningen' {
    return user.settings.verhuurdVakantiewoningen ? 'vakantiewoningen' : user.userType;
}

export function getAnalyticsVatType({
    verhuurdVakantiewoningen,
    vatLiable
}: {
    verhuurdVakantiewoningen: boolean;
    vatLiable: VatStatus;
}): VatStatus | null {
    return verhuurdVakantiewoningen ? null : vatLiable;
}
