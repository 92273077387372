
import { defineComponent } from 'vue';

import { HeadingTag, TextColorClass } from '@/components/typography';

interface HeadingProps {
    tag: HeadingTag;
    loading?: boolean;
    color: TextColorClass;
    className?: string;
}

export default defineComponent<HeadingProps>({
    name: 'Heading',
    props: {
        tag: {
            type: String,
            default: 'h3'
        },
        loading: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'dexxter-dark'
        },
        className: {
            type: String,
            default: ''
        }
    }
});
