import { removeAllTokens } from '@/helpers/auth';
import { reportError } from '@/helpers/logging';
import store from '../../store';

export async function redirectLoggedInUsersToDashboard(to, from, next) {
    try {
        // After this, all routes do not need auth
        if (to.name === 'login') {
            if (store.state.auth.isLoggedIn) {
                next({ name: 'dashboard' });
                return;
            }
        }

        next();
        return;
    } catch (e) {
        reportError(e);
    } finally {
        store.dispatch('stopLoading');
    }

    removeAllTokens();
}
