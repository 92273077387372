
import CompanyNumberField from '@/components/FormComponents/CompanyNumberField.vue';
import { BELGIAN_COMPANY, COMPANY_INSIDE_EU, COMPANY_OUTSIDE_EU, PRIVATE } from '@/config/constants';
import { checkValidCompanyNumber } from '@/helpers/checkValidCompanyNumber';
import { getEuCountries } from '@/helpers/countries';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { lookupCompanynumberMixin } from '@/mixins/lookupCompanynumberMixin';
import { useLookupCompanyNumber } from '@/mixins/lookupCompanynumberMixin.hook';
import { companyLookup } from '@/services/customer';
import { useSupplierStore } from '@/store/modules/suppliers';
import { CustomerType } from 'dexxter-types/customers/customer.types';
import { isEmpty, isNil } from 'lodash';
import { defineComponent } from 'vue';
import { reportError } from '../../helpers/logging';
import { analyticsTrack } from '../../services/analytics';
import { CreateSupplierData, UpdateSupplierData } from '../../services/suppliers.types';
import Loader from '../Loader.vue';
import SubmitButton from '../SubmitButton.vue';
import BottomSheetHeader from './BottomSheetHeader.vue';

export default defineComponent({
    components: {
        BottomSheetHeader,
        Loader,
        SubmitButton,
        CompanyNumberField
    },

    mixins: [formRulesMixin, lookupCompanynumberMixin],

    setup() {
        const store = useSupplierStore();
        const { fillInMissingCompanyInfoBasedOnLookup } = useLookupCompanyNumber();

        return { store, fillInMissingCompanyInfoBasedOnLookup };
    },

    data() {
        return {
            lookingUpCompany: false,
            valid: true,
            loading: false,
            BELGIAN_COMPANY: BELGIAN_COMPANY,
            PRIVATE,
            COMPANY_INSIDE_EU,
            COMPANY_OUTSIDE_EU,
            typeOfSupplier: [
                {
                    label: this.$t('customer.private'),
                    type: PRIVATE
                },
                {
                    label: this.$t('customer.belgian_company'),
                    type: BELGIAN_COMPANY
                },
                {
                    label: this.$t('customer.company_inside_eu'),
                    type: COMPANY_INSIDE_EU
                },
                {
                    label: this.$t('customer.company_outside_eu'),
                    type: COMPANY_OUTSIDE_EU
                }
            ] as const,
            countrycodes: getEuCountries.call(this, true)
        };
    },

    computed: {
        canShowMainInforForm(): boolean {
            if (this.store.currentSupplier.type == null) {
                return false;
            }

            if (this.store.currentSupplier.type === COMPANY_INSIDE_EU) {
                if (isEmpty(this.store.currentSupplier.address.country)) {
                    return false;
                }
            }

            return true;
        },
        companyNumberHintText(): string {
            if (this.store.currentSupplier.type === BELGIAN_COMPANY) {
                return this.$t('general.search_by_company_number') as string;
            }

            return '';
        },
        selectedCountry(): string | null {
            if (this.store.currentSupplier.type === BELGIAN_COMPANY) {
                return 'BE';
            }

            return this.store.currentSupplier.address.country;
        }
    },
    watch: {
        'store.isCreatingNewSupplier'(isCreatingNew): void {
            if (isCreatingNew) {
                // If a new entry needs to be created, make sure the form is empty.
                this.store.resetCurrentSupplier();
            }
        },
        'store.currentSupplier.type'(newType): void {
            if (newType === PRIVATE) {
                this.store.currentSupplier.company.number = null;
                this.store.currentSupplier.address.country = null;
            } else if (newType === BELGIAN_COMPANY) {
                this.store.currentSupplier.address.country = 'BE';
            }
        }
    },
    methods: {
        canHaveEUCompanyNumber(type: CustomerType) {
            return [BELGIAN_COMPANY, COMPANY_INSIDE_EU].includes(type);
        },
        resetForm() {
            (this.$refs.form as any).resetValidation();
            this.store.resetCurrentSupplier();
        },
        canLookupCompanyNumber(companyNumber: string, companyType: CustomerType) {
            if (companyType !== BELGIAN_COMPANY) {
                return false;
            }

            if (!checkValidCompanyNumber(companyNumber)) {
                return false;
            }

            return true;
        },
        async lookupCompany() {
            try {
                const companyNumber = this.store.currentSupplier.company.number;
                const type = this.store.currentSupplier.type;

                if (isNil(companyNumber)) {
                    throw new Error('Companynumber is nil');
                }
                if (isNil(type)) {
                    throw new Error('type is nil');
                }

                if (!this.canLookupCompanyNumber(companyNumber, type)) {
                    return;
                }

                this.lookingUpCompany = true;

                const result = await companyLookup(companyNumber);

                this.fillInMissingCompanyInfoBasedOnLookup(this.store.currentSupplier, result);
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.lookingUpCompany = false;
            }
        },
        async handleSubmit() {
            try {
                this.loading = true;
                if (this.store.isCreatingNewSupplier) {
                    // this.store.currentSupplier.VATObligatory = this.store.currentSupplier.VATObligatory
                    //     ? this.store.currentSupplier.VATObligatory
                    //     : false;

                    await this.store.createSupplier(this.store.currentSupplier as CreateSupplierData);

                    try {
                        analyticsTrack('User Creates Supplier', {
                            Type: this.store.currentSupplier.type
                        });
                    } catch (e) {
                        reportError(e);
                    }
                } else {
                    await this.store.updateSupplier(this.store.currentSupplier as UpdateSupplierData);
                }
                notify.call(this, {
                    title: this.store.isCreatingNewSupplier
                        ? this.$t('supplier.supplier_succesfuly_saved')
                        : this.$t('supplier.supplier_succesfuly_saved'),
                    text: this.store.isCreatingNewSupplier
                        ? this.$t('supplier.supplier_succesfuly_saved')
                        : this.$t('supplier.supplier_succesfuly_saved')
                });

                // Close bottom sheet when submit successful.
                this.store.newSupplierCreated();
                this.resetForm();
                this.store.closeAddSupplier();
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
});
