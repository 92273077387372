import { isNil } from 'lodash';

export function checkValidCompanyNumber(companyNumber) {
    if (isNil(companyNumber)) {
        return false;
    }

    // Remove all non-numeric characters from the string
    const companyNumberAsInt = parseInt(companyNumber);

    const remainder = Math.floor(companyNumberAsInt % 100);
    const division = Math.floor(companyNumberAsInt / 100);

    return 97 - (division % 97) == remainder;
}
