import { generateEndpoint } from './config';
import axios from 'axios';
import { CreateUnitData, Unit, UpdateUnitData } from '@/services/units.types';

export const BASE_URL_UNITS = generateEndpoint(`/api/units`);

export function sendGetUnitsRequest(): Promise<Unit[]> {
    return axios.get(`${BASE_URL_UNITS}`).then((response) => response.data);
}

export function sendCreateUnitRequest(unit: CreateUnitData): Promise<Unit> {
    return axios.post(`${BASE_URL_UNITS}`, { unit }).then((response) => response.data);
}

export function sendUpdateUnitRequest(unit: UpdateUnitData): Promise<Unit> {
    return axios
        .put(`${BASE_URL_UNITS}/${unit.id}`, {
            unit
        })
        .then((response) => response.data);
}
export function sendDeleteUnitRequest(id: number): Promise<void> {
    return axios.delete(`${BASE_URL_UNITS}/${id}`).then((response) => response.data);
}
