import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
// --- Time
import Vue from 'vue';
import VueCurrencyInput from 'vue-currency-input';
import Notifications from 'vue-notification';
import { monthNamesShort } from '@/config/constants';

Vue.use(VueCurrencyInput);
Vue.use(Notifications);
Vue.use(VueFilterDateFormat, {
    dayOfWeekNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
    dayOfWeekNamesShort: ['zo', 'ma', 'di', 'woe', 'do', 'vr', 'za'],
    monthNames: [
        'januari',
        'februari',
        'maart',
        'april',
        'mei',
        'juni',
        'juli',
        'augustus',
        'september',
        'oktober',
        'november',
        'december'
    ],
    monthNamesShort
});
