// --- Error tracking
import { abilitiesPlugin } from '@casl/vue';
import { GtmSupport as GtmPlugin, loadScript } from '@gtm-support/core';
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import { isNil } from 'lodash';
import { PiniaVuePlugin, createPinia } from 'pinia';
// don't forget to import CSS styles
// --- Time
import Vue from 'vue';
import { Plugin } from 'vue-fragment';
import VueShepherd from 'vue-shepherd';
import App from './App.vue';
import { displayServiceUnavailableAndReportError } from './helpers/errorHandling';
import { reportError } from './helpers/logging';
import { successMessage } from './helpers/successNotification';
import i18n from './i18n';
// --- Helpers
import { migrateTokens } from './migrateToken';
import vuetify from './plugins/vuetify';
import router from './router';
import './setup/dayjs.ts';
import store from './store/index';

// For migration: migrate array of tokens to one single token. If the user is impersonating: take the last token
migrateTokens();

const script = document.createElement('script');
script.type = 'text/javascript';
script.async = true;
script.id = 'ze-snippet';
script.src = '//code.jivosite.com/widget/MtU8XhiJHh';

const first = document.getElementsByTagName('script')[0];
first.parentNode?.insertBefore(script, first);

window.jivo_onLoadCallback = () => {
    // Hide the widget on load
    window.jivo_api.close();

    // Unregister the callback, since we no longer need it
    window.jivo_onLoadCallback = undefined;
};

Vue.use(Plugin);

Vue.config.performance = process.env.VUE_APP_MODE === 'development';

Vue.use(abilitiesPlugin, store.getters['auth/ability'], {
    useGlobalProperties: true
});

Vue.use(VueShepherd);

Vue.prototype.$displayServiceUnavailableAndReportError = displayServiceUnavailableAndReportError;
Vue.prototype.$successMessage = successMessage;

const options = {
    id: 'GTM-WCFTRM9', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    nonce: '2726c7f26c', // Will add `nonce` to the script tag
    enabled: process.env.VUE_APP_MODE === 'production', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    loadScript: true // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
};

const gtmPlugin = new GtmPlugin(options);
loadScript('GTM-WCFTRM9', options);
Vue.prototype.$gtm = Vue.gtm = gtmPlugin;

(function setGTMPrototype() {
    Vue.prototype.$gtmTrackEvent = (parameters: Parameters<GtmPlugin['trackEvent']>[0]) => {
        if (process.env.VUE_APP_MODE === 'production') {
            gtmPlugin.trackEvent(parameters);
        }
    };
})();

require('./setup/directives');
require('./setup/globalcomponents');
require('./setup/mixins');
require('./setup/libraries');
require('./setup/filters/register');

require('./interceptors/index');

if (
    process.env.VUE_APP_MODE === 'production' &&
    (isNil(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY) || isNil(process.env.VUE_APP_MOLLIE_CLIENT_ID))
) {
    reportError(
        new Error(
            `Clients is nil: VUE_STRIPE_PUBLISHABLE_KEY: ${process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY} VUE_MOLLIE_CLIENT_ID: ${process.env.VUE_APP_MOLLIE_CLIENT_ID}`
        )
    );
}

const result = {
    stripePublishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY ?? '',
    env: process.env.VUE_APP_MODE,
    mollieClientId: process.env.VUE_APP_MOLLIE_CLIENT_ID ?? '',
    version: process.env.PACKAGE_VERSION
};

if (result.env !== 'development') {
    Sentry.init({
        integrations: [new Integrations.BrowserTracing()],
        Vue: Vue,
        release: `dexxter@${process.env.PACKAGE_VERSION}`,
        dsn: process.env.VUE_APP_SENTRY_DNS,
        environment: result.env,
        logErrors: true
    });
}

store.dispatch('setServerInfo', result);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

new Vue({
    render: (h) => h(App),
    store,
    i18n,
    vuetify,
    router,
    pinia
}).$mount('#app');
