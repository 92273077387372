import { generateEndpoint } from './config';
import axios from 'axios';
import { initializePaginationVars } from '@/store/utils';
import { filteringOptions } from '@/common/types';

export const BASE_URL_ZIPCODES = generateEndpoint(`/api/zipcodes`);

export function sendGetZipcodePaginationRequest({ options, year }: { options: filteringOptions; year: number }) {
    return axios
        .get(`${BASE_URL_ZIPCODES}/year/${year}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function sendCreateZipcodeRequest(zipcode: any) {
    const formData = new FormData();
    formData.append('file', zipcode.file);

    return axios.post(`${BASE_URL_ZIPCODES}/year/${zipcode.year}`, formData).then((response) => response.data);
}

export function sendUpdateZipcodeRequest(zipcode: any) {
    return axios.put(`${BASE_URL_ZIPCODES}/${zipcode.id}`, zipcode).then((response) => response.data);
}
