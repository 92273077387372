import LoggedInLayout from '../layouts/LoggedInLayout.vue';

export const communityRoutes = [
    {
        path: '/community',
        component: LoggedInLayout,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'communityRoot',
                redirect: {
                    name: 'communityQuestions'
                }
            },
            {
                path: 'create-question',
                name: 'createCommunityQuestion',
                component: () =>
                    import(
                        /* webpackChunkName: 'community' */
                        '../views/UpdateCommunityquestion.vue'
                    ),
                meta: {
                    title: 'pageTitles.create_communityquestion'
                }
            },
            {
                path: 'update-question/:id',
                name: 'update-communityquestion',
                component: () =>
                    import(
                        /* webpackChunkName: 'community' */
                        '../views/UpdateCommunityquestion.vue'
                    ),
                meta: {
                    title: 'pageTitles.update_communityquestion'
                }
            },
            {
                path: 'update-answer/:id',
                name: 'update-communityanswer',
                component: () =>
                    import(
                        /* webpackChunkName: 'community' */
                        '../views/UpdateCommunityanswer.vue'
                    ),
                meta: {
                    title: 'pageTitles.update_communityanswer'
                }
            },
            {
                path: 'view-user-communityquestion/:id',
                name: 'viewUserCommunityQuestion',
                component: () =>
                    import(
                        /* webpackChunkName: 'community' */
                        '../views/ViewUserCommunityquestions.vue'
                    )
            },
            {
                path: 'questions',
                name: 'communityQuestions',
                component: () =>
                    import(
                        /* webpackChunkName: 'community' */
                        '../views/Communityquestions.vue'
                    ),
                meta: {
                    title: 'pageTitles.community'
                }
            },
            {
                path: 'question/:id',
                name: 'communityquestion',
                component: () =>
                    import(
                        /* webpackChunkName: 'community' */
                        '../views/Communityquestion.vue'
                    )
            }
        ]
    }
];
