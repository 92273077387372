
import Vue from 'vue';

export default Vue.extend({
    name: 'BackButton',
    methods: {
        emitClickEvent(): void {
            this.$emit('click');
        }
    }
});
