/* eslint-disable no-unused-vars */

import { RootState } from '@/store/store.types';
import { IncomesState } from '@/store/types/incomes';
import axios from 'axios';
import { Module } from 'vuex';
import {
    BASE_URL_CREDITNOTES,
    BASE_URL_DAILYRECEIPTS,
    BASE_URL_INVOICES,
    BASE_URL_JOBSTUDENT,
    BASE_URL_QUOTATIONS
} from '../../services/invoicable';
import { initializePaginationVars } from '../utils';

function initialState(): IncomesState {
    return {
        newInvoiceEvents: [],
        newQuotationEvents: [],
        newCreditnoteEvents: [],
        newDailyReceiptEvents: [],
        newJobstudentEvents: []
    };
}

export default {
    namespaced: true,

    state: initialState(),

    actions: {
        // [Begin: Create data]
        async createAccountingInvoicable(context, invoicable) {
            const formData = new FormData();
            formData.append('file', invoicable.file);
            formData.append(invoicable.type, JSON.stringify(invoicable));

            const prepend = invoicable.historical ? `/historical` : '';

            if (invoicable.type === 'invoice') {
                return axios
                    .post(`${BASE_URL_INVOICES}/accounting${prepend}`, formData)
                    .then((response) => response.data);
            } else if (invoicable.type === 'creditnote') {
                return axios
                    .post(`${BASE_URL_CREDITNOTES}/accounting${prepend}`, formData)
                    .then((response) => response.data);
            } else {
                throw new Error('Not an invoicable type');
            }
        },
        async updateAccountingInvoicable(context, invoicable) {
            const formData = new FormData();
            formData.append('file', invoicable.file);
            formData.append(invoicable.type, JSON.stringify(invoicable));

            const prepend = invoicable.historical ? `historical/` : '';

            if (invoicable.type === 'invoice') {
                return axios
                    .put(`${BASE_URL_INVOICES}/accounting/${prepend}${invoicable.id}`, formData)
                    .then((response) => response.data);
            } else if (invoicable.type === 'creditnote') {
                return axios
                    .put(`${BASE_URL_CREDITNOTES}/accounting/${prepend}${invoicable.id}`, formData)
                    .then((response) => response.data);
            } else {
                throw new Error('Not an invoicable type');
            }
        },
        async createInvoicable(context, invoicable) {
            const formData = new FormData();
            formData.append('file', invoicable.file);
            formData.append(invoicable.type, JSON.stringify(invoicable));

            const prepend = invoicable.historical ? `/historical` : '';

            if (invoicable.type === 'invoice') {
                return axios.post(`${BASE_URL_INVOICES}${prepend}`, formData).then((response) => response.data);
            } else if (invoicable.type === 'creditnote') {
                return axios.post(`${BASE_URL_CREDITNOTES}${prepend}`, formData).then((response) => response.data);
            } else if (invoicable.type === 'quotation') {
                return axios.post(`${BASE_URL_QUOTATIONS}${prepend}`, formData).then((response) => response.data);
            }
        },
        async updateInvoicable(context, invoicable) {
            const formData = new FormData();
            formData.append('file', invoicable.file);
            formData.append(invoicable.type, JSON.stringify(invoicable));

            const prepend = invoicable.historical ? `historical/` : '';

            if (invoicable.type === 'invoice') {
                return axios
                    .put(`${BASE_URL_INVOICES}/${prepend}${invoicable.id}`, formData)
                    .then((response) => response.data);
            } else if (invoicable.type === 'creditnote') {
                return axios
                    .put(`${BASE_URL_CREDITNOTES}/${prepend}${invoicable.id}`, formData)
                    .then((response) => response.data);
            } else if (invoicable.type === 'quotation') {
                return axios
                    .put(`${BASE_URL_QUOTATIONS}/${prepend}${invoicable.id}`, formData)
                    .then((response) => response.data);
            }
        },
        async deleteQuotation(context, quotationId) {
            return axios.delete(`${BASE_URL_QUOTATIONS}/${quotationId}`).then((response) => response.data);
        },
        async toggleSendToCustomerQuotation(context, quotationId) {
            return axios
                .put(`${BASE_URL_QUOTATIONS}/${quotationId}/toggleSendToCustomer`)
                .then((response) => response.data);
        },
        async toggleAcceptedQuotation(context, { id, value }) {
            return axios
                .put(`${BASE_URL_QUOTATIONS}/${id}/setAccepted`, {
                    accepted: value
                })
                .then((response) => response.data);
        },
        async createDailyReceiptsArray(context, dailyReceiptsArray) {
            return axios
                .post(`${BASE_URL_DAILYRECEIPTS}/multiple`, dailyReceiptsArray)
                .then((response) => response.data);
        },
        async correctDailyReceipt(context, { correctionComment, dailyreceiptID }) {
            return axios
                .put(`${BASE_URL_DAILYRECEIPTS}/${dailyreceiptID}`, { correctionComment })
                .then((response) => response.data);
        },
        async getDailyreceiptsPagination({ rootState }, options) {
            const year = rootState.selectedYear;
            return axios
                .get(`${BASE_URL_DAILYRECEIPTS}/year/${year}/pagination`, {
                    params: {
                        ...initializePaginationVars(options.pagination),
                        ...options.query
                    }
                })
                .then((response) => response.data);
        },
        async getQuotationsPagination({ rootState }, options) {
            const year = rootState.selectedYear;

            return axios
                .get(`${BASE_URL_QUOTATIONS}/year/${year}/pagination`, {
                    params: {
                        ...initializePaginationVars(options.pagination),
                        ...options.query
                    }
                })
                .then((response) => response.data);
        },
        async getInvoicablelById(context, { id, type }) {
            if (type === 'invoice') {
                return axios.get(`${BASE_URL_INVOICES}/${id}`).then((response) => response.data);
            } else if (type === 'creditnote') {
                return axios.get(`${BASE_URL_CREDITNOTES}/${id}`).then((response) => response.data);
            } else if (type === 'quotation') {
                return axios.get(`${BASE_URL_QUOTATIONS}/${id}`).then((response) => response.data);
            }
        },
        async getAccountingInvoicablelById(context, { id, type }) {
            if (type === 'invoice') {
                return axios.get(`${BASE_URL_INVOICES}/${id}`).then((response) => response.data);
            } else if (type === 'creditnote') {
                return axios.get(`${BASE_URL_CREDITNOTES}/${id}`).then((response) => response.data);
            }
        },
        async getCreditnoteById(context, creditnoteId) {
            return axios.get(`${BASE_URL_CREDITNOTES}/${creditnoteId}`).then((response) => response.data);
        },
        async getQuotationById(context, quotationId) {
            return axios.get(`${BASE_URL_QUOTATIONS}/${quotationId}`).then((response) => response.data);
        },
        async newInvoiceCreated({ commit }) {
            commit('SET_NEW_INVOICE_CREATED_EVENT');
        },
        async newQuotationCreated({ commit }) {
            commit('SET_NEW_QUOTATION_CREATED_EVENT');
        },
        async newCreditnoteCreated({ commit }) {
            commit('SET_NEW_CREDITNOTE_CREATED_EVENT');
        },
        async newDailyReceiptCreated({ commit }) {
            commit('SET_NEW_DAILY_RECEIPT_CREATED_EVENT');
        },
        async newDailyReceiptUpdated({ commit }) {
            commit('SET_NEW_DAILY_RECEIPT_CREATED_EVENT');
        },
        async newJobstudentUpdated({ commit }) {
            commit('SET_NEW_JOBSTUDENT_EVENT');
        },
        async getJobstudentincomeById(context, jobstudentIncomeID) {
            return axios.get(`${BASE_URL_JOBSTUDENT}/${jobstudentIncomeID}`).then((response) => response.data);
        },
        async createJobstudentIncome(context, jobstudentIncome) {
            return axios.post(`${BASE_URL_JOBSTUDENT}`, { jobstudentIncome }).then((response) => response.data);
        },
        async updateJobstudentIncome(context, jobstudentIncome) {
            return axios
                .put(`${BASE_URL_JOBSTUDENT}/${jobstudentIncome.id}`, { jobstudentIncome })
                .then((response) => response.data);
        },
        async deleteJobstudentIncome(context, jobstudentIncomeId) {
            return axios.delete(`${BASE_URL_JOBSTUDENT}/${jobstudentIncomeId}`).then((response) => response.data);
        },
        async deleteDailyreceipteincome(context, dailyreceiptId) {
            return axios.delete(`${BASE_URL_DAILYRECEIPTS}/${dailyreceiptId}`).then((response) => response.data);
        },
        async getJobstudentIncomePagination({ rootState }, options) {
            const year = rootState.selectedYear;

            return axios
                .get(`${BASE_URL_JOBSTUDENT}/year/${year}/pagination`, {
                    params: {
                        ...initializePaginationVars(options.pagination),
                        ...options.query
                    }
                })
                .then((response) => response.data);
        }
    },

    mutations: {
        SET_NEW_INVOICE_CREATED_EVENT(state) {
            state.newInvoiceEvents = state.newInvoiceEvents.concat(1);
        },
        SET_NEW_QUOTATION_CREATED_EVENT(state) {
            state.newQuotationEvents = state.newQuotationEvents.concat(1);
        },
        SET_NEW_CREDITNOTE_CREATED_EVENT(state) {
            state.newCreditnoteEvents = state.newCreditnoteEvents.concat(1);
        },
        SET_NEW_DAILY_RECEIPT_CREATED_EVENT(state) {
            state.newDailyReceiptEvents = state.newDailyReceiptEvents.concat(1);
        },
        SET_NEW_JOBSTUDENT_EVENT(state) {
            state.newJobstudentEvents = state.newJobstudentEvents.concat(1);
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<IncomesState, RootState>;
