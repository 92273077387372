import { RootState } from '@/store/store.types';
import { ExpensesState } from '@/store/types/expenses';
import { Module } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import * as AccountingExpenseService from '../../services/accountingexpenses';
import * as ExpenseService from '../../services/expenses';

function initialState(): ExpensesState {
    return {
        expensesSum: null,
        accountingExpensesSum: null,
        newExpenseEvents: []
    };
}

export default {
    namespaced: true,

    state: initialState(),

    getters: {
        getField
    },

    actions: {
        // [Begin: Create data]
        async getExpensesSum({ state, commit }, { year, group }) {
            if (state.expensesSum) {
                return Promise.resolve(state.expensesSum);
            }

            return ExpenseService.getExpensesSum(year, group).then((data) => {
                commit('SET_EXPENSESUM', data);
                return data;
            });
        },
        async getAccountingExpensesSum({ state, commit }, { year, group }) {
            if (state.accountingExpensesSum) {
                return Promise.resolve(state.accountingExpensesSum);
            }

            return AccountingExpenseService.getExpensesSum(year, group).then((data) => {
                commit('SET_ACCOUNTING_EXPENSESUM', data);
                return data;
            });
        },
        async getCountAllUnpaidExpenses() {
            return ExpenseService.getCountAllUnpaidExpenses();
        },

        createAccountingExpense({ dispatch }, expense) {
            return AccountingExpenseService.createExpense(expense).then((response) => {
                // Actions to perform when expense has been created
                dispatch('getExpenses', {
                    force: true
                });
                dispatch('resetExpensesSum');

                return response;
            });
        },

        async getAccountingExpenses({ rootState }, options) {
            const year = rootState.selectedYear;
            return AccountingExpenseService.getExpenses(year as number, options).then((response) => {
                return response;
            });
        },

        async getExpenses({ rootState }, options) {
            const year = rootState.selectedYear;
            return AccountingExpenseService.getExpenses(year as number, options);
        },

        // [Begin: Update data]
        updateAccountingExpense({ dispatch }, { expense, id }) {
            return AccountingExpenseService.updateExpense({ expense, id }).then((response) => {
                // Actions to perform when expense has been created
                dispatch('getExpenses', {
                    force: true
                });
                dispatch('resetExpensesSum');

                return response;
            });
        },
        // [End: Update data]

        // [Begin: Delete data]
        deleteExpense({ dispatch }, id) {
            return ExpenseService.deleteExpense(id).then((response) => {
                // Actions to perform when expense has been created
                dispatch('getExpenses', {
                    force: true
                });

                dispatch('resetExpensesSum');

                return response;
            });
        },
        // [End: Delete data]
        async newExpenseEvent({ commit }) {
            commit('SET_NEW_EXPENSE_EVENT');
        },
        resetExpensesSum({ commit }) {
            commit('SET_EXPENSESUM', null);
        },
        resetAccouningExpensesSum({ commit }) {
            commit('SET_ACCOUNTING_EXPENSESUM', null);
        }
    },

    mutations: {
        updateField,
        SET_EXPENSESUM(state, expensesSum) {
            state.expensesSum = expensesSum;
        },
        SET_ACCOUNTING_EXPENSESUM(state, accountingExpensesSum) {
            state.accountingExpensesSum = accountingExpensesSum;
        },
        SET_NEW_EXPENSE_EVENT(state) {
            state.newExpenseEvents = state.newExpenseEvents.concat(1);
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<ExpensesState, RootState>;
