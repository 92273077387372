import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import { IAccountingOfficeViewModel } from 'dexxter-types';

const BASE_URL = generateEndpoint('/api/accountants');

export function inviteAccountantRequest(accountantEmail: string): Promise<void> {
    return axios
        .post(`${BASE_URL}/register`, {
            accountantEmail
        })
        .then((response) => response.data);
}

export function deleteAccountantAccessToUserRequest(): Promise<void> {
    return axios.delete(`${BASE_URL}/accountantoffice`).then((response) => response.data);
}

export function getAccountantOfUserRequest(): Promise<IAccountingOfficeViewModel | null> {
    return axios.get(`${BASE_URL}`).then((response) => response.data);
}
