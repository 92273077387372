import { isAccountant, isAdmin, isUserAccount } from '@/helpers/auth';
import store from '../../store';
import { routeRequiresAuth } from './helpers/index';

export function redirectIfAccessingUseraccountPageWithoutHavingUserAccount(to, from, next) {
    const requiresAuth = routeRequiresAuth(to);
    const requiresUserAccount = to.matched.some((record) => record.meta.requiresUserAccount);

    if (requiresAuth) {
        const user = store.state.auth.currentUserData;

        if (requiresUserAccount) {
            if (isUseraccountOrIsImpersonatingUser() === false) {
                if (isAccountant(user)) {
                    if (to.name !== 'accountant') {
                        return next({ name: 'accountant' });
                    }
                } else if (isAdmin(user)) {
                    if (to.name !== 'users') {
                        return next({ name: 'users' });
                    }
                }
            }
        }
    }

    return next();
}

function isUseraccountOrIsImpersonatingUser() {
    const currentUserData = store.state.auth.currentUserData;
    return isUserAccount(currentUserData);
}
