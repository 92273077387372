import fs from 'fs';
import { noop } from 'lodash';
import path from 'path';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// This condition actually should detect if it's an Node environment (for testing purposes)
if (typeof require.context === 'undefined') {
    require.context = (base = '.', scanSubDirectories = false, regularExpression = /\.js$/) => {
        const files = {};

        function readDirectory(directory) {
            fs.readdirSync(directory).forEach((file) => {
                const fullPath = path.resolve(directory, file);

                if (fs.statSync(fullPath).isDirectory()) {
                    if (scanSubDirectories) readDirectory(fullPath);

                    return;
                }

                if (!regularExpression.test(fullPath)) return;

                files[fullPath] = true;
            });
        }

        readDirectory(path.resolve(__dirname, base));

        function Module(file) {
            return require(file);
        }

        Module.keys = () => Object.keys(files);

        return Module;
    };
}

export function loadLocaleMessages() {
    const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

function getBrowserLocale(options = {}) {
    const defaultOptions = { countryCodeOnly: false };

    const opt = { ...defaultOptions, ...options };

    const navigatorLocale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;

    if (!navigatorLocale) {
        return undefined;
    }

    const trimmedLocale = opt.countryCodeOnly ? navigatorLocale.trim().split(/-|_/)[0] : navigatorLocale.trim();

    return trimmedLocale;
}

export function getStartingLocale() {
    const browserLocale = getBrowserLocale({ countryCodeOnly: true });
    const supportedLocales = ['nl'];

    if (supportedLocales.includes(browserLocale)) {
        return browserLocale;
    } else {
        return 'nl';
    }
}

export function getFallbackLocale() {
    return 'nl';
}

const urlParams = new URLSearchParams(window.location.search);

const i18nConfigObj = urlParams.get('i18nkeys')
    ? {
          missing: noop
      }
    : {
          locale: getStartingLocale(),
          fallbackLocale: getFallbackLocale(),
          messages: loadLocaleMessages()
      };

export default new VueI18n(i18nConfigObj);
