<template>
    <v-overlay z-index="999" absolute :color="overlayColor || 'white'">
        <v-overlay :opacity="0.25"
            ><v-progress-circular color="primary" indeterminate size="32" width="4" z-index="999"
        /></v-overlay>
    </v-overlay>
</template>

<script>
export default {
    props: {
        overlayColor: {
            default: '',
            type: String
        }
    }
};
</script>
