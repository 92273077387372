import { USER_ACCESS_OPERATIONS } from '@/config/constants';
import LoggedInLayout from '../layouts/LoggedInLayout.vue';

export const settingRoutes = [
    {
        path: '/user-settings/general',
        component: LoggedInLayout,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'userSettingsGeneral',
                // route level code-splitting
                component: () => import('../views/settings/General.vue'),
                meta: {
                    title: 'pageTitles.setting_titels.general',
                    freeTier: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Usersetting'
                }
            }
        ]
    }
];
