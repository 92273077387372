import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useGlobalStore = defineStore('global', () => {
    const selectedYear = ref<number>(0);
    const userHasAccountant = ref<boolean>(false);

    return {
        selectedYear,
        userHasAccountant
    };
});
