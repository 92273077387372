
import { defineComponent } from 'vue';

interface KeyValuePairProps {
    keyName?: string;
    value?: string;
    loading?: boolean;
}

export default defineComponent<KeyValuePairProps>({
    name: 'KeyValuePair',
    props: {
        keyName: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
});
