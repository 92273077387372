
import { defineComponent } from 'vue';
import { Color } from '@/common/types';

interface InfoTagProps {
    icon?: string;
    backgroundColor?: Color | 'primary' | 'success' | 'warning' | 'error' | 'info';
    fontSize?: string;
    textColor?: Color | 'primary' | 'success' | 'warning' | 'error' | 'info';
    loading?: boolean;
}

export default defineComponent<InfoTagProps>({
    name: 'Tag',
    props: {
        icon: {
            type: String
        },
        backgroundColor: {
            type: String,
            default: 'info'
        },
        textColor: {
            type: String,
            default: '#3a3a3e'
        },
        fontSize: {
            type: String,
            default: '0.85rem'
        },
        loading: {
            type: Boolean,
            default: false
        }
    }
});
