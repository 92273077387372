import PublicInvoicableLayout from '../layouts/PublicInvoicableLayout.vue';

export const publicRoutes = [
    {
        path: '/public',
        component: PublicInvoicableLayout,
        meta: {
            requiresAuth: false,
            dontTrack: true
        },
        children: [
            {
                path: 'invoice/:uuid',
                name: 'invoice-view-public',
                props: true,
                component: () => import('../views/PublicInvoiceView.vue'),
                meta: {
                    title: 'pageTitles.invoicable_view',
                    dontTrack: true
                }
            },
            {
                path: 'creditnote/:uuid',
                name: 'creditnote-view-public',
                props: true,
                component: () => import('../views/PublicCreditnoteView.vue'),
                meta: {
                    title: 'pageTitles.invoicable_view',
                    dontTrack: true
                }
            },
            {
                path: 'quotation/:uuid',
                name: 'quotation-view-public',
                props: true,
                component: () => import('../views/PublicQuotationView.vue'),
                meta: {
                    title: 'pageTitles.invoicable_view',
                    dontTrack: true
                }
            },
            {
                path: 'thanks', // TODO /:uuid',
                name: 'thanks-view-public',
                props: true,
                component: () => import('../views/PublicThanksView.vue'),
                meta: {
                    title: 'pageTitles.thanks_view',
                    dontTrack: true
                }
            }
        ]
    }
];
