
import { computed, defineComponent } from 'vue';
import { TextTag } from '@/components/typography';

interface TextSkeletonProps {
    width: string;
    height: string;
    noBottomMargin?: boolean;
    tag?: TextTag;
}

export default defineComponent<TextSkeletonProps>({
    name: 'TextSkeleton',
    props: {
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '1rem'
        },
        noBottomMargin: {
            type: Boolean,
            default: false
        },
        // When a tag is provided, the skeleton wil take on the font-size and include margin-bottom to simulate line-height of the tag
        tag: {
            type: String
        }
    },
    setup(props) {
        const cssVars = computed((): Record<string, string> => {
            return {
                '--width': props.width,
                '--height': props.height
            };
        });
        return { cssVars };
    }
});
