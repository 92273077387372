import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import { IIntegrationViewModel, MollieInformationViewModel } from '@/services/integrations.types';

const BASE_URL = generateEndpoint('/api/integrations');

export function sendConnectMollieRequest(code: string, code_verifier: string): Promise<void> {
    return axios.post(`${BASE_URL}/mollie`, { code, code_verifier }).then((response) => response.data);
}

export function sendCreateMollieProfileRequest({
    name,
    website,
    email,
    phone
}: {
    name: string;
    website: string;
    email: string;
    phone: string;
}): Promise<void> {
    return axios.post(`${BASE_URL}/mollie/profile`, { name, website, email, phone }).then((response) => response.data);
}

export function sendDeleteMollieConnection(): Promise<void> {
    return axios.delete(`${BASE_URL}/mollie`).then((response) => response.data);
}

export function sendUpdatePaymentlinksMollieConnection(value: boolean): Promise<void> {
    return axios.put(`${BASE_URL}/mollie/paymentlinks`, { value }).then((response) => response.data);
}

export function sendGetMollieInformationRequest(): Promise<MollieInformationViewModel | null> {
    return axios.get(`${BASE_URL}/mollie`).then((response) => response.data);
}

export function sendGetAllIntegrationsRequest(): Promise<IIntegrationViewModel[]> {
    return axios.get(`${BASE_URL}`).then((response) => response.data);
}
