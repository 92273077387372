import { generateEndpoint } from './config';
import axios from 'axios';
import { initializePaginationVars } from '@/store/utils';
import { filteringOptions } from '@/common/types';

export const BASE_URL_USERS = generateEndpoint(`/api/users`);

export const sendDeleteUserRequest = (userId: number) => {
    return axios.delete(`${BASE_URL_USERS}/${userId}`).then((response) => response.data);
};

export function sendGetAllUserPaginationRequest(options: filteringOptions): Promise<any> {
    return axios
        .get(`${BASE_URL_USERS}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function sendGetNumberOfRegistrationsRequest({
    year,
    groupingMode,
    type
}: {
    year: number;
    groupingMode: 'YEAR' | 'MONTH' | 'QUARTER';
    type: string;
}): Promise<number> {
    return axios
        .get(`${BASE_URL_USERS}/count/newUsers`, {
            params: {
                year,
                groupingMode,
                type
            }
        })
        .then((response) => response.data);
}

export function sendUpdateUserRequest(userid: number, user: any) {
    return axios.patch(`${BASE_URL_USERS}/${userid}`, user).then((response) => response.data);
}

export function sendToggleDisableUserRequest({
    userId,
    disableReason
}: {
    userId: number;
    disableReason: string;
}): Promise<void> {
    return axios
        .post(`${BASE_URL_USERS}/disableUserToggle/${userId}`, {
            disableReason
        })
        .then((response) => response.data);
}
