import { USER_ACCESS_OPERATIONS } from '@/config/constants';
import LoggedInLayout from '../layouts/LoggedInLayout.vue';

export const accountantRoutes = [
    {
        path: '/accountant',
        component: LoggedInLayout,
        meta: {
            title: 'pageTitles.accountant',
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'accountant',
                redirect: {
                    name: 'accountantClients'
                }
            },
            {
                path: 'create-client',
                name: 'create-client-for-accountingoffice',
                component: () =>
                    import(
                        /* webpackChunkName: 'accountant' */
                        '../views/AccountantApp/views/createClient/CreateClient.vue'
                    ),
                meta: {
                    title: 'pageTitles.createClient',
                    requiresAuth: true
                }
            },
            {
                path: 'clients',
                name: 'accountantClients',
                component: () =>
                    import(
                        /* webpackChunkName: 'accountant' */
                        '../views/AccountantApp/views/accountantusers/Accountant.vue'
                    ),
                meta: {
                    title: 'pageTitles.accountantClients',
                    requiresAuth: true
                }
            },
            {
                path: 'settings/general',
                name: 'accountantSettings',
                component: () =>
                    import(
                        /* webpackChunkName: 'accountant' */
                        '../views/AccountantApp/views/settings/General.vue'
                    ),
                meta: {
                    title: 'pageTitles.setting_titels.general',
                    requiresAuth: true
                }
            },
            {
                path: 'settings/accountingoffice',
                name: 'accountantOfficeSettings',
                component: () =>
                    import(
                        /* webpackChunkName: 'accountant' */
                        '../views/AccountantApp/views/settings/AccountingOffice.vue'
                    ),
                meta: {
                    title: 'pageTitles.setting_titels.accountingOffice',
                    requiresAuth: true,
                    action: USER_ACCESS_OPERATIONS.UPDATE,
                    subject: 'manageAccountingOffice'
                }
            },
            {
                path: 'manage',
                name: 'manageAccountants',
                component: () =>
                    import(
                        /* webpackChunkName: 'accountant' */
                        '../views/ManageAccountants.vue'
                    ),
                meta: {
                    title: 'pageTitles.manageAccountants',
                    requiresAuth: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'ManageAccountant'
                }
            },
            {
                path: 'invitations',
                name: 'accountantInvitations',
                component: () =>
                    import(
                        /* webpackChunkName: 'accountant' */
                        '../views/accountant/Invitations'
                    ),
                meta: {
                    title: 'pageTitles.invitations',
                    requiresAuth: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'ManageInvitations'
                }
            },
            {
                path: '/accountant-document-communication',
                name: 'accountantDocumentCommunication',
                component: () => import('../views/communication/DocumentCommunication'),
                meta: {
                    title: 'pageTitles.document_communication',
                    requiresAuth: true
                }
            },
            {
                path: 'invoicable/view/:type/:id',
                name: 'invoicable-view',
                props: true,
                component: () => import('../views/UserApp/invoicable-view/InvoicableView.vue'),
                meta: {
                    title: 'pageTitles.invoicable_view',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Useronlyaccess'
                }
            },
            {
                path: 'accounting-invoicable/view/:type/:id',
                name: 'accounting-invoicable-view',
                props: true,
                component: () => import('../views/UserApp/invoicable-view/AccountingInvoicableView.vue'),
                meta: {
                    title: 'pageTitles.invoicable_view',
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Accountinginvoicable'
                }
            },
            {
                path: '/view-accounting-expense/:id',
                name: 'view-accounting-expense',
                component: () => import('../views/accountingexpense/view/ViewAccountingExpense.vue'),
                meta: {
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Accountingexpense'
                }
            }
        ]
    }
];
