<template>
    <div v-if="currentUserData" id="pageLayout">
        <div class="content-wrapper">
            <side-navigation
                :current-user-data="currentUserData"
                :is-side-nav-collapsed="isSideNavCollapsed"
                @toggleSideNavigation="toggleSideNavigation"
            />
            <div id="header-and-content" class="header-and-content">
                <notificationbar />

                <div class="overlay" :class="{ hidden: isSideNavCollapsed }" @click="toggleSideNavigation">&nbsp;</div>
                <HeaderComp
                    :is-side-nav-collapsed="isSideNavCollapsed"
                    :total-number-of-learning-center-items="totalNumberOfLearningCenterItems"
                    @toggle-learningcenter="toggleLearningcenter"
                    @toggleSideNavigation="toggleSideNavigation"
                />

                <mobile-header-navigation
                    v-if="isMobileNavigationOpen"
                    v-model="isMobileNavigationOpen"
                    @toggle-learningcenter="toggleLearningcenter"
                />

                <router-view
                    class="main-content"
                    :class="{ 'sidebar-collapsed': isSideNavCollapsed }"
                    @toggle-learning-center="toggleLearningcenter"
                />
            </div>
        </div>

        <loader v-if="loading" overlay-color="none" />

        <support-dialog :value="openSupportDialog" @input="closeSupportDialog" />

        <component
            :is="informationPopupComponent"
            v-if="canShowInformationpopupComponent"
            v-bind="informationpopupData"
            @input="closedInformationpopup"
        />

        <base-modal />

        <LearningCenterDrawer
            v-if="canShowLearningCenter"
            v-model="learningModuleStatus"
            :show-all-items.sync="canShowAllLearningcenterItems"
            @total-number-of-items="handleTotalNumberOfLearningCenterItems"
            @selected-item="handleSelectedLearningCenterItem"
        />

        <tooltip-popup
            v-if="canRenderTextPopup"
            v-model="openPopup"
            :title="textPopup.title"
            :content="textPopup.summary"
        />

        <change-bookyear-dialog v-model="isChangeBookyearModalOpen" />

        <!-- Bottom sheets -->
        <add-unit />
        <edit-notification />
        <add-supplier />
        <add-unit />

        <popup-video v-if="openVideoPopup" v-model="openVideoPopup" v-bind="videoPopupData" />
    </div>
</template>

<script>
import BaseSliderModal from '@/components/BaseSliderModal';
import BaseTour from '@/components/BaseTour';
import AddSupplier from '@/components/BottomSheets/AddSupplier';
import AddUnit from '@/components/BottomSheets/AddUnit';
import EditNotification from '@/components/BottomSheets/EditNotification.vue';
import ChangeBookyearDialog from '@/components/ChangeBookyearDialog.vue';
import HeaderComp from '@/components/Header/Header';
import LearningCenterDrawer from '@/components/LearningCenterDrawer';
import Loader from '@/components/Loader';
import MobileHeaderNavigation from '@/components/MobileHeaderNavigation.vue';
import BaseModal from '@/components/Modal/BaseModal';
import SideNavigation from '@/components/Navigation/SideNavigation';
import Notificationbar from '@/components/Notificationbar.vue';
import PopupVideo from '@/components/PopupVideo.vue';
import SupportDialog from '@/components/SupportDialog';
import TooltipPopup from '@/components/TooltipPopup';
import { InformationpopupTypes } from '@/config/constants';
import { isUserAccount } from '@/helpers/auth';
import { reportError } from '@/helpers/logging';
import { LearningBlog, LearningTextPopup, LearningVideo } from '@/models/learningitem';
import { mapStores } from 'pinia';
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { screenSizeMixin } from '@/mixins/screenSizeMixin'; // --- States ---
import { useGlobalStore } from '@/store/pinia/global.store';
import { getAccountantOfUserRequest } from '@/services/accountants';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { isNil } from 'lodash';
import { chatMixin } from '@/mixins/chat';

export default {
    name: 'LoggedInLayout',

    components: {
        HeaderComp,
        MobileHeaderNavigation,
        TooltipPopup,
        LearningCenterDrawer,
        SupportDialog,
        Loader,
        AddSupplier,
        AddUnit,
        EditNotification,
        PopupVideo,
        ChangeBookyearDialog,
        SideNavigation,
        BaseModal,
        Notificationbar
    },
    mixins: [screenSizeMixin, chatMixin],

    data() {
        return {
            openSupportDialog: false,
            accountantOfUser: null,
            isSideNavCollapsed: false,
            headerTransparant: true,
            openPopup: false,
            textPopup: {
                title: null,
                content: null
            },
            newNotificationInterval: null,
            totalNumberOfLearningCenterItems: 0,
            openVideoPopup: false,
            videoPopupData: {
                title: null,
                url: null
            }
        };
    },

    computed: {
        ...mapStores(useGlobalStore),
        ...mapState('auth', ['currentUserData']),
        ...mapState(['selectedYear']),
        ...mapFields('informationpopup', ['currentInformationpopup']),
        ...mapFields([
            'loading',
            'learningModuleStatus',
            'canShowAllLearningcenterItems',
            'supportDialogOpen',
            'isMobileNavigationOpen',
            'isChangeBookyearModalOpen'
        ]),
        canRenderTextPopup() {
            return Boolean(this.textPopup.title) && Boolean(this.textPopup.summary) && this.openPopup;
        },
        canShowLearningCenter() {
            return isUserAccount(this.currentUserData);
        },
        canShowInformationpopupComponent() {
            if (!this.currentInformationpopup) {
                return false;
            }

            if (this.currentInformationpopup.type === InformationpopupTypes.TOUR && this.isSmallDevice) {
                return false;
            }

            return Boolean(this.informationPopupComponent);
        },
        informationpopupData() {
            if (this.currentInformationpopup.type === InformationpopupTypes.TOUR) {
                return {
                    name: this.currentInformationpopup.name
                };
            } else if (this.currentInformationpopup.type === InformationpopupTypes.POPUP) {
                return {
                    notificationInput: this.currentInformationpopup,
                    dataId: this.currentInformationpopup.name
                };
            } else if (this.currentInformationpopup.type === InformationpopupTypes.ACTION) {
                return null;
            }

            throw new Error('Invalid type');
        },
        informationPopupComponent() {
            if (!this.canShowInformationpopup(this.currentInformationpopup)) {
                return null;
            }

            if (this.currentInformationpopup.type === InformationpopupTypes.TOUR) {
                return BaseTour;
            } else if (this.currentInformationpopup.type === InformationpopupTypes.POPUP) {
                return BaseSliderModal;
            }

            throw new Error('Invalid type');
        }
    },

    watch: {
        accountantOfUser(newValue) {
            this.globalStore.userHasAccountant = !isNil(newValue);
        },
        supportDialogOpen(newValue) {
            if (newValue === true && !this.globalStore.userHasAccountant) {
                this.openSupportDialog = true;
            } else if (newValue === true && this.globalStore.userHasAccountant) {
                this.openChatDialog({
                    email: this.currentUserData.userinformation.email,
                    name: `${this.currentUserData.userinformation.firstName} ${this.currentUserData.userinformation.name}`,
                    phone: this.currentUserData.userinformation.phone ?? undefined,
                    id: this.currentUserData.id.toString()
                });

                this.supportDialogOpen = false;
            } else if (newValue === false) {
                this.openSupportDialog = false;
            }
        },
        isSmallDevice: {
            immediate: true,
            handler(value) {
                this.isSideNavCollapsed = value;

                if (this.isSideNavCollapsed === true && value === false) {
                    this.isSideNavCollapsed = false;
                }
            }
        },
        selectedYear: {
            immediate: true,
            handler(value) {
                this.globalStore.selectedYear = value;
            }
        }
    },

    async created() {
        if (isUserAccount(this.currentUserData)) {
            await this.getHasNewNotification();

            this.newNotificationInterval = setInterval(async () => {
                await this.getHasNewNotification();
            }, 100000);

            try {
                this.accountantOfUser = await getAccountantOfUserRequest();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            }
        }

        this.getAllInformationpopups();

        window.addEventListener('scroll', this.handleScroll);
    },

    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
        clearInterval(this.newNotificationInterval);
    },

    methods: {
        ...mapActions('informationpopup', [
            'getAllInformationpopups',
            'markInformationpopupByIDAsReadAndRemoveFromPopupList',
            'removeFromPopupListByName'
        ]),
        ...mapActions(['startLoading', 'stopLoading', 'toggleLearningcenter']),
        ...mapActions('notifications', ['getHasNewNotification']),
        closeSupportDialog() {
            this.supportDialogOpen = false;
        },
        handleTotalNumberOfLearningCenterItems(totalNumberOfLearningCenterItems) {
            this.totalNumberOfLearningCenterItems = totalNumberOfLearningCenterItems;
        },
        handleSelectedLearningCenterItem(learningItem) {
            this.closeLearningCenter();

            if (learningItem instanceof LearningBlog) {
                window.open(learningItem.url, '_blank').focus();
            } else if (learningItem instanceof LearningVideo) {
                this.openVideoPopup = true;
                this.videoPopupData = {
                    title: learningItem.title,
                    url: learningItem.url
                };
            } else if (learningItem instanceof LearningTextPopup) {
                this.openPopup = true;
                this.textPopup = {
                    title: learningItem.title,
                    summary: learningItem.summary
                };
            }
        },
        canShowInformationpopup(currentInformationpopup) {
            if (!currentInformationpopup) {
                return false;
            }

            if (!currentInformationpopup?.type) {
                return false;
            }

            if (currentInformationpopup?.type === InformationpopupTypes.ACTION) {
                return false;
            }

            return true;
        },
        async closedInformationpopup(closeEvent) {
            if (closeEvent.status === false) {
                try {
                    if (this.currentInformationpopup.id) {
                        return await this.markInformationpopupByIDAsReadAndRemoveFromPopupList(
                            this.currentInformationpopup.id
                        );
                    }

                    return this.removeFromPopupListByName(this.currentInformationpopup.name);
                } catch (e) {
                    reportError(e);
                }
            }
        },
        toggleSideNavigation() {
            this.isSideNavCollapsed = !this.isSideNavCollapsed;
        },
        handleScroll() {
            if (window.pageYOffset > 64) {
                this.headerTransparant = false;
            } else {
                this.headerTransparant = true;
            }
        },
        closeLearningCenter() {
            this.learningModuleStatus = false;
        }
    }
};
</script>

<style scoped lang="scss">
@import '../sass/app.scss';
@import '../sass/variables.scss';

.content-wrapper {
    width: 100vw;
    height: 100vh;
    overflow: hidden;

    .header-and-content {
        position: relative;
        height: 100vh;

        .overlay {
            background-color: rgba(rgb(33, 33, 33), 0.5);
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            width: 100vw;
            height: 100vh;
            z-index: 5;

            &.hidden {
                display: none;
            }
        }
        .main-content {
            overflow: auto;
            height: calc(100vh - #{$header-height});
        }
    }

    @media screen and (min-width: #{$sm}) {
        .header-and-content {
            .overlay {
                display: none;
            }

            .main-content {
                height: calc(100vh - #{$header-height});
            }
        }
    }
}
</style>
