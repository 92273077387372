<template>
    <v-text-field
        ref="input"
        v-currency="options"
        outlined
        dense
        hide-details="auto"
        :value="formattedValue"
        :class="{ required }"
        v-bind="$attrs"
        @change="onChange"
        @input="onInput"
        @focus="emitFocusEvent"
        @blur="emitBlurEvent"
        @click:prepend-inner="$emit('click:prepend-inner', $event)"
    />
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        value: {
            default: null
        },
        allowNegative: {
            type: Boolean,
            default: false
        },
        valueAsInteger: {
            type: Boolean,
            default: false
        },
        min: {
            type: Number,
            default: null
        },
        max: {
            type: Number,
            default: null
        },
        required: {
            type: Boolean,
            default: false
        },
        updateOnInput: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            formattedValue: null,
            options: {
                locale: 'nl-BE',
                currency: null,
                autoDecimalMode: false,
                distractionFree: true,
                precision: this.valueAsInteger
                    ? 0
                    : {
                          min: 2,
                          max: 8
                      },
                allowNegative: this.allowNegative,
                valueAsInteger: this.valueAsInteger
            }
        };
    },
    watch: {
        value(value) {
            this.setValue(value);
        },
        min: {
            handler(newMin) {
                if (newMin) {
                    this.options = {
                        ...this.options,
                        valueRange: {
                            min: newMin
                        }
                    };
                }
            },
            immediate: true
        },
        max: {
            handler(newMax) {
                if (newMax) {
                    this.options = {
                        ...this.options,
                        valueRange: {
                            max: newMax
                        }
                    };
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.setValue(this.value);
    },
    methods: {
        emitFocusEvent() {
            this.$emit('focus');
        },
        emitBlurEvent() {
            this.$emit('blur');
        },
        validate() {
            return this.$refs.input.validate();
        },
        setValue(value) {
            this.$ci.setValue(this.$refs.input, value);
        },
        onInput(value) {
            this.formattedValue = value;
            if (this.updateOnInput) {
                this.$emit('input', this.$ci.getValue(this.$refs.input));
            }
        },
        onChange(value) {
            if (!this.updateOnInput) {
                this.$emit('input', this.$ci.getValue(this.$refs.input));
                this.$emit('change', this.$ci.getValue(this.$refs.input));
                this.formattedValue = value;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
