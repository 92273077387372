import { acceptInvitationRequest, declineInvitationRequest, getAllInvitationsRequest } from '@/services/invitations';
import { AccountantState } from '@/store/types/accountant';
import { RootState } from '@/store/store.types';
import { Module } from 'vuex';

function initialState(): AccountantState {
    return {
        pendingInvitations: 0
    };
}

export default {
    namespaced: true,

    state: initialState(),

    actions: {
        async acceptInvitation({ dispatch }, invitationId) {
            await acceptInvitationRequest(invitationId);

            dispatch('getNumberOfPendingInvitations');
        },
        async declineInvitation({ dispatch }, invitationId) {
            await declineInvitationRequest(invitationId);

            dispatch('getNumberOfPendingInvitations');
        },
        async getNumberOfPendingInvitations({ commit, state }) {
            return getAllInvitationsRequest().then((response) => {
                commit('SET_PENDING_INVITATIONS', response);
                return state.pendingInvitations;
            });
        }
    },

    mutations: {
        SET_PENDING_INVITATIONS(state, invitiations) {
            state.pendingInvitations = invitiations.length;
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<AccountantState, RootState>;
