<template>
    <div id="tourModal"></div>
</template>

<script>
// --- Components ---
// --- Mixins ---
import { getTourMap } from '@/helpers/vueTourSteps';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';

export default {
    mixins: [formRulesMixin],
    props: {
        name: {
            type: String
        }
    },

    data() {
        return {
            current: 0,
            modal: false
        };
    },

    computed: {
        steps() {
            return getTourMap(this.name);
        }
    },

    watch: {
        steps: {
            immediate: true,
            deep: true,
            handler(newSteps) {
                this.startTour(newSteps);
            }
        }
    },

    methods: {
        startTour(steps) {
            this.$nextTick(() => {
                const tour = this.$shepherd({
                    useModalOverlay: true,
                    classPrefix: 'my-tour-dexxter',
                    defaultStepOptions: {
                        popperOptions: {
                            modifiers: [{ name: 'offset', options: { offset: [0, 12] } }]
                        },
                        scrollTo: true
                    }
                });
                tour.addSteps(steps);
                tour.start();

                tour.on('cancel', () => {
                    this.completeTour();
                });

                tour.on('complete', () => {
                    this.completeTour();
                });
            });
        },
        completeTour() {
            const headerAndContent = document.getElementById('header-and-content');
            headerAndContent.scrollIntoView();
            this.$emit('input', { status: false });
        }
    }
};
</script>
