export const InformationpopupTypes = {
    TOUR: 'TOUR',
    POPUP: 'POPUP',
    ACTION: 'ACTION'
};

export const GrootboekrekeningNumbersIncome = {
    income: 710000,
    income_invoice: 710010,
    income_creditnote: 710011,
    income_dailyreceipt: 710012
};

export const monthNamesShort = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];

export const USER_ACCESS_OPERATIONS = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    READ: 'READ'
};
export const EUCountries = Object.freeze([
    'AT',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'EL',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'RI',
    'ES',
    'SE'
]);

/**
 * Learning items
 */
export const Learning = {
    VATTypes: {
        44: '44',
        56: '56',
        VATLiable: 'VATLiable',
        ALL: 'ALL'
    },
    UserTypes: {
        STUDENT: 'student',
        MAIN: 'main',
        SECONDARY: 'secondary',
        ALL: 'ALL'
    },
    Types: {
        POPUPTEXT: 'POPUPTEXT',
        VIDEO: 'VIDEO',
        BLOG: 'BLOG'
    }
};

export type LearningCenterType = 'POPUPTEXT' | 'VIDEO' | 'BLOG';

export type STUDENT = 'student';
export const STUDENT = 'student';
export type MAIN = 'main';
export const MAIN = 'main';
export type SECONDARY = 'secondary';
export const SECONDARY = 'secondary';
export type ACCOUNTANT = 'accountant';
export const ACCOUNTANT = 'accountant';
export type ADMIN = 'admin';
export const ADMIN = 'admin';
export const UserTypes = [STUDENT, MAIN, SECONDARY] as const;
export type UserTypes = typeof UserTypes[number];
export const UserTypesForRegistration = [STUDENT, SECONDARY, MAIN] as const;
export type UserTypesForRegistration = typeof UserTypesForRegistration[number];
export type UserTypesEligableForSocialContributions = 'student' | 'secondary';

/**
 * Responses
 */
export const responseCodes = {
    GET: 200,
    OK: 200,
    CREATED_WITH_RESPONSE: 200,
    CREATED_WITHOUT_RESPONSE: 204,
    UPDATED_WITH_RESPONSE: 201,
    UPDATED_WITHOUT_RESPONSE: 201,
    DELETE_WITHOUT_RESPONSE: 200,
    NOT_FOUND: 404,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    VALIDATION_ERROR: 400,
    BAD_REQUEST: 400,
    INTERNAL_SERVER_ERROR: 500
};

type SERVICES = 0;
type GOODS = 1;
type INVESTEMENTS = 2;
export type expenseTypeOfServiceType = SERVICES | GOODS | INVESTEMENTS | null;

/**
 * Quarters
 */
export const Q1 = 'Q1';
export const Q2 = 'Q2';
export const Q3 = 'Q3';
export const Q4 = 'Q4';

export const Quarters = ['Q1', 'Q2', 'Q3', 'Q4'] as const;
export type Quarters = typeof Quarters[number];

export type QuarterNumbers = 1 | 2 | 3 | 4;

export const QuarterStartMonths = {
    Q1: 1,
    Q2: 4,
    Q3: 7,
    Q4: 10
} as const;

export const QuarterEndMonths = {
    Q1: 3,
    Q2: 6,
    Q3: 9,
    Q4: 12
};

export const tours = {
    SECONDARY_STARTUP_TOUR: 'SECONDARY_STARTUP_TOUR',
    SECONDARY_STARTUP_TOUR_PRE_ACCOUNTING: 'SECONDARY_STARTUP_TOUR_PRE_ACCOUNTING',
    STARTUP_VAKANTIEWONINGEN: 'STARTUP_VAKANTIEWONINGEN'
};

export const popupNotificationNames = {
    WELCOME_TO_DEXXTER: 'WELCOME_TO_DEXXTER',
    WELCOME_TO_DEXXTER_MOBILE: 'WELCOME_TO_DEXXTER_MOBILE',
    WHITELIST_DEXXTER_EMAIL: 'WHITELIST_DEXXTER_EMAIL',
    SOCIALCONTRIBUTIONS_OPENFIRSTTIME: 'SOCIALCONTRIBUTIONS_OPENFIRSTTIME',
    INVOICESETTINGS_OPENFIRSTTIME: 'INVOICESETTINGS_OPENFIRSTTIME',
    BOOK_INTRODUCTION_CALL: 'BOOK_INTRODUCTION_CALL'
};

export const actionNames = {
    SOCIALCONTRIBUTIONS_SELECTEXEMPTSTATUS: 'SOCIALCONTRIBUTIONS_SELECTEXEMPTSTATUS'
};

export type VatStatus = 'VATLIABLE' | 'NOTVATLIABLE' | 'VATEXEMPT';

export const vatStatuses = {
    VATLIABLE: 'VATLIABLE',
    NOTVATLIABLE: 'NOTVATLIABLE',
    VATEXEMPT: 'VATEXEMPT'
};

/**
 * Type of invoicables
 */
export const invoicableTypes = {
    INVOICE: 'invoice',
    CREDITNOTE: 'creditnote',
    QUOTATION: 'quotation'
};

export const VOTABLE_TYPES = {
    QUESTION: 'QUESTION',
    ANSWER: 'ANSWER'
};
export const GROUPING_MODES = {
    YEAR: 'YEAR',
    MONTH: 'MONTH',
    QUARTER: 'QUARTER'
};

export const depreciationStatus = {
    SOLD: 'SOLD',
    BROKEN: 'BROKEN'
};

/**
 * Type of depreciations
 */
export const depreciationTypes = {
    BUILDING: 'BUILDING',
    GROUND: 'GROUND',
    EQUIPMENT: 'EQUIPMENT',
    MACHINE: 'MACHINE',
    ROLLING_STOCK: 'ROLLING_STOCK'
};

/**
 * Billing plans
 */
export const BILLING_PLANS = {
    FREE_TIER: 'free_tier',
    PRO_TIER: 'pro_tier',
    LITE_TIER: 'lite_tier'
};

export const disabledReasons = {
    SUBSCRIPTION_NOT_PAYED: 'SUBSCRIPTION_NOT_PAYED',
    BAD_USE: 'BAD_USE'
};

/** const printDagboek = () => {
            const printContent = document.getElementById('dagboek');

            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            const winPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');

            if (winPrint && printContent) {
                const prtHtml = printContent.innerHTML;

                winPrint.document.write(`<!DOCTYPE html>
                  <html>
                    <head>
                      ${stylesHtml}
                    </head>
                    <body>
                      ${prtHtml}
                    </body>
                  </html>`);

                winPrint.document.close();
                winPrint.focus();
                winPrint.print();
                winPrint.close();
            }
        };
 * Type of customers
 */
export const PRIVATE = 0;
export const BELGIAN_COMPANY = 1;
export const COMPANY_INSIDE_EU = 2;
export const COMPANY_OUTSIDE_EU = 3;

export type supplierType = 0 | 1 | 2 | 3;

/**
 * Type of service
 */
export const SERVICES = 0;
export const GOODS = 1;
export const INVESTEMENTS = 2;

/**
 * Smartnotifications
 */
export const SMART_NOTIFICATION_TYPES = {
    LOSS_MONITOR: 'LOSS_MONITOR',
    SOCIAL_CONTRIBUTION_APPROACHING_BOUNDRY_MONITOR: 'SOCIAL_CONTRIBUTION_APPROACHING_BOUNDRY_MONITOR'
};

export const ACCOUTNANT = 'accountant';

export const vatSubmissionFineStartDay = 20;

// Months in dayjs are Zero-indexed
export const vatSubmissionMonths: { [key in Quarters]: number } = {
    Q1: 3,
    Q2: 6,
    Q3: 9,
    Q4: 0
};
