import dayjs from 'dayjs';
import { lowerCase } from 'lodash';
import numbro from 'numbro';
import languages from 'numbro/dist/languages.min';
import sanitizeHtml from 'sanitize-html';
import striptags from 'striptags';

numbro.registerLanguage(languages['nl-NL']);
numbro.setLanguage('nl-NL');

export function capitalizeFirstLetter(string) {
    if (!string) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toLowerCase(string) {
    return lowerCase(string);
}

export const addEuroSign = (value) => {
    return '€' + value;
};

export const addPercentSign = (value) => {
    return value + '%';
};

export const sanitize = (value) => {
    return sanitizeHtml(value);
};

export const removeHTMLTags = (value) => {
    return striptags(value);
};

export function numericFormat(value) {
    if (!value) {
        return 0;
    }

    return numbro(value).format({
        mantissa: 2,
        optionalMantissa: true,
        trimMantissa: true,
        thousandSeparated: true
    });
}

export function formatDate(dateYYYYMMDD, withoutYear = false) {
    if (dateYYYYMMDD) {
        return dayjs.utc(dateYYYYMMDD, 'YYYY-MM-DD').format(withoutYear ? 'DD-MM' : 'DD-MM-YYYY');
    }
}

export function formatDateCustom(date, format) {
    return dayjs.utc(date).format(format);
}

export function formatDateUnix(unix) {
    return dayjs.unix(unix).format('DD-MM-YYYY');
}

export function truncate(value, limit) {
    if (value.length > limit) {
        value = value.substring(0, limit - 3) + '...';
    }

    return value;
}

export function formatTimestamp(date) {
    if (date) {
        return dayjs.utc(date).tz('Europe/Brussels').format('DD-MM-YYYY HH:mm:ss');
    }
}
