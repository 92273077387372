export default (store) => {
    const ability = store.getters['auth/ability'];

    ability.update(store.state.auth.permissions);

    return store.subscribe((mutation) => {
        switch (mutation.type) {
            case 'auth/SET_PERMISSIONS':
                ability.update(mutation.payload);
                break;
        }
    });
};
