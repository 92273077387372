import { computed, onMounted, onUnmounted, Ref, ref } from 'vue';
import { getRemainingWindowHeight } from '@/helpers/styleHelpers';

export function useRemainingWindowHeight(element: Ref<HTMLDivElement | HTMLElement | undefined>, extraPadding: number) {
    const remainingWindowHeight = ref(0);

    function setRemainingWindowHeight() {
        if (element.value) {
            // We pass scrollbar height because we have to count in the scrollbar on main-content in LoggedInLayout and one in this container
            remainingWindowHeight.value = getRemainingWindowHeight(element.value, extraPadding);
        }
    }

    onMounted(() => {
        setRemainingWindowHeight();
        window.addEventListener('resize', setRemainingWindowHeight);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', setRemainingWindowHeight);
    });

    const remainingWindowHeightCssVar = computed(() => {
        return `--remaining-window-height: ${remainingWindowHeight.value}px`;
    });

    return {
        remainingWindowHeightCssVar
    };
}
