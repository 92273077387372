<template>
    <div>
        <v-list-item v-if="$can('READ', 'Useronlyaccess')" :to="{ name: 'dashboard' }" :disabled="!canViewDashboard">
            <v-list-item-icon>
                <v-icon>mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.dashboard') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-group
            v-if="$can('READ', 'Useronlyaccess')"
            id="v-tour-start-income"
            no-action
            :disabled="!canViewIncomes"
            dense
        >
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-credit-card-plus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.incomes') }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item link :to="{ name: 'quotations' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.quotations') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'invoices' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.invoices') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'creditNotes' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.creditnotes') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="isStudent" link :to="{ name: 'jobStudentIncomes' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.jobstudentincomes') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'dailyReceipts' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.dailyreceipts') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
        <v-list-group
            v-if="$can('READ', 'Useronlyaccess')"
            id="v-tour-start-expenses"
            no-action
            :disabled="!canViewExpenses"
        >
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-credit-card-minus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.expenses') }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'expenses' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.expenses') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$can('READ', 'Depreciationfiche')" :to="{ name: 'depreciations' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.depreciations') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'manageCostCategory' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.manage_costcategory') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>

        <v-list-group
            v-if="$can('READ', 'Useronlyaccess')"
            id="v-tour-start-reports"
            no-action
            :disabled="!canViewExpenses"
        >
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-chart-bubble</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.reports') }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'expenseByCategoryFrequencies' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.expenseCategoryFrequencies') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
        <!--
        <v-list-group v-if="false" no-action :disabled="!canViewTimeRegistration">
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-timer-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.time_registration') }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'addTimeRegistration' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.create_timeregistration') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-title @click="openEditTimeregistrationTimer">{{
                        $t('navigation.timer')
                    }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'timeRegistration' }">
                <v-list-item-content>
                    <v-list-item-title>{{
                        $t('navigation.timeregistration.time_registration_overview')
                    }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'projects' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.timeregistration.projects') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'timeregistration-summary' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.timeregistration.report') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
        -->
        <v-list-item
            v-if="$can('CREATE', 'SendDocumentsToAccountant')"
            :to="{ name: 'send-documents-to-accountant' }"
            replace
        >
            <v-list-item-icon>
                <v-icon>mdi-send-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.send_documents_to_accountant') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item
            v-if="$can('READ', 'Timeline') && !isFreeTierAccount"
            id="v-tour-start-calendar"
            :to="{ name: 'timeline' }"
            :disabled="!canViewCalendar"
        >
            <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('pageTitles.timeline') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item
            v-if="$can('READ', 'Useronlyaccess')"
            :to="{ name: 'comingSoonTimeRegistration' }"
            :disabled="!canViewDashboard"
        >
            <v-list-item-icon>
                <v-icon>mdi-timer-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.time_registration') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-group
            v-if="!isFreeTierAccount && $can('READ', 'Communityquestion')"
            id="v-tour-start-learningcenter-navigation"
            no-action
        >
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-account-group</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Community</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'communityQuestions' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('pageTitles.community') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'createCommunityQuestion' }">
                <v-list-item-content>
                    <v-list-item-title>Stel een vraag</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'viewUserCommunityQuestion', params: { id: currentUserData.id } }">
                <v-list-item-content>
                    <v-list-item-title>Bekijk je vragen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>

        <v-divider class="px-4" />

        <v-subheader v-if="!isCollapsed">{{ $t('navigation.management').toUpperCase() }}</v-subheader>

        <div v-if="$can('READ', 'Useronlyaccess')" id="v-tour-start-manage">
            <v-list-item to="/invoiceitems" :disabled="!canViewInvoiceitems">
                <v-list-item-icon>
                    <v-icon>mdi-briefcase-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.invoiceitems') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-group no-action>
                <template #activator>
                    <v-list-item-icon>
                        <v-icon>mdi-account-group</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.relations') }}</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item to="/customers" :disabled="!canViewCustomers">
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.clients') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/suppliers" :disabled="!canViewSuppliers">
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('supplier.suppliers') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </div>
        <v-list-group id="v-tour-start-settings" no-action @click="jumpIntoView">
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settings') }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item to="/user-settings/general">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settingsMenu.general') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!isFreeTierAccount" to="/user-settings/templates">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settingsMenu.templates') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!isFreeTierAccount" to="/user-settings/invoice">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settingsMenu.invoice') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/user-settings/subscription">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settingsMenu.subscription') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!isFreeTierAccount" to="/user-settings/notifications" replace>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settingsMenu.notifications') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$can('READ', 'ManageAccountant')" to="/user-settings/accountant" replace>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settingsMenu.accountant') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                v-if="false && $can('READ', 'AccountantEmailBCC')"
                to="/user-settings/accountantemailbcc"
                replace
            >
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.settingsMenu.accountantemailbcc') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
    </div>
</template>

<script>
// --- Mixins ---
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';

import { canVatTypeSubmitClientListing, isVatLiable } from '@/helpers/VATHelper';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { isUserAccount, isUserAccountWithFreeTier } from '@/helpers/auth';

export default {
    name: 'LiteTierNavigationItems',
    mixins: [screenSizeMixin],
    props: {
        isCollapsed: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState('auth', ['isUserImpersonating']),
        ...mapState('auth', ['currentUserData']),
        ...mapFields('users', ['selectedUsers']),
        isStudent() {
            return this.getCurrentUserType('student');
        },
        canShowClientlisting() {
            return canVatTypeSubmitClientListing(this.currentUserData.settings.vatLiable);
        },
        canViewDashboard() {
            return this.defaultAccountOrImpersonating;
        },
        canViewIncomes() {
            return this.defaultAccountOrImpersonating;
        },
        canViewExpenses() {
            return this.defaultAccountOrImpersonating;
        },
        canViewTimeRegistration() {
            return this.defaultAccountOrImpersonating;
        },
        showVATDeclaration() {
            return this.defaultAccountOrImpersonating;
        },
        canViewVATDeclaration() {
            return isVatLiable(this.currentUserData.settings.vatLiable) && this.defaultAccountOrImpersonating;
        },
        canViewCalendar() {
            return this.defaultAccountOrImpersonating;
        },
        canViewAnalysis() {
            return this.defaultAccountOrImpersonating;
        },
        canViewCustomers() {
            return this.defaultAccountOrImpersonating;
        },
        canViewInvoiceitems() {
            return this.defaultAccountOrImpersonating;
        },
        canViewSuppliers() {
            return this.defaultAccountOrImpersonating;
        },
        canViewAllUsers() {
            return this.isAdmin(this.currentUserData);
        },
        canManageNotifications() {
            return this.isAdmin(this.currentUserData);
        },
        canManageCalendarevents() {
            return this.isAdmin(this.currentUserData);
        },
        canViewGlobalSettings() {
            return this.isAdmin(this.currentUserData);
        },
        defaultAccountOrImpersonating() {
            return isUserAccount(this.currentUserData) || this.isUserImpersonating;
        },
        isFreeTierAccount() {
            return isUserAccountWithFreeTier(this.currentUserData);
        }
    },
    methods: {
        ...mapActions('auth', ['getCurrentUserType']),
        ...mapActions('timeregistrations', ['openEditTimeregistrationTimer']),
        jumpIntoView(element) {
            if (element && element.srcElement) {
                setTimeout(() => {
                    element.srcElement.scrollIntoView({ block: 'start', inline: 'nearest' });
                }, 250);
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
