// const standardErrors = ['invalidArguments', 'INTERNAL'];

import ErrorModal from '@/components/Modal/ErrorModal';
import { errorModalProps } from '@/helpers/errorModals';
import { reportError } from './logging';

export const getErrorPath = (error, prefix) => {
    let message;

    let errorMessage = error?.response?.data?.message?.path || error?.response?.data?.message;

    if (error?.response?.status === 403) {
        return 'errors.forbiddenError';
    }

    if (!errorMessage) {
        return 'errors.generalError';
    } else {
        // if (standardErrors.includes(errorMessage)) {
        //     message = 'errors.' + errorMessage;
        // } else {
        if (prefix) {
            message = 'errors.' + prefix + '/' + errorMessage;
        } else {
            message = 'errors.' + errorMessage;
        }
        // }
    }

    return message;
};

export function findErrorModalProps(errorMessage) {
    return errorModalProps.filter(({ key }) => {
        return key === errorMessage;
    });
}

export const apiErrorAndDisplay = function (error, prefix) {
    if (process.env.VUE_APP_MODE !== 'production') {
        // eslint-disable-next-line no-console
        console.error('devLog', error);
    } else {
        reportError(error);
    }

    const errorModalProps = findErrorModalProps(error.response?.data.message);

    if (errorModalProps?.length >= 1) {
        displayErrorModal.call(this, errorModalProps[0]);
    } else {
        notifyError.call(this, error, prefix);
    }
};

export const displayServiceUnavailableAndReportError = function ({ serviceName }, error) {
    if (error) {
        reportError(error);
    }

    displayServiceUnavailable(serviceName);
};

export const displayServiceUnavailable = function ({ serviceName }) {
    displayError(
        this,
        `${this.$i18n.t('errors.serviceUnavailable.title')}`,
        `${this.$i18n.t('errors.serviceUnavailable.content', { serviceName })}`
    );
};

export const displayErrorModal = function (errorModalProps) {
    this.$store.commit('modal/setModalContent', {
        component: ErrorModal,
        props: errorModalProps
    });
};

function notifyError(error, prefix) {
    const messagePath = getErrorPath(error, prefix);
    const messageData = error.data;

    if (error?.response?.status === 503) {
        displayError.call(
            this,
            `Service down ${error?.response?.data?.message ? error?.response?.data?.message : error?.response?.data}`
        );
    } else if (error?.response?.status === 429) {
        displayError.call(
            this,
            this.$i18n.t('errors.tooManyRequests.title'),
            this.$i18n.t('errors.tooManyRequests.content')
        );
    } else if (error?.response?.status === 422 && error.response.data.status === 'thirdPartyValidationError') {
        displayError.call(this, `${error.response.data.message}`);
    } else if (error?.response?.status === 400 && error.response.data.status === 'featureDisabledRequest') {
        displayError.call(this, `The following feature is currently unavailable: ${error.response.data.message}`);
    } else if (error?.response?.status === 500 && error.response.data.status === 'thirdPartyError') {
        displayError.call(this, `${error.response.data.message}`);
    } else if (checkIfMessagePathHasTitleAndContent.call(this, messagePath)) {
        displayError.call(
            this,
            this.$i18n.t(`${messagePath}.title`, messageData),
            this.$i18n.t(`${messagePath}.content`, messageData)
        );
    } else if (checkIfHasTranslationButNoTitleAndContent.call(this, messagePath)) {
        displayError.call(this, this.$i18n.t('general.error'), this.$i18n.t(messagePath, messageData));
    } else if (hasDefaultMessage(error)) {
        // Notify sentry
        reportError(`Translation does not yet exist: ${error?.response?.data?.defaultMessage}`);
        displayError.call(this, this.$i18n.t('general.error'), error?.response?.data?.defaultMessage);
    } else {
        displayError.call(
            this,
            `${this.$i18n.t('general.error')} ${!this.$i18n.te(messagePath) ? '(' + messagePath + ')' : ''}`,
            this.$i18n.te(messagePath) ? this.$i18n.t(messagePath, messageData) : this.$i18n.t('general.error')
        );
    }
}

export const displayError = function (title, text) {
    this.$notify({
        title,
        text,
        type: 'error',
        duration: 15000
    });
};

function checkIfMessagePathHasTitleAndContent(messagePath) {
    return (
        this.$i18n.te(messagePath) && this.$i18n.te(`${messagePath}.title`) && this.$i18n.te(`${messagePath}.content`)
    );
}

function checkIfHasTranslationButNoTitleAndContent(messagePath) {
    return (
        this.$i18n.te(messagePath) && !this.$i18n.te(`${messagePath}.title`) && !this.$i18n.te(`${messagePath}.content`)
    );
}

function hasDefaultMessage(error) {
    return error?.response?.data?.defaultMessage;
}
