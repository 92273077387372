import _dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat.js';
import isBetween from 'dayjs/plugin/isBetween.js';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore.js';
import timezone from 'dayjs/plugin/timezone.js';
import utc from 'dayjs/plugin/utc.js';
import weekOfYear from 'dayjs/plugin/weekOfYear';
require('dayjs/locale/nl');
require('dayjs/locale/en');
require('dayjs/locale/fr');

_dayjs.extend(isSameOrBefore);
_dayjs.extend(isSameOrAfter);
_dayjs.extend(timezone);
_dayjs.extend(utc);
_dayjs.extend(customParseFormat);
_dayjs.extend(isBetween);
_dayjs.extend(isBetween);
_dayjs.extend(weekOfYear);

export const dayjs = _dayjs;
