/* eslint-disable no-unused-vars */

import { RootState } from '@/store/store.types';
import { BookingsState } from '@/store/types/bookings';
import { isNil } from 'lodash';
import { Module } from 'vuex';
import { sendDeleteBookingRequest, sendGetBookingsFromQuarterAndRasterRequest } from '../../services/quarter';

function initialState(): BookingsState {
    return {};
}

export default {
    namespaced: true,

    state: initialState(),

    actions: {
        // [Begin: Read data]
        async getBookingsFromQuarterAndRaster(
            { rootState },
            {
                quarter,
                raster
            }: {
                quarter: 'Q1' | 'Q2' | 'Q3' | 'Q4';
                raster: number;
            }
        ) {
            const year = rootState.selectedYear;
            if (isNil(year)) {
                throw new Error('year not defined');
            }
            return sendGetBookingsFromQuarterAndRasterRequest(year, quarter, raster);
        },
        async deleteBooking(context, bookingId) {
            return sendDeleteBookingRequest(bookingId);
        }
    },

    mutations: {
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<BookingsState, RootState>;
