import * as InformationpopupService from '../../services/informationpopup';
import { getField, updateField } from 'vuex-map-fields';
import { Module } from 'vuex';
import { RootState } from '@/store/store.types';
import { InformationPopupState } from '@/store/types/informationpopup';

function initialState(): InformationPopupState {
    return {
        allInformationpopups: [],
        currentInformationpopup: null
    };
}
export default {
    namespaced: true,

    state: initialState(),

    getters: {
        getField
    },

    actions: {
        getAllInformationpopups({ commit }) {
            return InformationpopupService.getAllInformationpopups().then((informationpopups) => {
                commit('SET_ALL_INFORMATIONPOPUPS', informationpopups);

                return informationpopups;
            });
        },
        markInformationpopupByIDAsReadAndRemoveFromPopupList({ commit }, informationpopupId) {
            return InformationpopupService.markInformationpopupAsDone(informationpopupId).then(() => {
                commit('DELETE_INFORMATIONPOPUP_FROM_ALL_BY_ID', informationpopupId);
            });
        },
        removeFromPopupListByName({ commit }, name) {
            commit('DELETE_INFORMATIONPOPUP_FROM_ALL_BY_NAME', name);
        },
        setCurrentInformationpopup({ commit }, popup) {
            commit('SET_CURRENT_INFORMATIONPOPUP', popup);
        }
    },

    mutations: {
        updateField,
        DELETE_INFORMATIONPOPUP_FROM_ALL_BY_NAME(state, name) {
            state.allInformationpopups = state.allInformationpopups.filter(
                (_informationpopup) => _informationpopup.name !== name
            );
        },
        DELETE_INFORMATIONPOPUP_FROM_ALL_BY_ID(state, id) {
            state.allInformationpopups = state.allInformationpopups.filter(
                (_informationpopup) => _informationpopup.id !== id
            );
        },
        SET_ALL_INFORMATIONPOPUPS(state, informationpopups) {
            state.allInformationpopups = informationpopups;
        },
        SET_CURRENT_INFORMATIONPOPUP(state, popup) {
            state.currentInformationpopup = popup;
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<InformationPopupState, RootState>;
