import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export const bottomSheetsMixin = {
    computed: {
        ...mapFields('bottomSheets', [
            // --- Income ---
            'isAddDailyReceiptOpen',
            'currentDailyReceipt',
            'isCreatingNewAsEmployee',
            'currentAsEmployee',
            // --- Expenses ---
            'isCreatingNewExpense',
            'currentExpense',
            // --- Projects ---
            'isAddProjectOpen',
            'isCreatingNewProject',
            'currentProject',
            // --- Account ---
            'isAddAccountOpen',
            'isCreatingNewAccount',
            'currentAccount',
            // --- Quotation ---
            // --- Invoice ---
            // --- Creditnote ---
            'isAddCreditnoteOpen',
            'isCreatingNewCreditnote',
            'currentCreditnote',
            // --- Creditnote ---
            'isAddPaymentOpen',
            'isCreatingNewPayment'
        ])
    },

    methods: {
        ...mapActions('bottomSheets', ['editIncomeAsEmployee', 'editExpense', 'editAccount'])
    }
};
