import { getAllLanguagesRequest } from '@/services/language';
import store from '../../store';

export async function getAllLanguagesGuard(to, from, next) {
    if (store.state.languages.length === 0) {
        await getAllLanguages();
    }

    return next();
}

async function getAllLanguages() {
    await store.dispatch('setLanguages', await getAllLanguagesRequest());
}
