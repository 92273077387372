import { USER_ACCESS_OPERATIONS } from '@/config/constants';
import LoggedInLayout from '../layouts/LoggedInLayout.vue';

export const notificationRoutes = [
    {
        path: '/notifications',
        component: LoggedInLayout,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'notifications',
                component: () => import('../views/Notifications.vue'),
                meta: {
                    title: 'pageTitles.notifications',
                    requiresAuth: true,
                    freeTier: true,
                    action: USER_ACCESS_OPERATIONS.READ,
                    subject: 'Notification'
                }
            }
        ]
    }
];
