import { tours } from '@/config/constants';

const toursMap = {};

export const getTourMap = (tourName) => {
    return toursMap[tourName];
};

function intializeStartupTour() {
    return [
        {
            attachTo: { element: '#v-tour-learning-center', on: 'bottom' },
            title: 'Learning Center',
            text: "Als ondernemer sta je niet alleen. In ons leercentrum vind je heel wat artikels en video's die je helpen jouw boekhouding beter te begrijpen.",
            highlightClass: 'highlighted-learning-center',
            modalOverlayOpeningRadius: 24,
            buttons: [
                {
                    action: function () {
                        return this.cancel();
                    },
                    secondary: true,
                    label: 'skip-tour',
                    text: 'Tour overslaan'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    label: 'next-step',
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-income', on: 'right-end' },
            title: 'De place to be voor al je inkomsten!',
            text: 'Uitgaande verkoopfacturen, offertes, creditnota’s en dagontvangsten, je kan ze hier allemaal aanmaken en terugvinden.',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    label: 'next-step',
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-expenses', on: 'right-end' },
            title: 'Vol vertrouwen een beroepsmatige uitgave gedaan? Of twijfel je nog bij de term "beroepsmatig"?',
            text: 'No worries, onze handige flow om een uitgave toe te voegen staat voor je klaar en de info-iconen zetten je goed op weg.',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-personal-taxes', on: 'right-end' },
            title: 'Je jaarlijkse belastingaangifte',
            text: 'Hier kan je alle gegevens terugvinden met betrekking tot je zelfstandige activiteit om je jaarlijkse personenbelasting te vervolledigen. Inclusief een up-to-date overzicht om je belastingimpact op te volgen.',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-socialcontributions', on: 'right-end' },
            title: 'Sociale bijdragen',
            text: 'Vrijgesteld van sociale bijdragen? Leuk, maar hou je vrijstellingsgrens goed in het oog via deze module! Als je wel sociale bijdragen betaalt, kan je hier je persoonlijke berekening terugvinden, zo kom je nooit meer voor verrassingen te staan.',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-calendar', on: 'right-end' },
            title: 'Al je deadlines in één overzicht!',
            text: 'Hier vind je alle belangrijke data, handig opgenomen in één overzicht.',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-reports', on: 'right-end' },
            title: 'Rapporten',
            text: 'Hier kan je je resultatenrekening terugvinden, inclusief handig kostenrapport. En ontdek zeker ook de ontbrekende kosten, onze module om elk van je beroepsmatige uitgaven zijn verdiend plaatsje in de boekhouding te geven!',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-learningcenter-navigation', on: 'right-end' },
            title: 'Dexxter helpt je ondernemen!',
            text: 'In de community kan je met al ondernemersvragen terecht, het Dexxter-team en je mede-ondernemers staan er voor je klaar! Daarnaast vind je hier ook ons leercentrum en de Dexxter-adviseurs terug.',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-manage', on: 'right-end' },
            title: 'Je verkoopartikelen en relaties',
            text: 'Wil je met vaste verkoopartikelen werken? Of een overzicht van je relaties? Zowel je verkoopartikelen als klanten en leveranciers bewaar je hier.',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-settings', on: 'right-end' },
            title: 'Instellingen',
            text: 'Kies een eigen bedrijfskleur of upload je logo, leuk om je verkoopfactuur te personaliseren! ',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    label: 'complete-tour',
                    action: function () {
                        return this.next();
                    },
                    text: "Let's go!"
                }
            ]
        }
    ];
}

function intializeStartupTourPreAccounting() {
    return [
        {
            attachTo: { element: '#v-tour-start-income', on: 'right' },
            title: 'De place to be voor al je inkomsten!',
            text: 'Uitgaande verkoopfacturen, offertes, creditnota’s en dagontvangsten, je kan ze hier allemaal aanmaken en terugvinden.',
            buttons: [
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-expenses', on: 'right-end' },
            title: 'Vol vertrouwen een beroepsmatige uitgave gedaan? Of twijfel je nog bij de term "beroepsmatig"?',
            text: 'No worries, onze handige flow om een uitgave toe te voegen staat voor je klaar en de info-iconen zetten je goed op weg.',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-manage', on: 'right-end' },
            title: 'Je verkoopartikelen en relaties',
            text: 'Wil je met vaste verkoopartikelen werken? Of een overzicht van je relaties? Zowel je verkoopartikelen als klanten en leveranciers bewaar je hier.',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-settings', on: 'right-end' },
            title: 'Instellingen',
            text: 'Kies een eigen bedrijfskleur of upload je logo, leuk om je verkoopfactuur te personaliseren! ',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.complete();
                    },
                    label: 'complete-tour',
                    text: "Let's go!"
                }
            ]
        }
    ];
}

function intializeStartupTourVakantiewoningen() {
    return [
        {
            attachTo: { element: '#v-tour-start-income', on: 'right' },
            title: 'De place to be voor al je inkomsten!',
            text: 'Uitgaande verkoopfacturen, offertes, creditnota’s en dagontvangsten, je kan ze hier allemaal aanmaken en terugvinden.',
            buttons: [
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-expenses', on: 'right-end' },
            title: 'Vol vertrouwen een beroepsmatige uitgave gedaan? Of twijfel je nog bij de term "beroepsmatig"?',
            text: 'No worries, onze handige flow om een uitgave toe te voegen staat voor je klaar en de info-iconen zetten je goed op weg.',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-manage', on: 'right-end' },
            title: 'Je verkoopartikelen en relaties',
            text: 'Wil je met vaste verkoopartikelen werken? Of een overzicht van je relaties? Zowel je verkoopartikelen als klanten en leveranciers bewaar je hier.',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.next();
                    },
                    text: 'Volgende'
                }
            ]
        },
        {
            attachTo: { element: '#v-tour-start-settings', on: 'right-end' },
            title: 'Instellingen',
            text: 'Kies een eigen bedrijfskleur of upload je logo, leuk om je verkoopfactuur te personaliseren! ',
            buttons: [
                {
                    action: function () {
                        return this.back();
                    },
                    secondary: true,
                    text: 'Vorige'
                },
                {
                    action: function () {
                        return this.complete();
                    },
                    label: 'complete-tour',
                    text: "Let's go!"
                }
            ]
        }
    ];
}

toursMap[tours.SECONDARY_STARTUP_TOUR] = intializeStartupTour();
toursMap[tours.SECONDARY_STARTUP_TOUR_PRE_ACCOUNTING] = intializeStartupTourPreAccounting();
toursMap[tours.STARTUP_VAKANTIEWONINGEN] = intializeStartupTourVakantiewoningen();
