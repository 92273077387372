/* eslint-disable no-unused-vars */

import { QuarterType } from '@/services/quarter.types';
import { RootState } from '@/store/store.types';
import { VatDeclarationState } from '@/store/types/vatDeclaration';
import { isNil } from 'lodash';
import { Module } from 'vuex';
import { sendGetVATDeclarationTextRequest } from '../../services/quarter';

function initialState(): VatDeclarationState {
    return {};
}

export default {
    namespaced: true,

    state: initialState(),

    actions: {
        async getVATDeclarationText({ rootState }, quarter: QuarterType) {
            const year = rootState.selectedYear;

            if (isNil(year)) {
                throw new Error('Year not defined');
            }

            return sendGetVATDeclarationTextRequest(year, quarter);
        }
    },

    mutations: {
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<VatDeclarationState, RootState>;
