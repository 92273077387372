import axios, { AxiosResponse } from 'axios';
import { generateEndpoint } from '@/services/config';
const BASE_URL_SERVERINFO = generateEndpoint('/api/serverInformation');

interface ServerInformation {
    stripePublishableKey: string;
    env: string;
    mollieClientId: string;
    version: string;
}
export function sendServerInformationRequest(): Promise<AxiosResponse<ServerInformation | undefined>> {
    return axios.get(`${BASE_URL_SERVERINFO}`);
}
export async function sendGetCurrentVersionRequest(): Promise<string | undefined> {
    const { data } = (await sendServerInformationRequest()) || {};
    return data?.version;
}
