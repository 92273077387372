import { filteringOptions } from '@/common/types';
import { CreateProjectData, UpdateProjectData } from '@/store/modules/project.types';
import { RootState } from '@/store/store.types';
import { ProjectState } from '@/store/types/project';
import { Module } from 'vuex';
import {
    createProject,
    deleteProject,
    generateInvoicePerProject,
    getActiveProjects,
    getActiveProjectsWithTimeregistrations,
    getAllProjectsPagination,
    searchProjects,
    sendGetProjectsRequest,
    toggleProjectClosed,
    updateProject
} from '../../services/project';

function initialState(): ProjectState {
    return {
        projects: [],
        newProjectEvents: []
    };
}

export default {
    namespaced: true,

    state: initialState(),

    actions: {
        getProjects({ state, dispatch }) {
            if (state.projects) {
                return Promise.resolve(state.projects);
            }

            return sendGetProjectsRequest().then((response) => {
                dispatch('setProjects', response);
            });
        },
        getAllProjectsPagination(context, options: filteringOptions) {
            return getAllProjectsPagination(options);
        },

        generateInvoicePerProject(context, invoiceRequest) {
            return generateInvoicePerProject(invoiceRequest);
        },

        getActiveProjects() {
            return getActiveProjects();
        },

        getActiveProjectsWithTimeregistrations() {
            return getActiveProjectsWithTimeregistrations();
        },

        searchProjects(context, input) {
            return searchProjects(input);
        },
        createProject({ dispatch }, project: CreateProjectData) {
            return createProject(project).then((response) => {
                dispatch('clearProjects');
                return response;
            });
        },
        updateProject(context, project: UpdateProjectData) {
            return updateProject(project);
        },
        toggleProjectClosed(context, projectId: number) {
            return toggleProjectClosed(projectId);
        },
        deleteProject(context, id: number) {
            return deleteProject(id);
        },
        newProjectEvent({ commit }) {
            commit('SET_NEW_PROJECT_EVENT');
        },

        setProjects({ commit }, projects) {
            commit('SET_PROJECTS', projects);
        },

        clearProjects({ commit }) {
            commit('CLEAR_PROJECTS');
        }
    },

    mutations: {
        SET_NEW_PROJECT_EVENT(state) {
            state.newProjectEvents = state.newProjectEvents.concat(1);
        },
        SET_PROJECTS(state, projects) {
            state.projects = projects;
        },
        CLEAR_PROJECTS(state) {
            state.projects = [];
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<ProjectState, RootState>;
