<template>
    <v-bottom-sheet
        class="bottom-sheet"
        transition="scale-transition"
        :value="showBottomSheet"
        inset
        v-bind="$attrs"
        retain-focus
        scrollable
        overlay-color="#12263f"
        overlay-opacity="0.5"
        eager
        @input="$emit('input', $event)"
    >
        <div class="inner-wrapper">
            <div class="close-button">
                <v-btn color="white" icon small @click="runMiddleware(registeredSubmitMiddleware)"
                    ><v-icon>mdi-close</v-icon></v-btn
                >
            </div>
            <div>
                <slot name="header" />
            </div>
            <div class="content-wrapper">
                <slot name="content" />
            </div>
        </div>
        <confirmation-dialog
            v-if="closeDialog"
            v-model="closeDialog"
            :title="$t('general.close')"
            :message="$t('bottomSheets.confirm_close')"
            persistent
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.close')"
            @click-positive="closeBottomSheet"
            @click-negative="closeDialog = false"
        />
    </v-bottom-sheet>
</template>

<script>
import { middlewareMixin } from '@/mixins/middlewareMixin';
import ConfirmationDialog from './Dialogs/ConfirmationDialog.vue';

export default {
    components: {
        ConfirmationDialog
    },

    mixins: [middlewareMixin],
    inheritAttrs: false,

    props: {
        value: {},
        closable: {
            default: true,
            type: Boolean
        }
    },

    data() {
        return {
            showBottomSheet: false,
            registeredSubmitMiddleware: [this.checkCancloseBottomSheet],
            closeDialog: false
        };
    },

    watch: {
        value: {
            immediate: true,
            handler() {
                this.showBottomSheet = this.value;
            }
        }
    },

    methods: {
        checkCancloseBottomSheet() {
            if (this.closable) {
                return this.closeBottomSheet();
            } else {
                this.closeDialog = true;
            }
        },
        closeBottomSheet() {
            this.showBottomSheet = false;
            this.$emit('input', false);
        }
    }
};
</script>

<style scoped lang="scss">
.bottom-sheet {
    overflow-y: auto;
}

.inner-wrapper {
    position: relative;
    background-color: white;
    padding: 3%;
    border-radius: 10px;

    .content-wrapper {
        max-height: 57vh;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 6px;
    }
}

.close-button {
    position: absolute;
    right: -25px;
    top: -25px;
}

@media (max-width: 600px) {
    .close-button {
        position: absolute;
        right: 50%;
        top: -30px;
    }
}
</style>
