import axios from 'axios';
import { ExpenseCategoryParentWithChildrenViewModel } from 'dexxter-types';
import { generateEndpoint } from './config';
import { initializePaginationVars } from '@/store/utils';
import { filteringOptions, PaginationResponse } from '@/common/types';
import { CreateExpenseCategoryData, UpdateExpenseCategoryData } from '@/services/expensecategories.types';
import { expenseTypeOfServiceType } from '@/services/accountingexpenses.types';

export const BASE_URL_EXPENSECATEGORIES = generateEndpoint('/api/expenseCategories');

export function sendGetExpenseCategoriesRequest(): Promise<ExpenseCategoryParentWithChildrenViewModel[]> {
    return axios.get(`${BASE_URL_EXPENSECATEGORIES}`).then((response) => response.data);
}

export const sendIsSocialcontributionExpensecategoryRequestURL = (expensecategoryID: number) =>
    `${BASE_URL_EXPENSECATEGORIES}/issocialcontribution/${expensecategoryID}`;

export function sendIsSocialcontributionExpensecategoryRequest(expensecategoryID: number): Promise<boolean> {
    return axios
        .get(sendIsSocialcontributionExpensecategoryRequestURL(expensecategoryID))
        .then((response) => response.data);
}

export function sendGetExpenseSubCategoriesPaginationRequest(options: filteringOptions): Promise<
    PaginationResponse<{
        id: number;
        parentId: number | null;
        parentCategory: { id: number; parentId: null; number: number | null; name: string };
        tag: { number: number; language: string; tags: string[] }[];
        number: number | null;
        name: string;
        potentialInvestement: boolean;
        forTypeOfService: expenseTypeOfServiceType;
        frequency: string | null;
        expectedAmount: number | null;
        visible: boolean;
        deletable: boolean;
        businessPercentage: number;
        VATDeductible: number;
    }>
> {
    return axios
        .get(`${BASE_URL_EXPENSECATEGORIES}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function getExpenseCategoriesFrequencies(year: number): Promise<any> {
    return axios.get(`${BASE_URL_EXPENSECATEGORIES}/frequencies/year/${year}`).then((response) => response.data);
}

export function createExpenseCategories(expenseCategory: CreateExpenseCategoryData): Promise<void> {
    return axios.post(`${BASE_URL_EXPENSECATEGORIES}`, expenseCategory).then((response) => response.data);
}

export function sendUpdatexpenseCategoriesRequest(expenseCategory: UpdateExpenseCategoryData): Promise<void> {
    return axios
        .put(`${BASE_URL_EXPENSECATEGORIES}/${expenseCategory.id}`, expenseCategory)
        .then((response) => response.data);
}

export function toggleVisibleExpenseCategories(expenseCategoryId: number): Promise<void> {
    return axios
        .put(`${BASE_URL_EXPENSECATEGORIES}/${expenseCategoryId}/toggleVisible`)
        .then((response) => response.data);
}

export function deleteExpenseCategory(expenseCategoryId: number): Promise<void> {
    return axios.delete(`${BASE_URL_EXPENSECATEGORIES}/${expenseCategoryId}`).then((response) => response.data);
}
