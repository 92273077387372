import { isUserAccount } from '@/helpers/auth';
import store from '../../store';
import { routeRequiresAuth } from './helpers/index';
export async function getAllGlobalSettings(to, from, next) {
    const requiresAuth = routeRequiresAuth(to);

    if (requiresAuth) {
        const user = store.state.auth.currentUserData;

        if (isUserAccount(user)) {
            await getAllGlobalSettingsIfNotExists();
        }
    }

    return next();
}

async function getAllGlobalSettingsIfNotExists() {
    if (store.getters.globalSettingsLoaded === false) {
        await store.dispatch('getAllGlobalSettings', store.state.selectedYear);
    }
}
