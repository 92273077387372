<template>
    <div id="PublicInvoicableLayout" class="fill-height">
        <v-container>
            <router-view :key="$route.fullPath"></router-view>
        </v-container>
        <loader v-if="loading" overlay-color="none" />
    </div>
</template>

<script>
import Loader from '@/components/Loader';

export default {
    name: 'LoggedInLayout',

    components: {
        Loader
    },

    data() {
        return {
            loading: false
        };
    }
};
</script>

<style lang="scss">
@import '../sass/app.scss';

#PublicInvoicableLayout {
    max-width: 70% !important;
    margin: 0 auto;
}
</style>
