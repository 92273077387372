
import Vue from 'vue';
// --- State ---
import { mapState } from 'vuex';
// --- Components ---
import SliderModal from '@/components/Modal/SliderModal.vue';

export default Vue.extend({
    name: 'BaseModal',
    components: { SliderModal },
    computed: {
        ...mapState('modal', [
            'isModalOpen',
            'activeComponentId',
            'modalComponents',
            'modalComponentPropsArray',
            'modalComponentListenersArray',
            'nextTransition',
            'dataId'
        ])
    },
    methods: {
        goToPreviousModal() {
            this.$store.commit('modal/goToPreviousAndRemoveLastModalPage');
        }
    }
});
