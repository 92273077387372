import { authTokenKey } from './helpers/auth';

export function migrateTokens() {
    try {
        const authToken = localStorage.getItem(authTokenKey);

        if (authToken) {
            const parsedToken = JSON.parse(authToken);
            if (Array.isArray(parsedToken)) {
                localStorage.setItem(authTokenKey, JSON.stringify(parsedToken[parsedToken.length - 1]));
            }
        }
    } catch (e) {
        localStorage.removeItem(authTokenKey);
    }
}
