/* eslint-disable no-unused-vars */

import { Module } from 'vuex';
import { RootState } from '@/store/store.types';
import { CustomersState } from '@/store/types/customers';

function initialState(): CustomersState {
    return {
        newCustomerEvents: []
    };
}

export default {
    namespaced: true,

    state: initialState(),

    actions: {
        async newCustomerCreated({ commit }) {
            commit('SET_NEW_CUSTOMER_CREATED_EVENT');
        }
    },

    mutations: {
        SET_NEW_CUSTOMER_CREATED_EVENT(state) {
            state.newCustomerEvents = state.newCustomerEvents.concat(1);
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<CustomersState, RootState>;
