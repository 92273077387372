import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import { IEmailDataRequest } from '@/services/creditnote.types';
import { IQuotationViewModel } from '@/services/quotation.types';

const BASE_URL = generateEndpoint('/api/quotations');

export function sendQuotationEmail(quotationID: number, emailData: IEmailDataRequest): Promise<void> {
    return axios.post(`${BASE_URL}/${quotationID}/email`, { emailData }).then((response) => response.data);
}

export function getQuotation(quotationID: number): Promise<IQuotationViewModel> {
    return axios.get(`${BASE_URL}/${quotationID}`).then((response) => response.data);
}

export const getPdfRepresentationOfQuotationUrl = (invoiceID: number): string => `${BASE_URL}/${invoiceID}/pdf`;

export function getHTMLRepresentationOfQuotation(quotationId: number): Promise<string> {
    return axios.get(`${BASE_URL}/${quotationId}/html`).then((response) => response.data);
}

export function getQuotationByUUID(quotationUUID: string): Promise<IQuotationViewModel> {
    return axios.get(`${BASE_URL}/byUUID/${quotationUUID}`).then((response) => response.data);
}
