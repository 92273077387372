import {
    cantRefreshToken,
    doesTokenExpiredWithin,
    getCurrentToken,
    getImpersonateToken,
    isTokenExpired,
    refreshToken,
    removeAllTokens,
    resetLoggingState
} from '@/helpers/auth';
import { reportError } from '@/helpers/logging';
import axios from 'axios';
import dayjs from 'dayjs';
import jwtDecode from 'jwt-decode';
import { addDexxterHeaderSourceInterceptor } from './dexxter-header-source.interceptor';

const endpointsExcludeRefreshTokens = ['/api/auth/login', '/api/auth/refresh'];
const endpointsExcludeRefreshTokensInclude = ['storage.googleapis.com'];

/**
 *
 * @param url {string}
 * @returns {boolean}
 */
function shouldExcludeURL(url) {
    if (url.includes('/api/auth/login/impersonate')) {
        return false;
    }

    return (
        endpointsExcludeRefreshTokens.some((_toExclude) => {
            return url.includes(_toExclude);
        }) ||
        endpointsExcludeRefreshTokensInclude.some((_excludeIncludePattern) => url.includes(_excludeIncludePattern))
    );
}

// Interceptor - adding authorization token
(function addAuthorizationToken() {
    axios.interceptors.request.use(
        async (config) => {
            if (shouldExcludeURL(config.url)) {
                return config;
            }

            let authTokens = getCurrentToken();

            if (authTokens) {
                const { exp: tokenExpiresAt } = jwtDecode(authTokens.access_token);

                if (isTokenExpired(jwtDecode(authTokens.refresh_token).exp)) {
                    await cantRefreshToken();
                } else if (doesTokenExpiredWithin(dayjs().toDate(), tokenExpiresAt, 5)) {
                    authTokens = await refreshToken();
                }

                config.headers['Authorization'] = `Bearer ${authTokens.access_token}`;
            }

            return config;
        },

        async (error) => {
            return Promise.reject(error);
        }
    );
})();

// Interceptor - adding authorization token
(function addImpersonateHeader() {
    axios.interceptors.request.use(
        async (config) => {
            const impersonateToken = getImpersonateToken();

            if (impersonateToken) {
                config.headers['impersonate'] = impersonateToken;
            }

            return config;
        },

        async (error) => {
            return Promise.reject(error);
        }
    );
})();

// Interceptor - adding authorization token
(function addAuthorizationTokenResponse() {
    axios.interceptors.response.use(
        (response) => {
            // Return a successful response back to the calling service
            return response;
        },
        async (error) => {
            // Return any error which is not due to authentication back to the calling service
            if (401 !== error?.response?.status) {
                return new Promise((resolve, reject) => {
                    reject(error);
                });
            }

            // Check if there is an impersonating token, if so we have to redirect the user back to their dashboard
            // const isUserImpersonating = isImpersonating();
            // if (isUserImpersonating) {
            //     removeImpersonateToken();
            //     router.replace('login');
            //     return;
            // }

            if (error.config.url.includes('/api/auth/refresh')) {
                // We have to keep this if-statement here, since we pro-activly refesh a token and we dont need to refresh the token on a failed refreshtoken-request
                await cantRefreshToken();
            } else {
                const tokens = getCurrentToken();
                if (!tokens || isTokenExpired(jwtDecode(tokens.refresh_token).exp)) {
                    await cantRefreshToken();
                } else {
                    // Try request again with new token
                    return refreshToken()
                        .then((tokens) => {
                            // New request with new token
                            const config = error.config;
                            if (shouldExcludeURL(config.url)) {
                                return config;
                            }

                            config.headers['Authorization'] = `Bearer ${tokens.access_token}`;

                            return new Promise((resolve, reject) => {
                                axios
                                    .request(config)
                                    .then((response) => {
                                        resolve(response);
                                    })
                                    .catch((error) => {
                                        reject(error);
                                    });
                            });
                        })
                        .catch((error) => {
                            return Promise.reject(error);
                        });
                }
            }
        }
    );
})();

// Interceptor - When unauthorized request, log the user out
(function checkUnauthorization() {
    const exclude = ['/api/auth/login'];

    axios.interceptors.response.use(
        (response) => {
            return response;
        },

        async (error) => {
            if (401 === error?.response?.status) {
                if (error.response.config.url.includes(exclude)) {
                    throw error;
                }

                resetLoggingState();
                removeAllTokens();
                window.location = '/login';
                throw error;
            } else {
                throw error;
            }
        }
    );
})();

// Interceptor - Check if all requests are redirected to the correct api-endpoint and not the relative path url
(function addApiLocationCheck() {
    axios.interceptors.request.use((config) => {
        if (process.env.VUE_APP_MODE === 'production' && !config.url.includes('http')) {
            reportError(new Error(`Url detected without http ${config.url}`));
        }

        return config;
    });
})();

addDexxterHeaderSourceInterceptor();
