import { isUserAccount } from '@/helpers/auth';
import i18n from '../../i18n';
import vueitfy from '../../plugins/vuetify';
import store from '../../store';
import { routeRequiresAuth } from './helpers/index';
export function setUserLanguage(to, from, next) {
    const requiresAuth = routeRequiresAuth(to);

    if (requiresAuth) {
        const user = store.state.auth.currentUserData;

        if (isUserAccount(user)) {
            changeLanguage(user.userinformation?.language || 'nl');
        }
    }

    return next();
}

function changeLanguage(language) {
    // TODO: NOG EENS BEKIJKEN
    i18n.locale = language;
    vueitfy.framework.lang.current = language;
}
