import * as AccountantemailbccService from '@/services/accountantemailbcc';
import { AccountantEmailBCCState } from '@/store/types/accountantEmailBCC';
import { Module } from 'vuex';
import { RootState } from '@/store/store.types';

function initialState(): AccountantEmailBCCState {
    return {
        accountantEmailBCCs: null
    };
}

export default {
    namespaced: true,

    state: initialState(),

    actions: {
        // [Begin: Create data]
        async getAccountantEmailBCC({ state, commit }) {
            if (state.accountantEmailBCCs) {
                return state.accountantEmailBCCs;
            }

            return AccountantemailbccService.getAccountantEmailBCCs().then((response) => {
                commit('SET_ACCOUNTANTEMAILBCCS', response);
                return state.accountantEmailBCCs;
            });
        },
        refetchAccountantEmailBCC({ commit, dispatch }) {
            commit('RESET_ACCOUNTANTEMAILBCCS');
            return dispatch('getAccountantEmailBCC');
        }
    },

    mutations: {
        SET_ACCOUNTANTEMAILBCCS(state, accountantEmailBCCs) {
            state.accountantEmailBCCs = accountantEmailBCCs;
        },
        RESET_ACCOUNTANTEMAILBCCS(state) {
            state.accountantEmailBCCs = null;
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<AccountantEmailBCCState, RootState>;
