<template>
    <div>
        <v-navigation-drawer v-model="isOpen" temporary app right class="navigation px-6">
            <v-list class="menu" flat>
                <v-list-item-group>
                    <v-list-item @click="toggleLearningcenter">
                        <v-list-item-icon>
                            <v-icon>mdi-school</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('navigation.learningcenter') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item :to="{ name: 'notifications' }">
                        <v-list-item-icon>
                            <v-icon>mdi-bell</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('navigation.notifications') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="openSupportDialog">
                        <v-list-item-icon>
                            <v-icon>mdi-face-agent</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('navigation.support') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="isChangeBookyearModalOpen = true">
                        <v-list-item-icon>
                            <v-icon>mdi-calendar</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('navigation.change_bookyear') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="logout">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ $t('navigation.logout') }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <status-text-subscription
                                :current-user-data="currentUserData"
                                :user-billinginfo="userBillinginfo"
                            />
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { isUserAccount, isUserAccountWithFreeTier } from '@/helpers/auth';
import { mapFields } from 'vuex-map-fields';
// --- Mixins ---
import dayjs from 'dayjs';
import { mapActions, mapGetters, mapState } from 'vuex';
import { screenSizeMixin } from '../mixins/screenSizeMixin';
import StatusTextSubscription from './StatusTextSubscription.vue';

export default {
    components: { StatusTextSubscription },
    mixins: [screenSizeMixin],

    props: {
        value: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            isAccountInfoOpen: false
        };
    },

    computed: {
        isOpen: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        },

        ...mapFields(['selectedYear', 'globalSettingsYears', 'isChangeBookyearModalOpen']),
        ...mapFields('timeregistrations', ['currentTimeregistrationTimer', 'timeDifference']),
        ...mapState('auth', ['currentUserData', 'userBillinginfo']),
        ...mapState('notifications', ['hasNewNotification']),
        ...mapGetters('auth', ['getCurrentUserInitials', 'getCurrentUserFullName']),
        canShowLearningCenter() {
            return isUserAccount(this.currentUserData);
        },
        isFreeUser() {
            return isUserAccountWithFreeTier(this.currentUserData);
        },
        pageName() {
            return this.$route.meta.title;
        },
        years() {
            return [...this.globalSettingsYears].sort((a, b) => (a < b ? 1 : -1));
        },
        isInTrial() {
            return this.userBillinginfo.isInTrial;
        },
        trialDaysRemaining() {
            if (this.userBillinginfo.currentPeriodEnds) {
                return -dayjs().diff(this.userBillinginfo.currentPeriodEnds, 'day');
            }

            return 0;
        },
        menuIcon() {
            if (this.isSmallDevice) {
                return 'mdi-menu';
            }

            if (this.miniModus) {
                return 'mdi-chevron-right-circle-outline';
            } else {
                return 'mdi-chevron-left-circle-outline';
            }
        },
        hasRunningTimer() {
            return this.currentTimeregistrationTimer && this.currentTimeregistrationTimer.id;
        }
    },
    methods: {
        ...mapActions('auth', ['logout']),
        ...mapActions(['startLoading', 'stopLoading', 'openSupportDialog', 'toggleLearningcenter']),
        toggleHelp() {
            this.openSupportDialog();
        },
        isUserAccount: isUserAccount
    }
};
</script>

<style lang="scss" scoped>
.avatar {
    color: white;
}

:deep().v-toolbar__title {
    overflow: visible;
    margin-right: 16px;
}
section.left {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

section.right {
    display: flex;
    align-items: center;
}

.NavigationWrapper {
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: space-between;
}

.app-bar {
    height: auto !important;
    background-color: #ffffff !important;
}

.app-bar .v-app-bar__nav-icon {
    color: $color-text-grey;
}

.navbar-button i::before {
    color: $color-text-grey !important;
}

.navbar-button:hover {
    background-color: none;
}
.navbar-button i::before {
    font-size: 22px !important;
}
.app-bar .v-app-bar__nav-icon:hover,
.app-bar .v-app-bar__nav-icon:focus,
.app-bar .v-app-bar__nav-icon {
    color: rgba(0, 0, 0, 0) !important;
}

.headerContentWrapper > * {
    margin-bottom: 20px;
}

.headerContentWrapper > *:last-child {
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>
