<template>
    <v-snackbar v-model="versionIsOutdated" timeout="-1">
        {{ $t('general.new_version_snackbar.text') }}
        <SubmitButton class="ml-2 snackbar-refresh-button" @click="refreshPage">
            <v-icon class="mr-2">mdi-autorenew</v-icon> {{ $t('general.new_version_snackbar.button_text') }}
        </SubmitButton>
    </v-snackbar>
</template>

<script>
// --- State ---
import { mapState } from 'vuex';
// --- Services ---
import { sendGetCurrentVersionRequest } from '@/services/serverinformation';
// --- Helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

export default {
    name: 'NewVersionSnackbar',
    data() {
        return {
            pollingIntervalId: setInterval(this.checkIfVersionIsOutdated.bind(this), 60_000),
            versionIsOutdated: false
        };
    },
    computed: {
        ...mapState(['serverInfo'])
    },
    destroyed() {
        clearInterval(this.pollingIntervalId);
    },
    methods: {
        async checkIfVersionIsOutdated() {
            try {
                const currentVersion = await sendGetCurrentVersionRequest();
                if (!currentVersion) return;

                const { version: storedVersion } = this.serverInfo;

                const parsedCurrentVersion = parseInt(currentVersion.replace('.', ''));
                const parsedStoredVersion = parseInt(storedVersion.replace('.', ''));

                if (parsedCurrentVersion > parsedStoredVersion) {
                    this.versionIsOutdated = true;
                }
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            }
        },
        refreshPage() {
            this.$router.go();
        }
    }
};
</script>

<style scoped></style>
