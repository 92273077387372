<template>
    <base-bottom-sheet
        v-if="isAddUnitOpen && currentUserData"
        v-model="isAddUnitOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
        class="add-income-billed-sheet"
    >
        <template #header>
            <bottom-sheet-header>
                {{ isCreatingNewUnit ? $t('units.add_units_title') : $t('units.edit_units_title') }}
            </bottom-sheet-header>
        </template>
        <template #content>
            <v-form ref="form" v-model="valid">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6" class="pt-0 pb-0">
                            <text-field
                                v-model="currentUnit.singular"
                                :label="$t('units.singular')"
                                outlined
                                persistent-hint
                                required
                                :rules="requiredRules"
                            />
                        </v-col>

                        <v-col cols="12" md="6" class="pt-0 pb-0">
                            <text-field
                                v-model="currentUnit.plural"
                                :label="$t('units.plural')"
                                outlined
                                persistent-hint
                                required
                                :rules="requiredRules"
                            />
                        </v-col>

                        <v-col cols="12" md="12">
                            <v-btn color="primary" small rounded :disabled="!valid" @click="handleSubmit">
                                {{ isCreatingNewUnit ? $t('general.create') : $t('general.save') }}
                                <v-icon right dense dark> mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </template>

        <loader v-if="loading" />
    </base-bottom-sheet>
</template>

<script>
// --- State ---
import { mapActions, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
// --- Components ---
import BottomSheetHeader from './BottomSheetHeader';
import Loader from '../Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- Constants ---
// --- Helper ---
import { notify } from '@/helpers/successNotification';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { required, percentageValidator } from '@/helpers/validationRules';

export default {
    components: {
        BottomSheetHeader,
        Loader
    },

    mixins: [formRulesMixin],

    data() {
        return {
            valid: true,
            loading: false
        };
    },

    computed: {
        ...mapFields('bottomSheets', ['isAddUnitOpen', 'currentUnit', 'isCreatingNewUnit']),
        ...mapState('auth', ['currentUserData'])
    },

    methods: {
        ...mapActions('units', ['createUnit', 'updateUnit', 'resetUnit']),
        async handleSubmit() {
            try {
                this.loading = true;
                if (this.isCreatingNewUnit) {
                    await this.createUnit(this.currentUnit);
                } else {
                    await this.updateUnit(this.currentUnit);
                }
                notify.call(this, {
                    title: this.$t('settings.succesfuly_saved'),
                    text: this.$t('settings.succesfuly_saved')
                });

                this.$store.dispatch('units/newUnitEvent');

                // Close bottom sheet when submit successful.
                this.isAddUnitOpen = false;
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        VATDeductibleRules(stringError) {
            if (!this.currentUnit.businessPercentage) {
                return [required, percentageValidator];
            }
            return [
                function (value) {
                    return value <= this.currentUnit.businessPercentage || stringError;
                }.bind(this),
                required,
                percentageValidator
            ];
        },
        businessExpenseRules(stringError) {
            if (!this.currentUnit.VATDeductible) {
                return [required, percentageValidator];
            }

            return [
                function (value) {
                    return value >= this.currentUnit.VATDeductible || stringError;
                }.bind(this),
                required,
                percentageValidator
            ];
        }
    }
};
</script>
