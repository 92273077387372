<template>
    <div :id="id" class="tooltipWrapper" :data-testid="dataTestid">
        <span class="activator" :class="{ underlined: underlined }" @click.stop="open = true">
            <slot name="activator" />
        </span>

        <tooltip-popup v-if="open" v-model="open" v-bind="{ ...$props }" />
    </div>
</template>

<script>
import { screenSizeMixin } from '../mixins/screenSizeMixin';
import TooltipPopup from './TooltipPopup.vue';
export default {
    components: { TooltipPopup },
    mixins: [screenSizeMixin],
    inheritAttrs: false,

    props: {
        id: {
            type: String,
            required: false
        },
        dataTestid: {
            type: String,
            required: false
        },
        i18nPath: {
            type: String
        },
        content: {
            type: String
        },
        title: {
            type: String
        },
        readmore: {
            type: String,
            default: null
        },
        underlined: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            open: false
        };
    }
};
</script>

<style lang="scss" scoped>
.tooltipWrapper {
    display: inline-block;
}

.vCardHeader_Title {
    font-size: 1.2rem;
}

.Header {
    border: 0px;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-radius: 0px !important;
}
.Footer {
    border: 0px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    border-radius: 0px !important;
}

.TooltipContent {
    padding-bottom: 10px;
    overflow-y: auto;

    p {
        margin-bottom: 16px !important;
    }
}

.tooltip {
    padding: 20px !important;
}
.readmore {
    padding: 0 19.5555555556px;
}

.activator {
    cursor: pointer;
}

.underlined {
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: #2590fb;
    text-decoration-thickness: 2px;
}
</style>
