<template>
    <v-list-item data-id="learningcenter-video" @click="clickLearningAction(item)">
        <v-list-item-avatar>
            <v-icon>{{ item.getIcon() }}</v-icon>
        </v-list-item-avatar>

        <v-list-item-content>
            <div class="d-flex align-baseline">
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <v-icon small>mdi-open-in-new</v-icon>
            </div>
            <v-list-item-subtitle class="learningcenter-item-subtitle" v-html="item.summary"></v-list-item-subtitle>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
export default {
    props: {
        item: {}
    },

    methods: {
        clickLearningAction(learningItem) {
            return this.$emit('click', learningItem);
        }
    }
};
</script>

<style lang="scss" scoped>
.learningcenter-item-subtitle {
    white-space: pre-wrap;
}
</style>
