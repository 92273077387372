import Vue from 'vue';

export const chatMixin = Vue.extend({
    methods: {
        openChatDialog(data: { name: string; email: string; phone?: string; id: string }) {
            window.jivo_init();
            window.jivo_onLoadCallback = () => {
                window.jivo_api.setContactInfo({
                    email: data.email,
                    name: data.name,
                    phone: data.phone
                });
                window.jivo_api.setCustomData([
                    {
                        content: data.id,
                        key: 'De Dexxter applicatie id',
                        title: 'Applicatie id'
                    }
                ]);

                window.jivo_api.open();
            };
            window.jivo_onClose = () => {
                window.close();
            };
        }
    }
});
