<template>
    <div>
        <v-list-item v-if="$can('READ', 'Useronlyaccess')" :to="{ name: 'dashboard' }" :disabled="!canViewDashboard">
            <v-list-item-icon>
                <v-icon>mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.dashboard') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-group
            v-if="$can('READ', 'Useronlyaccess')"
            id="v-tour-start-income"
            no-action
            :disabled="!canViewIncomes"
            dense
        >
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-credit-card-plus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.incomes') }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item link :to="{ name: 'quotations' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.quotations') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'accountingInvoices' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.invoices') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'accountingCreditNotes' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.creditnotes') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="canShowStudentIncomes" link :to="{ name: 'jobstudentincomes' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.jobstudentincomes') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item link :to="{ name: 'dailyReceipts' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.dailyreceipts') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>

        <v-list-group
            v-if="$can('READ', 'Useronlyaccess')"
            id="v-tour-start-expenses"
            no-action
            :disabled="!canViewExpenses"
        >
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-credit-card-minus-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.expenses') }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'expenses' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.expenses') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$can('READ', 'Depreciationfiche')" :to="{ name: 'depreciations' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.depreciations') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'manageCostCategory' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.manage_costcategory') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>

        <v-list-group v-if="!isFreeTierAccount && canShowVATMenuItem" no-action>
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-file-document-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.declarations') }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item v-if="$can('READ', 'VATDeclaration')" link :to="{ name: 'vat-declaration' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.vat_declaration') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$can('READ', 'ICListing')" link :to="{ name: 'ic-listing' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.ic_listing') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="canShowClientlisting" to="/vat-listing">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.client_listing') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>

        <v-list-group v-if="$can('READ', 'Personaltaxes')" id="v-tour-start-personal-taxes" no-action>
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-file-settings-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.personal_taxes') }}</v-list-item-title>
                </v-list-item-content>
            </template>

            <v-list-item :to="{ name: 'personalTaxes' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.personal_taxes_aangifte') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'personalTaxesOverview' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.personaltaxes_overview') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/deposits">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.deposits') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/inventory">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.inventory') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>

        <v-list-item
            v-if="$can('READ', 'Socialcontribution')"
            id="v-tour-start-socialcontributions"
            :to="{ name: 'socialContributionsOverview' }"
        >
            <v-list-item-icon>
                <v-icon>mdi-account-child</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title
                    >{{ $t('navigation.socialcontributions_overview')
                    }}<v-chip class="ml-2" color="primary" x-small> Nieuw </v-chip></v-list-item-title
                >
            </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="canShowDependentOn" :to="{ name: 'dependentOn' }">
            <v-list-item-icon>
                <v-icon>mdi-account-supervisor</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('pageTitles.dependent_on') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <v-list-item
            v-if="$can('READ', 'Timeline') && !isFreeTierAccount"
            id="v-tour-start-calendar"
            to="/timeline"
            :disabled="!canViewCalendar"
        >
            <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('pageTitles.timeline') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>

        <!--
    <div v-if="false && $can('READ', 'Accountinginvoicable')" id="v-tour-start-to-book">
        <v-list-item :to="{ name: 'documentsToBook' }">
            <v-list-item-icon>
                <v-icon>mdi-text-box-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.to_book') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </div>-->

        <v-list-group
            v-if="$can('READ', 'Useronlyaccess')"
            id="v-tour-start-reports"
            no-action
            :disabled="!canViewExpenses"
        >
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-chart-bubble</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.reports') }}</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item to="/reports/incomestatement">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.incomestatement') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'resultatenrekeningAdvanced' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.resultatenrekening') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'startResultatenrekening' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.start_resultatenrekening') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item to="/reports/expensecategory-frequencies">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.expenseCategoryFrequencies') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'dagboeken' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.dagboeken') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
        <v-list-item v-if="!isFreeTierAccount" :to="{ name: 'comingSoonAnalyse' }" :disabled="!canViewAnalysis">
            <v-list-item-icon>
                <v-icon>mdi-head-sync</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ $t('pageTitles.analysis') }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-group
            v-if="$can('READ', 'Communityquestion') && !isFreeTierAccount"
            id="v-tour-start-learningcenter-navigation"
            no-action
        >
            <template #activator>
                <v-list-item-icon>
                    <v-icon>mdi-school</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>Leeromgeving</v-list-item-title>
                </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'communityQuestions' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('pageTitles.community') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'viewUserCommunityQuestion', params: { id: currentUserData.id } }">
                <v-list-item-content>
                    <v-list-item-title>Bekijk je vragen</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{ name: 'learningCenter' }">
                <v-list-item-content>
                    <v-list-item-title>Leercentrum</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!userHasAdvisor" @click="openAdvisorsPage">
                <v-list-item-content>
                    <v-list-item-title>Adviseurs</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="userHasAdvisor" :to="{ name: 'documentCommunication' }">
                <v-list-item-content>
                    <v-list-item-title>{{ $t('pageTitles.communication') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>

        <v-divider class="px-4" />

        <v-subheader v-if="!isCollapsed">{{ $t('navigation.management').toUpperCase() }}</v-subheader>

        <div v-if="$can('READ', 'Useronlyaccess')" id="v-tour-start-manage">
            <v-list-item to="/invoiceitems" :disabled="!canViewInvoiceitems">
                <v-list-item-icon>
                    <v-icon>mdi-briefcase-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.invoiceitems') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item v-if="$can('READ', 'Car')" :to="{ name: 'get-all-cars' }">
                <v-list-item-icon>
                    <v-icon>mdi-car</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ $t('navigation.cars') }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-group no-action>
                <template #activator>
                    <v-list-item-icon>
                        <v-icon>mdi-account-group</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.relations') }}</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item to="/customers" :disabled="!canViewCustomers">
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.clients') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/suppliers" :disabled="!canViewSuppliers">
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('supplier.suppliers') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </div>
        <div id="v-tour-start-settings">
            <v-list-group no-action @click="jumpIntoView">
                <template #activator>
                    <v-list-item-icon>
                        <v-icon>mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.settings') }}</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item to="/user-settings/general">
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.settingsMenu.general') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!isFreeTierAccount" to="/user-settings/templates">
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.settingsMenu.templates') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!isFreeTierAccount" to="/user-settings/invoice">
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.settingsMenu.invoice') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="/user-settings/subscription">
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.settingsMenu.subscription') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item :to="{ name: 'user-settings-notifications' }" replace>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.settingsMenu.notifications') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="!isFreeTierAccount" to="/user-settings/calculations" replace>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.settingsMenu.calculations') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="$can('READ', 'ManageAccountant')" to="/user-settings/accountant" replace>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.settingsMenu.accountant') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="$can('READ', 'Integration')" to="/integrations" replace>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.settingsMenu.integrations') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="$can('READ', 'AccountantEmailBCC')" to="/user-settings/accountantemailbcc" replace>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.settingsMenu.accountantemailbcc') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item v-if="$can('READ', 'Activity')" :to="{ name: 'activity' }" replace>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('navigation.settingsMenu.activity') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>
        </div>
    </div>
</template>

<script>
import { canVatTypeSubmitClientListing } from '@/helpers/VATHelper';
import { isUserAccount, isUserAccountWithFreeTier } from '@/helpers/auth';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';
import { useTaxStore } from '@/store/pinia/tax/tax.store';
import { mapStores } from 'pinia';
import { defineComponent } from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { useGlobalStore } from '@/store/pinia/global.store';

export default defineComponent({
    name: 'NavigationItems',
    mixins: [screenSizeMixin],
    props: {
        isCollapsed: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            advisor: undefined
        };
    },
    computed: {
        ...mapStores(useGlobalStore),
        ...mapState('auth', ['isUserImpersonating']),
        ...mapState('auth', ['currentUserData']),
        ...mapFields('users', ['selectedUsers']),
        ...mapGetters('auth', ['isUserType']),
        ...mapState(['selectedYear']),
        ...mapStores(useTaxStore),
        isUserBeenStudent() {
            return this.taxStore.wasUserStudentInCurrentYear;
        },
        canShowStudentIncomes() {
            return this.isUserBeenStudent;
        },
        canShowDependentOn() {
            return this.isUserBeenStudent;
        },
        canShowVATMenuItem() {
            return this.$can('READ', 'ICListing') || this.$can('READ', 'VATDeclaration');
        },
        canShowClientlisting() {
            return canVatTypeSubmitClientListing(this.currentUserData.settings.vatLiable);
        },
        canViewDashboard() {
            return this.defaultAccountOrImpersonating;
        },
        canViewIncomes() {
            return this.defaultAccountOrImpersonating;
        },
        canViewExpenses() {
            return this.defaultAccountOrImpersonating;
        },
        showVATDeclaration() {
            return this.defaultAccountOrImpersonating;
        },
        canViewAnalysis() {
            return this.defaultAccountOrImpersonating;
        },
        canViewCalendar() {
            return this.defaultAccountOrImpersonating;
        },
        canViewCustomers() {
            return this.defaultAccountOrImpersonating;
        },
        canViewInvoiceitems() {
            return this.defaultAccountOrImpersonating;
        },
        canViewSuppliers() {
            return this.defaultAccountOrImpersonating;
        },
        canViewAllUsers() {
            return this.isAdmin(this.currentUserData);
        },
        canManageNotifications() {
            return this.isAdmin(this.currentUserData);
        },
        canManageCalendarevents() {
            return this.isAdmin(this.currentUserData);
        },
        canViewGlobalSettings() {
            return this.isAdmin(this.currentUserData);
        },
        defaultAccountOrImpersonating() {
            return isUserAccount(this.currentUserData) || this.isUserImpersonating;
        },
        isFreeTierAccount() {
            return isUserAccountWithFreeTier(this.currentUserData);
        },
        userHasAdvisor() {
            return this.globalStore.userHasAccountant;
        }
    },
    async created() {
        if (!this.isFreeTierAccount) {
            this.taxStore.loadTax(this.selectedYear);
        }

        this.getCurrentUserType();
    },
    methods: {
        ...mapActions(['openLearningcenterWithAllItems']),
        ...mapActions('auth', ['getCurrentUserType']),
        openAdvisorsPage() {
            window.open('https://dexxter.be/adviseurs/', '_blank').focus();
        },
        jumpIntoView(element) {
            if (element && element.srcElement) {
                setTimeout(() => {
                    element.srcElement.scrollIntoView({ block: 'start', inline: 'nearest' });
                }, 250);
            }
        }
    }
});
</script>
