import { Learning, VatStatus, vatStatuses } from '@/config/constants';
import { Learningitem } from '@/models/learningitem';
import { UserBookYearType } from '@dexxter/accountancy-tools';
import { distance } from 'fastest-levenshtein';
import { cloneDeep, isEmpty, uniqBy } from 'lodash';
import { pipe } from './general';

export function getItemsForLocation(
    items: { places: string[]; VATType: string[]; userType: string[] }[],
    path: string,
    userTypes: UserBookYearType[],
    vatLiable: VatStatus
) {
    if (path.length === 0) {
        return [];
    }

    const pathWithoutLeadingSlash = path[0] === '/' ? path.substring(1) : path;

    return userContext(
        items.filter((_item) => _item.places.some((_place) => pathWithoutLeadingSlash.includes(_place))),
        userTypes,
        vatLiable
    );
}

/**
 * Loops through all the items with a given searchQuery.
 * First, we split up the searchQuery in multiple words (searchquery may be a sentence) and then we
 * loop over all the tags of a given item. We then check if there is an exact match or, when to word is of a large enough size,
 * we calculate the levenstein distance to see if there may be a misspelling.
 */
export function searchItem(items: any[], searchQuery: string, userTypes: UserBookYearType[], vatLiable: VatStatus) {
    return userContext(
        items.filter((_item) =>
            _item.tags.some((_tag: string) => {
                if (isEmpty(searchQuery)) {
                    return true;
                }

                return searchQuery
                    .split(' ')
                    .filter((_item) => !isEmpty(_item))
                    .some((term) => {
                        if (_tag.toLowerCase().includes(term.toLowerCase())) {
                            return true;
                        } else if (term.length >= 4 && distance(term.toLowerCase(), _tag.toLowerCase()) <= 2) {
                            return true;
                        }

                        return false;
                    });
            })
        ),
        userTypes,
        vatLiable
    );
}

export function getAllLearningcenterItems(items: any[], userTypes: UserBookYearType[], vatLiable: VatStatus) {
    return userContext(items, userTypes, vatLiable);
}

function getUserTypeFiltering(userTypes: UserBookYearType[]) {
    return (items: Learningitem[]) => {
        const result = cloneDeep(items);

        let studentItems: Learningitem[] = [];
        let secondaryItems: Learningitem[] = [];
        let mainItems: Learningitem[] = [];

        if (userTypes.includes('student')) {
            studentItems = result.filter(onlyStudentItems);
        }
        if (userTypes.includes('secondary')) {
            secondaryItems = result.filter(onlySecondaryItems);
        }
        if (userTypes.includes('main')) {
            mainItems = result.filter(onlyMainItems);
        }

        return uniqBy([...studentItems, ...secondaryItems, ...mainItems], (item) => item.id);
    };
}

function getVATTypeFiltering(vatType: VatStatus) {
    return (items: any) => {
        let result = cloneDeep(items);

        if (vatType === vatStatuses.VATLIABLE) {
            result = result.filter(onlyVATLiableItems);
        } else if (vatType === vatStatuses.VATEXEMPT) {
            result = result.filter(onlyVATExemptItems);
        } else if (vatType === vatStatuses.NOTVATLIABLE) {
            result = result.filter(onlyNonVATLIableItems);
        }

        return result;
    };
}

function userContext(items: any, userTypes: UserBookYearType[], vatLiable: VatStatus) {
    const result = cloneDeep(items);

    if (isEmpty(result)) {
        return [];
    }

    return pipe(getVATTypeFiltering(vatLiable), getUserTypeFiltering(userTypes))(items);
}

function onlyStudentItems(item: Learningitem) {
    return item.userType.some((type) => type === Learning.UserTypes.STUDENT || type === Learning.UserTypes.ALL);
}

function onlySecondaryItems(item: Learningitem) {
    return item.userType.some((type) => type === Learning.UserTypes.SECONDARY || type === Learning.UserTypes.ALL);
}

function onlyMainItems(item: Learningitem) {
    return item.userType.some((type) => type === Learning.UserTypes.MAIN || type === Learning.UserTypes.ALL);
}

function onlyVATLiableItems(item: Learningitem) {
    return item.VATType.some((type) => type === Learning.VATTypes.VATLiable || type === Learning.VATTypes.ALL);
}

function onlyVATExemptItems(item: Learningitem) {
    return item.VATType.some((type) => type === Learning.VATTypes[44] || type === Learning.VATTypes.ALL);
}

function onlyNonVATLIableItems(item: Learningitem) {
    return item.VATType.some((type) => type === Learning.VATTypes[56] || type === Learning.VATTypes.ALL);
}
