import { initializePaginationVars } from '@/store/utils';
import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import {
    IAccountingCreditnoteViewModel,
    ICreateAccountingCreditnoteData,
    ICreateHistoricalAccountingCreditnoteData
} from '@/services/accountingcreditnotes.types';
import { PaginationResponse } from '@/common/types';

const BASE_URL = generateEndpoint('/api/creditnotes/accounting');

export function getAccountingCreditnote(creditnoteID: number): Promise<IAccountingCreditnoteViewModel> {
    return axios.get(`${BASE_URL}/${creditnoteID}`).then((response) => response.data);
}

export function bookCreditnote(
    creditnote: ICreateAccountingCreditnoteData & { id: number }
): Promise<IAccountingCreditnoteViewModel> {
    return axios
        .post(`${BASE_URL}/book/${creditnote.id}`, {
            creditnote: JSON.stringify(creditnote)
        })
        .then((response) => response.data);
}

export function bookHistoricalCreditnote(
    creditnote: ICreateHistoricalAccountingCreditnoteData & { id: number }
): Promise<IAccountingCreditnoteViewModel> {
    return axios
        .post(`${BASE_URL}/book/historical/${creditnote.id}`, {
            creditnote: JSON.stringify(creditnote)
        })
        .then((response) => response.data);
}

export function getAccountingCreditnotesPagination(
    year: number,
    options: Record<string, any>
): Promise<PaginationResponse<IAccountingCreditnoteViewModel>> {
    return axios
        .get(`${BASE_URL}/year/${year}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}
