
import { defineComponent } from 'vue';
import { Paddings } from '@/common/types';

interface CardProps {
    wrapperClass: string;
    padding: Paddings;
}

export default defineComponent<CardProps>({
    name: 'Card',
    props: {
        wrapperClass: {
            type: String,
            default: ''
        },
        padding: {
            type: String,
            default: '24'
        }
    }
});
