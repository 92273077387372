import { isImpersonating, removeAllTokens } from '@/helpers/auth';
import { reportError } from '@/helpers/logging';
import store from '../../store';
import { routeRequiresAuth } from './helpers/index';

export async function redirectIfUserDidNotCompleteUserRegistration(to, from, next) {
    try {
        const requiresAuth = routeRequiresAuth(to);
        if (requiresAuth) {
            const user = store.state.auth.currentUserData;

            if (!isEmailadresVerified(user) && !(await isImpersonating()) && to.path != '/emailadres-verified') {
                return next({ name: 'emailadres-verified' });
            } else if (!isEmailadresVerified(user) && !(await isImpersonating()) && to.path == '/emailadres-verified') {
                return next();
            } else if (isEmailadresVerified(user) && to.path == '/emailadres-verified') {
                return next({ name: 'dashboard' });
            } else if (!hasCompletedUserRegistration(user) && !to.path.includes('complete-user-registration')) {
                return next({ name: 'completeUserRegistration' });
            }
        }

        return next();
    } catch (e) {
        reportError(e);
    } finally {
        store.dispatch('stopLoading');
    }

    removeAllTokens();
    return next({ name: 'login' });
}

function isEmailadresVerified(user) {
    return user.userinformation.verifiedEmailadres;
}

function hasCompletedUserRegistration(user) {
    return Array.isArray(user.userinformation.roles) && user.userinformation.roles.length !== 0;
}
