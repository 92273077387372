<template>
    <div class="mobile-header">
        <v-btn icon color="textGrey" @click="$parent.$emit('toggleSideNavigation')">
            <v-icon size="20" class="toggle-navigation-icon mobile">mdi-menu</v-icon>
        </v-btn>

        <router-link :to="{ name: 'dashboard' }" class="logo-link">
            <img class="dexxter-logo" alt="Dexxter" src="@/assets/img/DEXXTER-V1_PNG.png" />
        </router-link>

        <v-btn icon color="textGrey" @click="openMobileMenu">
            <v-icon size="20"> mdi-menu-open</v-icon>
        </v-btn>
    </div>
</template>

<script>
// --- State ---
import { mapActions } from 'vuex';

export default {
    name: 'MobileHeader',
    methods: {
        ...mapActions(['openMobileMenu'])
    }
};
</script>

<style lang="scss" scoped>
@import '../../sass/variables.scss';

.mobile-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and(min-width: $sm) {
        display: none;
    }

    .logo-link {
        display: flex;
        align-items: center;
        justify-content: left;

        .dexxter-logo {
            height: 28px;
        }
    }

    @media screen and (min-width: $sm) {
        .logo-link {
            display: none;
        }
    }
}
</style>
