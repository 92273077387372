<template>
    <base-bottom-sheet
        v-if="isEditNotificationOpen && currentNotification"
        v-model="isEditNotificationOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
        class="add-income-billed-sheet"
    >
        <template #content>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <h2>Edit</h2>
                    </v-col>
                </v-row>
            </v-container>
            <v-form ref="form" v-model="valid">
                <v-container>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-datetime-field
                                v-model="currentNotification.dateToPublish"
                                name="dateToPublish"
                                :label="$t('general.date')"
                                outlined
                                :rules="requiredRules"
                            ></v-datetime-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="currentNotification.segments"
                                :items="segments"
                                item-value="value"
                                item-text="label"
                                :label="$t('general.segments')"
                                outlined
                                multiple
                                clearable
                                persistent-hint
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="currentNotification.segmentOperation"
                                :items="operators"
                                item-value="value"
                                item-text="label"
                                :label="$t('general.operators')"
                                outlined
                                clearable
                                persistent-hint
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-select
                                v-model="currentNotification.language"
                                :items="languages"
                                item-value="value"
                                item-text="label"
                                :label="$t('general.language')"
                                disabled
                                outlined
                                clearable
                                persistent-hint
                                required
                            />
                        </v-col>
                        <v-col cols="12" md="12">
                            <text-field
                                v-model="currentNotification.title"
                                :label="$t('general.title')"
                                outlined
                                clearable
                                :hint="$t('notifications.title_of_notification')"
                                persistent-hint
                                required
                                type="text"
                            ></text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                            <text-area
                                v-model="currentNotification.content"
                                :label="$t('general.description')"
                                outlined
                                clearable
                                persistent-hint
                                required
                                auto-grow
                                :rules="minRules(3)"
                            ></text-area>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-btn
                                color="primary"
                                small
                                rounded
                                :disabled="!valid || checkValid()"
                                @click="handleSubmit"
                            >
                                {{ $t('notifications.save_notification') }}
                                <v-icon right dense dark>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>

            <loader v-if="loading"></loader>
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
import { mapFields } from 'vuex-map-fields';
// --- Actions ---
import { mapActions } from 'vuex';
// --- Components ---
import Loader from '../Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { segmentMixin } from '@/mixins/segmentMixin.js';
// --- helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';

import _ from 'lodash';

import vDatetimeField from '@/components/vDatetimeField.vue';
export default {
    components: {
        Loader,
        vDatetimeField
    },
    mixins: [formRulesMixin, segmentMixin],

    data() {
        return {
            valid: true,
            loading: false,
            currentLanguage: 'nl',
            currentTab: null,
            icons: ['mdi-alert', 'mdi-information'],
            languages: [
                {
                    label: 'nl',
                    value: 'nl'
                },
                {
                    label: 'fr',
                    value: 'fr'
                },
                {
                    label: 'en',
                    value: 'en'
                }
            ]
        };
    },

    computed: {
        ...mapFields('bottomSheets', ['isEditNotificationOpen', 'currentNotification', 'isCreatingNewNotification'])
    },
    watch: {
        isCreatingNewNotification(isCreatingNew) {
            if (isCreatingNew) {
                // If a new entry needs to be created, make sure the form is empty.
                this.resetForm();
            }
        }
    },
    methods: {
        ...mapActions('notifications', ['createNotification', 'editNotifications']),
        resetForm() {
            this.$refs.form.reset();
        },
        checkValid() {
            return !Object.keys(this.$i18n.messages).every(
                (language) =>
                    this.currentNotification.title[language] !== null &&
                    this.currentNotification.content[language] !== null
            );
        },
        async handleSubmit() {
            try {
                this.loading = true;

                const notification = _.cloneDeep(this.currentNotification);
                delete notification.ids;

                await this.editNotifications(notification);
                notify.call(this, {
                    title: this.$t('notifications.notification_succesfuly_edited'),
                    text: this.$t('notifications.notification_succesfuly_edited')
                });
                // Close bottom sheet when submit successful.
                this.isEditNotificationOpen = false;

                this.$store.dispatch('notifications/newNotificationEvent');
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
