<template>
    <div>
        <v-textarea :class="{ required }" v-bind="$attrs" hide-details="auto" outlined auto-grow v-on="$listeners">
            <template v-for="(index, name) in $scopedSlots" #[name]="data">
                <slot :name="name" v-bind="data"></slot>
            </template>
        </v-textarea>
    </div>
</template>

<script>
export default {
    name: 'TextArea',
    inheritAttrs: false,

    props: {
        required: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style lang="scss" scoped></style>
