import { reportError } from '@/helpers/logging';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { BILLING_PLAN_TYPE, VATType } from 'dexxter-types';
import isCi from 'is-ci';
import { UserTypes } from '../config/constants';
import { isImpersonating } from '../helpers/auth';
import { DeepNullable } from '../helpers/types';

let analyticsObj: AnalyticsBrowser;

const env_check = (): boolean => {
    return (
        !isCi &&
        process.env.VUE_APP_MODE !== 'test' &&
        (Boolean(process.env.VUE_APP_SEGMENT_WRITE_KEY) ||
            (process.env.VUE_APP_MODE === 'production' && isImpersonating() === false))
    );
};

if (env_check()) {
    analyticsObj = AnalyticsBrowser.load({ writeKey: process.env.VUE_APP_SEGMENT_WRITE_KEY as string });
}

export type UserProps = Partial<
    DeepNullable<{
        Has_Ever_Had_Paying_Account: boolean;
        Manual_Subscription_Ends_At: string;
        Plan_Type: BILLING_PLAN_TYPE | 'trial';
        Trial_Expires_At: string;
        Birth_Date: string;
        Company_Number: string;
        Completed_Registration: boolean;
        Dexxter_Start: string;
        email: string;
        Enterprise_City: string;
        Enterprise_House_Number: string;
        Enterprise_Postal_Code: string;
        Enterprise_Start: string;
        Enterprise_Street_Name: string;
        firstName: string;
        Knows_Us_From: string;
        lastName: string;
        phone: string;
        Promo_Code: string;
        Social_Insurance_Fund: string;
        User_Type: UserTypes;
        Vat_Type: VATType;
        Verified_Email: boolean;
    }>
>;

export const analyticsIdentify = (id: number, props: UserProps) => {
    if (!id) {
        reportError('Id not defined!');
        return;
    }

    if (env_check()) {
        analyticsObj.identify(id.toString(), props, {
            traits: props
        });
    }
};

export const analyticsLogout = () => {
    if (env_check()) analyticsObj.reset();
};

export const analyticsTrack = async (name: string, props?: Record<string, any> & UserProps) => {
    if (env_check()) analyticsObj.track(name, props);
};
