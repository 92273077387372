import { initializePaginationVars } from '@/store/utils';
import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import { filteringOptions, PaginationResponse } from '@/common/types';
import {
    companyLookupResponse,
    CreateCustomerData,
    ICustomerViewModel,
    UpdateCustomerData
} from '@/services/customer.types';

const BASE_URL = generateEndpoint('/api/customers');

export function sendCreateCustomerRequest(customer: CreateCustomerData): Promise<ICustomerViewModel> {
    return axios.post(`${BASE_URL}`, customer).then((response) => response.data);
}

export function sendDeleteCustomerRequest(customerId: number): Promise<void> {
    return axios.delete(`${BASE_URL}/${customerId}`).then((response) => response.data);
}

export function companyLookup(companyNumber: string): Promise<companyLookupResponse> {
    return axios.get(`${BASE_URL}/companyLookup`, { params: { companyNumber } }).then((response) => response.data);
}

export function sendGetAllCustomersPaginationRequest(
    options: filteringOptions
): Promise<PaginationResponse<ICustomerViewModel>> {
    return axios
        .get(`${BASE_URL}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function sendUpdateCustomerRequest(customer: UpdateCustomerData): Promise<ICustomerViewModel> {
    return axios.put(`${BASE_URL}/${customer.id}`, customer).then((response) => response.data);
}

export function sendGetCustomerRequest(customerId: number): Promise<ICustomerViewModel> {
    return axios.get(`${BASE_URL}/${customerId}`).then((response) => response.data);
}
