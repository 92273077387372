<template>
    <div class="tooltipWrapper">
        <template v-if="hasActivatorSlot">
            <slot name="activator" v-bind="{ on: openVideo }" />
        </template>
        <template v-else>
            <span class="activator" @click.stop="openVideo">
                <v-icon color="primary">mdi-youtube</v-icon> {{ $t(`${i18nPath}.activator`) }}
            </span>
        </template>

        <popup-video
            v-if="open"
            v-model="open"
            :title="$t(`${i18nPath}.title`)"
            :url="$t(`${i18nPath}.url`)"
            v-bind="{
                ...$props
            }"
        />
    </div>
</template>

<script>
import { screenSizeMixin } from '../mixins/screenSizeMixin';
import PopupVideo from './PopupVideo.vue';
export default {
    components: { PopupVideo },
    mixins: [screenSizeMixin],
    inheritAttrs: false,

    props: {
        i18nPath: {
            type: String
        }
    },

    data() {
        return {
            open: false
        };
    },

    computed: {
        hasActivatorSlot() {
            if (!this.$scopedSlots) {
                return false;
            }
            return !!this.$scopedSlots.activator;
        }
    },

    methods: {
        openVideo() {
            this.open = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.tooltipWrapper {
    display: inline-block;
}

.vCardHeader_Title {
    font-size: 1.2rem;
}

.Header {
    border: 0px;
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    border-radius: 0px !important;
}
.Footer {
    border: 0px;
    display: flex;
    align-items: center;
    padding-top: 10px;
    border-radius: 0px !important;
}

.TooltipContent {
    padding-bottom: 10px;
    overflow-y: auto;

    p {
        margin-bottom: 16px !important;
    }
}

.tooltip {
    padding: 20px !important;
}
.readmore {
    padding: 0 19.5555555556px;
}

.activator {
    cursor: pointer;
}

.underlined {
    text-decoration-line: underline;
    text-decoration-style: dotted;
    text-decoration-color: #2590fb;
    text-decoration-thickness: 2px;
}
</style>
