<template>
    <div>
        <v-list v-if="areThereItemsOnThisPage" class="learningItems">
            <template v-for="(item, index) in items">
                <component
                    :is="getLearningcenteritemComponent(item)"
                    :key="`div-${index}-component`"
                    :item="item"
                    @click="clickLearningAction"
                />
                <v-divider :key="`div-${index}-divider`" inset></v-divider>
            </template>
        </v-list>
        <div v-else class="pa-5 mt-6 text-center">
            {{ $t('learningcenter.no_items_yet') }}
        </div>
    </div>
</template>

<script>
// --- State ---
import { screenSizeMixin } from '../mixins/screenSizeMixin';

import { LearningBlog, LearningTextPopup, LearningVideo } from '@/models/learningitem';

import LearningcenterPopuptext from './LearningcenterPopuptext.vue';
import LearningcenterVideo from './LearningcenterVideo.vue';
import LearningcenterBlog from './LearningcenterBlog.vue';

export default {
    mixins: [screenSizeMixin],

    props: {
        items: {}
    },

    computed: {
        areThereItemsOnThisPage() {
            return this.items.length > 0;
        }
    },

    methods: {
        getLearningcenteritemComponent(item) {
            if (item instanceof LearningBlog) {
                return LearningcenterBlog;
            } else if (item instanceof LearningVideo) {
                return LearningcenterVideo;
            } else if (item instanceof LearningTextPopup) {
                return LearningcenterPopuptext;
            }

            throw new Error('cannot find learnincenteritem component');
        },
        clickLearningAction(learningItem) {
            return this.$emit('selected-item', learningItem);
        }
    }
};
</script>

<style lang="scss" scoped>
.learningItems {
    width: 95%;
}

.learningItems {
    .v-list-item__avatar {
        align-self: baseline;
    }

    .v-list-item__title,
    .v-list-item__subtitle {
        white-space: unset;
    }

    .v-list-item__subtitle {
        margin-top: 5px;
    }
}
</style>
