import { UserTypes } from '@/config/constants';
import {
    addImpersonateToken,
    isImpersonating,
    removeAllTokens,
    removeImpersonateToken,
    setToken
} from '@/helpers/auth';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { GetterTreeAdaptor } from '@/helpers/types';
import { mapDTOToUserBillinfo } from '@/modules/subscription/domain/mappers';
import {
    sendChangeCompanyColorRequest,
    sendChangeLogoRequest,
    sendChangePasswordRequest,
    sendDownloadUserDataRequest,
    sendForgotpasswordRequest,
    sendGetBillinginfoRequest,
    sendGetCurrentUserRequest,
    sendGetFeaturesRequest,
    sendGetPermissionsRequest,
    sendImpersonateRequest,
    sendLoginRequest,
    sendMigrateLoginRequest,
    sendResendVerifyMailRequest,
    sendResetpasswordRequest,
    sendSetIncomeCountersRequest,
    sendSetLanguageRequest,
    sendSetSocialSecretariatPercentageRequest,
    sendSetVatLiableRequest,
    sendSetbtwMedecontractantStatusRequest,
    sendSignupRequest,
    sendVerifyMailRequest
} from '@/services/auth';
import { sendGetAllIntegrationsRequest } from '@/services/integrations';
import {
    sendRemoveLogoRequest,
    sendUpdateDefaultPaymentDaysRequest,
    sendUpdateDefaultVATPercentageRequest
} from '@/services/usersettings';
import { registrationData } from '@/store/modules/auth.types';
import { AuthState, UserStates } from '@/store/types/auth';
import { Ability } from '@casl/ability';
import { IRegisteredUseraccountViewModel, IUseraccountViewModel } from 'dexxter-types';
import { isNil } from 'lodash';
import { ActionContext, Module } from 'vuex';
import { afterLogoutActions, logoutUsersFromDexxter } from '../../helpers/logoutUser';
import { RootState } from '../store.types';

export interface InitializedUseraccountAuthState extends AuthState {
    currentUserData: IUseraccountViewModel;
}

export interface InitializedUseraccountNotCompletedRegistrationAuthState extends AuthState {
    currentUserData: IRegisteredUseraccountViewModel;
}

export interface InitializedGeneralAuthState extends AuthState {
    currentUserData: UserStates;
}

function initialState(): AuthState {
    return {
        isLoggedIn: false,
        isUserImpersonating: false,
        currentUserData: null as null | IUseraccountViewModel,
        userBillinginfo: null,
        permissions: [],
        currentUserIntegrations: [],
        currentUserFeatures: [],
        currentUserType: null
    };
}

type Context = ActionContext<AuthState, RootState>;

export interface AuthGetters {
    ability: Ability;
    isUserType: (userType: UserTypes) => boolean;
    getCurrentUserInitials: string;
    getCurrentUserFullName: string;
}

const getters: GetterTreeAdaptor<AuthGetters, AuthState, RootState> = {
    ability(): Ability {
        return new Ability();
    },

    isUserType:
        (state: AuthState) =>
        (userType: UserTypes): boolean => {
            return state.currentUserType === userType;
        },

    getCurrentUserInitials(state: AuthState): string {
        let result = '';
        if (state.currentUserData) {
            result =
                state.currentUserData.userinformation.firstName[0].toUpperCase() +
                state.currentUserData.userinformation.name[0].toUpperCase();
        } else {
            result = '';
        }

        return result;
    },
    getCurrentUserFullName(state: AuthState): string {
        if (state.currentUserData && state.currentUserData.userinformation) {
            return `${state.currentUserData.userinformation.firstName} ${state.currentUserData.userinformation.name}`;
        } else {
            return '';
        }
    }
};

export default {
    namespaced: true,

    state: initialState(),

    getters,

    actions: {
        setUserImpersonating(context: Context, value: boolean) {
            context.state.isUserImpersonating = value;
        },
        setUserLoggedIn(context: Context, value: boolean) {
            context.state.isLoggedIn = value;
        },
        // [Begin: Authorisation actions]
        async getCurrentUserType(context: Context): Promise<void> {
            if (context.state.currentUserData?._type === 'completedRegistrationUserAccount') {
                context.commit('SET_CURRENT_USER_TYPE', context.state.currentUserData.userType);
            }
        },
        login(context: Context, { email, password }: { email: string; password: string }): Promise<boolean> {
            return sendLoginRequest({ email, password }).then((authData) => {
                if (authData.authenticated) {
                    removeAllTokens();
                    setToken({
                        access_token: authData.access_token,
                        expires_in: authData.expires_in,
                        refresh_token: authData.refresh_token
                    });
                }

                return authData.authenticated === false;
            });
        },
        changeCognitoPassword(context: Context, { email, password }: { email: string; password: string }) {
            return sendMigrateLoginRequest({ email, password });
        },
        async logout({ dispatch }: Context) {
            try {
                dispatch('startLoading', null, { root: true });

                const isUserImpersonating = isImpersonating();
                if (isUserImpersonating) {
                    removeImpersonateToken();
                } else {
                    await logoutUsersFromDexxter();
                }

                await afterLogoutActions({
                    impersonating: isUserImpersonating
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                dispatch('stopLoading', null, { root: true });
            }
        },
        impersonateUser(context: Context, uid: number) {
            return sendImpersonateRequest(uid).then((response) => {
                addImpersonateToken(response.token);
            });
        },
        createUser(context: Context, user: registrationData) {
            return sendSignupRequest(user);
        },
        getPermissions({ commit }: Context) {
            return sendGetPermissionsRequest().then((permissions) => {
                commit('SET_PERMISSIONS', permissions);
            });
        },
        async fetchCurrentUser(context: Context) {
            return sendGetCurrentUserRequest().then((userData) => {
                if (userData.userinformation.billinginfo) {
                    context.commit(
                        'SET_CURRENT_USER_BILLING_DATA',
                        mapDTOToUserBillinfo(userData.userinformation.billinginfo)
                    );
                }
                context.commit('SET_CURRENT_USER_DATA', userData);
                return userData;
            });
        },
        async getCurrentUser(context: Context) {
            // Return the db call so that it can be awaited.
            const [result] = await Promise.all([
                context.dispatch('fetchCurrentUser'),
                context.dispatch('fetchIntegrations'),
                context.dispatch('fetchUserFeatures')
            ]);

            return result;
        },
        fetchIntegrations({ commit }: Context) {
            return sendGetAllIntegrationsRequest().then((integrations) => {
                commit('SET_CURRENT_USER_INTEGRATIONS', integrations);
            });
        },
        fetchUserFeatures({ commit }: Context) {
            return sendGetFeaturesRequest().then((features) => {
                commit('SET_CURRENT_USER_FEATURES', features);
            });
        },
        getCurrentUserBillinginfo() {
            // Return the db call so that it can be awaited.
            return sendGetBillinginfoRequest();
        },
        unsetCurrentUser({ commit }: Context) {
            commit('SET_CURRENT_USER_DATA', null);
        },
        async sendForgotPasswordEmail(context: Context, { email }: any) {
            return sendForgotpasswordRequest(email);
        },
        resetPassword(
            context: Context,
            { resetPasswordToken, password }: { resetPasswordToken: string; password: string }
        ) {
            return sendResetpasswordRequest({ resetPasswordToken, password });
        },
        verifyEmailadres(context: Context, { token }: { token: string }) {
            return sendVerifyMailRequest(token);
        },
        resendVerificationemail() {
            return sendResendVerifyMailRequest();
        },
        setLanguage(context: Context, language: string) {
            return sendSetLanguageRequest(language);
        },
        downloadUserData() {
            return sendDownloadUserDataRequest();
        },
        setIncomeCounters(
            { rootState },
            counters: { invoiceCounter: number; quotationCounter: number; creditnoteCounter: number }
        ) {
            const year = rootState.selectedYear;
            if (isNil(year)) {
                throw new Error('No year defined');
            }
            return sendSetIncomeCountersRequest(year, counters);
        },
        async setDefaultPaymentDays(context: Context, defaultPaymentDays: number): Promise<void> {
            await sendUpdateDefaultPaymentDaysRequest(defaultPaymentDays);
            await context.dispatch('fetchCurrentUser');
        },
        async removeLogo(context: Context): Promise<void> {
            await sendRemoveLogoRequest();
            await context.dispatch('fetchCurrentUser');
        },
        async setDefaultVATPercentage(context: Context, defaultVATPercentage: number): Promise<void> {
            await sendUpdateDefaultVATPercentageRequest(defaultVATPercentage);
            await context.dispatch('fetchCurrentUser');
        },
        setSocialSecretariatPercentage(
            context: Context,
            socialSecretariatInformation: {
                socialsecretariatId: number;
            }
        ) {
            return sendSetSocialSecretariatPercentageRequest(socialSecretariatInformation);
        },
        changeBtwMedecontractant(context: Context, btwMedecontractant: boolean) {
            return sendSetbtwMedecontractantStatusRequest(btwMedecontractant);
        },
        changeVATLiable() {
            return sendSetVatLiableRequest();
        },
        async changePassword(
            context: Context,
            { oldPassword, newPassword }: { oldPassword: string; newPassword: string }
        ) {
            return sendChangePasswordRequest({ oldPassword, newPassword });
        },
        changeCompanyColor(context: Context, color: string) {
            return sendChangeCompanyColorRequest(color);
        },
        changeLogo(context: Context, { logo }: { logo: string }) {
            return sendChangeLogoRequest(logo);
        }
    },
    mutations: {
        SET_CURRENT_USER_TYPE(state: AuthState, type: UserTypes | null) {
            state.currentUserType = type;
        },
        SET_PERMISSIONS(state: AuthState, permissions: any) {
            state.permissions = permissions;
        },
        SET_CURRENT_USER_DATA(state: AuthState, userData: any) {
            state.currentUserData = userData;
        },
        SET_CURRENT_USER_INTEGRATIONS(state: AuthState, integrations: string[]) {
            state.currentUserIntegrations = integrations;
        },
        SET_CURRENT_USER_FEATURES(state: AuthState, featureGroups: string[]) {
            state.currentUserFeatures = featureGroups;
        },
        SET_CURRENT_USER_BILLING_DATA(state: AuthState, userBilling: any) {
            state.userBillinginfo = userBilling;
        },
        RESET_STATE(state: AuthState) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<AuthState, RootState>;
