import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import { accountantRoutes } from './accountantRoutes';
import { adminRoutes } from './adminRoutes';
import { authRoutes } from './authRoutes';
import { communityRoutes } from './communityRoutes';
import {
    errorTracking,
    fetchUserInfo,
    getAllGlobalSettings,
    getAllLanguagesGuard,
    getUserTaxInformation,
    loggedInGuard,
    permissionGuard,
    redirectIfAccessingUseraccountPageWithoutHavingUserAccount,
    redirectIfNonAdminuserVisitsAdminPage,
    redirectIfUserDidNotCompleteUserRegistration,
    redirectIfUserHasNoSubscription,
    redirectIfUserIsDisabled,
    redirectIfUserIsMigrated,
    redirectLoggedInUsersToDashboard,
    setLoginStatusGuard,
    setUserImpersonating,
    setUserLanguage,
    useraccountTracking
    // useraccountTracking
} from './guards/index';
import { notificationRoutes } from './notificationRoutes';
import { publicRoutes } from './publicRoutes';
import { settingRoutes } from './settingRoutes';
import { userRoutes } from './userRoutes';

// We dont want to use the custom vue router in our unit tests
if (process.env.NODE_ENV !== 'test') {
    Vue.use(VueRouter);
}

// We dont want to use the custom vue router in our unit tests
if (process.env.NODE_ENV !== 'test') {
    Vue.use(VueRouter);
}

const routes = [
    ...accountantRoutes,
    ...adminRoutes,
    ...authRoutes,
    ...communityRoutes,
    ...publicRoutes,
    ...userRoutes,
    ...notificationRoutes,
    ...settingRoutes,
    {
        path: '/404',
        name: '404',
        // route level code-splitting
        component: () => import('../views/NotFound.vue'),
        meta: {}
    },
    {
        path: '*',
        redirect: '/404'
    }
];

const router = new VueRouter({
    mode: 'history',
    hash: false,
    routes
});

// router.afterEach((to, from) => {
router.afterEach(() => {
    // Add css class to app to identify current route.
    // const vApp = document.querySelector('.v-application');
    // vApp.classList.remove(from.name);
    // vApp.classList.add(to.name);
    store.dispatch('stopLoading');
});

router.beforeEach(setLoginStatusGuard);
router.beforeEach(loggedInGuard);
router.beforeResolve(permissionGuard.bind(this, router));
router.beforeEach(redirectLoggedInUsersToDashboard);
router.beforeEach(getAllLanguagesGuard);
router.beforeEach(fetchUserInfo);
router.beforeEach(redirectIfUserIsDisabled);
router.beforeEach(redirectIfUserIsMigrated);
router.beforeEach(redirectIfUserDidNotCompleteUserRegistration);
router.beforeEach(errorTracking);
router.beforeEach(redirectIfAccessingUseraccountPageWithoutHavingUserAccount);
router.beforeEach(redirectIfUserHasNoSubscription);
router.beforeEach(useraccountTracking.bind(this, router));
router.beforeEach(getUserTaxInformation);
router.beforeEach(getAllGlobalSettings);
router.beforeEach(setUserLanguage);
router.beforeEach(setUserImpersonating);
router.beforeEach(redirectIfNonAdminuserVisitsAdminPage);

// router.afterEach(function clearnNotifications() {
//     router.app.$notify({
//         clean: true
//     });
// });

export default router;
