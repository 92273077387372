import Vue from 'vue';
import BaseBottomSheet from '@/components/BaseBottomSheet.vue';
import BaseIconRight from '@/components/BaseIconRight.vue';
import BaseSliderModal from '@/components/BaseSliderModal.vue';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog';
import DateField from '@/components/FormComponents/DateField.vue';
import NumberField from '@/components/FormComponents/NumberField.vue';
import SelectInput from '@/components/FormComponents/SelectInput.vue';
import TextArea from '@/components/FormComponents/TextArea.vue';
import TextField from '@/components/FormComponents/TextField.vue';
import Loader from '@/components/Loader';
import SubmitButton from '@/components/SubmitButton.vue';
import Tooltip from '@/components/Tooltip.vue';
import Widget from '@/components/Widget.vue';
import DxtButton from '@/components/Button/DxtButton';
import { AvatarSkeleton, TextSkeleton } from '@/components/skeleton';
import { Heading, KeyValuePair, Paragraph } from '@/components/typography';
import { Card, CardHeader } from '@/components/card';
import Sidebar from '@/components/sidebar/Sidebar.vue';
import Alert from '@/components/alert/Alert.vue';
import Tag from '@/components/tag/Tag.vue';

Vue.component('Widget', Widget);
Vue.component('ConfirmationDialog', ConfirmationDialog);
Vue.component('TextField', TextField);
Vue.component('NumberField', NumberField);
Vue.component('TextArea', TextArea);
Vue.component('DateField', DateField);
Vue.component('SelectInput', SelectInput);
Vue.component('SubmitButton', SubmitButton);
Vue.component('Tooltip', Tooltip);
Vue.component('BaseBottomSheet', BaseBottomSheet);
Vue.component('BaseIconRight', BaseIconRight);
Vue.component('BaseSliderModal', BaseSliderModal);

// Call to actions
Vue.component('DxtButton', DxtButton);

// Skeletons
Vue.component('AvatarSkeleton', AvatarSkeleton);
Vue.component('TextSkeleton', TextSkeleton);

// Typography
Vue.component('Heading', Heading);
Vue.component('Paragraph', Paragraph);
Vue.component('KeyValuePair', KeyValuePair);

// Containers
Vue.component('Card', Card);
Vue.component('CardHeader', CardHeader);
Vue.component('Sidebar', Sidebar);

// Information display
Vue.component('Alert', Alert);
Vue.component('Tag', Tag);
Vue.component('Loader', Loader);
