import store from '../../store';
import { routeRequiresAuth } from './helpers/index';

export async function loggedInGuard(to, from, next) {
    const requiresAuth = routeRequiresAuth(to);

    if (requiresAuth) {
        if (!store.state.auth.isLoggedIn) {
            next({ name: 'login', query: { redirect: to.fullPath } });
            return;
        }
    }

    next();
}
