/* eslint-disable no-unused-vars */

import { RootState } from '@/store/store.types';
import { NotificationsState } from '@/store/types/notifications';
import { Module } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import { reportError } from '../../helpers/logging';
import { sendGetAllNotificationbarsRequest, sendGetHasNewNotificationRequest } from '../../services/notifications';

function initialState(): NotificationsState {
    return {
        hasNewNotification: false,

        notificationEvents: [],

        notificationbars: []
    };
}
export default {
    namespaced: true,

    state: initialState(),

    getters: {
        getField
    },

    actions: {
        getHasNewNotification({ commit }) {
            return sendGetHasNewNotificationRequest().then((data) => {
                commit('SET_HAS_NEW_NOTIFICATION', data);
                return data;
            });
        },

        getNotificationbars({ commit }) {
            return sendGetAllNotificationbarsRequest()
                .then((data) => {
                    commit('SET_HAS_NOTIFICATIONBAR', data);
                    return data;
                })
                .catch((e) => {
                    reportError(e);
                });
        },

        // [End: Update data]
        async newNotificationEvent({ commit }) {
            commit('SET_NEW_NOTIFICATION_EVENT');
        }
    },

    mutations: {
        updateField,
        SET_HAS_NEW_NOTIFICATION(state, hasNewNotification) {
            state.hasNewNotification = hasNewNotification;
        },
        SET_HAS_NOTIFICATIONBAR(state, notificationbars) {
            state.notificationbars = notificationbars;
        },
        SET_NEW_NOTIFICATION_EVENT(state) {
            state.notificationEvents = state.notificationEvents.concat(1);
        },
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<NotificationsState, RootState>;
