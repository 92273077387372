import VueI18n from 'vue-i18n';

export const notify = successMessage;

export function successMessage(
    this: any,
    { title, text }: { title: VueI18n.TranslateResult; text: VueI18n.TranslateResult }
): void {
    this.$notify({
        title,
        text,
        duration: 8000,
        type: 'success'
    });
}
