// --- Modules ---
import { filteringOptions } from '@/common/types';
import { RootState } from '@/store/store.types';
import { UsersState } from '@/store/types/users';
import { Module } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import {
    sendGetAllUserPaginationRequest,
    sendGetNumberOfRegistrationsRequest,
    sendToggleDisableUserRequest,
    sendUpdateUserRequest
} from '../../services/users';

function initialState(): UsersState {
    return {
        selectedUsers: [] // Use array because of vuetify. (multi-select also possible)
    };
}
export default {
    namespaced: true,

    state: initialState(),

    getters: {
        getField
    },

    actions: {
        // [Begin: Update data]
        getAllUserPagination(context, options: filteringOptions) {
            return sendGetAllUserPaginationRequest(options);
        },
        getNumberOfRegistrations(
            context,
            {
                year,
                groupingMode,
                type
            }: {
                year: number;
                groupingMode: 'YEAR' | 'MONTH' | 'QUARTER';
                type: string;
            }
        ) {
            return sendGetNumberOfRegistrationsRequest({
                year,
                groupingMode,
                type
            });
        },
        updateUser({ rootState }, user) {
            const currentUser = rootState.auth.currentUserData;
            return sendUpdateUserRequest(currentUser!.id, user);
        },
        toggleDisableUser(
            context,
            {
                userId,
                disableReason
            }: {
                userId: number;
                disableReason: string;
            }
        ) {
            return sendToggleDisableUserRequest({
                userId,
                disableReason
            });
        }
    },

    mutations: {
        updateField,
        RESET_STATE(state) {
            // acquire initial state
            const s = initialState();
            Object.keys(s).forEach((key) => {
                (state as any)[key] = (s as any)[key];
            });
        }
    }
} as Module<UsersState, RootState>;
