<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290"
    >
        <template #activator="{ on, attrs }">
            <text-field
                :id="id"
                :value="dateTimeString"
                :name="name"
                :label="label"
                readonly
                :hide-details="hideDetails"
                :outlined="outlined"
                :dense="dense"
                v-bind="attrs"
                v-on="on"
            ></text-field>
        </template>
        <v-date-picker v-show="isDatePickerVisible" v-model="date" @input="showTimePicker"></v-date-picker>
        <v-time-picker
            v-show="isTimePickerVisible"
            v-model="time"
            :format="timeFormat"
            @click:hour="updateHour"
            @click:minute="updateMinute"
        ></v-time-picker>
    </v-menu>
</template>

<script>
// --- Modules ---
import dayjs from 'dayjs';

export default {
    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        value: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        name: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        hideDetails: {
            type: [String, Boolean],
            default: false
        },
        outlined: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        timeFormat: {
            type: String,
            default: '24hr'
        },
        rules: {
            type: Array,
            default: null
        }
    },

    data() {
        return {
            menu: false,
            date: dayjs().format('YYYY-MM-DD'),
            time: '00:00',
            isDatePickerVisible: true,
            isTimePickerVisible: false
        };
    },

    computed: {
        dateTimeString() {
            if (this.value) {
                return dayjs(this.value).format('DD/MM/YYYY HH:mm');
            } else {
                return 'dd/mm/jjjj --:--';
            }
        }
    },

    watch: {
        value(newValue) {
            const dateTime = dayjs(newValue);
            this.date = dateTime.format('YYYY-MM-DD');
            this.time = dateTime.format('HH:mm');
        }
    },

    methods: {
        showTimePicker() {
            this.isDatePickerVisible = false;
            this.isTimePickerVisible = true;
            this.$emit('input', `${this.date}T${this.time}`);
        },
        updateHour(hour) {
            this.$emit('input', `${this.date}T${hour}:${this.time.split(':')[1]}`);
        },
        updateMinute(minute) {
            this.$emit('input', `${this.date}T${this.time.split(':')[0]}:${minute < 10 ? `0${minute}` : minute}`);
            this.close();
        },
        close() {
            this.menu = false;
            this.isDatePickerVisible = true;
            this.isTimePickerVisible = false;
        }
    }
};
</script>
