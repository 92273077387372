
import { Learning, LearningCenterType } from '@/config/constants';
import { getAllLearningcenterItems, getItemsForLocation, searchItem } from '@/helpers/learningcenterItems';
import { MapStateToComputed } from '@/helpers/types';
import { LearningBlog, LearningTextPopup, LearningVideo } from '@/models/learningitem';
import { sendGetLearningcenteritemByLanguageRequest } from '@/services/learningcenter';
import { InitializedUseraccountAuthState } from '@/store/modules/auth';
import { UserBookYearType } from '@dexxter/accountancy-tools';
import { debounce, isEmpty } from 'lodash';
import { mapState as mapStatePinia } from 'pinia';
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import { screenSizeMixin } from '../mixins/screenSizeMixin';
import { useTaxStore } from '../store/pinia/tax/tax.store';
import LearningcenterItems from './LearningcenterItems.vue';

export type LearningcenterItem = {
    translations: { id: number; title: string; description: string; url: string; tags: string }[];
    usertypes: { name: string }[];
    VATtypes: { name: string }[];
    locations: { name: string }[];
    type: LearningCenterType;
};

export default Vue.extend({
    components: {
        LearningcenterItems
    },
    mixins: [screenSizeMixin],

    props: {
        value: {
            type: Boolean
        },
        showAllItems: {
            type: Boolean
        }
    },
    data() {
        return {
            learningcenteritems: [] as any[],
            searchTerm: '' as string
        };
    },
    computed: {
        ...(mapState(['selectedYear']) as MapStateToComputed<{ selectedYear: number }>),
        ...mapStatePinia(useTaxStore, ['tax']),
        ...(mapState('auth', [
            'currentUserData',
            'currentUserType'
        ]) as MapStateToComputed<InitializedUseraccountAuthState>),

        items(): any[] {
            if (this.currentUserType === null) {
                return [];
            }

            const searchTerm = this.searchTerm;
            const routePath = this.$route.path;

            let items;
            if (this.showAllItems) {
                items = getAllLearningcenterItems(
                    this.learningcenteritems,
                    !this.tax[this.selectedYear as number]?.statuutQ1
                        ? [this.currentUserData.userType]
                        : [
                              this.tax[this.selectedYear as number]?.statuutQ1 as UserBookYearType,
                              this.tax[this.selectedYear as number]?.statuutQ2 as UserBookYearType,
                              this.tax[this.selectedYear as number]?.statuutQ3 as UserBookYearType,
                              this.tax[this.selectedYear as number]?.statuutQ4 as UserBookYearType
                          ],
                    this.currentUserData.settings.vatLiable
                );
            } else if (isEmpty(searchTerm)) {
                items = getItemsForLocation(
                    this.learningcenteritems,
                    routePath as string,
                    !this.tax[this.selectedYear as number]?.statuutQ1
                        ? [this.currentUserData.userType]
                        : [
                              this.tax[this.selectedYear as number]?.statuutQ1 as UserBookYearType,
                              this.tax[this.selectedYear as number]?.statuutQ2 as UserBookYearType,
                              this.tax[this.selectedYear as number]?.statuutQ3 as UserBookYearType,
                              this.tax[this.selectedYear as number]?.statuutQ4 as UserBookYearType
                          ],
                    this.currentUserData.settings.vatLiable
                );
                this.$emit('total-number-of-items', items.length);
            } else {
                items = searchItem(
                    this.learningcenteritems,
                    searchTerm,
                    !this.tax[this.selectedYear as number]?.statuutQ1
                        ? [this.currentUserData.userType]
                        : [
                              this.tax[this.selectedYear as number]?.statuutQ1 as UserBookYearType,
                              this.tax[this.selectedYear as number]?.statuutQ2 as UserBookYearType,
                              this.tax[this.selectedYear as number]?.statuutQ3 as UserBookYearType,
                              this.tax[this.selectedYear as number]?.statuutQ4 as UserBookYearType
                          ],
                    this.currentUserData.settings.vatLiable
                );
            }

            return items;
        },
        drawer: {
            get(): boolean {
                return this.value;
            },
            set(value: boolean): void {
                this.$emit('input', value);
            }
        }
    },
    watch: {
        drawer(value) {
            if (value === false) {
                this.$emit('update:showAllItems', value);
            }
        },
        $router() {
            this.resetSearch();
        }
    },
    async created() {
        this.getCurrentUserType();
        this.learningcenteritems = (
            await sendGetLearningcenteritemByLanguageRequest(this.currentUserData.userinformation.language)
        ).map(this.mapToDomain);
    },
    methods: {
        ...mapActions('auth', ['getCurrentUserType']),
        resetSearch() {
            this.searchTerm = '';
        },
        trackSearch: debounce(function (this: Vue, searchTerm: string | null | undefined) {
            if (searchTerm && typeof searchTerm === 'string' && searchTerm.length > 3) {
                // track('search-learningcenter', {
                //     searchTerm
                // });
            }
        }, 500),
        mapToDomain(learningcenteritem: LearningcenterItem): any {
            // translations[0] because we ask for learningcenteritems of a specific language. This gives back only a translation array with 1 element (the language asked for)
            if (learningcenteritem.type === Learning.Types.BLOG) {
                return new LearningBlog(
                    learningcenteritem.translations[0].id,
                    learningcenteritem.translations[0].title,
                    learningcenteritem.translations[0].description,
                    learningcenteritem.translations[0].tags.split(' '),
                    learningcenteritem.usertypes.map((type) => type.name),
                    learningcenteritem.VATtypes.map((type) => type.name),
                    learningcenteritem.locations.map((type) => type.name),
                    learningcenteritem.translations[0].url
                );
            } else if (learningcenteritem.type === Learning.Types.VIDEO) {
                return new LearningVideo(
                    learningcenteritem.translations[0].id,
                    learningcenteritem.translations[0].title,
                    learningcenteritem.translations[0].description,
                    learningcenteritem.translations[0].tags.split(' '),
                    learningcenteritem.usertypes.map((type) => type.name),
                    learningcenteritem.VATtypes.map((type) => type.name),
                    learningcenteritem.locations.map((type) => type.name),
                    learningcenteritem.translations[0].url
                );
            } else if (learningcenteritem.type === Learning.Types.POPUPTEXT) {
                return new LearningTextPopup(
                    learningcenteritem.translations[0].id,
                    learningcenteritem.translations[0].title,
                    learningcenteritem.translations[0].description,
                    learningcenteritem.translations[0].tags.split(' '),
                    learningcenteritem.usertypes.map((type) => type.name),
                    learningcenteritem.VATtypes.map((type) => type.name),
                    learningcenteritem.locations.map((type) => type.name),
                    learningcenteritem.translations[0].url
                );
            }
        },
        clickLearningAction(learningItem: LearningcenterItem): void {
            this.$emit('selected-item', learningItem);
        }
    }
});
