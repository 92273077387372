import { getGlobalAppSettingsRequest } from '@/services/getGlobalAppSettings';
import { sendGetNumberOfTaxesYearsRequest } from '@/services/tax';
import modal from '@/store/modules/modal';
import { RootState } from '@/store/store.types';
import axios from 'axios';
import Vue from 'vue';
import Vuex, { Commit, StoreOptions } from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
import { BASE_URL_GLOBALSETTINGS } from '../services/globalsettings';
import accountant from './modules/accountant';
import accountantEmailBCC from './modules/accountantEmailBCC';
import auth from './modules/auth';
import bookings from './modules/bookings';
import bottomSheets from './modules/bottomSheets';
import customers from './modules/customers';
import depreciationfiches from './modules/depreciationfiches';
import expenseCategories from './modules/expenseCategories';
import expensefiles from './modules/expensefiles';
import expenses from './modules/expenses';
import incomes from './modules/incomes';
import informationpopup from './modules/informationpopup';
import invoiceitems from './modules/invoiceitem';
import notifications from './modules/notifications';
import projects from './modules/project';
import taxes from './modules/taxes';
import timeregistrations from './modules/timeRegistrations';
import units from './modules/units';
import users from './modules/users';
import vatDeclaration from './modules/vatDeclaration';
import zippercentage from './modules/zipcode';
import abilityPlugin from './plugins/ability';

Vue.use(Vuex);

const MODULES = {
    auth,
    bookings,
    zippercentage,
    accountantEmailBCC,
    bottomSheets,
    customers,
    depreciationfiches,
    expenseCategories,
    expenses,
    expensefiles,
    informationpopup,
    incomes,
    invoiceitems,
    notifications,
    projects,
    taxes,
    timeregistrations,
    units,
    users,
    vatDeclaration,
    accountant,
    modal
};

export const storeConfig: StoreOptions<RootState> = {
    modules: MODULES,

    plugins: [abilityPlugin],

    /**
     * Make state a function for using multiple stores in the tests: https://vuex.vuejs.org/guide/modules.html#module-reuse
     */
    state() {
        return {
            globalAppSettings: null,
            globalSettingsSelfEmployed: null,
            globalSettingsStudent: null,
            globalSettingsAdmin: null,
            globalSettingsMain: null,
            selectedYear: localStorage.getItem('selectedYear')
                ? parseInt(localStorage.getItem('selectedYear') as string)
                : null,
            loading: false,
            languages: [],
            learningModuleStatus: false,
            serverInfo: null,
            supportDialogOpen: false,
            isMobileNavigationOpen: false,
            isChangeBookyearModalOpen: false,
            canShowAllLearningcenterItems: false,
            globalSettingsYears: []
        } as any;
    },

    getters: {
        getField,
        globalSettingsLoaded(state) {
            return Boolean(
                state.globalSettingsAdmin &&
                    state.globalSettingsMain &&
                    state.globalSettingsStudent &&
                    state.globalSettingsSelfEmployed &&
                    state.globalAppSettings
            );
        }
    },

    actions: {
        openSupportDialog({ commit }) {
            commit('OPEN_SUPPORT_DIALOG');
        },
        openMobileMenu({ commit }) {
            commit('OPEN_MOBILEMENU');
        },
        toggleLearningcenter({ commit, state }) {
            commit('SET_LEARNING_CENTER_MODAL', !state.learningModuleStatus);
        },
        openLearningcenterWithAllItems({ commit, state }) {
            commit('SET_LEARNING_CENTER_MODAL', true);
            commit('SET_LEARNING_CENTER_VIEW_ALL_ITEMS', true);
        },
        startLoading({ commit }) {
            commit('START_LOADING');
        },
        setServerInfo({ commit }, serverinfo) {
            commit('SET_SERVER_INFO', serverinfo);
        },
        stopLoading({ commit }) {
            commit('STOP_LOADING');
        },
        setSelectedYear({ commit }, year) {
            commit('SET_SELECTED_YEAR', year);
        },
        setLanguages({ commit }, languages) {
            commit('SET_LANGUAGES', languages);
        },
        getAllGlobalSettings({ dispatch }, year) {
            return Promise.all([
                dispatch('getGlobalAppSettings'),
                dispatch('getGlobalSettingsStudent', year),
                dispatch('getGlobalSettingsMain', year),
                dispatch('getGlobalSettingsSecondary', year),
                dispatch('getGlobalSettingsAdmin', year)
            ]).catch((e) => {
                // eslint-disable-next-line no-console
                console.error(e);
            });
        },

        getGlobalAppSettings({ commit }) {
            return getGlobalAppSettingsRequest().then((response) => {
                commit('SET_GLOBALSETTINGS_APP', response);
            });
        },

        getGlobalSettingsStudent({ commit }, year) {
            return getGlobalSettingsFrom(commit, 'students', 'STUDENT', year);
        },

        getGlobalSettingsMain({ commit }, year) {
            return getGlobalSettingsFrom(commit, 'mains', 'MAIN', year);
        },

        getGlobalSettingsSecondary({ commit }, year) {
            return getGlobalSettingsFrom(commit, 'secondaries', 'SELFEMPLOYED', year);
        },

        getGlobalSettingsAdmin({ commit }, year) {
            return getGlobalSettingsFrom(commit, 'admins', 'ADMIN', year);
        },

        getNumberOfTaxesYears({ commit }) {
            return sendGetNumberOfTaxesYearsRequest().then((response) => {
                commit('SET_YEARS', response);
                return response;
            });
        },
        clearAll({ commit }, doNotClearAuth = false) {
            const clearableModules = MODULES;

            Object.keys(clearableModules).forEach((module) => {
                if (doNotClearAuth === true && module === 'auth') {
                    return;
                }
                commit(`${module}/RESET_STATE`);
            });
        }
    },

    mutations: {
        updateField,
        OPEN_SUPPORT_DIALOG(state) {
            state.supportDialogOpen = true;
        },
        OPEN_MOBILEMENU(state) {
            state.isMobileNavigationOpen = true;
        },
        SET_LEARNING_CENTER_MODAL(state, value) {
            state.learningModuleStatus = value;
        },
        SET_LEARNING_CENTER_VIEW_ALL_ITEMS(state, value) {
            state.canShowAllLearningcenterItems = value;
        },
        SET_GLOBALSETTINGS_APP(state, settings) {
            state.globalAppSettings = settings;
        },
        SET_GLOBALSETTINGS_SELFEMPLOYED(state, settings) {
            state.globalSettingsSelfEmployed = settings;
        },
        SET_GLOBALSETTINGS_STUDENT(state, settings) {
            state.globalSettingsStudent = settings;
        },
        SET_GLOBALSETTINGS_ADMIN(state, settings) {
            state.globalSettingsAdmin = settings;
        },
        SET_GLOBALSETTINGS_MAIN(state, settings) {
            state.globalSettingsMain = settings;
        },
        SET_SELECTED_YEAR(state, year) {
            localStorage.setItem('selectedYear', year);
            state.selectedYear = parseInt(year);
        },
        SET_LANGUAGES(state, languages) {
            state.languages = languages;
        },
        START_LOADING(state) {
            state.loading = true;
        },
        STOP_LOADING(state) {
            state.loading = false;
        },
        SET_SERVER_INFO(state, info) {
            state.serverInfo = info;
        },
        SET_YEARS(state, value) {
            state.globalSettingsYears = value;
        }
    }
};

function getGlobalSettingsFrom(commit: Commit, role: string, roleState: string, year: number) {
    return axios
        .get(`${BASE_URL_GLOBALSETTINGS}/${role}/${year}`)
        .then((response) => response.data)
        .then((response) => {
            commit(`SET_GLOBALSETTINGS_${roleState}`, response);
        });
}
