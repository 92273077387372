import { RouteConfig } from 'vue-router';
import { USER_ACCESS_OPERATIONS } from '../../config/constants';

export const carRoutes: RouteConfig[] = [
    {
        path: 'cars',
        name: 'get-all-cars',
        component: () => import('@/views/UserApp/taxes/cars/getAll/GetAll.vue'),
        props: true,
        meta: {
            title: 'pageTitles.cars',

            action: USER_ACCESS_OPERATIONS.READ,
            subject: 'Car'
        }
    }
];
